import { useQuery } from '@tanstack/react-query';
import { getAllPayslips } from 'network/Human-resources/payslips';
import { queryKeys } from 'services/react-query/queryKeys';

function usePaySlipList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.paySlipList, searchQueryStr], () => getAllPayslips(searchQueryStr));

  const paySlipList = data?.model;

  return {
    paySlipList,
    paySlipListLod: isLoading,
  };
}

export default usePaySlipList;

export const queryKeys = {
  metadata: "metadata",
  addressList: "address-list",
  calendarList: "calendar-list",
  periodList: "period-list",
  bankList: "bank-list",
  taxList: "tax-list",
  supplierList: "supplier-list",
  BankStatementsList: "bank-statemnt-list",
  bankTransferList: "bank-فransfe-list",
  currencyList: "currency-list",
  structureList: "structure-list",
  budgetList: "budget-list",
  creditInvoiceList: "credit-invoice-list",
  creditInvoice: "credit-invoice",
  customerList: "customer-list",
  receiptList: "receipt-list",
  ARSystemOptions: "ARSystemOptions",
  paymentTermsList: "payment-terms",
  salesOrdersList: "sales-order-list",
  dailyRateList: "daily-rate-list",
  budgetDividedPeriods: "budget-divided-periods",
  accountList: "account-list",
  accountPeriodList: "account-period-list",
  journalManualList: "journal-manual-list",
  journalRecurringList: "journal-recurring-list",
  GLStructureList: "general-leadger-structure-list",
  GLSegmentList: "general-leadger-segment-list",
  GLValueSetList: "general-leadger-value-set-list",
  GLValueList: "general-leadger-value-list",
  fixedAssetList: "fixed-asset-list",
  fixedAssetStructureList: "fixed-asset-structure-list",
  fixedAssetSegmentList: "fixed-asset-segment-list",
  fixedAssetValueSetList: "fixed-asset-value-set-list",
  fixedAssetValueList: "fixed-asset-value-list",
  APDistributionList: "AP-Distribution-list",
  APInvoicesList: "AP-Invoices-list",
  APSpecialCalenderList: "AP-Special-Calender-list",
  warehouse: "warehouse",
  warehouseLocation: "warehouse-location",
  APRecurringInvoiceList: "AP-Recurring-Invoice-list",
  operationTypes: "operation-types",
  inventoryConfiguration: "inventory-configuration",
  categories: "categories",
  reordering: "reordering",
  products: "products",
  salesTeam: "salesTeam",
  salesTeamLeads: "salesTeamLeads",
  salesMembers: "salesMembers",
  vendorPriceList: "vendorPriceList",
  purchasingRequest: "purchasingRequest",
  purchasingOrder: "purchasingOrder",
  projects: "projects",
  tasks: "tasks",
  subTasks: "sub-tasks",
  unitsOfMeasurements: "units-of-measurements",
  rates: "rates",
  expenditureTypes: "expenditure-types",
  departments: "departments",
  workTimes: "work-times",
  workLocations: "work-Locations",
  jobApplication: "job-application",
  employees: "employees",
  employee: "employee",
  appraislList: "appraislList",
  contractList: "contract-list",
  expenses: "expenses",
  expensesCategory: "expenses-category",
  timeOffList: "time-off-list",
  salaryStructureTypeList: "salary-structure-type-list",
  salaryStructureType: "salary-structure-type",
  salaryStructureList: "salary-structure-list",
  salaryStructure: "salary-structure",
  salaryRuleList: "salary-rule-list",
  salaryRule: "salary-rule",
  workEntryTypeList: "work-entry-type-list",
  workEntryType: "work-entry-type",
  workEntryList: "work-entry-list",
  workEntry: "work-entry",
  appraisalSkillList: "appraisal-skill-list",
  appraisalQuestionList: "appraisal-question-list",
  appraisalQuestion: "appraisal-question",
  paySlipList: "pay-slip-list",
  employeeGoalList: "employee-goal-list",
  resources: "resources",
};

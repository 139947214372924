import { App, Button, Checkbox, Form, Input, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import constents from 'utils/constants';
import { createSalaryStructure, getSalaryStructure, updateSalaryStructure } from 'network/Human-resources/salary-structure';
import useSalaryStructureTypeList from 'hooks/api/useSalaryStructureTypeList';
import useSalaryRuleList from 'hooks/api/useSalaryRuleList';
import useWorkEntryTypeList from 'hooks/api/useWorkEntryTypeList';

function StructureNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();
  const { salaryStructureTypeList, salaryStructureTypeListLod } = useSalaryStructureTypeList();
  const { salaryRuleList, salaryRuleListLod } = useSalaryRuleList();
  const { workEntryTypeList, workEntryTypeListLod } = useWorkEntryTypeList();

  const client = useQueryClient();
  const { data: salaryStructure } = useQuery([queryKeys.salaryStructure, params?.id], () => getSalaryStructure(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('salaryStructure', salaryStructure);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.salaryStructureList]);

    form.resetFields();
    navigate('/human-resources/setup/salary/structure');
  };

  const { mutate: onCreateStructure, isLoading: onCreateStructureLod } = useMutation(createSalaryStructure, {
    onSuccess,
  });
  const { mutate: onUpdateStructure, isLoading: onUpdateStructureLod } = useMutation(updateSalaryStructure, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && salaryStructure) {
      const editItem = salaryStructure?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['startDate']), dayjs(dataObj['endDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, salaryStructure, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.useWorkedDayLines = !!dataObj.useWorkedDayLines;
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = salaryStructure?.model?.id;
      return onUpdateStructure(dataObj);
    }

    onCreateStructure(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Setup' }, { title: <Link to="/human-resources/setup/salary/structure">Structures</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="nameEN" label="Name English" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Name Arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="payslipName" label="Pay Slip Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="structureTypeId" label="Structure Type" rules={[{ required: true }]}>
              <Select
                loading={salaryStructureTypeListLod}
                options={salaryStructureTypeList?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="salaryRules" label="Salary Rules" rules={[{ required: true }]}>
              <Select
                loading={salaryRuleListLod}
                options={salaryRuleList?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
                mode="multiple"
              />
            </Form.Item>
            <Form.Item name="workEntryTypes" label="Work Entry Types" rules={[{ required: true }]}>
              <Select
                loading={workEntryTypeListLod}
                options={workEntryTypeList?.map(e => ({
                  label: e?.payrollCode,
                  value: e?.id,
                }))}
                mode="multiple"
              />
            </Form.Item>
            <Form.Item name="useWorkedDayLines" label=" " valuePropName="checked">
              <Checkbox>Use Worked Day Lines</Checkbox>
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateStructureLod || onUpdateStructureLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default StructureNew;

import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, message, Select, Spin } from "antd";
import OwnModal from "components/own/OwnModal";
import dayjs from "dayjs";
import {
  useMutateProject,
  useProject,
} from "hooks/api/projects-management/projects";
import { useEmployees } from "hooks/human-resourses/useEmployees";
import useModalParams from "hooks/useModalParams";

export const ModalMutateProject = () => {
  const { isNew, isEdit, close, id } = useModalParams("projects");
  const { mutate, isLoading } = useMutateProject();
  const { initialValues, isFetching } = useProject(id);
  const { employees } = useEmployees();

  console.log(employees, "employees");

  const onFinish = (values) => {
    const body = {
      name: values?.name,
      number: values?.number,
      startDate: values?.date?.[0]
        ? dayjs(values?.date?.[0]).format("YYYY-MM-DD")
        : null,
      finishDate: values?.date?.[1]
        ? dayjs(values?.date?.[1]).format("YYYY-MM-DD")
        : null,
      projectManagerId: values?.projectManagerId,
      id: isEdit ? id : null,
    };

    mutate(body, {
      onSuccess: () => {
        message.success(
          `Project ${isNew ? "created" : "updated"}  successfully`,
        );
        close("id");
      },
      onError: (error) => message.error(`Error: ${error?.message}`),
    });
  };

  return (
    <OwnModal
      title={isNew ? "Creating a New Project" : "Edit Project"}
      open={isNew || isEdit}
      onCancel={() => close("id")}
      width={600}
    >
      {isFetching
        ? (
          <div className="flex flex-col gap-5 items-center justify-center py-36">
            <Spin />
            <p>Fetching Project Data</p>
          </div>
        )
        : (
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={isEdit ? initialValues : null}
          >
            <Form.Item
              label="Project Name"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Project Number"
              name="number"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Project Planned Duration"
              name="date"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker format="YYYY-MM-DD" />
            </Form.Item>

            <Form.Item
              label="Project Manager"
              name="projectManagerId"
              rules={[{ required: true }]}
            >
              <Select
                options={employees?.map((employee) => (
                  {
                    label: employee?.name,
                    value: employee?.id,
                  }
                ))}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                block
              >
                {isNew ? "Create" : "Edit"}
              </Button>
            </Form.Item>
          </Form>
        )}
    </OwnModal>
  );
};

export const ModalDeleteProject = () => {
  const { isDelete, close, id } = useModalParams("projects");
  const { mutate, isLoading } = useMutateProject();

  const deleteProject = () => {
    mutate(id, {
      onSuccess: () => {
        message.success("Project deleted");
        close("id");
      },
      onError: (error) => {
        message.error(`Error deleting the project: ${error.message}`);
      },
    });
  };

  return (
    <OwnModal
      title="Delete Project"
      open={isDelete}
      onCancel={() => close("id")}
      width={700}
      centered
    >
      <div className="flex flex-col items-center gap-5">
        <CloseCircleOutlined className="text-color-danger text-[86px]" />
        <p className="font-bold">
          Are you sure you want to delete this project?
        </p>
        <div className="flex items-center gap-3">
          <Button
            onClick={deleteProject}
            size="large"
            className="w-[150px]"
            loading={isLoading}
            disabled={isLoading}
            danger
          >
            Yes
          </Button>
          <Button
            disabled={isLoading}
            size="large"
            className="w-[150px]"
            onClick={() => close("id")}
          >
            No
          </Button>
        </div>
      </div>
    </OwnModal>
  );
};

import { Button, Checkbox, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import ModalBankTransfer from "components/modals/ModalBankTransfer";
import ModalCashStatmenet from "components/modals/ModalCashStatmenet";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import useSearchQuery from "hooks/useSearchQuery";
import React, { useState } from "react";
import { queryKeys } from "services/react-query/queryKeys";
import { arrayFrom } from "utils/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllBankTransfer } from "network/cash-managment/bankTransfer";
import dayjs from "dayjs";
import constents from "utils/constants";

function BankTransfer() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();

  const { data: bankTransferList } = useQuery(
    [queryKeys.bankTransferList, searchQueryStr],
    () => getAllBankTransfer(searchQueryStr)
  );

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: "From Account",
      dataIndex: "fromBankAccount",
      key: "fromBankAccount",
      render: (_, record) => <p>{record?.fromBankAccount?.accountNumber}</p>,
    },
    {
      title: "From Bank",
      dataIndex: "FromBank",
      key: "FromBank",
      render: (_, record) => <p>{record?.fromBankAccount?.bankName}</p>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "To Account",
      dataIndex: "toBankAccount",
      key: "toBankAccount",
      render: (_, record) => <p>{record?.toBankAccount?.accountNumber}</p>,
    },
    {
      title: "To Bank",
      dataIndex: "ToBank",
      key: "ToBank",
      render: (_, record) => <p>{record?.toBankAccount?.bankName}</p>,
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => (
        <p>{dayjs(record?.creationDate).format(constents.dateFormatHM)}</p>
      ),
    },
  ];

  const data = arrayFrom(12)?.map((_, i) => ({
    key: i,
    FromAccount: "12345678",
    ToAccount: "10000000000",
    FromBank: "CIB",
    ToBank: "ABD",
    Amount: "654,221,33",
    date: "12/11/2023",
  }));

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Bank Transfer" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table
          sticky
          columns={columns}
          dataSource={bankTransferList?.model || []}
        />
      </div>
      <ModalBankTransfer
        open={iseModalUpdat}
        onCancel={() => setIseModalUpdat(false)}
      />
    </>
  );
}

export default BankTransfer;

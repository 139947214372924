import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllPeriods = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Periods?${searchQuery}`);
  return data;
});

export const getPeriod = catchAsync(async id => {
  const { data } = await axiosInstance(`/Periods/${id}`);
  return data;
});

export const createPeriod = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Periods`, body);
  return data;
});

export const updatePeriod = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Periods`, body);
  return data;
});

export const deletePeriod = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Periods/${id}`);
  return data;
});

export const setPeriodStatus = catchAsync(async (periodId, statusId) => {
  const { data } = await axiosInstance(`/Periods/OpenClose/${periodId}/${statusId}`);
  return data;
});

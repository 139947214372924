import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Table,
  Tag,
  Upload,
} from "antd";
import OwnModal from "components/own/OwnModal";
import { useState } from "react";
import ReturnModal from "./ReturnModal";

function ReceiptModal({ onCancel, ...props }) {
  const [isReturnModalOpen, setReturnModalOpen] = useState(false);
  const [receiptMode, setReceiptMode] = useState("new");
  const [form] = Form.useForm();
  return (
    <>
      <OwnModal
        {...props}
        title="Receipt"
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
      >
        <div className="">
          <Tag
            style={{
              padding: "15px 50px",
              fontWeight: "600",
              fontSize: "18px",
            }}
            color={receiptMode === "new"
              ? "green"
              : receiptMode === "draft"
              ? "gray"
              : "red"}
          >
            {receiptMode.toUpperCase()}
          </Tag>
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={console.log}
          initialValues={{
            products: [""],
          }}
        >
          <Divider>Receipts Information</Divider>
          <div className="col-2">
            <Form.Item
              label="Receive From"
              name="receiveFrom"
              rules={[{ required: true }]}
              required
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="label"
                options={[
                  {
                    label: "levelup",
                    value: 1,
                  },
                  {
                    label: "levelup-2",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Operation Type"
              name="operationType"
              rules={[{ required: true }]}
              required
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="label"
                options={[
                  {
                    label: "levelup:operation",
                    value: 1,
                  },
                  {
                    label: "levelup-2:operation",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Destination Location"
              name="destinationLocation"
              rules={[{ required: true }]}
              required
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="label"
                options={[
                  {
                    label: "WH/Stock",
                    value: 1,
                  },
                  {
                    label: "WH2/Stock",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Scheduled Date"
              name="scheduledDate"
              rules={[{ required: true }]}
              required
            >
              <DatePicker
                showTime={{
                  format: "hh:mm a",
                }}
                format="YYYY-MM-DD hh:mm A"
              />
            </Form.Item>
            <Form.Item
              label="Source Document"
              name="sourceDocument"
              getValueFromEvent={(e) => {
                return e?.fileList[0];
              }}
            >
              <Upload
                multiple={false}
                beforeUpload={() => false}
                maxCount={1}
              >
                <Button>Attach a File</Button>
              </Upload>
            </Form.Item>
          </div>

          <Divider>Operations</Divider>

          <Form.List name="products">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <div className="col-3" key={field.key}>
                    <Form.Item
                      name={[field.name, "product"]}
                      rules={[{ required: true }]}
                      label="Product"
                      required
                    >
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        options={[
                          {
                            label: "Product 1",
                            value: 1,
                          },
                          {
                            label: "Product 2",
                            value: 2,
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, "quantity"]}
                      label="Quantity"
                      rules={[{ required: true }]}
                      required
                    >
                      <InputNumber className="w-full" min={1} />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        onClick={() => remove(field.name)}
                        disabled={fields.length <= 1}
                        danger
                        block
                      >
                        Remove
                      </Button>
                    </Form.Item>
                  </div>
                ))}
                <Button onClick={add} className="mt-5">
                  Add a Product
                </Button>
              </>
            )}
          </Form.List>

          <div className="col-3 mt-10">
            <Form.Item>
              <Button type="primary" size="large" htmlType="submit" block>
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => setReceiptMode("draft")}
                size="large"
                block
              >
                Draft
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => setReturnModalOpen(true)}
                size="large"
                block
              >
                Return
              </Button>
            </Form.Item>
          </div>
        </Form>
      </OwnModal>
      <ReturnModal
        open={isReturnModalOpen}
        products={form.getFieldValue("products")}
        onCancel={() => setReturnModalOpen(false)}
      />
    </>
  );
}

export default ReceiptModal;

import { FilterOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Switch, Table, App, Dropdown, Button, Row, Space } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useAppraislList from 'hooks/api/useAppraislList';
import useCustomerList from 'hooks/api/useCustomerList';
import { deleteCustomer } from 'network/account-receivable/customer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { arrayFrom, dateFormat } from 'utils/utils';
import AppraisalCard from './services/AppraisalCard';

function Appraisals() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { appraislList, appraislListLod } = useAppraislList();
  console.log('Appraisals  appraislList:', appraislList);

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: 'Human Resources' }]} current="Appraisals" />
      <SearchHero
        onAddBtn={() => navigate('new')}
        // extra={<CustomerSearch setFilterdCustomerList={setFilterdCustomerList} isfilterMood={isfilterMood} setIsfilterMood={setIsfilterMood} />}
        extra={
          <>
            <Dropdown
              menu={{
                items: [
                  { key: 'Department', label: 'Department', type: 'group' },
                  { key: 'all', label: 'All' },
                  { key: 'Administration', label: 'Administration' },
                ],
                className: 'w-40',
              }}>
              <Button type="primary" icon={<UserOutlined />} />
            </Dropdown>
            <Dropdown
              menu={{
                items: [
                  { key: 'Status', label: 'Status', type: 'group' },
                  { key: 'all', label: 'All' },
                  { key: 'To confirm', label: 'To confirm' },
                  { key: 'Confirmed', label: 'Confirmed' },
                  { key: 'Done', label: 'Done' },
                  { key: 'Canceled', label: 'Canceled' },
                ],
                className: 'w-40',
              }}>
              <Button type="primary" icon={<FilterOutlined />} />
            </Dropdown>
          </>
        }
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {appraislList?.map(el => (
          <AppraisalCard key={el?.id} appraisl={el} />
        ))}
      </div>
    </div>
  );
}

export default Appraisals;

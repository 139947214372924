import { ClockCircleFilled } from '@ant-design/icons';
import { Button, Rate, Space } from 'antd';
import React from 'react';
import { randomNumber } from 'utils/utils';

function LeadsItemCard({ el }) {
  return (
    <div className="space-y-2 bg-white p-2 rounded text-xs">
      <h5>Wagdi Hussain</h5>
      {!!randomNumber() && (
        <div className="text-gray-600">
          <p>500,00 LE</p>
          <p>7mama</p>
        </div>
      )}
      <div className="flex items-center justify-between gap-x-2">
        <Space>
          <Rate count={3} value={randomNumber(0, 3)} style={{ fontSize: 12 }} />
          <Button
            type="link"
            icon={<ClockCircleFilled style={{ fontSize: 12 }} />}
            className="!p-0 !text-xs inline-flex items-center justify-center !min-h-[auto] !w-5 !h-5"
          />
        </Space>

        <Button type="primary" className="!p-0 !text-xs inline-flex items-center justify-center !min-h-[auto] !text-white !w-6 !h-6">
          W
        </Button>
      </div>
    </div>
  );
}

export default LeadsItemCard;

import { MoreOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Dropdown, Space, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalAddSegment from 'components/modals/ModalAddSegment';
import ModalSegmentQualifiers from 'components/modals/ModalSegmentQualifiers';
import ModalSegmentValues from 'components/modals/ModalAddValue';
import ModalSegmentValueSet from 'components/modals/ModalAddValueSet';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useSearchQuery from 'hooks/useSearchQuery';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { arrayFrom } from 'utils/utils';

function SegmentDefine() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const client = useQueryClient();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [isSetValueMod, setIsSetValueMod] = useState(false);
  const [isQualifiersMod, setIsQualifiersMod] = useState(false);
  const [isValuesMod, setIsValuesMod] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  console.log('SegmentDefine  selectedSegment:', selectedSegment);

  const columns = [
    {
      title: 'number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'column',
      dataIndex: 'column',
      key: 'column',
    },
    {
      title: 'value Set',
      dataIndex: 'valueSet',
      key: 'valueSet',
    },
    {
      title: 'Enable',
      key: 'enable',
      render: () => <Switch checked={Math.round(Math.random())} />,
    },
  ].concat([
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'value-set',
              label: 'Value set',
              onClick: () => setIsSetValueMod(true),
            },
            {
              key: 'qualifiers',
              label: 'Qualifiers',
              onClick: () => setIsQualifiersMod(true),
            },
            {
              key: 'Edit',
              label: 'Edit',
              // onClick: () => setEditItem(record),
            },
            {
              key: 'Delete',
              label: 'Delete',
              // onClick: () => onDeleteCurrency(record?.id),
            },
          ]}
        />
      ),
    },
  ]);

  const data = arrayFrom(12)?.map((_, i) => ({
    key: i,
    number: 'Text here',
    name: 'Text here',
    column: 'Text here',
    valueSet: 'Text here',
    display: 'Text here',
    enable: 'Text here',
  }));

  const onSelectChange = newSelectedRowKeys => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    const selectedRowKey = newSelectedRowKeys?.slice(-1)?.[0];
    setSelectedRowKeys([selectedRowKey]);
    const selectedItem = data?.find(item => item?.key === selectedRowKey) || null;
    setSelectedSegment(selectedItem);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb items={[{ title: <Link to="/structure/define">Structure</Link> }]} current="Segments" />
        <SearchHero
          onAddBtn={() => {
            setIseModalUpdat(true);
          }}
        />
        <Table
          dataSource={data}
          sticky
          columns={!!data?.length ? columns : []}
          pagination={{
            // pageSize: 1,
            current: +searchQueryObj.page || 1,
            onChange: page => {
              const queryObj = { ...searchQueryObj };
              queryObj['page'] = page;
              setSearchQuery(queryObj);
            },
          }}
        />
        {/* <Space className="flex justify-end mt-4">
          <Button
            type="primary"
            disabled={!selectedSegment}
            onClick={() => setIsSetValueMod(true)}
          >
            Value set
          </Button>
          <Button
            type="primary"
            disabled={!selectedSegment}
            onClick={() => setIsValuesMod(true)}
          >
            Value
          </Button>
          <Button
            type="primary"
            disabled={!selectedSegment}
            onClick={() => setIsQualifiersMod(true)}
            // onClick={() => navigate((selectedSegment?.key).toString())}
          >
            Qualifiers
          </Button>
        </Space> */}
      </div>
      <ModalAddSegment open={iseModalUpdat} onCancel={() => setIseModalUpdat(false)} editItem={selectedSegment} />
      <ModalSegmentValueSet open={isSetValueMod} onCancel={() => setIsSetValueMod(false)} />
      {/* <ModalSegmentValues
        open={isValuesMod}
        onCancel={() => setIsValuesMod(false)}
      /> */}
      <ModalSegmentQualifiers open={isQualifiersMod} onCancel={() => setIsQualifiersMod(false)} />
    </>
  );
}

export default SegmentDefine;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import { addPurchasingOrder, getPurchasingOrder } from "network/supply-chain/purchasingOrder";

export const usePurchasingOrder = () => {
  const { data, ...rest } = useQuery({
    queryFn: getPurchasingOrder,
    queryKey: [queryKeys.purchasingOrder],
  });
  const purchasingOrder = data?.model;
  return { purchasingOrder, ...rest };
};

export const useCreatePurchasingOrder = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addPurchasingOrder,
    onSuccess: () => client.invalidateQueries([queryKeys.purchasingOrder]),
  });
  const { mutate, isLoading } = mutation;
  return { mutate, isLoading };
};

import React, { useState } from "react";
import { Tag as AntTag } from "antd";

const tagColor = {
  draft: "gray",
  waiting: "yellow",
  ready: "blue",
  done: "green",
};

/**
 * @param {('draft' | 'waiting'| 'ready'| 'done')} initialValue
 */
function useOwnTag(initialValue = "ready") {
  const [value, setValue] = useState(initialValue);
  const Tag = () => (
    <AntTag
      style={{
        padding: "15px 50px",
        fontWeight: "600",
        fontSize: "18px",
      }}
      color={tagColor[value]}
    >
      {value.toUpperCase()}
    </AntTag>
  );

  /**
   * @param {('draft' | 'waiting'| 'ready'| 'done')} value
   */
  const setTagValue = (value) => {
    if (!value) {
      throw new Error(
        "value isn't provided to setTagValue",
      );
    }
    setValue(value);
  };

  return {
    value,
    setTagValue,
    Tag,
  };
}

export default useOwnTag;

import { useQuery } from '@tanstack/react-query';
import { getAllGLStructures } from 'network/general-ledger/GLStructures';
import { queryKeys } from 'services/react-query/queryKeys';

function useGLStructureList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.GLStructureList, searchQueryStr], () => getAllGLStructures(searchQueryStr));

  const GLStructureList = data?.model;

  return {
    GLStructureList,
    GLStructureListLod: isLoading,
  };
}

export default useGLStructureList;

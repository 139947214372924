import { useQuery } from '@tanstack/react-query';
import { getAllPeriods } from 'network/general-ledger/period';
import { queryKeys } from 'services/react-query/queryKeys';

function usePeriodList(searchQueryStr) {
  const { data, isLoading } = useQuery(
    [queryKeys.periodList, searchQueryStr],
    () => getAllPeriods(searchQueryStr)
  );

  const periodList = data?.model;

  return {
    periodList,
    periodListLod: isLoading,
  };
}

export default usePeriodList;

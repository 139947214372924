import { useQuery } from '@tanstack/react-query';
import { getAllGLSegments } from 'network/general-ledger/GLSegments';
import { queryKeys } from 'services/react-query/queryKeys';

function useGLSegmentList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.GLSegmentList, searchQueryStr], () => getAllGLSegments(searchQueryStr));

  const GLSegmentList = data?.model;

  return {
    GLSegmentList,
    GLSegmentListLod: isLoading,
  };
}

export default useGLSegmentList;

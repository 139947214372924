import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllFixedAssets = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/FixedAssets?${searchQuery}`);
  return data;
});

export const getFixedAsset = catchAsync(async id => {
  const { data } = await axiosInstance(`/FixedAssets/${id}`);
  return data;
});

export const createFixedAsset = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/FixedAssets`, body);
  return data;
});

export const updateFixedAsset = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/FixedAssets`, body);
  return data;
});

export const deleteFixedAsset = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/FixedAssets/${id}`);
  return data;
});

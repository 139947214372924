import { Button, Switch, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import ModalAddTax from "components/modals/ModalAddTax";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import React, { useState } from "react";
import { arrayFrom } from "utils/utils";
import { queryKeys } from "services/react-query/queryKeys";
import useSearchQuery from "hooks/useSearchQuery";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllTaxes } from "network/taxes/taxes";
import dayjs from "dayjs";
import constents from "utils/constants";

function Taxes() {
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const client = useQueryClient();

  const { data: taxList } = useQuery([queryKeys.taxList, searchQueryStr], () =>
    getAllTaxes(searchQueryStr)
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tax Type",
      dataIndex: "TaxType",
      key: "TaxType",
      render: (_, record) => <p>{record?.taxType?.nameEN}</p>,
    },
    {
      title: "Tax Rate",
      dataIndex: "TaxRate",
      key: "taxRate",
      render: (_, record) => <p>{record?.taxRate?.nameEN}</p>,
    },
    {
      title: "Level",
      dataIndex: "Level",
      key: "taxApplyingLevel",
      render: (_, record) => <p>{record?.taxApplyingLevel?.nameEN}</p>,
    },
    {
      title: "Method",
      dataIndex: "Method",
      key: "taxApplyingMethod",
      render: (_, record) => <p>{record?.taxApplyingMethod?.nameEN}</p>,
    },
    {
      title: "Liability Account",
      dataIndex: "liabilityAccount",
      key: "liabilityAccount",
    },
    {
      title: "Expense Account",
      dataIndex: "expenseAccount",
      key: "expenseAccount",
    },
    {
      title: "Available At",
      dataIndex: "AvailableAt",
      key: "effectiveStartDate",
      render: (_, record) => (
        <p>
          {/* {console.log(record?.effectiveStartDate, "recordrecord")} */}
          {dayjs(record?.effectiveStartDate).format(constents.dateFormat) -
            dayjs(record?.effectiveEndDate).format(constents.dateFormat)}
        </p>
      ),
    },
    {
      title: "Active",
      key: "isActive",
      dataIndex: "isActive",

      render: (val) => <Switch checked={val} />,
    },
  ];

  const data = arrayFrom(12)?.map((_, i) => ({
    key: i,
    TaxName: "tax 15%",
    TaxType: "payable",
    TaxRate: "15%",
    Level: "Invoice",
    Method: "automatic",
    LiabilityAccount: "Liability Account",
    AvailableAt: "12/20/2023  -  12/20/2023 ",
  }));

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Taxes" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table
          // dataSource={data}
          dataSource={taxList?.model || []}
          sticky
          columns={columns}
          // rowSelection={rowSelection}
        />
      </div>
      <ModalAddTax
        open={iseModalUpdat}
        onCancel={() => setIseModalUpdat(false)}
      />
    </>
  );
}

export default Taxes;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";


export const getExpenses = catchAsync(async (id) => {
    const { data } = await axiosInstance.get("/EmployeeExpenses", {
      params: {
        id,
      },
    });
    return data;
  });
  
  export const addExpenses = catchAsync(async (body) => {
    const res = await axiosInstance.post("/EmployeeExpenses", body);
    return res;
  });
  
  export const updateExpenses = catchAsync(async (body) => {
    const res = await axiosInstance.put("/Expensess", body);
    return res;
  });
  
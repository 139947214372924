import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import useModalParams from "hooks/useModalParams";
import {
  useCreateWorkLocations,
  useUpdateWorkLocations,
} from "hooks/human-resourses/useWorkingLocations";

function WorkLocationsModal({ open, handelClose, LocationId }) {
  const { createWorkLocations, isCreatingWorkLocations } =
    useCreateWorkLocations();
  const { updateWorkLocations, isUpdateWorkLocations } =
    useUpdateWorkLocations();
  console.log(LocationId, "LocationId");
  const onFinish = (values) => {
    if (!LocationId) {
      createWorkLocations(
        {
          ...values,
          addressId: "2F1C2BD7-DB31-45BB-E54B-08DBA3695500",
        },
        {
          onSuccess: () => {
            message.success("Created successfully");
            handelClose();
          },
          onError: (error) => message.error(`Error: ${error?.message}`),
        }
      );
    } else {
      updateWorkLocations(
        {
          ...values,
          id: LocationId,
          addressId: "2F1C2BD7-DB31-45BB-E54B-08DBA3695500",
        },
        {
          onSuccess: () => {
            message.success("Updated successfully");
            handelClose();
          },
          onError: (error) => message.error(`Error: ${error?.message}`),
        }
      );
    }
  };
  return (
    <OwnModal
      title={!LocationId ? "Add Work Locations" : "Edit Work Locations"}
      open={open}
      onCancel={() => handelClose()}
      width={500}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-1 py-4">
          <Form.Item
            label="Name English"
            name="nameEN"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Name Arabic"
            name="nameAR"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Address"
            name="addressId"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  label: "Address 1",
                  value: 1,
                },
                {
                  label: "Address 2",
                  value: 2,
                },
                {
                  label: "Address 3",
                  value: 3,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Location Number"
            name="locationNumber"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
        </div>
        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={
              LocationId ? isUpdateWorkLocations : isCreatingWorkLocations
            }
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default WorkLocationsModal;

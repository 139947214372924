import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getCategories = catchAsync(async (id) => {
  const { data } = await axiosInstance.get("/ProductCategory", {
    params: {
      id,
    },
  });
  return data;
});

export const addCategories = catchAsync(async (fd) => {
  const res = await axiosInstance.post("/ProductCategory", fd);
  return res;
});

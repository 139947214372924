import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllWorkEntries = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/WorkEntries?${searchQuery}`);
  return data;
});

export const getWorkEntry = catchAsync(async id => {
  const { data } = await axiosInstance(`/WorkEntries/${id}`);
  return data;
});

export const createWorkEntry = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/WorkEntries`, body);
  return data;
});

export const updateWorkEntry = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/WorkEntries`, body);
  return data;
});

export const deleteWorkEntry = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/WorkEntries/${id}`);
  return data;
});

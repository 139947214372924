import { FileOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, DatePicker, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import constents from 'utils/constants';
import useMetaData from 'hooks/api/useMetaData';
import useCurrencyList from 'hooks/api/useCurrencyList';
import usePeriodList from 'hooks/api/usePeriodList';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import useAccountList from 'hooks/api/useAccountList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createManualJournal } from 'network/general-ledger/journals';
import { queryKeys } from 'services/react-query/queryKeys';

function JournalsManualNew({ isEdit }) {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const metadata = useMetaData();
  const { currencyList, currencyListLod } = useCurrencyList();
  const { periodList, periodListLod } = usePeriodList();
  const { accountList, accountListLod } = useAccountList();
  const userData = useSelector(s => s.user?.data);

  const client = useQueryClient();
  const { mutate: onCreateManualJournal, isLoading: createManualJournalLod } = useMutation(createManualJournal, {
    onSuccess: res => {
      console.log('BudgetNew  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.journalManualList]);

      form.resetFields();
      navigate('/general-ledger/journals/manual');
    },
  });

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['effectiveDateFrom'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['effectiveDateTo'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    dataObj['controlTotal'] = 0;
    dataObj['journalLines'] = dataObj?.names?.map(el => ({
      lineNumber: el.lineNumber,
      credit: el.credit,
      debit: el.debit,
      description: el.description,
      accountId: el.accountId,
    }));
    delete dataObj['names'];

    console.log('BudgetNew  dataObj:', dataObj);
    // return;
    onCreateManualJournal(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: <Link to="/general-ledger/journals/manual">Manual Journals</Link> }]}
        current={`${isEdit ? 'Edit' : 'New'} Manual Journal`}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ ledgerId: userData?.ledger?.id, names: [''] }}>
        <div className="space-y-6">
          <div className="bg-white py-8 px-8 rounded-lg">
            <div className="flex items-center justify-between gap-2 flex-wrap mb-4">
              <h2 className="title-24">Create Journals</h2>
              <Button type="primary " icon={<FileOutlined />}>
                IMPORT
              </Button>
            </div>

            <div className="col-3">
              <Form.Item name="nameEN" label="Journal">
                <Input />
              </Form.Item>
              <Form.Item name="categoryId" label="Category">
                <Select options={metadata?.categories?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
              </Form.Item>
              <Form.Item name="accountTypeId" label="Method">
                <Select options={metadata?.accountTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
              </Form.Item>

              <Form.Item name="description" label="Descreption">
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
              <Form.Item name="fromTo" label="Effective Dates From - To" rules={[{ required: true }]}>
                <DatePicker.RangePicker className="w-full" format="YYYY-MM-DD" disabledDate={constents.disabledDate} />
              </Form.Item>
              <Form.Item name="conversionTypeId" label="Conversion Type">
                <Select options={metadata?.conversionTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
              </Form.Item>
              <Form.Item name="journalStatusId" label="Stauts">
                <Select options={metadata?.journalStatuses?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
              </Form.Item>

              <Form.Item name="ledgerId" label="Ledger" rules={[{ required: true }]}>
                <Select
                  options={[
                    {
                      value: userData?.ledger?.id,
                      label: userData?.ledger?.nameEN,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="currencyId" label="Currency">
                <Select loading={currencyListLod} options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
              </Form.Item>

              <Form.Item name="periodId" label="Period">
                <Select
                  loading={periodListLod}
                  options={periodList?.map(p => ({
                    value: p?.id,
                    label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                  }))}
                />
              </Form.Item>
            </div>
          </div>

          <div className="bg-white py-8 px-8 rounded-lg">
            <Form.List name="names">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5  gap-x-3  bg-gray-50 p-3 rounded items-end">
                          <Form.Item name={[index, 'lineNumber']} label="Line">
                            <Input />
                          </Form.Item>
                          <Form.Item name={[index, 'accountId']} label="Account">
                            <Select
                              loading={accountListLod}
                              options={accountList?.map(el => ({
                                value: el?.id,
                                label: el?.id,
                              }))}
                            />
                          </Form.Item>

                          <Form.Item name={[index, 'debit']} label="Debit">
                            <InputNumber className="w-full" />
                          </Form.Item>
                          <Form.Item name={[index, 'credit']} label="Credit">
                            <InputNumber className="w-full" />
                          </Form.Item>
                          <Form.Item name={[index, 'description']} label="Descreption">
                            <Input.TextArea autoSize={{ maxRows: 1 }} />
                          </Form.Item>
                        </div>

                        {fields.length > 1 ? (
                          <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                            Remove Above Field
                          </Button>
                        ) : null}
                      </div>
                    ))}
                    <Divider />
                    <Form.Item>
                      <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
          <Space className="flex justify-end mt-4">
            <Button type="primary" htmlType="submit" loading={createManualJournalLod}>
              APPROVE
            </Button>
            <Button type="primary" ghost htmlType="reset" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default JournalsManualNew;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getInventoryConfiguration = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/InventoryConfiguration`);
  return data;
});

export const updateInventoryConfiguration = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/InventoryConfiguration`, body);
  return data;
});

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { setPeriodStatus } from 'network/general-ledger/period';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalPeriodOpenClose({ open, onCancel, editItem, setEditItem }) {
  console.log('ModalPeriodOpenClose  editItem:', editItem);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const metadata = useMetaData();
  const client = useQueryClient();

  const { mutate: onSetPeriodStatus, isLoading: setPeriodStatusLod } = useMutation(statusId => setPeriodStatus(editItem?.id, statusId), {
    onSuccess: res => {
      console.log('ModalPeriodOpenClose  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.periodList]);

      form.resetFields();
      onCancel();
    },
  });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    console.log('ModalPeriodOpenClose  dataObj:', dataObj);

    onSetPeriodStatus(dataObj?.statusId);
  };
  return (
    <OwnModal title="Open and Close Periods" open={open} onCancel={onCancel} width={600}>
      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="">
          <Form.Item name="statusId" label="Status">
            <Select loading={!metadata} options={metadata?.periodStatuses?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
          </Form.Item>
        </div>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={setPeriodStatusLod}>
            Apply
          </Button>

          <Button type="primary" htmlType="reset" onClick={onCancel}>
            cancel
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalPeriodOpenClose;

import { useQuery } from '@tanstack/react-query';
import { getAllGLValueSets } from 'network/general-ledger/GLValueSets';
import { queryKeys } from 'services/react-query/queryKeys';

function useGLValueSetList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.GLValueSetList, searchQueryStr], () => getAllGLValueSets(searchQueryStr));

  const GLValueSetList = data?.model;

  return {
    GLValueSetList,
    GLValueSetListLod: isLoading,
  };
}

export default useGLValueSetList;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllAppraisals = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/EmployeeAppraisals?${searchQuery}`);
  return data;
});

export const getAppraisal = catchAsync(async id => {
  const { data } = await axiosInstance(`/EmployeeAppraisals/${id}`);
  return data;
});

export const createAppraisal = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/EmployeeAppraisals`, body);
  return data;
});

export const updateAppraisal = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/EmployeeAppraisals`, body);
  return data;
});

export const deleteAppraisal = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/EmployeeAppraisals/${id}`);
  return data;
});

export const confirmAppraisal = catchAsync(async () => {
  const { data } = await axiosInstance.patch(`/EmployeeAppraisals`);
  return data;
});

export const getAppraisalAnswers = catchAsync(async (id, searchQuery = '') => {
  const { data } = await axiosInstance(`/EmployeeAppraisals/Answers/${id}?${searchQuery}`);
  return data;
});

export const getAppraisalSkills = catchAsync(async (id, searchQuery = '') => {
  const { data } = await axiosInstance(`/EmployeeAppraisals/Skills/${id}?${searchQuery}`);
  return data;
});

export const createAppraisalSkill = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/EmployeeAppraisals/AddSkill`, body);
  return data;
});

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateWarehouseLocation } from "network/supply-chain/warehouseLocation";
import { queryKeys } from "services/react-query/queryKeys";

const useUpdateWarehouseLocation = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateWarehouseLocation,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.warehouseLocation]);
    },
  });

  mutation.isUpdating = mutation.isLoading;
  mutation.update = mutation.mutate;
  return mutation;
};

export default useUpdateWarehouseLocation;

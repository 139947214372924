import { useQuery } from '@tanstack/react-query';
import { getAllAPRecurringInvoices } from 'network/account-payable/recurring-invoices';
import { queryKeys } from 'services/react-query/queryKeys';

function useAPRecurringInvoiceList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.APRecurringInvoiceList, searchQueryStr], () => getAllAPRecurringInvoices(searchQueryStr));

  const APRecurringInvoiceList = data?.model;

  return {
    APRecurringInvoiceList,
    APRecurringInvoiceListLod: isLoading,
  };
}

export default useAPRecurringInvoiceList;

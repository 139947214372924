import { Button, Table, Tag } from "antd";
import SearchHero from "components/common/SearchHero";
import ApplicationModal from "components/modals/human-resources/setup/ApplicationModal";
import EmployeeModal from "components/modals/human-resources/setup/EmployeeModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useEmployee } from "hooks/human-resourses/useEmployee";
import { useState } from "react";

function Employees() {
  const {employeeData}=useEmployee()
  console.log(employeeData,"employeeData");
  // const { openAsNew, openAsEdit } = useModalParams();
  const [isOpen, setIsOpen] = useState(false);
  // "Employee Name": "Application 1",
  //     "Applicant's Name": "Applicant 1",
  //     email: "email1@example.com",
  //     "Phone Number": "123456789",
  //     "Applied Job": "Job 1",
  //     Department: "Department 1",
  //     "Expected Salary": 50000,
  //     "Date Of Birth": "2022-01-01",
  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId ",
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "departmentId",
    },
    {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
   
    // {
    //   title: "Status",
    //   key: "Status",
    //   render: ({ id }) => <Tag color="magenta">Proposal</Tag>,
    // },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: ({ id }) => (
    //     <>
    //       <Button type="primary" ghost onClick={() => setIsOpen({ id })}>
    //         Edit
    //       </Button>
    //       {/* <Button type="primary" onClick={() => setIsOpen({ id })}>View</Button> */}
    //     </>
    //   ),
    // },
  ];

  const data = [
    {
      "Employee Name": "Application 1",
      "Applicant's Name": "Mostafa gamal",
      email: "email1@example.com",
      "Phone Number": "123456789",
      "Applied Job": "Job 1",
      Department: "Department 1",
      "Expected Salary": 50000,
      "Date Of Birth": "2022-01-01",
      "Job Position": "Manger",
    },
    {
      "Employee Name": "Application 2",
      "Applicant's Name": "Ali ahmed",
      email: "email2@example.com",
      "Phone Number": "987654321",
      "Applied Job": "Job 2",
      Department: "Department 2",
      "Expected Salary": 60000,
      "Date Of Birth": "2022-02-01",
      "Job Position": "Manger",
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Human Resources / Setup / Employees" />
        <SearchHero onAddBtn={() => setIsOpen(true)} />
        <Table sticky dataSource={employeeData} columns={columns} />
      </div>
      <EmployeeModal open={isOpen} handelClose={() => setIsOpen(false)} />
    </>
  );
}

export default Employees;

import OwnModal from "components/own/OwnModal";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  message,
} from "antd";
import useModalParams from "hooks/useModalParams";
import PhoneInput from "react-phone-number-input";
import dayjs from "dayjs";
import {
  useCreateEmployee,
  useEmployee,
} from "hooks/human-resourses/useEmployee";
import { useDepartments } from "hooks/human-resourses/useDepartment";
import useMetaData from "hooks/api/useMetaData";
import { useWorkingTimes } from "hooks/human-resourses/useWorkTimes";
import { useWorkingLocations } from "hooks/human-resourses/useWorkingLocations";

function EmployeeModal({ open, handelClose }) {
  const x = useMetaData();
  console.log(x, "flfofof");
  const { createEmployee, isCreatingEmployee } = useCreateEmployee();
  const { departmentData } = useDepartments();
  const { employeeData } = useEmployee();
  const { workingTimesData } = useWorkingTimes();
  const { workLocationsData } = useWorkingLocations();

  const { degrees, jobs, applicationstauses } = useMetaData();
  console.log(workLocationsData, "employeeData managerId");

  // {
  //   "name": "Ahmed Ahmed", **********************
  //   "workPhoneNumber": "01143134889",******************************
  //   "workMobileNumber": "01143134889", *************************
  //   "workEmail": "ahmed@test.com", *******************************
  //   "departmentId": "ed94945d-cac5-4c2e-3c95-08dbf371e946", **********************
  //   "jobId": "592670b5-0304-4cc0-c8e1-08dbf371215f", ******************************
  //   "managerId": null, *************************************************
  //   "workLocationId": "fc54fc96-d889-4923-83c0-08dbf34d894c", *********************
  //   "workingTimeId": "70cd7850-28cb-4609-1883-08dbeebd5e2b", ***************************
  //   "address": "", *********************
  //   "phoneNumber": "", ********************
  //   "email": "",****************************
  //   "idNumber": "", **********************************
  //   "passportNumber": ""*******************************
  // }

  const onFinish = (values) => {
    createEmployee(values, {
      onSuccess: () => {
        message.success("Created successfully");
        handelClose();
      },
      onError: (error) => message.error(`Error: ${error?.message}`),
    });
  };
  return (
    <OwnModal
      title={open ? "Add Employee" : "Edit Employee"}
      open={open}
      onCancel={() => handelClose()}
      // width={500}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-2 py-4">
          <Form.Item label="Employee Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Id Number" name="idNumber">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input type="email" />
          </Form.Item>
          <Form.Item label="Address" name="address">
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label="Phone Number">
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item label="Department" name="departmentId">
            <Select
              options={departmentData?.map((e) => ({
                label: e?.nameEN,
                value: e?.id,
              }))}
            />
          </Form.Item>
          <Form.Item label="Job Position" name="jobId">
            <Select
              options={jobs?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item label="Manager" name="managerId">
            <Select
              options={employeeData?.map((s) => ({
                value: s?.managerId,
                label: s?.name,
              }))}
            />
          </Form.Item>
          <Form.Item label="Degree" name="degreeId">
            <Select
              options={degrees?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
        </div>
        <Divider />
        <h4 className="text-3xl font-medium text-[#424242]">Work Inormation</h4>
        <div className="col-2 py-4">
          <Form.Item name="workPhoneNumber" label="Work Phone Number">
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item name="workMobileNumber" label="Work Mobile Number">
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item label="Work Email" name="workEmail">
            <Input type="email" />
          </Form.Item>
          <Form.Item label="Work Location" name="workLocationId">
            <Select
              options={workLocationsData?.map((s) => ({
                value: s?.id,
                label: `${s?.nameEN} `,
              }))}
            />
          </Form.Item>
          <Form.Item label="Working Time" name="workingTimeId">
            <Select
              options={workingTimesData?.map((s) => ({
                value: s?.id,
                label: `${dayjs(s?.workFrom, "HH:mm:ss").format(
                  "HH:mm"
                )} - ${dayjs(s?.workTo, "HH:mm:ss").format("HH:mm")}`,
              }))}
            />
          </Form.Item>
          <Form.Item label="Time Zone" name="timeZone">
            <Input />
          </Form.Item>
          {/* <Form.Item label="Available Date" name="Available">
            <DatePicker />
          </Form.Item> */}
        </div>
        <Divider />
        <h4 className="text-3xl font-medium text-[#424242]">
          Private Information
        </h4>
        <div className="col-2 py-4">
          <Form.Item label="Address" name="address2">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="Email">
            <Input type="email" />
          </Form.Item>
          <Form.Item name="Phone Number" label="Phone Number">
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item label="Language" name="language">
            <Input />
          </Form.Item>
          <Form.Item label="How Work Distance" name="How Work Distance">
            <Input />
          </Form.Item>
          <Form.Item label="Private Car Plate" name="Private Car Plate">
            <Input />
          </Form.Item>
          <Form.Item label="Martial Status" name="Martial Status">
            <Select
              options={[
                {
                  label: "Single",
                  value: 1,
                },
                {
                  label: "Married",
                  value: 2,
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="certificate Level" name="certificate Level">
            <Input />
          </Form.Item>
          <Form.Item label="Field Of Study" name="Field Of Study">
            <Input />
          </Form.Item>
          <Form.Item label="School" name="School">
            <Input />
          </Form.Item>
          <Form.Item
            label="Number Of Dependent Children"
            name="Number Of Dependent Children"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Contact Name" name="Contact Name">
            <Input />
          </Form.Item>
          <Form.Item label="Contact phone" name="Contact phone">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Nationality" name="Nationality">
            <Input />
          </Form.Item>
          <Form.Item label="Identification No" name="Identification No">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Passport No" name="passportNumber">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Gender" name="Gender">
            <Select
              options={[
                {
                  label: "Male",
                  value: 1,
                },
                {
                  label: "Female",
                  value: 2,
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Visa No" name="Visa No">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Date Of Birth" name="Available">
            <DatePicker />
          </Form.Item>
          <Form.Item label="Work Permit No" name="Work Permit No">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="place Of Birth" name="place Of Birth">
            <Input />
          </Form.Item>
          <Form.Item label="Visa Expire Date" name="Visa Expire Date">
            <Input type="number" />
          </Form.Item>
          <Form.Item label="place Of Birth" name="place Of Birth">
            <Input />
          </Form.Item>
          <Form.Item
            label="Work Permit Expire Date"
            name="Work Permit Expire Date"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Work Permit" name="Work Permit">
            <Input type="number" />
          </Form.Item>
        </div>
        <Divider />
        <h4 className="text-3xl font-medium text-[#424242]">HR Settings</h4>
        <div className="col-2 py-4">
          <Form.Item label="Employee Typee" name="Employee type">
            <Select
              options={[
                {
                  label: "Employee 1",
                  value: 1,
                },
                {
                  label: "Employee 2",
                  value: 2,
                },
                {
                  label: "Employee 3",
                  value: 3,
                },
              ]}
            />
          </Form.Item>
          <Form.Item label="Related User" name="Related User">
            <Input />
          </Form.Item>
        </div>
        <Divider />
        <h4 className="text-3xl font-medium text-[#424242] mb-20">PayRoll</h4>

        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={isCreatingEmployee}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default EmployeeModal;

import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import ModalRequestForQutation from "components/modals/orders/ModalRequestForQutation";
import { usePurchasingRequest } from "hooks/api/supply-chain/purchasing/purchasingRequest";
import dayjs from "dayjs";
import TagElement from "components/common/TagElement";
import { usePurchasingOrder } from "hooks/api/supply-chain/purchasing/purchasingOrder";

export default function Qutations({ order }) {
  const { purchasingRequest, isLoading } = usePurchasingRequest();
  //  const { purchasingOrder } = usePurchasingOrder();
  //  console.log(purchasingOrder,"purchasingOrder");
  console.log(purchasingRequest, "purchasingRequest");
  const [locationwarehouseModal, setLocationWarehouseModal] = useState(false);
  const navigate = useNavigate();

  const columns = [
    // {
    //   title: "Refrence",
    //   dataIndex: "refrence",
    //   key: "refrence",
    // },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
    },
    // {
    //   title: "Buyer",
    //   dataIndex: "buyer",
    //   key: "buyer",
    // },
    {
      title: "Order Deadline",
      // dataIndex: "orderDeadline",
       key: "expectedArrival",
      render: ({ expectedArrival }) => {
        return <h3>{dayjs(expectedArrival).format('YYYY-MM-DD')}</h3>;      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },
    // {
    //   title: "Source Document",
    //   dataIndex: "sourceDocument",
    //   key: "sourceDocument",
    // },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      // dataIndex: "status",
      key: "statusId",
      render: ({ statusId }) => <TagElement type={statusId} />,
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb
          current={`Purchasing ${order == "request" ? "order" : "request"} `}
        />
        <SearchHero onAddBtn={() => setLocationWarehouseModal(true)} />
        <Table
          loading={isLoading}
          dataSource={purchasingRequest}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalRequestForQutation
        open={locationwarehouseModal}
        order={order}
        onCancel={() => setLocationWarehouseModal(false)}
      />
    </>
  );
}

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Divider, Form, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import dayjs from 'dayjs';
import useAccountList from 'hooks/api/useAccountList';
import useBudgetList from 'hooks/api/useBudgetList';
import useCurrencyList from 'hooks/api/useCurrencyList';
import usePeriodList from 'hooks/api/usePeriodList';
import { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import { transferLines } from 'network/general-ledger/budget';
import constents, { budgetStatusOptions } from 'utils/constants';
import TransferRow from './TransferRow';
import { useNavigate } from 'react-router-dom';

function BudgetEnterTransfer() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const { budgetList, budgetListLod } = useBudgetList();
  const { currencyList, currencyListLod } = useCurrencyList();
  const { periodList, periodListLod } = usePeriodList();
  const { accountList, accountListLod } = useAccountList();
  const [collectTransferAmount, setCollectTransferAmount] = useState(0);

  const [selectedBudget, setSelectedBudget] = useState(null);

  const { mutate: onTransferLines, isLoading: transferLinesLod } = useMutation(transferLines, {
    onSuccess: res => {
      console.log('BudgetEnterTransfer  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.budgetList]);

      navigate('/general-ledger/budget/define');
    },
  });

  const onFinish = values => {
    console.log('BudgetEnterTransfer  values:', values);
    const dataObj = { ...values };

    dataObj['transfers'] = dataObj?.names?.map(el => {
      const lineFrom = {
        amount: {
          amountPeriod: el?.lineFrom?.amountPeriod,
          amount: collectTransferAmount,
        },
        accountId: el?.lineFrom?.accountId,
      };

      const lineTo = {
        amountPeriod: el?.lineTo?.amountPeriod,
        accountId: el?.lineTo?.accountId,
      };

      return {
        lineFrom,
        lineTo,
      };
    });
    delete dataObj['names'];
    console.log('onFinish  dataObj:', dataObj);

    onTransferLines(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb noHome items={[{ title: 'Budget' }]} current="Enter Transfer" />

      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [''] }}>
        <div className="space-y-6">
          <div className="bg-white py-8 px-8 rounded-lg">
            <div className="col-2">
              <Form.Item name="budgetId" label="Budget" className="col-span-full">
                <Select
                  loading={budgetListLod}
                  options={budgetList?.map(el => ({
                    value: el?.id,
                    label: el?.nameEN,
                    item: el,
                  }))}
                  onChange={(_, option) => setSelectedBudget(option?.item)}
                />
              </Form.Item>
              {/* <Form.Item name="companyId" label="Budget Organization">
                <Select
                  options={[
                    {
                      value: userData?.legalEntity?.companyId,
                      label: 'Our Company',
                    },
                  ]}
                />
              </Form.Item> */}

              <Form.Item label="Funds Stauts">
                <Select disabled value={selectedBudget?.status} options={budgetStatusOptions} />
              </Form.Item>

              <Form.Item label="Currency">
                <Select
                  disabled
                  value={selectedBudget?.currencyId}
                  loading={currencyListLod}
                  options={currencyList?.map(el => ({
                    value: el?.id,
                    label: el?.nameEN,
                  }))}
                />
              </Form.Item>
            </div>
            <div>
              <Divider>
                <h4 className="title-18 mb-2">Account Periods</h4>
              </Divider>
              <div className="col-2">
                <Form.Item label="From">
                  <Select
                    disabled
                    value={selectedBudget?.periodFromId}
                    loading={periodListLod}
                    options={periodList?.map(p => ({
                      value: p?.id,
                      label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                    }))}
                  />
                </Form.Item>
                <Form.Item label="To">
                  <Select
                    disabled
                    value={selectedBudget?.periodToId}
                    loading={periodListLod}
                    options={periodList?.map(p => ({
                      value: p?.id,
                      label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                    }))}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="bg-white py-8 px-8 rounded-lg">
            <Form.List name="names">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <TransferRow
                        key={field.key}
                        field={field}
                        index={index}
                        fields={fields}
                        remove={remove}
                        accountList={accountList}
                        accountListLod={accountListLod}
                        selectedBudget={selectedBudget}
                        collectTransferAmount={collectTransferAmount}
                        setCollectTransferAmount={setCollectTransferAmount}
                      />
                    ))}
                    <Divider />
                    <Form.Item>
                      <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
          <Space className="flex justify-end mt-4">
            <Button type="primary" htmlType="submit" loading={transferLinesLod}>
              Transfer Amounts
            </Button>
            <Button type="primary" htmlType="reset" ghost>
              Reset
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
}

export default BudgetEnterTransfer;

import catchAsync from "utils/catchAsync";
import { axiosInstance } from "services/axiosInstance";

export const get = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/ProjectTasks/${id}`);
  return data;
});

export const getAll = catchAsync(async (Page, word) => {
  const { data } = await axiosInstance.get(`/ProjectTasks`, {
    params: {
      Page,
      word,
    },
  });
  return data;
});

export const create = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/ProjectTasks`, body);
  return data;
});

export const update = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/ProjectTasks`, body);
  return data;
});

export const delete_ = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/ProjectTasks/${id}`);
  return data;
});

export const duplicate = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/ProjectTasks/Duplicate/${id}`);
  return data;
});

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllGLSegments = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Segments?${searchQuery}`);
  return data;
});

export const getGLSegment = catchAsync(async id => {
  const { data } = await axiosInstance(`/Segments/${id}`);
  return data;
});

export const createGLSegment = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Segments`, body);
  return data;
});

export const updateGLSegment = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Segments`, body);
  return data;
});

export const deleteGLSegment = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Segments/${id}`);
  return data;
});

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import { createFixedAssetStructure, updateFixedAssetStructure } from 'network/fixed-assets/fixedAssetStructures';
import { createGLStructure, updateGLStructure } from 'network/general-ledger/GLStructures';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ModalStructure({ open, onCancel, editItem, setEditItem, fixedAsset }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();
  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys[fixedAsset ? 'fixedAssetStructureList' : 'GLStructureList']]);
    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateStructure, isLoading: createStructureLod } = useMutation(fixedAsset ? createFixedAssetStructure : createGLStructure, { onSuccess });
  const { mutate: onUpdateStructure, isLoading: updateStructureLod } = useMutation(fixedAsset ? updateFixedAssetStructure : updateGLStructure, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;

      onUpdateStructure(dataObj);
    } else {
      onCreateStructure(dataObj);
    }
  };
  return (
    <OwnModal title={editItem ? 'Edit Structure' : 'Add Structure'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name="code" label="Code" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>
          <Form.Item name="title" label="Title" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
          </Form.Item>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createStructureLod || updateStructureLod}>
            DONE
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalStructure;

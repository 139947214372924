import { useQuery } from '@tanstack/react-query';
import { getAllContracts } from 'network/Human-resources/contracts';
import { queryKeys } from 'services/react-query/queryKeys';

function useContractList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.contractList, searchQueryStr], () => getAllContracts(searchQueryStr));

  const contractList = data?.model;

  return {
    contractList,
    contractListLod: isLoading,
  };
}

export default useContractList;

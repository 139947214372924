import { App, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Radio, Select, Space, Switch, TimePicker, Tooltip } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { createSalesInvoice, getSalesInvoice, updateSalesInvoice } from 'network/account-receivable/sales-invoice';
import useCurrencyList from 'hooks/api/useCurrencyList';
import { disabledDateBeforeToday } from 'utils/utils';
import useCustomerList from 'hooks/api/useCustomerList';
import usePaymentTermsList from 'hooks/api/usePaymentTermsList';
import useTaxList from 'hooks/api/useTaxList';
import constents from 'utils/constants';

function TaskNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [salesInvoiceLines, setSalesInvoiceLines] = useState([]);
  const isReminder = Form.useWatch('reminder', form);
  const isRepeat = Form.useWatch('repeat', form);

  const metadata = useMetaData();
  const { currencyList } = useCurrencyList();
  const { paymentTermsList } = usePaymentTermsList();
  console.log('TaskNew  paymentTermsList:', paymentTermsList);

  const client = useQueryClient();
  const { data: salesInvoiceData } = useQuery([queryKeys.salesInvoiceList, params?.id], () => getSalesInvoice(params?.id), {
    enabled: !!params?.id,
  });
  console.log('salesInvoiceData', salesInvoiceData);

  const onSuccess = res => {
    console.log('onSuccess  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.salesInvoiceList]);

    form.resetFields();
    navigate('/account-receivable/invoices/sales-invoices');
  };

  const { mutate: onCreateSalesInvoice, isLoading: onCreateSalesInvoiceLod } = useMutation(createSalesInvoice, {
    onSuccess,
  });
  const { mutate: onUpdateSalesInvoice, isLoading: onUpdateSalesInvoiceLod } = useMutation(updateSalesInvoice, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && salesInvoiceData) {
      console.log('useEffect  salesInvoiceData:', salesInvoiceData);
      const editItem = salesInvoiceData?.model || {};
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      dataObj['dueDate'] = dayjs(dataObj['dueDate']);

      setSalesInvoiceLines(dataObj.salesInvoiceLines);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, salesInvoiceData]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    navigate('/CRM/tasks');
    return message.success('Task Added Successfully');
    const dataObj = { ...values };
    dataObj.salesInvoiceLines = salesInvoiceLines;

    dataObj['date'] = dayjs(dataObj['date']).format(constents.dateFormat);
    dataObj['glDate'] = dayjs(dataObj['glDate']).format(constents.dateFormat);
    dataObj['dueDate'] = dayjs(dataObj['dueDate']).format(constents.dateFormat);
    // dataObj['isComplate'] = !!dataObj['isComplate'];
    dataObj['isActive'] = !!dataObj['isActive'];
    console.log('TaskNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = salesInvoiceData?.model?.id;
      return onUpdateSalesInvoice(dataObj);
    }

    onCreateSalesInvoice(dataObj);
  };

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb items={[{ title: <Link to="/CRM/Tasks">Tasks</Link> }]} current={isEdit ? 'Edit Task' : 'New Task'} />

        <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
          <div className="col-2">
            <Form.Item name="Task Owner" label="Task Owner" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="Subject" label="Subject" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="date" label="Due Date" rules={[{ required: true }]}>
              <DatePicker disabledDate={disabledDateBeforeToday} />
            </Form.Item>
            <Form.Item name="Contact" label="Contact" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: 'Lead', label: 'Lead' },
                  { value: 'Contact', label: 'Contact' },
                ]}
              />
            </Form.Item>
            <Form.Item name="Account" label="Account" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: 'Deal', label: 'Deal' },
                  { value: 'Product', label: 'Product' },
                  { value: 'Quote', label: 'Quote' },
                  { value: 'Sales Order', label: 'Sales Order' },
                  { value: 'Purchase Order', label: 'Purchase Order' },
                  { value: 'Invoice', label: 'Invoice' },
                  { value: 'Campaign', label: 'Campaign' },
                ]}
              />
            </Form.Item>
            <Form.Item name="Status" label="Status" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: 'Not Started', label: 'Not Started' },
                  { value: 'Defrred', label: 'Defrred' },
                  { value: 'In Progress', label: 'In Progress' },
                  { value: 'Completed', label: 'Completed' },
                  { value: 'Wating For Input', label: 'Wating For Input' },
                ]}
              />
            </Form.Item>
            <Form.Item name="Priority" label="Priority" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: 'Highest', label: 'Highest' },
                  { value: 'High', label: 'High' },
                  { value: 'Normal', label: 'Normal' },
                  { value: 'Low', label: 'Low' },
                  { value: 'Lowest', label: 'Lowest' },
                ]}
              />
            </Form.Item>
            <Form.Item name="Description" label="Description" rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
            <Form.Item name="reminder" label="Reminder">
              <Switch />
            </Form.Item>
            {isReminder && (
              <div className="col-span-full bg-white px-4 rounded-lg mb-4">
                <Divider>Reminder Options</Divider>
                <Radio.Group>
                  {/* <Space direction="vertical"> */}
                  <div className="col-3">
                    <Radio value={0}>
                      <Form.Item name="reminder date" label="On Date" rules={[{ required: true }]}>
                        <DatePicker showHour showMinute />
                      </Form.Item>
                    </Radio>
                    <Radio value={1} className="col-span-2">
                      <div className="col-2">
                        <Form.Item name="reminder days" label="On days of due date" rules={[{ required: true }]}>
                          <InputNumber className="w-full" />
                        </Form.Item>
                        <Form.Item name="reminder days time" label="At time" rules={[{ required: true }]}>
                          <TimePicker showHour showMinute showSecond={false} />
                        </Form.Item>
                      </div>
                    </Radio>
                  </div>
                  {/* </Space> */}
                </Radio.Group>
                <div className="col-2">
                  <Form.Item name="Repeat Type" label="Repeat Type" rules={[{ required: true }]}>
                    <Select options={[{ value: 'None', label: 'None' }]} />
                  </Form.Item>
                  <Form.Item name="Notify" label="Notify" rules={[{ required: true }]}>
                    <Select
                      options={[
                        { value: 'Email', label: 'Email' },
                        { value: 'Message', label: 'Message' },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            )}

            <Form.Item name="repeat" label="Repeat">
              <Switch />
            </Form.Item>
            {isRepeat && (
              <div className="col-span-full bg-white px-4 rounded-lg mb-4">
                <Divider>Repeat Options</Divider>
                <div className="col-2">
                  <Form.Item name="Repeat Type" label="Repeat Type" rules={[{ required: true }]}>
                    <Select
                      options={[
                        { value: 'Daily', label: 'Daily' },
                        { value: 'Monthly', label: 'Monthly' },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item name="Repeat Ends" label="Repeat Ends" rules={[{ required: true }]}>
                    <Select
                      options={[
                        { value: 'Never', label: 'Never' },
                        { value: 'After Times', label: 'After Times' },
                        { value: 'On Day', label: 'On Day' },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
          <Space wrap className="flex justify-end">
            <Button
              type="primary"
              onClick={() => {
                navigate(-1);
                form.resetFields();
              }}>
              Cancel
            </Button>

            <Button type="primary" htmlType="submit" loading={onCreateSalesInvoiceLod || onUpdateSalesInvoiceLod}>
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </Space>
        </Form>
      </div>
    </>
  );
}

export default TaskNew;

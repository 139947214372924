import { useQuery } from "@tanstack/react-query";
import { getWarehouseLocation } from "network/supply-chain/warehouseLocation";
import { queryKeys } from "services/react-query/queryKeys";

const useWarehouseLocation = ({ enabled = true, id }) => {
  const query = useQuery({
    queryFn: () => getWarehouseLocation(id),
    // queryKey: [queryKeys.warehouseLocation],
    select: (data) => data?.model,
    enabled,
  });

  return query;
};

export default useWarehouseLocation;

import { Button, Checkbox, Space, Table, Tag, message } from "antd";
import SearchHero from "components/common/SearchHero";
import ModalBank from "components/modals/ModalBank";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ModalCashStatmenet from "components/modals/ModalCashStatmenet";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import React, { useState } from "react";
import { arrayFrom } from "utils/utils";
import { queryKeys } from "services/react-query/queryKeys";
import useSearchQuery from "hooks/useSearchQuery";
import {
  deleteBankAccounts,
  getAllBankAccounts,
} from "network/cash-managment/bank";

function BankS() {
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const client = useQueryClient();

  const { data: bankList } = useQuery(
    [queryKeys.bankList, searchQueryStr],
    () => getAllBankAccounts(searchQueryStr)
  );

  const columns = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Bank Branch",
      dataIndex: "branchName",
      key: "branchName",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "branchAddress",
      dataIndex: "branchAddress",
      key: "branchAddress",
    },
    {
      title: "usage",
      dataIndex: "usage",
      key: "usage",
      render: (_, record) => (
        <Space size={[0, 8]} wrap>
          {record?.isUsedForPayables && <Tag color="magenta">Payables</Tag>}
          {record?.isUsedForPayroll && <Tag color="geekblue">Payroll</Tag>}
          {record?.isUsedForReceivables && <Tag color="gold">Receivables</Tag>}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Bank" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table
          dataSource={bankList?.model || []}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            current: +searchQueryObj?.page || 1,
            onChange: (page) => {
              const queryObj = { ...searchQueryObj };
              queryObj["page"] = page;
              setSearchQuery(queryObj);
            },
          }}
        />
      </div>
      <ModalBank
        open={iseModalUpdat}
        onCancel={() => setIseModalUpdat(false)}
      />
    </>
  );
}

// add contact in bank (name, email, mobile number)
// no delete in tax only active and inActive as Enable in segment

export default BankS;

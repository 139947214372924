import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import OwnModal from "components/own/OwnModal";
import OwnUpload from "components/own/OwnUpload";
import useOwnTag from "components/own/useOwnTag";
import { useState } from "react";

function InternalModal({ onCancel, ...props }) {
  const { Tag, setTagValue } = useOwnTag("ready");
  const [form] = Form.useForm();

  return (
    <OwnModal
      {...props}
      title="Internal"
      onCancel={() => {
        form.resetFields();
        setTagValue("waiting");
        onCancel();
      }}
    >
      <div className="flex flex-row items-center justify-end my-10">
        <Tag />
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          products: [""],
        }}
        onFinish={() => setTagValue("done")}
      >
        <div className="col-2">
          <Form.Item
            label="Contact"
            name="contact"
            rules={[{ required: true }]}
            required
          >
            <Select
              options={[{
                label: "Xyz",
                value: 1,
              }, {
                label: "Abc",
                value: 2,
              }]}
            />
          </Form.Item>
          <Form.Item
            label="Scheduled Date"
            name="scheduledDate"
            rules={[{ required: true }]}
            required
          >
            <DatePicker
              showTime={{
                format: "hh:mm a",
              }}
              format="YYYY-MM-DD hh:mm A"
            />
          </Form.Item>
          <Form.Item
            label="Source Location"
            name="sourceLocation"
            rules={[{ required: true }]}
            required
          >
            <Select
              options={[{
                label: "WH/Stock",
                value: 1,
              }, {
                label: "WH/Stock 2",
                value: 2,
              }]}
            />
          </Form.Item>
          <Form.Item
            label="Destination Location"
            name="destinationLocation"
            rules={[{ required: true }]}
            required
          >
            <Select
              options={[{
                label: "WH/Stock",
                value: 1,
              }, {
                label: "WH/Stock 2",
                value: 2,
              }]}
            />
          </Form.Item>
          <Form.Item
            label="Source Document"
            name="sourceDocument"
          >
            <OwnUpload />
          </Form.Item>
        </div>

        <Divider>Addtional Info</Divider>

        <div className="col-2">
          <Form.Item label="Shipping Policy" name="shippingPolicy">
            <Input />
          </Form.Item>
          <Form.Item label="Responsible" name="responsilbe">
            <Input />
          </Form.Item>
          <Form.Item className="col-span-2" label="Note" name="note">
            <Input.TextArea />
          </Form.Item>
        </div>

        <Divider>Operations</Divider>

        <Form.List name="products">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div
                  className="col-6 border-b border-solid border-b-blue-100 mt-9 py-5"
                  key={field.key}
                >
                  <Form.Item
                    className="col-span-2"
                    label="Name"
                    name={[field.name, "name"]}
                  >
                    <Select
                      options={[{
                        label: "Product 1",
                        value: 1,
                      }, {
                        label: "Product 2",
                        value: 2,
                      }]}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "reserved"]}
                    label="Reserve"
                    rules={[{ required: true }]}
                    required
                  >
                    <InputNumber className="w-full" min={0} />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "done"]}
                    label="Done"
                    rules={[{ required: true }]}
                    required
                  >
                    <InputNumber className="w-full" min={0} />
                  </Form.Item>
                  <Form.Item label=" ">
                    <Button
                      type="primary"
                      style={{ width: 50 }}
                      danger
                      block
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                    />
                  </Form.Item>
                </div>
              ))}
              <Button className="mt-8" onClick={add}>
                Add a Product
              </Button>
            </>
          )}
        </Form.List>

        <div className="col-5 mt-10">
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              onClick={() => setTagValue("draft")}
              block
            >
              Set Draft
            </Button>
          </Form.Item>
          <Form.Item>
            <Button size="large" block>
              Reserve
            </Button>
          </Form.Item>
          <Form.Item>
            <Button size="large" block>
              Unreserve
            </Button>
          </Form.Item>
          <Form.Item>
            <Button size="large" block>
              Check Availability
            </Button>
          </Form.Item>
        </div>
      </Form>
    </OwnModal>
  );
}

export default InternalModal;

import { css } from '@emotion/css';
import { Space } from 'antd';
import { analysisIcon } from 'assets/icons';
import React from 'react';
import Chart from 'react-apexcharts';

function RevenueUpdates() {
  const series = [
    {
      type: 'boxPlot',
      data: [
        {
          x: 'Jan 2017',
          y: [31, 39, 45, 51, 59],
        },
        {
          x: 'Jan 2018',
          y: [39, 46, 55, 65, 71],
        },
        {
          x: 'Jan 2019',
          y: [29, 31, 35, 39, 44],
        },
        {
          x: 'Jan 2020',
          y: [41, 49, 58, 61, 67],
        },
        {
          x: 'Jan 2021',
          y: [54, 59, 66, 71, 88],
        },
      ],
    },
  ];
  const options = {
    chart: {
      type: 'boxPlot',
      height: 50,
    },
    title: {
      text: 'Basic BoxPlot Chart',
      align: 'left',
    },
    plotOptions: {
      boxPlot: {
        width: 5,
        colors: {
          upper: '#5d87ff',
          lower: '#49beff',
        },
      },
    },
  };

  return (
    <div className="bg-white p-4 border rounded-xl shadow">
      <div>
        <h4 className="text-xl font-medium">Sales Overview</h4>
        <p className="text-gray-600">Every Month</p>
      </div>

      <div className="my-4 flex justify-center">
        <Chart options={options} series={series} type="boxPlot" width="500" />
      </div>
      <div className="flex justify-between gap-2 flex-wrap">
        {/*  */}
        <Space>
          <span className="inline-block w-6 border rounded p-0.5 border-[#49beff] text-[#49beff]">{analysisIcon}</span>
          <div className="flex flex-col">
            <span className="font-medium">$23,450</span>
            <span className="text-sm text-gray-600">Profit</span>
          </div>
        </Space>
        {/*  */}
        <Space>
          <span className="inline-block w-6 border rounded p-0.5 border-[#5d87ff] text-[#5d87ff]">{analysisIcon}</span>
          <div className="flex flex-col">
            <span className="font-medium">$23,450</span>
            <span className="text-sm text-gray-600">Expance</span>
          </div>
        </Space>
      </div>
    </div>
  );
}

export default RevenueUpdates;

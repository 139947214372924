import { useQuery } from '@tanstack/react-query';
import { getAllAppraisals } from 'network/Human-resources/appraisal';
import { queryKeys } from 'services/react-query/queryKeys';

function useAppraislList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.appraislList, searchQueryStr], () => getAllAppraisals(searchQueryStr));

  const appraislList = data?.model;

  return {
    appraislList,
    appraislListLod: isLoading,
  };
}

export default useAppraislList;

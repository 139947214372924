import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllGLValues = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/SegmentValues?${searchQuery}`);
  return data;
});

export const getGLValue = catchAsync(async id => {
  const { data } = await axiosInstance(`/SegmentValues/${id}`);
  return data;
});

export const createGLValue = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/SegmentValues`, body);
  return data;
});

export const updateGLValue = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/SegmentValues`, body);
  return data;
});

export const deleteGLValue = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/SegmentValues/${id}`);
  return data;
});

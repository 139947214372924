import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllStructures = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Structures?${searchQuery}`);
  return data;
});

export const getStructure = catchAsync(async id => {
  const { data } = await axiosInstance(`/Structures/${id}`);
  return data;
});

export const createStructure = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Structures`, body);
  return data;
});

export const updateStructure = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Structures`, body);
  return data;
});

export const deleteStructure = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Structures/${id}`);
  return data;
});

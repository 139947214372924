import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import ModalAddValueSet from 'components/modals/ModalAddValueSet';
import { deleteGLValueSet } from 'network/general-ledger/GLValueSets';
import useGLValueSetList from 'hooks/api/useGLValueSetList';

function ValueSets() {
  const { message } = App.useApp();
  const client = useQueryClient();
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const { GLValueSetList } = useGLValueSetList();

  const { mutate: onDeleteValueSet } = useMutation(deleteGLValueSet, {
    onSuccess: res => {
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.GLValueSetList]);
    },
  });

  const columns = [
    {
      title: 'name',
      dataIndex: 'nameEN',
      key: 'nameEN',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Maximum Size',
      dataIndex: 'maximumSize',
      key: 'maximumSize',
    },

    {
      title: 'Independent',
      key: 'isIndependent',
      dataIndex: 'isIndependent',
      render: val => <Switch checked={val} />,
    },
  ].concat([
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteValueSet(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ]);

  const data = GLValueSetList;

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Value Sets" />
        <SearchHero
          onAddBtn={() => {
            setIseModalUpdat(true);
          }}
        />
        <Table dataSource={data} sticky columns={columns} />
      </div>

      <ModalAddValueSet
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setIseModalUpdat(false);
          setEditItem(null);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
      {/* <ModalSegmentQualifiers open={isQualifiersMod} onCancel={() => setIsQualifiersMod(false)} /> */}
    </>
  );
}

export default ValueSets;

import { css } from '@emotion/css';
import { useIsFetching } from '@tanstack/react-query';
import { Spin } from 'antd';

function OwnFetchingIndicator() {
  const OwnFetchingIndicatorStyles = css`
    position: fixed;
    top: 50%;
    /* inset-inline-end: 2rem; */
    inset-inline-start: 50%;
    transform: translate(-50%, -50%);

    z-index: 999;
    opacity: 0.6;
  `;
  // const OwnFetchingIndicatorStyles = css`
  //   position: fixed;
  //   top: 1rem;
  //   /* inset-inline-end: 2rem; */
  //   inset-inline-start: 50%;
  //   transform: translateX(-50%);

  //   z-index: 999;
  //   opacity: 0.3;
  // `;
  const isFetching = useIsFetching();

  if (!isFetching) return null;

  return (
    <div className={OwnFetchingIndicatorStyles}>
      <Spin />
    </div>
  );
}

export default OwnFetchingIndicator;

import AddProducts from "pages/inventory/products/add-product/AddProducts";
import InventoryConfiguration from "pages/inventory/inventory-configration/InventoryConfiguration";
import Receipts from "pages/inventory/operation/Receipts";
import Deliveries from "pages/inventory/operation/Deliveries";
import CategoriesProduct from "pages/inventory/products/CategoriesProduct";
import Reordering from "pages/inventory/products/Reordering";
import WarehouseLocation from "pages/inventory/warehouse/WarehouseLocation";
import OperationType from "pages/inventory/warehouse/OperationType";
import Warehouse from "pages/inventory/warehouse/Warehouse";
import Internal from "pages/inventory/operation/Internal";
import Products from "pages/inventory/products/Products";

const routesInventory = [
  {
    path: "inventory",
    children: [
      {
        path: "operation",
        children: [
          {
            path: "deliveries",
            element: <Deliveries />,
          },
          {
            path: "receipts",
            element: <Receipts />,
          },
          {
            path: "internal",
            element: <Internal />,
          },
        ],
      },
      {
        path: "inventory-configration",
        element: <InventoryConfiguration />,
      },
      {
        path: "warehouse",
        children: [
          {
            index: true,
            element: <Warehouse />,
          },
          {
            path: "locations",
            element: <WarehouseLocation />,
          },
          {
            path: "operation-types",
            element: <OperationType />,
          },
          {
            path: "putaway-rules",
            element: <h1>putaway rules</h1>,
          },
          // {
          //   path: 'edit/:id',
          //   element: <h1>edit ware</h1>,
          // },
        ],
      },
      {
        path: "products",
        children: [
          { index: true, element: <Products /> },
          {
            path: "categories",
            element: <CategoriesProduct />,
          },
          {
            path: "reordering",
            element: <Reordering />,
          },
        ],
      },
    ],
  },
];

export default routesInventory;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllFixedAssetValues = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/FixedAssetValues?${searchQuery}`);
  return data;
});

export const getFixedAssetValue = catchAsync(async id => {
  const { data } = await axiosInstance(`/FixedAssetValues/${id}`);
  return data;
});

export const createFixedAssetValue = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/FixedAssetValues`, body);
  return data;
});

export const updateFixedAssetValue = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/FixedAssetValues`, body);
  return data;
});

export const deleteFixedAssetValue = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/FixedAssetValues/${id}`);
  return data;
});

import { useQuery } from '@tanstack/react-query';
import { getAllSalaryRules } from 'network/Human-resources/salary-rules';
import { queryKeys } from 'services/react-query/queryKeys';

function useSalaryRuleList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.salaryRuleList, searchQueryStr], () => getAllSalaryRules(searchQueryStr));

  const salaryRuleList = data?.model;

  return {
    salaryRuleList,
    salaryRuleListLod: isLoading,
  };
}

export default useSalaryRuleList;

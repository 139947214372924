import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import React, { useEffect } from 'react';

function ModalSalesInvoiceLines({ open, onCancel, salesInvoiceLines, setSalesInvoiceLines }) {
  console.log('ModalSalesInvoiceLines  salesInvoiceLines:', salesInvoiceLines);
  const [form] = Form.useForm();
  const metadata = useMetaData();

  useEffect(() => {
    if (!!salesInvoiceLines?.length) {
      form.setFieldsValue({ fields: salesInvoiceLines });
    } else {
      form.resetFields();
    }
  }, [salesInvoiceLines]);

  const onFinish = values => {
    console.log('ModalSalesInvoiceLines  values:', values);
    const lineList = values.fields?.map(field => ({
      item: field?.item,
      amount: field?.amount,
      itemUnitId: field?.itemUnitId,
      number: field?.number,
      lineType: field?.lineType,
      quantity: field?.quantity,
      unitPrice: field?.unitPrice,
      id: field?.id || null,
    }));

    setSalesInvoiceLines(lineList);
    onCancel();
  };
  return (
    <OwnModal
      // title={editItem ? 'Edit Segment' : 'Add Segment'}
      title={'Line Items'}
      open={open}
      onCancel={onCancel}
      width={1366}
      style={{ top: 20 }}>
      <div className="space-y-">
        <div className="max-w-4xl mx-auto items-center flex justify-between flex-wrap gap-2">
          <div>
            <label className="title-18">Transaction Total</label>
            <Input disabled size="large" value={5000} />
          </div>
          <div>
            <label className="title-18">Lines Total</label>
            <Input disabled size="large" value={12000} />
          </div>

          <Button type="primary">IMPORT</Button>
        </div>
        <Divider className="!max-w-3xl min-w-0 mx-auto" />
        <div className="bg-white rounded-lg">
          <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ fields: [undefined] }}>
            <Form.List name="fields">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <div className="col-7 bg-gray-50 p-3 rounded items-end">
                          <Form.Item name={[index, 'number']} label="Number">
                            <Input />
                          </Form.Item>
                          <Form.Item name={[index, 'lineType']} label="Line Type">
                            <Select options={metadata?.creditInvoiceLineTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>
                          <Form.Item name={[index, 'item']} label="Item">
                            <Input />
                          </Form.Item>
                          <Form.Item name={[index, 'itemUnitId']} label="UOM">
                            <Select options={metadata?.itemUnits?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>
                          <Form.Item name={[index, 'quantity']} label="Quantity">
                            <InputNumber className="w-full" />
                          </Form.Item>
                          <Form.Item name={[index, 'unitPrice']} label="Unit Price">
                            <InputNumber className="w-full" />
                          </Form.Item>
                          <Form.Item name={[index, 'amount']} label="Amount">
                            <InputNumber className="w-full" />
                          </Form.Item>
                        </div>

                        {fields.length > 1 ? (
                          <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                            Remove Above Field
                          </Button>
                        ) : null}
                      </div>
                    ))}
                    <Divider />
                    <Form.Item>
                      <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Space wrap className="flex justify-end mt-4">
              <Button type="primary" htmlType="submit">
                Done
              </Button>
            </Space>
          </Form>
        </div>
      </div>
    </OwnModal>
  );
}

export default ModalSalesInvoiceLines;

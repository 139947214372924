import { Table } from "antd";
import ActionsMenu from "components/common/ActionsMenu";
import SearchHero from "components/common/SearchHero";
import DeleteExpenditureTypeModal from "components/modals/project-manager/setup/DeleteExpenditureTypeModal";
import ExpenditureTypeModal from "components/modals/project-manager/setup/ExpenditureTypeModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useExpenditureTypes } from "hooks/api/projects-management/setup/expenditureType";
import useModalParams from "hooks/useModalParams";
import { Link } from "react-router-dom";
import { dateFormat } from "utils/utils";

const Ratables = [1, 4, 2, 3];

function ExpenditureTypes() {
  const {
    openAsNew: newExpenditureType,
    openAsEdit: editExpenditureType,
    openAsDelete: deleteExpenditureType,
  } = useModalParams("expenditure-type");
  const { types } = useExpenditureTypes();

  console.log(types, "types in type page");

  const columns = [
    {
      title: "Name",
      key: "name",
      fixed: "left",
      render: (_, type) => (
        <>
          {Ratables.includes(type?.projectCategoryId)
            ? (
              <Link className="text-color-primary" to={`${type?.id}/rates`}>
                {type?.name}
              </Link>
            )
            : (type?.name)}
        </>
      ),
    },
    {
      title: "Expenditure Category",
      key: "expenditureCategory",
      dataIndex: "expenditureCategory",
      children: [
        {
          title: "Name",
          key: "ecName",
          render: (_, type) => type?.expenditureCategory?.nameEN,
        },
        {
          title: "Effective From",
          key: "ecEffctiveFrom",
          render: (_, type) =>
            dateFormat(type?.expenditureCategory?.effectiveDateFrom),
        },
        {
          title: "Effective To",
          dataIndex: "ecEffctiveTo",
          key: "ecEffctiveTo",
          render: (_, type) =>
            dateFormat(type?.expenditureCategory?.effectiveDateTo),
        },
      ],
    },
    {
      title: "Unit of Measurement",
      key: "uof",
      children: [
        {
          title: "Name",
          dataIndex: "uofName",
          key: "uofName",
          render: (_, type) => type?.unitOfMeasurement?.nameEN,
        },
        {
          title: "Effective From",
          dataIndex: "uofEffectiveFrom",
          key: "uofEffectiveFrom",
          render: (_, type) =>
            dateFormat(type?.unitOfMeasurement?.effectiveDateFrom),
        },
        {
          title: "Effective To",
          dataIndex: "uofEffectiveTo",
          key: "uofEffectiveTo",
          render: (_, type) =>
            dateFormat(type?.unitOfMeasurement?.effectiveDateTo),
        },
      ],
    },
    {
      title: "Action",
      key: "action",
      render: (_, type) => {
        const items = [
          {
            label: "Edit",
            onClick: () => editExpenditureType({ id: type?.id }),
          },
          {
            label: "Delete",
            onClick: () => deleteExpenditureType({ id: type?.id }),
            danger: true,
          },
        ];

        // if (Ratables.includes(type?.expenditureCategory?.id)) {
        //   items.splice(1, 0, {
        //     label: "Define Rate",
        //     onClick: () => newRate({ ec: type?.expenditureCategory?.id }),
        //   });
        // }

        return (
          <ActionsMenu
            items={items}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Project Managment / Setup / Expenditure Types" />
        <SearchHero onAddBtn={() => newExpenditureType()} />
        <Table
          sticky
          dataSource={types ?? []}
          columns={columns}
        />
      </div>
      <ExpenditureTypeModal />
      <DeleteExpenditureTypeModal />
    </>
  );
}

export default ExpenditureTypes;

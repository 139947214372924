import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getAll = catchAsync(async ({ word, Page } = {}) => {
  const { data } = await axiosInstance.get("/ProjectUnitOfMeasurements", {
    params: {
      word,
      Page,
    },
  });
  return data;
});

export const get = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/ProjectUnitOfMeasurements/${id}`);
  return data;
});

export const create = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/ProjectUnitOfMeasurements`, body);
  return data;
});

export const update = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/ProjectUnitOfMeasurements`, body);
  return data;
});

export const delete_ = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(
    `/ProjectUnitOfMeasurements/${id}`,
  );
  return data;
});

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getAll = catchAsync(async ({ word, Page } = {}) => {
  const { data } = await axiosInstance.get("/ProjectCategoryRates", {
    params: {
      word,
      Page,
    },
  });
  return data;
});

export const get = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/ProjectCategoryRates/${id}`);
  return data;
});

export const create = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/ProjectCategoryRates`, body);
  return data;
});

export const update = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/ProjectCategoryRates`, body);
  return data;
});

export const delete_ = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/ProjectCategoryRates/${id}`);
  return data;
});

export const getAllByExpenditureTypeId = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(
    `/ProjectCategoryRates/ExpenditureType/${id}`,
  );
  return data;
});

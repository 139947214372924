import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getSalesTeam = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/SalesTeam`);
  return data;
});

export const getSalesTeamMembers = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/SalesPerson/getmembers`);
  return data;
});

export const getSalesTeamLead = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/SalesPerson/getleads`);
  return data;
});

export const addSalesTeam = catchAsync(async (body) => {
  try {
    const res = await axiosInstance.post(`/SalesTeam`, body);
    return res;
  } catch (error) {
    return error;
  }
});

export const addSalesMember = catchAsync(async (body) => {
  try {
    const res = await axiosInstance.post(`/SalesPerson/members`, body);
    return res;
  } catch (error) {
    return error;
  }
});

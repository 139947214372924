import { App, Button, DatePicker, Form, Input, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import constents from 'utils/constants';
import { createPayslip, getPayslip, updatePayslip } from 'network/Human-resources/payslips';
import useContractList from 'hooks/api/useContractList';
import useSalaryStructureList from 'hooks/api/useSalaryStructureList';

function PaySlipNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { contractList, contractListLod } = useContractList();
  const { salaryStructureList, salaryStructureListLod } = useSalaryStructureList();

  const client = useQueryClient();
  const { data: cusromerData } = useQuery([queryKeys.paySlipList, params?.id], () => getPayslip(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.paySlipList]);

    form.resetFields();
    navigate('/human-resources/payroll/pay-slip');
  };

  const { mutate: onCreatePaySlip, isLoading: onCreatePaySlipLod } = useMutation(createPayslip, {
    onSuccess,
  });
  const { mutate: onUpdatePaySlip, isLoading: onUpdatePaySlipLod } = useMutation(updatePayslip, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && cusromerData) {
      const editItem = cusromerData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['fromPeriodDate']), dayjs(dataObj['toPeriodDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, cusromerData, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['fromPeriodDate'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['toPeriodDate'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = cusromerData?.model?.id;
      return onUpdatePaySlip(dataObj);
    }

    onCreatePaySlip(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Payroll' }, { title: <Link to="/human-resources/payroll/work-entry/pay-slip">Contracts</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="nameEN" label="Name english" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Name arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="fromTo" label="From - To" rules={[{ required: true }]}>
              <DatePicker.RangePicker
                className="w-full"
                format="YYYY-MM-DD"
                disabledDate={current => {
                  // Can not select days before today and today
                  return current && current < dayjs().endOf('day');
                }}
              />
            </Form.Item>
            <Form.Item name="batch" label="Batch" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item name="employeeContractId" label="Employee contract" rules={[{ required: true }]}>
              <Select
                loading={contractListLod}
                options={contractList?.map(el => ({
                  value: el?.id,
                  label: el?.name,
                }))}
              />
            </Form.Item>
            <Form.Item name="salaryStructureId" label="Salary structure" rules={[{ required: true }]}>
              <Select
                loading={salaryStructureListLod}
                options={salaryStructureList?.map(el => ({
                  value: el?.id,
                  label: el?.name,
                }))}
              />
            </Form.Item>
            <Form.Item name="notes" label="Notes" rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreatePaySlipLod || onUpdatePaySlipLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default PaySlipNew;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addEmployee, getAllEmployees } from "network/Human-resources/employees";
import { queryKeys } from "services/react-query/queryKeys";

export const useEmployee = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.employee, id],
    queryFn: () => getAllEmployees(),
  });
  const employeeData = data?.model;
  return { employeeData, ...rest };
};

export const useCreateEmployee = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addEmployee,
    onSuccess: () => client.invalidateQueries([queryKeys.employee]),
  });
  mutation.createEmployee = mutation.mutate;
  mutation.isCreatingEmployee = mutation.isLoading;
  return mutation;
};

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Progress } from 'antd';
import React, { useState } from 'react';
import { arrayFrom, randomNumber } from 'utils/utils';
import LeadsItemCard from './LeadsItemCard';

function LeadsItem({ title, total, list }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="bg-gray-100 ">
      <div className="py-2 px-1">
        <div className="flex items-center justify-between gap-x-12 gap-y-3">
          <h4>{title}</h4>
          <Button
            type="link"
            icon={collapsed ? <PlusOutlined /> : <MinusOutlined />}
            size="small"
            className="!p-0 !min-h-0 h-7 !text-gray-700"
            onClick={() => setCollapsed(prev => !prev)}
          />
        </div>

        <div className="flex items-center gap-x-2">
          <div className="flex-1">
            <Progress showInfo={false} percent={randomNumber(10, 100)} strokeColor="#ccc" />
          </div>
          <span className="text-sm">{total}</span>
        </div>
      </div>

      <div className="space-y-3 ">
        {collapsed ? (
          <div className="h-3 bg-white hover:bg-gray-200 rounded-lg transition" onClick={() => setCollapsed(false)} />
        ) : (
          <>
            {arrayFrom(4)?.map((el, i) => (
              <LeadsItemCard key={i} el={el} />
            ))}
            <div className="h-3 bg-white hover:bg-gray-200 rounded-lg transition" onClick={() => setCollapsed(true)} />
          </>
        )}
      </div>
    </div>
  );
}

export default LeadsItem;

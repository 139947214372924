import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import {
  create,
  delete_,
  get,
  getAll,
  update,
} from "network/project-managments/setup/expenditureType";

import { get as getUnit } from "network/project-managments/setup/unitsOfMeasures";
import useMetaData from "hooks/api/useMetaData";

export const useExpenditureTypes = () => {
  const { projectCategories } = useMetaData();
  const query = useQuery({
    queryFn: getAll,
    queryKey: [queryKeys.expenditureTypes],
  });

  const queries = useQueries({
    queries: query.data?.model?.map((type) => {
      return {
        queryKey: [
          queryKeys.expenditureTypes,
          type?.projectUnitOfMeasurementId,
        ],
        queryFn: () => getUnit(type?.projectUnitOfMeasurementId),
      };
    }) ?? [],
  });

  const types = query.data?.model?.map((type, i) => {
    return {
      ...type,
      unitOfMeasurement: {
        ...queries?.[i].data?.model,
      },
      expenditureCategory: {
        ...projectCategories?.filter((category) =>
          category?.id === type?.projectCategoryId
        )?.[0],
      },
    };
  });

  query.types = types;
  return query;
};

export const useExpenditureType = (id) => {
  const query = useQuery({
    queryFn: () => get(id),
    queryKey: [queryKeys.expenditureTypes, id],
    enabled: Boolean(id),
  });

  query.type = query.data?.model;
  return query;
};

export const useCreateExpenditureType = () => {
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: create,
    onSuccess: () => client.invalidateQueries([queryKeys.expenditureTypes]),
  });

  mutation.create = mutation.mutate;
  mutation.isCreating = mutation.isLoading;

  return mutation;
};

export const useUpdateExpenditureType = (id) => {
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: update,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.expenditureTypes]);
      client.invalidateQueries([queryKeys.expenditureTypes, id]);
    },
  });

  mutation.update = mutation.mutate;
  mutation.isUpdating = mutation.isLoading;

  return mutation;
};

export const useDeleteExpenditureType = () => {
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: delete_,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.expenditureTypes]);
    },
  });

  mutation.delete_ = mutation.mutate;
  mutation.isDeleting = mutation.isLoading;

  return mutation;
};

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllFixedAssetValueSets = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/FixedAssetValueSets?${searchQuery}`);
  return data;
});

export const getFixedAssetValueSet = catchAsync(async id => {
  const { data } = await axiosInstance(`/FixedAssetValueSets/${id}`);
  return data;
});

export const createFixedAssetValueSet = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/FixedAssetValueSets`, body);
  return data;
});

export const updateFixedAssetValueSet = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/FixedAssetValueSets`, body);
  return data;
});

export const deleteFixedAssetValueSet = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/FixedAssetValueSets/${id}`);
  return data;
});

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getOperationType = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/OperationType/${id}`);
  return data;
});

export const getOperationTypes = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/OperationType`);
  return data;
});

export const getOperationTypeByName = catchAsync(async (name) => {
  const { data } = await axiosInstance.get(`/OperationType/getbyname/${name}`);
  return data;
});

export const createOperationType = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/OperationType`, body);
  return data;
});

export const updateOperationType = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/OperationType`, body);
  return data;
});

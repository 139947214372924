import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, InputNumber, Select } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { addressCreate } from 'network/address';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from 'services/store/slices/user';

function ModalAddress({ open, onCancel, editItem, setEditItem }) {
  console.log('ModalAddress  editItem:', editItem);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { message } = App.useApp();
  const userData = useSelector(s => s.user?.data);

  const { cities, countries, metaDataLod } = useMetaData();
  console.log('ModalAddress  countries:', countries);
  const client = useQueryClient();

  const { mutate: onAddressCreateOrUpdate, isLoading: addressCreateOrUpdateLod } = useMutation(addressCreate, {
    onSuccess: res => {
      console.log('AddressWrapper  res:', res);

      const newUserData = { ...userData, address: res?.model };
      dispatch(setUserData(newUserData));
      message.success(res?.metas?.message);

      onCancel?.();
    },
  });

  const onFinish = values => {
    console.log('ModalAddress  values:', values);
    const data = { ...values };
    console.log('onFinish  data:', data);

    if (!!editItem) data.Id = editItem.id;

    onAddressCreateOrUpdate(data);
  };
  return (
    <OwnModal title={editItem ? 'Edit Address' : 'Add Address'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" initialValues={{ ...editItem }} form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name="state" label="State" rules={[{ required: true }]}>
            <Select
              loading={metaDataLod}
              options={countries?.map(el => ({
                value: el?.nameEN,
                label: el?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="cityId" label="City" rules={[{ required: true }]}>
            <Select
              loading={metaDataLod}
              options={cities?.map(el => ({
                value: el?.id,
                label: el?.nameEN,
              }))}
            />
          </Form.Item>

          <Form.Item name="location" label="Location" rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
          </Form.Item>
          <Form.Item name="postalCode" label="Postal Code" rules={[{ required: true }]}>
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item name="address1" label="Address 1" rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
          </Form.Item>
          <Form.Item name="address2" label="Address 2">
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
          </Form.Item>
        </div>

        <Button loading={addressCreateOrUpdateLod} type="primary" htmlType="submit" className="block ms-auto w-full max-w-xs">
          DONE
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalAddress;

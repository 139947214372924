import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addWorkingLocations,
  getWorkingLocations,
  updateWorkingLocations,
} from "network/Human-resources/work-locations";
import { queryKeys } from "services/react-query/queryKeys";

export const useWorkingLocations = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.workLocations, id],
    queryFn: () => getWorkingLocations(id),
  });
  const workLocationsData = data?.model;
  return { workLocationsData, ...rest };
};

export const useCreateWorkLocations = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addWorkingLocations,
    onSuccess: () => client.invalidateQueries([queryKeys.workLocations]),
  });
  mutation.createWorkLocations = mutation.mutate;
  mutation.isCreatingWorkLocations = mutation.isLoading;
  return mutation;
};

export const useUpdateWorkLocations = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateWorkingLocations,
    onSuccess: () => client.invalidateQueries([queryKeys.workLocations]),
  });
  mutation.updateWorkLocations = mutation.mutate;
  mutation.isUpdateWorkLocations = mutation.isLoading;
  return mutation;
};

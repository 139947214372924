import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";


export const getExpensesCategory = catchAsync(async (id) => {
    const { data } = await axiosInstance.get("/EmployeeExpenseCategories", {
      params: {
        id,
      },
    });
    return data;
  });
  
  export const addExpensesCategory = catchAsync(async (body) => {
    const res = await axiosInstance.post("/EmployeeExpenseCategories", body);
    return res;
  });
  
  export const updateExpensesCategory = catchAsync(async (body) => {
    const res = await axiosInstance.put("/EmployeeExpenseCategories", body);
    return res;
  });
  
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, Space, Tabs } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
// import useARSystemOptions from 'hooks/api/useARSystemOptions';
// import { updateARSystemOptions } from 'network/account-receivable/system-options';
import { createFABook } from 'network/fixed-assets/book';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import AccountingRules from './tabs/AccountingRules';
import Calendar from './tabs/Calendar';
// import NaturalAccounts from './tabs/NaturalAccounts';

function Book() {
  const { tabKey } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const client = useQueryClient();

  // const { ARSystemOptions } = useARSystemOptions();
  // console.log('SystemOption  ARSystemOptions:', ARSystemOptions);

  const { mutate: onCreateFABook, isLoading: createFABookLod } = useMutation(createFABook, {
    onSuccess: res => {
      console.log('createCustome  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.ARSystemOptions]);

      // form.resetFields();
    },
  });

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.allowTaxExemptions = !!dataObj.allowTaxExemptions;
    dataObj.allowTaxExceptions = !!dataObj.allowTaxExceptions;
    dataObj.revalueAccumulatedDepreciation = !!dataObj.revalueAccumulatedDepreciation;
    dataObj.revalueYTDDepreciation = !!dataObj.revalueYTDDepreciation;
    dataObj.retireRevaluationReserve = !!dataObj.retireRevaluationReserve;
    dataObj.amortizeRevaluationReserve = !!dataObj.amortizeRevaluationReserve;
    dataObj.revalueFullyReservedAssets = !!dataObj.revalueFullyReservedAssets;
    console.log('ModalStructure  dataObj:', dataObj);
    // return;
    onCreateFABook(dataObj);
    // if (editItem) {
    //   dataObj.id = editItem.id;
    //   onUpdateAPRecurringInvoice(dataObj);
    // } else {
    //   onCreateAPRecurringInvoice(dataObj);
    // }
  };

  // useEffect(() => {
  //   if (ARSystemOptions) {
  //     // console.log('useEffect  editItem:', editItem);
  //     const editItem = ARSystemOptions;
  //     const dataObj = { ...editItem };

  //     form.setFieldsValue(dataObj);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [ARSystemOptions]);

  const items = [
    {
      key: 'calendar',
      label: 'Calendar',
      children: <Calendar />,
      forceRender: true,
    },
    {
      key: 'accounting-rules',
      label: 'AccountingRules',
      children: <AccountingRules />,
      forceRender: true,
    },
    // {
    //   key: 'natural-accounts',
    //   label: 'NaturalAccounts',
    //   children: <NaturalAccounts />,
    //   forceRender: true,
    // },
  ];

  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Book" />
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="bg-white py-6 px-6 rounded-lg mb-6">
          <div className="col-2">
            <Form.Item name="name" label="Book">
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>
        </div>
        <div className="bg-white py-6 px-6 rounded-lg">
          <Tabs activeKey={tabKey || 'calendar'} onChange={key => navigate('/fixed-assets/setup/book/' + key)} items={items} />
        </div>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createFABookLod}>
            CONFIRM
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default Book;

import { useQuery } from '@tanstack/react-query';
import { getAllCustomers } from 'network/account-receivable/customer';
import { queryKeys } from 'services/react-query/queryKeys';

function useCustomerList(searchQueryStr) {
  const { data, isLoading } = useQuery(
    [queryKeys.customerList, searchQueryStr],
    () => getAllCustomers(searchQueryStr)
  );

  const customerList = data?.model;

  return {
    customerList,
    customerListLod: isLoading,
  };
}

export default useCustomerList;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllAPRecurringInvoices = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/RecurringInvoices?${searchQuery}`);
  return data;
});

export const getAPRecurringInvoice = catchAsync(async id => {
  const { data } = await axiosInstance(`/RecurringInvoices/${id}`);
  return data;
});

export const createAPRecurringInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/RecurringInvoices`, body);
  return data;
});

export const updateAPRecurringInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/RecurringInvoices`, body);
  return data;
});

export const deleteAPRecurringInvoice = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/RecurringInvoices/${id}`);
  return data;
});

export const searchAPRecurringInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/RecurringInvoices/Search`, body);
  return data;
});

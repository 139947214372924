import { useQuery } from '@tanstack/react-query';
import { getAllAPInvoices } from 'network/account-payable/invoices';
import { queryKeys } from 'services/react-query/queryKeys';

function useAPInvoicesList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.APInvoicesList, searchQueryStr], () => getAllAPInvoices(searchQueryStr));

  const APInvoicesList = data?.model;

  return {
    APInvoicesList,
    APInvoicesListLod: isLoading,
  };
}

export default useAPInvoicesList;

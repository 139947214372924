import catchAsync from "utils/catchAsync";
import { axiosInstance } from "services/axiosInstance";

export const getSubTasks = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/ProjectSubTasks`);
  return data;
});

export const getSubTask = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/ProjectSubTasks/${id}`);
  return data;
});

export const createSubTask = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/ProjectSubTasks`, body);
  return data;
});

export const updateSubTask = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/ProjectSubTasks`, body);
  return data;
});

export const deleteSubTask = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/ProjectSubTasks/${id}`);
  return data;
});

import { useQueries, useQuery } from "@tanstack/react-query";
import { getWarehouses } from "network/supply-chain/warehouse";
import { getWarehouseLocation } from "network/supply-chain/warehouseLocation";
import { queryKeys } from "services/react-query/queryKeys";

const useWarehouses = () => {
  const warehouses = useQuery({
    queryFn: getWarehouses,
    queryKey: [queryKeys.warehouse],
    select: (data) => data?.model,
  })

  const warehousesLocation = useQueries({
    queries: warehouses?.data
      ? warehouses?.data?.map(({ locationId }) => ({
        queryKey: ["location", locationId],
        queryFn: () => getWarehouseLocation(locationId),
        select: (data) => data?.model,
      }))
      : [],
  });

  const result = warehouses?.data?.map((warehouse, index) => {
    return {
      ...warehouse,
      location: warehousesLocation?.[index]?.data,
    };
  });

  return {
    ...warehouses,
    data: result,
  };
};

export default useWarehouses;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDepartment, getDepartment } from "network/Human-resources/department";
import { queryKeys } from "services/react-query/queryKeys";

export const useDepartments = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.departments, id],
    queryFn: () => getDepartment(id),
  });
  const departmentData = data?.model;
  return { departmentData, ...rest };
};

export const useCreateDepartment = () => {
    const client = useQueryClient();
    const mutation = useMutation({
      mutationFn: addDepartment,
      onSuccess:()=>client.invalidateQueries([queryKeys.departments])
    });
    mutation.createDepartment = mutation.mutate;
    mutation.isCreatingDepartment = mutation.isLoading;
    return mutation;
  };
  
import { useQuery } from '@tanstack/react-query';
import { getAllFixedAssets } from 'network/fixed-assets/fixedAssets';
import { queryKeys } from 'services/react-query/queryKeys';

function useFixedAssetList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.fixedAssetList, searchQueryStr], () => getAllFixedAssets(searchQueryStr));

  const fixedAssetList = data?.model;

  return {
    fixedAssetList,
    fixedAssetListLod: isLoading,
  };
}

export default useFixedAssetList;

import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React from 'react';

function ActionsMenu({ items }) {
  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items,

        style: {
          minWidth: 140,
        },
      }}>
      <Button icon={<MoreOutlined />} className="block mx-auto" />
    </Dropdown>
  );
}

export default ActionsMenu;

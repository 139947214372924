import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import useModalParams from "hooks/useModalParams";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useCreateDepartment } from "hooks/human-resourses/useDepartment";
import {
  useCreateWorkTimes,
  useUpdateWorkTimes,
} from "hooks/human-resourses/useWorkTimes";
import {
  useCreateWorkLocations,
  useUpdateWorkLocations,
} from "hooks/human-resourses/useWorkingLocations";
import dayjs from "dayjs";

function WorkTimesModal({ open, handelClose, LocationId }) {
  const { createWorkTimes, isCreatingWorkTimes } = useCreateWorkTimes();
  const { updateWorkTimes, isUpdatingWorkTimes } = useUpdateWorkTimes();
  const onFinish = (values) => {
    console.log(
      dayjs(values?.workFrom).format("HH:mm:ss"),
      "ididid"
    );

    if (!LocationId) {
      createWorkTimes(
        {
          ...values,
          workFrom: dayjs(values?.workFrom).format("HH:mm:ss"),
          workTo: dayjs(values?.workTo).format("HH:mm:ss"),
          // addressId: "2F1C2BD7-DB31-45BB-E54B-08DBA3695500",
        },
        {
          onSuccess: () => {
            message.success("Created successfully");
            handelClose();
          },
          onError: (error) => message.error(`Error: ${error?.message}`),
        }
      );
    } else {
      updateWorkTimes(
        {
          ...values,
          id: LocationId,
          workFrom: dayjs(values?.workFrom).format("HH:mm:ss"),
          workTo: dayjs(values?.workTo).format("HH:mm:ss"),
        },
        {
          onSuccess: () => {
            message.success("Updated successfully");
            handelClose();
          },
          onError: (error) => message.error(`Error: ${error?.message}`),
        }
      );
    }
  };
  return (
    <OwnModal
      title={!LocationId ? "Add Work Times" : "Edit Work Times"}
      open={open}
      onCancel={() => handelClose()}
      width={1200}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-2 py-4">
          <Form.Item
            label="Name English"
            name="nameEN"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            {/* <DatePicker picker={"time"} /> */}
            <Input />
          </Form.Item>
          <Form.Item
            label="Name Arabic"
            name="nameAr"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            {/* <DatePicker picker={"time"} /> */}
            <Input />
          </Form.Item>
          <Form.Item
            label="Day Of Week"
            name="dayOfWeek"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  label: "Saturday",
                  value: 6,
                },
                {
                  label: "Sunday",
                  value: 0,
                },
                {
                  label: "Monday",
                  value: 1,
                },
                {
                  label: "Tuesday",
                  value: 2,
                },
                {
                  label: "wednesday",
                  value: 3,
                },
                {
                  label: "Thursday",
                  value: 4,
                },
                {
                  label: "Friday",
                  value: 5,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Day Shift"
            name="dayShift"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  label: "Morning",
                  value: 0,
                },
                {
                  label: "Between",
                  value: 1,
                },
                {
                  label: "Night",
                  value: 2,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Work Entry Type"
            name="workEntryType"
            rules={[{ required: true }]}
          >
            <Select
              options={[
                {
                  label: "Attendance",
                  value: 0,
                },
                {
                  label: "Time Sheet",
                  value: 1,
                },
              ]}
            />
          </Form.Item>
          {/* {/* <Form.Item label="Company Full Time" name="Company Full Time">
            <Input type="number" />
          </Form.Item> */}
          <Form.Item
            label="Work From"
            name="workFrom"
            rules={[{ required: true }]}
          >
            <DatePicker picker={"time"} />
          </Form.Item>
          <Form.Item label="Work To" name="workTo" rules={[{ required: true }]}>
            <DatePicker picker={"time"} />
          </Form.Item>
        </div>
        {/* <Form.List
          name="supplierPriceLines"
          initialValue={[""]} // Initial value, can be an array with default items
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                  <Form.Item
                    {...restField}
                    name={[name, "Name"]}
                    fieldKey={[fieldKey, "Name"]}
                    label="Name"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "Day Of Week"]}
                    fieldKey={[fieldKey, "Day Of Week"]}
                    label="Day Of Week"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: "Saturday",
                          value: 1,
                        },
                        {
                          label: "Sunday",
                          value: 2,
                        },
                        {
                          label: "Monday",
                          value: 3,
                        },
                        {
                          label: "Tuesday",
                          value: 4,
                        },
                        {
                          label: "wednesday",
                          value: 5,
                        },
                        {
                          label: "Thursday",
                          value: 6,
                        },
                        {
                          label: "Friday",
                          value: 7,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "Day Period"]}
                    fieldKey={[fieldKey, "Day Period"]}
                    label="Day Period"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: "Morning",
                          value: 1,
                        },
                        {
                          label: "Between",
                          value: 2,
                        },
                        {
                          label: "Night",
                          value: 3,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "Work Entry Type"]}
                    fieldKey={[fieldKey, "Work Entry Type"]}
                    label="Work Entry Type"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      options={[
                        {
                          label: "Attendance",
                          value: 1,
                        },
                        {
                          label: "Time Sheet",
                          value: 2,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "Work From"]}
                    fieldKey={[fieldKey, "Work From"]}
                    label="Work From"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker picker={"time"} />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "Work To"]}
                    fieldKey={[fieldKey, "Work To"]}
                    label="Work To"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker picker={"time"} />
                  </Form.Item>

                  {fields.length > 1 && (
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                      }}
                      style={{
                        margin: "8px 8px 0 0",
                        fontSize: "20px",
                        color: "#ff4d4f",
                      }}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  Add New Line
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List> */}
        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={LocationId ? isUpdatingWorkTimes : isCreatingWorkTimes}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default WorkTimesModal;

import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import useModalParams from "hooks/useModalParams";
import useMetaData from "hooks/api/useMetaData";
import {
  useCreateDepartment,
  useDepartments,
} from "hooks/human-resourses/useDepartment";
import { useEmployee } from "hooks/human-resourses/useEmployee";

function DepartmentModal({ open, handelClose }) {
  const res = useMetaData();
  const { createDepartment, isCreatingDepartment } = useCreateDepartment();
  const { employeeData } = useEmployee();
  const { departmentData } = useDepartments();

  const onFinish = (values) => {
    createDepartment(
      {
        values,
      },
      {
        onSuccess: () => {
          message.success("Created successfully");
          handelClose();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      }
    );
  };
  return (
    <OwnModal
      title={open ? "Add Department" : "Edit Department"}
      open={open}
      onCancel={() => handelClose()}
      width={500}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-1 py-4">
          <Form.Item
            label="Name English"
            name="nameEN"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name Arabic"
            name="nameAR"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Manager Name"
            name="managerId"
            rules={[{ required: true }]}
          >
            <Select
              options={employeeData?.map((s) => ({
                value: s?.managerId,
                label: s?.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="parent Department"
            name="parentDepartmentId"
            rules={[{ required: true }]}
          >
            <Select
              options={departmentData?.map((e) => ({
                label: e?.nameEN,
                value: e?.id,
              }))}
            />
          </Form.Item>
        </div>
        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={isCreatingDepartment}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default DepartmentModal;

import AssetsWorkbench from 'pages/fixed-assets/asset-workbench/AssetsWorkbench';
import AssetWorkbenchNew from 'pages/fixed-assets/asset-workbench/AssetWorkbenchNew';
import Book from 'pages/fixed-assets/Book/Book';
import FixedAssetSegments from 'pages/fixed-assets/Structure/segments/FixedAssetSegments';
import StructureDefine from 'pages/fixed-assets/Structure/FixedAssetStructures';
import FixedAssetValues from 'pages/fixed-assets/Structure/Values/FixedAssetValues';
import FixedAssetValueSets from 'pages/fixed-assets/Structure/value-sets/FixedAssetValueSets';

const routesFixedAssets = [
  {
    path: 'fixed-assets',
    children: [
      {
        path: 'asset-workbench',
        children: [
          {
            index: true,
            element: <AssetsWorkbench />,
          },
          {
            path: 'new',
            element: <AssetWorkbenchNew />,
          },
          {
            path: 'edit/:id',
            element: <AssetWorkbenchNew isEdit />,
          },
        ],
      },
      {
        path: 'setup',
        children: [
          {
            path: 'structure',
            children: [
              {
                path: 'define',
                element: <StructureDefine />,
              },
              {
                path: 'segments',
                element: <FixedAssetSegments />,
              },
              {
                path: 'value-sets',
                element: <FixedAssetValueSets />,
              },
              {
                path: 'values',
                element: <FixedAssetValues />,
              },
            ],
          },
          {
            path: 'book',
            children: [
              {
                index: true,
                element: <Book />,
              },
              {
                path: ':tabKey',
                element: <Book />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routesFixedAssets;

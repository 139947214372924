import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Badge, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalJournalsRecurringLines from 'components/modals/ModalJournalsRecurringLines';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useJournalRecurringList from 'hooks/api/useJournalRecurringList';
import { postManualJournal } from 'network/general-ledger/journals';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';

function JournalsRecurring() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [journalRecurringAddLines, setJournalRecurringAddLines] = useState(null);
  const client = useQueryClient();
  const { mutate: onPostManualJournal } = useMutation(postManualJournal, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.journalManualList]);
    },
  });
  const { journalRecurringList } = useJournalRecurringList();

  const columns = [
    {
      key: 'nameEN',
      dataIndex: 'nameEN',
      title: 'name',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'journals',
      dataIndex: 'journals',
      title: 'journals',
      render: (val, _) => {
        return <Badge count={val?.length || 0} showZero />;
      },
    },

    {
      key: 'enableSecurity',
      dataIndex: 'enableSecurity',
      title: 'Security',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    // {
    //   key: 'isDeleted',
    //   dataIndex: 'isDeleted',
    //   title: 'Deleted',
    //   render: (val, _) => {
    //     return <Switch checked={val} />;
    //   },
    // },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'add-lines',
              label: 'Add Lines',
              onClick: () => setJournalRecurringAddLines(record),
              disabled: record?.isDeleted,
            },
            // {
            //   key: 'edit',
            //   label: 'Edit',
            //   onClick: () => navigate(`edit/${record.id}`),
            //   disabled: record?.isDeleted,
            // },
            // {
            //   key: 'Delete',
            //   label: 'Delete',
            //   onClick: () => onDeleteReceipt(record?.id),
            //   disabled: record?.isDeleted,
            // },
          ]}
        />
      ),
    },
  ];
  const data = journalRecurringList;
  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Automatic Journals" />
        <SearchHero onAddBtn={() => navigate('/general-ledger/journals/automatic/new')} />
        <Table
          dataSource={data}
          sticky
          columns={columns}
          // scroll={{ y: '66vh' }}
        />
      </div>
      <ModalJournalsRecurringLines journalRecurring={journalRecurringAddLines} onCancel={() => setJournalRecurringAddLines(null)} />
    </>
  );
}

export default JournalsRecurring;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import { addPurchasingRequest, getPurchasingRequest } from "network/supply-chain/purchasingRequest";

export const usePurchasingRequest = () => {
  const { data, ...rest } = useQuery({
    queryFn: getPurchasingRequest,
    queryKey: [queryKeys.purchasingRequest],
  });
  const purchasingRequest = data?.model;
  return { purchasingRequest, ...rest };
};

export const useCreatePurchasingRequest = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addPurchasingRequest,
    onSuccess: () => client.invalidateQueries([queryKeys.purchasingRequest]),
  });
  const { mutate, isLoading } = mutation;
  return { mutate, isLoading };
};

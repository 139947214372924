import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  message,
} from "antd";
import OwnModal from "components/own/OwnModal";
import React, { useState } from "react";
import ModalStatementLines from "./ModalStatementLines";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import useMetaData from "hooks/api/useMetaData";
import { createTaxes, updateTaxes } from "network/taxes/taxes";
import { queryKeys } from "services/react-query/queryKeys";
import constents from "utils/constants";
import {
  createBankStatements,
  updateBankStatements,
} from "network/cash-managment/bankStatment";
import { getAllCurrencies } from "network/general-ledger/currency";
import { searchBankAccount } from "network/cash-managment/bank";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
function ModalCashStatmenet({ open, onCancel, editItem, setEditItem }) {
  const [form] = Form.useForm();
  const [isLinesMod, setIsLinesMod] = useState(false);
  const [bankStatmentInfo, setbankStatmentInfo] = useState(null);
  const [fromBankAccountList, setFromBankAccountList] = useState([]);
  const { bankStatementLineTypes } = useMetaData();
  // console.log(
  //   bankStatementLineTypes,
  //   "bankStatementLineTypebankStatementLineTypebankStatementLineTypebankStatementLineType"
  // );
  let timer = null;
  const onFinish = (values) => {
    console.log("ModalStructure  values:", values);
    // onCancel();
    // setIsLinesMod(true);
    // setbankStatmentInfo(values);
    onCreateBankStatements(values);
  };

  const client = useQueryClient();

  const { data: currencyData } = useQuery([queryKeys.currencyList, {}], () =>
    getAllCurrencies()
  );

  const { mutate: onSearchBankAccount, isLoading: searchLoading } = useMutation(
    searchBankAccount,
    {
      onSuccess: (res) => {
        setFromBankAccountList(res?.model || []);
      },
      onError: (err) => {
        console.log("err:", err);
      },
    }
  );

  const onSearch = (value) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      onSearchBankAccount(value);
    }, 1000);
  };

  const handelSetAccount = (_, option) => {
    console.log(option, "asdafsdsdfasdfsdf");
    form.setFieldsValue({
      bankName: option?.data?.bankName,
      accountName: option?.data?.accountName,
      branchAddress: option?.data?.branchName,
    });
  };

  const { mutate: onCreateBankStatements, isLoading } = useMutation(
    createBankStatements,
    {
      onSuccess: (res) => {
        console.log("ModalPeriod  res:", res);
        message.success(res?.metas?.message);
        client.invalidateQueries([queryKeys.BankStatementsList]);
        form.resetFields();
        onCancel();
      },
    }
  );

  const { mutate: onUpdateBankStatements, isLoading: updateLod } = useMutation(
    updateBankStatements,
    {
      onSuccess: (res) => {
        console.log("ModalTax  res:", res);
        message.success(res?.metas?.message);
        client.invalidateQueries([queryKeys.taxList]);
        form.resetFields();
        onCancel();
      },
    }
  );

  const onFinishAddTax = ({ effectiveDate, ...values }) => {
    console.log("ModalStructure  values:", values);
    const body = {
      ...values,
      effectiveStartDate: dayjs(values?.effectiveStartDate?.[0]).format(
        constents.dateFormat
      ),
      effectiveEndDate: dayjs(values?.effectiveEndDate?.[1]).format(
        constents.dateFormat
      ),
    };

    if (editItem) {
      body.id = editItem.id;
      onUpdateBankStatements(body);
    } else {
      onCreateBankStatements(body);
    }
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  return (
    <>
      <OwnModal
        title={editItem ? "Edit statement" : "Add statement"}
        open={open}
        onCancel={onCancel}
        width={902}
      >
        <Form
          layout="vertical"
          form={form}
          // size="large"
          onFinish={onFinish}
          // className="pt-4"
        >
          <div className="col-2">
            <Form.Item
              name="accountNumber"
              label="Account Number"
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                placeholder="EX:100043xxxxxxx"
                optionFilterProp="children"
                onSearch={onSearch}
                loading={searchLoading}
                onSelect={handelSetAccount}
              >
                {/* {fakedata?.map((x) => (
                  <Select.Option value={x?.id} data={x}>
                    {x?.accountNumber} ({x?.shortBankName})
                  </Select.Option>
                ))} */}
                {fromBankAccountList?.map((x) => (
                  <Select.Option value={x?.id} data={x}>
                    {x?.accountNumber} ({x?.shortBankName})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="bankName" label="Bank Name">
              <Input disabled />
            </Form.Item>
            <Form.Item name="accountName" label="Account Name">
              <Input disabled />
            </Form.Item>
            <Form.Item name="branchAddress" label="Bank Branch">
              <Input disabled />
            </Form.Item>
          </div>
          <div className="col-2">
            <Form.Item
              name="statmentDate"
              label="Date"
              rules={[{ required: true }]}
            >
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              name="currencyId"
              label="Currency"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                options={currencyData?.model?.map((s) => ({
                  value: s?.id,
                  label: s?.nameEN,
                }))}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name="documentNumber"
              label="Document Number"
            >
              <Input />
            </Form.Item>
            <Form.Item name="glDate" label="GL Date">
              <DatePicker />
            </Form.Item>
            <Form.Item name="checkDigits" label="Check Digits">
              <Input />
            </Form.Item>
          </div>
          <Divider>control totals</Divider>
          <div className="col-2">
            <Form.Item name="openingBalance" label="opening balance">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="receipts" label="Receipts">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="payments" label="Payments">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="closingBalance" label="Closing Balance">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="availableBalance" label="Available Balance">
              <Input type="number" />
            </Form.Item>
            <Form.Item name="valueDateBalance" label="Value Date Balance">
              <Input />
            </Form.Item>
          </div>
          <Form.List name="bankStatementLines">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <div className="col-7 bg-gray-50 p-3 rounded items-end">
                        <Form.Item name={[index, "number"]} label="Number">
                          <Input
                            disabled
                            defaultValue={index + 1}
                            value={index + 1}
                          />
                        </Form.Item>
                        <Form.Item
                          name={[index, "bankStatementLineTypeId"]}
                          label="Type"
                        >
                          <Select
                            allowClear
                            options={bankStatementLineTypes?.map((s) => ({
                              value: s?.id,
                              label: s?.nameEN,
                            }))}
                          />
                        </Form.Item>
                        {/* <Form.Item
                              name={[index, "Currency"]}
                              label="Currency"
                            >
                              <Input />
                            </Form.Item> */}
                        <Form.Item name={[index, "amount"]} label="Amount">
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[index, "amountCleared"]}
                          label="Amount cleared"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[index, "clearedDate"]}
                          label="Cleared date"
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item
                          name={[index, "date"]}
                          label="Transaction Date"
                        >
                          <DatePicker />
                        </Form.Item>
                        <Form.Item name={[index, "glDate"]} label="GL Date">
                          <DatePicker />
                        </Form.Item>
                      </div>

                      {fields.length > 1 ? (
                        <Button
                          type="danger"
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                          icon={<MinusCircleOutlined />}
                        >
                          Remove Above Field
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      onClick={add}
                      icon={<PlusOutlined />}
                    >
                      add new line
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Divider className="!max-w-3xl min-w-0 mx-auto" />

          <Button
            // loading={addressAddLod || addressUpdateLod}
            type="primary"
            htmlType="submit"
            className="block ms-auto w-full max-w-xs"
          >
            DONE
          </Button>
        </Form>
      </OwnModal>
      <ModalStatementLines
        open={isLinesMod}
        bankStatmentInfo={bankStatmentInfo}
        onCancel={() => setIsLinesMod(false)}
      />
    </>
  );
}

export default ModalCashStatmenet;

import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import DepartmentModal from "components/modals/human-resources/setup/DepartmentsModal";
import WorkLocationsModal from "components/modals/human-resources/setup/WorkLocationsModal";
import WorkTimesModal from "components/modals/human-resources/setup/WorkTimesModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useWorkingTimes } from "hooks/human-resourses/useWorkTimes";
import useModalParams from "hooks/useModalParams";
import { useState } from "react";

function WorkTimes() {
  const { workingTimesData } = useWorkingTimes();
  console.log(workingTimesData, "workingTimesData");
  const [isOpen, setIsOpen] = useState(false);
  const [LocationId, setLocationId] = useState("");
  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
    },
    {
      title: "work From",
      dataIndex: "workFrom",
      key: "workFrom",
    },
    {
      title: "work To",
      dataIndex: "workTo",
      key: "workTo",
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <Button
          onClick={() => {
            setIsOpen({ id });
            setLocationId(id);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Human Resources / Setup / Work Times" />
        <SearchHero
          onAddBtn={() => {
            setIsOpen(true);
            setLocationId("");
          }}
        />
        <Table sticky dataSource={workingTimesData} columns={columns} />
      </div>
      <WorkTimesModal
        open={isOpen}
        LocationId={LocationId}
        handelClose={() => setIsOpen(false)}
      />
    </>
  );
}

export default WorkTimes;

import { Button, Col, Input, Row } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React from 'react';
import { Link } from 'react-router-dom';
import LeadsItem from './services/LeadsItem';

function Leads() {
  return (
    <div className="space-y-5">
      <OwnBreadcrumb items={[{ title: 'CRM' }]} current="Leads" />

      <div className="flex items-center justify-between gap-x-12 gap-y-3 p-3 bg-white rounded">
        <Input.Search placeholder="Search..." className="max-w-[600px]" />
        <Link to={`new`}>
          <Button type="primary">New</Button>
        </Link>
      </div>

      <Row gutter={20} wrap={false} className="overflow-auto pb-5">
        <Col span={7}>
          <LeadsItem title={'New'} total={'500K'} />
        </Col>
        <Col span={7}>
          <LeadsItem title={'Qualified'} total={'30K'} />
        </Col>
        <Col span={7}>
          <LeadsItem title={'Proposition'} total={'120K'} />
        </Col>
        <Col span={7}>
          <LeadsItem title={'Won'} total={'30K'} />
        </Col>
        <Col span={7}>
          <LeadsItem title={'Lost'} total={'30K'} />
        </Col>
      </Row>
    </div>
  );
}

export default Leads;

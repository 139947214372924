import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllGLValueSets = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/ValueSets?${searchQuery}`);
  return data;
});

export const getGLValueSet = catchAsync(async id => {
  const { data } = await axiosInstance(`/ValueSets/${id}`);
  return data;
});

export const createGLValueSet = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/ValueSets`, body);
  return data;
});

export const updateGLValueSet = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/ValueSets`, body);
  return data;
});

export const deleteGLValueSet = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/ValueSets/${id}`);
  return data;
});

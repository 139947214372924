import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Form, message, Table } from "antd";
import {
  useCreateTask,
  useDeleteTask,
  useEditTask,
} from "hooks/api/projects-management/tasks";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import dayjs from "dayjs";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalAssignResources from "components/modals/project-manager/projects/ModalAssignResources";
import { useProject } from "hooks/api/projects-management/projects";
import { createMergedColumns } from "./columns";
import SubTasks from "./SubTasks";
import EditableCell from "./EditableCell";

const Tasks = () => {
  const { id: projectId } = useParams();
  const [editingId, setEditingId] = useState("");
  const { tasks, project, isLoading } = useProject(projectId);
  const [form] = Form.useForm();
  const { editTask } = useEditTask(projectId);
  const { deleteTask } = useDeleteTask(projectId);
  const { quickCreateTask } = useCreateTask(
    projectId,
    project?.projectManagerId,
  );

  const isEditing = (record) => record.id === editingId;
  const closeEditingMode = () => setEditingId("");

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
      plannedDate: [
        dayjs(record.plannedStartDate),
        dayjs(record.plannedFinishDate),
      ],
      transactionDate: [
        dayjs(record.transactionStartDate),
        dayjs(record.transactionFinishDate),
      ],
    });
    setEditingId(record.id);
  };

  const saveTask = async (record) => {
    try {
      const row = await form.validateFields();
      editTask({
        id: record.id,
        name: row?.name,
        number: row?.number,
        plannedStartDate: dayjs(row?.plannedDate[0]).toDate(),
        plannedFinishDate: dayjs(row?.plannedDate[1]).toDate(),
        transactionStartDate: dayjs(row?.transactionDate[0]).toDate(),
        transactionFinishDate: dayjs(row?.transactionDate[1]).toDate(),
        taskManagerId: row?.taskManagerId,
        projectId: record?.projectId,
      }, {
        onSuccess: () => {
          message.success("Edited task successfully");
          closeEditingMode();
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteTask = () => {
    deleteTask(editingId, {
      onSuccess: () => {
        message.success("Delete Task");
        closeEditingMode();
      },
      onError: (error) =>
        message.error(`Error: Couldn't delete task ${error?.message}`),
    });
  };

  const options = [
    {
      title: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable
          ? (
            <div className="space-x-2">
              <Button
                onClick={closeEditingMode}
                icon={<CloseOutlined />}
              />
              <Button
                icon={<SaveOutlined />}
                onClick={() => saveTask(record)}
              />
              <Button onClick={onDeleteTask} icon={<DeleteOutlined />} danger />
            </div>
          )
          : (
            <Button
              disabled={editingId !== ""}
              onClick={() => {
                edit(record);
              }}
              icon={<EditOutlined />}
            />
          );
      },
      width: isEditing ? 200 : 50,
    },
  ];

  const mergedColumns = createMergedColumns(
    options,
    isEditing,
    editingId !== "",
    {
      title: "Task Manager",
      dataIndex: "taskManagerId",
      key: Math.random(),
      width: 300,
      editable: true,
      datatype: "select",
      render: (_, record) => record?.taskManager?.name,
    },
  );

  const breadCrumbItems = [
    {
      title: <p className="text-black">Project Managment</p>,
    },
    {
      title: <Link to="/project-managment/projects">Projects</Link>,
    },
    {
      title: (
        <Link to={`/project-managment/projects/${projectId}`}>
          {project?.name}
        </Link>
      ),
    },
  ];

  return (
    <>
      {isLoading ? <div>loading</div> : (
        <div className="space-y-7">
          <h1 className="text-xl font-semibold">
            {project?.name} ({project?.number})
          </h1>
          <OwnBreadcrumb items={breadCrumbItems} />
          <Button disabled={editingId !== ""} onClick={quickCreateTask}>
            Create a Task
          </Button>
          <Form form={form}>
            <Table
              columns={mergedColumns}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={tasks ?? []}
              expandable={{
                expandedRowRender: (row) => <SubTasks task={row} />,
              }}
              pagination={{
                pageSize: Infinity,
                hideOnSinglePage: true,
              }}
              scroll={{
                x: 2800,
              }}
            />
          </Form>
          <ModalAssignResources />
        </div>
      )}
    </>
  );
};

export default Tasks;

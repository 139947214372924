import { App, Button, Checkbox, DatePicker, Divider, Form, Input, Select, Space, Tooltip } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import ModalSalesInvoiceLines from 'components/modals/ModalSalesInvoiceLines';
import ModalTransactionAmounts from 'components/modals/ModalTransactionAmounts';
import { createSalesInvoice, getSalesInvoice, updateSalesInvoice } from 'network/account-receivable/sales-invoice';
import useCurrencyList from 'hooks/api/useCurrencyList';
import { disabledDateBeforeToday } from 'utils/utils';
import useCustomerList from 'hooks/api/useCustomerList';
import usePaymentTermsList from 'hooks/api/usePaymentTermsList';
import useTaxList from 'hooks/api/useTaxList';
import constents from 'utils/constants';

function SalesInvoiceNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [isLinesMod, setIsLinesMod] = useState(false);
  const [isTransactioMod, setIsTransactioMod] = useState(false);
  const [salesInvoiceLines, setSalesInvoiceLines] = useState([]);

  const metadata = useMetaData();
  const { currencyList } = useCurrencyList();
  const { customerList } = useCustomerList();
  const { paymentTermsList } = usePaymentTermsList();
  console.log('SalesInvoiceNew  paymentTermsList:', paymentTermsList);
  const { taxList } = useTaxList();

  const [customerShipTo, setCustomerShipTo] = useState(null);
  const [customerBillTo, setCustomerBillTo] = useState(null);
  const client = useQueryClient();
  const { data: salesInvoiceData } = useQuery([queryKeys.salesInvoiceList, params?.id], () => getSalesInvoice(params?.id), {
    enabled: !!params?.id,
  });
  console.log('salesInvoiceData', salesInvoiceData);

  const onSuccess = res => {
    console.log('onSuccess  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.salesInvoiceList]);

    form.resetFields();
    navigate('/account-receivable/invoices/sales-invoices');
  };

  const { mutate: onCreateSalesInvoice, isLoading: onCreateSalesInvoiceLod } = useMutation(createSalesInvoice, {
    onSuccess,
  });
  const { mutate: onUpdateSalesInvoice, isLoading: onUpdateSalesInvoiceLod } = useMutation(updateSalesInvoice, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && salesInvoiceData) {
      console.log('useEffect  salesInvoiceData:', salesInvoiceData);
      const editItem = salesInvoiceData?.model || {};
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      dataObj['dueDate'] = dayjs(dataObj['dueDate']);

      setSalesInvoiceLines(dataObj.salesInvoiceLines);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, salesInvoiceData]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.salesInvoiceLines = salesInvoiceLines;

    dataObj['date'] = dayjs(dataObj['date']).format(constents.dateFormat);
    dataObj['glDate'] = dayjs(dataObj['glDate']).format(constents.dateFormat);
    dataObj['dueDate'] = dayjs(dataObj['dueDate']).format(constents.dateFormat);
    // dataObj['isComplate'] = !!dataObj['isComplate'];
    dataObj['isActive'] = !!dataObj['isActive'];
    console.log('SalesInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = salesInvoiceData?.model?.id;
      return onUpdateSalesInvoice(dataObj);
    }

    onCreateSalesInvoice(dataObj);
  };

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb items={[{ title: <Link to="/account-receivable/invoices/sales-invoices">Sales Invoices</Link> }]} current="New Sales Invoice" />

        <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-24 mb-4">Transaction</h2>
              <div className="col-2">
                <Form.Item name="salesInvoiceSourceId" label="Source" rules={[{ required: true }]}>
                  <Select options={metadata?.salesInvoiceSources?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                </Form.Item>
                <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                  <DatePicker disabledDate={disabledDateBeforeToday} />
                </Form.Item>
                <Form.Item name="number" label="Number" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="glDate" label="GL Date" rules={[{ required: true }]}>
                  <DatePicker disabledDate={disabledDateBeforeToday} />
                </Form.Item>
                <Form.Item name="salesInvoiceClassId" label="Class" rules={[{ required: true }]}>
                  <Select options={metadata?.salesInvoiceClasses?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                </Form.Item>
                <Form.Item name="currencyId" label="Currency" rules={[{ required: true }]}>
                  <Select
                    options={currencyList?.map(s => ({
                      value: s?.id,
                      label: s?.nameEN,
                    }))}
                  />
                </Form.Item>
                <Form.Item name="salesInvoiceTypeId" label="Type" rules={[{ required: true }]}>
                  <Select options={metadata?.salesInvoiceTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                </Form.Item>
                <Form.Item name="documentNumber" label="Document Number" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="reference" label="Refrance" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>

                <Form.Item
                  name="isActive"
                  // label="Active"
                  valuePropName="checked">
                  <Checkbox>Active</Checkbox>
                </Form.Item>
              </div>
            </div>
            <div className="col-span-3 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-20 mb-2">Balance Date</h2>

              <Form.Item label="Line">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Tax">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Freight">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Charges">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Total">
                <Input readOnly />
              </Form.Item>
              <Form.Item
                name="Complate"
                // label="Complate"
                valuePropName="checked">
                <Checkbox readOnly>Complate</Checkbox>
              </Form.Item>
              <Space wrap>
                <Button type="primary" ghost>
                  DETAILS
                </Button>
                <Button type="primary" ghost>
                  Refresh
                </Button>
              </Space>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-24 mb-4">Contact</h2>
              <Divider>
                <h2 className="title-20 mb-3">SHIP TO</h2>
              </Divider>
              <Form.Item name="shipToCustomerId" label="Customer" rules={[{ required: true }]}>
                <Select
                  onChange={(_, option) => setCustomerShipTo(option?.item)}
                  options={customerList?.map(s => ({
                    value: s?.id,
                    label: s?.name + ` (${s.number})`,
                    item: s,
                  }))}
                />
              </Form.Item>
              <div className="col-2">
                <Form.Item label="Name">
                  <Input readOnly value={customerShipTo?.name} />
                </Form.Item>
                <Form.Item label="Number">
                  <Input readOnly value={customerShipTo?.number} />
                </Form.Item>
                <Form.Item label="Location">
                  <Input readOnly value={customerShipTo?.location} />
                </Form.Item>
                <Form.Item label="Taxpayer ID">
                  <Input readOnly value={customerShipTo?.taxpayerId} />
                </Form.Item>
              </div>

              <Divider>
                <h2 className="title-20 mb-3">BILL TO</h2>
              </Divider>
              <Form.Item name="billToCustomerId" label="Customer" rules={[{ required: true }]}>
                <Select
                  onChange={(_, option) => setCustomerBillTo(option?.item)}
                  options={customerList?.map(s => ({
                    value: s?.id,
                    label: s?.name + ` (${s.number})`,
                    item: s,
                  }))}
                />
              </Form.Item>
              <div className="col-2">
                <Form.Item label="Name">
                  <Input readOnly value={customerBillTo?.name} />
                </Form.Item>
                <Form.Item label="Number">
                  <Input readOnly value={customerBillTo?.number} />
                </Form.Item>
                <Form.Item label="Location">
                  <Input readOnly value={customerBillTo?.location} />
                </Form.Item>
                <Form.Item label="Taxpayer ID">
                  <Input readOnly value={customerBillTo?.taxpayerId} />
                </Form.Item>
              </div>
              <Divider>
                <h2 className="title-20 mb-3">Other</h2>
              </Divider>
              <div className="col-2">
                <Form.Item name="paymentTermId" label="Payment Term" rules={[{ required: true }]}>
                  <Select options={paymentTermsList?.map(s => ({ value: s?.id, label: s?.name }))} />
                </Form.Item>
                <Form.Item name="taxId" label="Tax" rules={[{ required: true }]}>
                  <Select options={taxList?.map(s => ({ value: s?.id, label: s?.name }))} />
                </Form.Item>

                <Form.Item name="dueDate" label="Due Date" rules={[{ required: true }]}>
                  <DatePicker disabledDate={disabledDateBeforeToday} />
                </Form.Item>
              </div>
            </div>
            <div className="col-span-3 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-20 mb-2">SOLD TO</h2>
              <Form.Item label="Name">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Number">
                <Input readOnly />
              </Form.Item>
              <Divider />
              <h2 className="title-20 mb-2">PAYING CUSTOMER</h2>
              <Form.Item label="Name">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Number">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="Location">
                <Input readOnly />
              </Form.Item>
              <Divider />
              <h2 className="title-20 mb-2">PAYMENT DETAILS</h2>
              <Form.Item label="ReceiptMethod">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="PaymentMethod">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="InstrumentMethod">
                <Input readOnly />
              </Form.Item>
              <Button type="primary" ghost block>
                select instrument
              </Button>
            </div>
          </div>
          <Space wrap className="flex justify-end">
            <Button type="primary" onClick={() => setIsLinesMod(true)}>
              LINE ITEMS
            </Button>
            <Tooltip title="Need XD!">
              <Button type="primary">DISTRIBUTIONS</Button>
            </Tooltip>

            <Button type="primary" htmlType="submit" loading={onCreateSalesInvoiceLod || onUpdateSalesInvoiceLod} disabled={!salesInvoiceLines?.length}>
              {isEdit ? 'Update' : 'COMPLETE'}
            </Button>
          </Space>
        </Form>
      </div>
      <ModalSalesInvoiceLines
        open={isLinesMod}
        onCancel={() => setIsLinesMod(false)}
        salesInvoiceLines={salesInvoiceLines}
        setSalesInvoiceLines={setSalesInvoiceLines}
      />
      <ModalTransactionAmounts open={isTransactioMod} onCancel={() => setIsTransactioMod(false)} />
    </>
  );
}

export default SalesInvoiceNew;

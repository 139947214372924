import PurchasingOrder from "pages/orders/PurchasingOrder";
import PurchasingRequest from "pages/orders/PurchasingRequest";
import Qutations from "pages/orders/Qutations";
import SalesTeamMember from "pages/orders/SalesTeamMember";
import SalesTeamLeader from "pages/orders/SalesTeamLeader";
import VendorPricelists from "pages/orders/VendorPricelists";

const routesOrders = [
  {
    path: "orders",
    children: [
      {
        path: "vendor",
        children: [
          {
            path: "purchasing_request",
            element: <Qutations />,
          },
          {
            path: "purchasing_order",
            element: <PurchasingOrder />,
          },
        ],
        path: "customer",
        children: [
          {
            path: "purchasing_request",
            element: <Qutations />,
          },
          {
            path: "purchasing_order",
            element: <PurchasingOrder />,
          },
        ],
      },
      {
        path: "vendor_priceLists",
        element: <VendorPricelists />,
      },
      {
        path: "sales_team_members",
        element: <SalesTeamMember />,
      },
      {
        path: "sales_team_leaders",
        element: <SalesTeamLeader />,
      },
    ],
  },
];

export default routesOrders;

// import PurchasingRequest from "pages/orders/PurchasingRequest";
// import Qutations from "pages/orders/Qutations";
// import VendorPricelists from "pages/orders/VendorPricelists";

// const routesOrders = [
//   {
//     path: "orders",
//     children: [
//       {
//         path: "purchasing_request",
//         element: <Qutations />,
//       },
//       {
//         path: "purchasing_order",
//         element: <PurchasingRequest/>,
//       },
//       {
//         path: "vendor_priceLists",
//         element: <VendorPricelists/>,
//       },
//     ],
//   },
// ];

// export default routesOrders;

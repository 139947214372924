import { useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

function useSearchQuery() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQueryObj = useMemo(
    () => Object.fromEntries(searchParams),
    [searchParams],
  );

  /**
   * @param {object} query
   * @param {{pathname:string}} options
   * @returns
   */
  const handleSearchQuery = (query, options = {}) => {
    // console.log('handleSearchQuery  options:', options);
    // console.log('handleSearchQuery  query:', query);

    if (options.pathname) {
      navigate({
        pathname: options.pathname,
        search: createSearchParams(query).toString(),
      });
      return;
    }
    setSearchParams(query);
  };

  const removeParams = (...params) => {
    const newSearchQueryObj = {
      ...searchQueryObj,
    };
    params.forEach((param) => {
      delete newSearchQueryObj[param];
    });
    setSearchParams(newSearchQueryObj);
  };

  return {
    searchQueryStr: searchParams.toString(),
    searchQueryObj,
    isSearchQuery: !!searchParams.toString(),
    setSearchQuery: handleSearchQuery,
    removeParams,
  };
}

export default useSearchQuery;

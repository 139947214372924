import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import dayjs from 'dayjs';
import { createCurrency, updateCurrency } from 'network/general-ledger/currency';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ModalCurrency({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();
  const { mutate: onCreateCurrency, isLoading: createCurrencyLod } = useMutation(createCurrency, {
    onSuccess: res => {
      console.log('ModalCurrency  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.currencyList]);

      form.resetFields();
      onCancel();
    },
  });
  const { mutate: onUpdateCurrency, isLoading: updateCurrencyLod } = useMutation(updateCurrency, {
    onSuccess: res => {
      console.log('ModalCurrency  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.currencyList]);

      form.resetFields();
      onCancel();
    },
  });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['from']), dayjs(dataObj['to'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem?.id]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['from'] = dayjs(dataObj['fromTo'][0]).format(constents.dateFormat);
    dataObj['to'] = dayjs(dataObj['fromTo'][1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    dataObj['enabled'] = !!dataObj['enabled'];
    console.log('ModalCurrency  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateCurrency(dataObj);
    } else {
      onCreateCurrency(dataObj);
    }
  };
  return (
    <OwnModal title={editItem ? 'Edit Currency' : 'Add Currency'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name="code" label="Code" rules={constents.rulesRequeried}>
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item name="nameEN" label="Name english" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>
          <Form.Item name="nameAR" label="Name arabic" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>

          <Form.Item name="fromTo" label="From - To" rules={constents.rulesRequeried}>
            <DatePicker.RangePicker
              className="w-full"
              format="YYYY-MM-DD"
              disabledDate={current => {
                // Can not select days before today and today
                return current && current < dayjs().endOf('day');
              }}
            />
          </Form.Item>

          <Form.Item name="enabled" valuePropName="checked">
            <Checkbox>Enable</Checkbox>
          </Form.Item>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createCurrencyLod || updateCurrencyLod}>
            DONE
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalCurrency;

import { Button, Divider, Form, Input, Select, message } from "antd";
import OwnModal from "components/own/OwnModal";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  searchBankAccount,
  searchBankAccountTo,
} from "network/cash-managment/bank";
import { createBankTransfer } from "network/cash-managment/bankTransfer";
import { queryKeys } from "services/react-query/queryKeys";

function ModalBankTransfer({ open, onCancel, editItem, setEditItem }) {
  const [form] = Form.useForm();
  const [fromBankAccountList, setFromBankAccountList] = useState([]);
  const [toBankAccountList, setToBankAccountList] = useState([]);
  const client = useQueryClient();

  const { mutate: onCreateBankTransfer, isLoading } = useMutation(
    createBankTransfer,
    {
      onSuccess: (res) => {
        console.log("ModalPeriod  res:", res);
        message.success(res?.metas?.message);
        client.invalidateQueries([queryKeys.bankTransferList]);
        form.resetFields();
        onCancel();
      },
    }
  );
  const { mutate: onSearchBankAccount, isLoading: searchLoading } = useMutation(
    searchBankAccount,
    {
      onSuccess: (res) => {
        setFromBankAccountList(res?.model || []);
      },
      onError: (err) => {
        console.log("err:", err);
      },
    }
  );
  const { mutate: onSearchBankAccountSendTo, isLoading: searchToLoading } =
    useMutation(searchBankAccountTo, {
      onSuccess: (res) => {
        setToBankAccountList(res?.model || []);
      },
      onError: (err) => {
        console.log("err:", err);
      },
    });
  // useEffect(() => {
  //     const dataObj = { ...editItem };

  //     dataObj.areaId = editItem?.area_id;
  //     dataObj.provinceId = editItem?.province_id;

  //     form.setFieldsValue(dataObj);
  //   }, [editItem?.id]);
  const onSearch = (value) => {
    onSearchBankAccount(value);
  };
  const onFinish = (values) => {
    console.log("ModalStructure  values:", values);
    const body = {
      fromBankAccountId: values?.fromBankAccountId,
      toBankAccountId: values?.toBankAccountId,
      amount: +values?.amount,
    };
    onCreateBankTransfer(body);
  };

  const handelSetAccountFrom = (_, option) => {
    form.setFieldsValue({
      fromBankName: option?.data?.bankName,
      fromAccountName: option?.data?.accountName,
      fromBranchAddress: option?.data?.branchName,
    });
  };

  const handelSetAccountTo = (_, option) => {
    form.setFieldsValue({
      toBankName: option?.data?.bankName,
      toAccountName: option?.data?.accountName,
      toBranchAddress: option?.data?.branchName,
    });
  };

  return (
    <>
      <OwnModal
        title={editItem ? "Bank transfer" : "Bank transfer"}
        open={open}
        onCancel={onCancel}
        width={902}
      >
        <Form
          layout="vertical"
          form={form}
          // size="large"
          onFinish={onFinish}
          // className="pt-4"
        >
          <div className="col-2" style={{ columnGap: 20 }}>
            <div className="col-1">
              <Divider>From</Divider>
              {/* <Form.Item name="accountNumber" label="Account Number">
                <Search
                  placeholder="EX:100043xxxxxxx"
                  onSearch={onSearch}
                  enterButton
                />
              </Form.Item> */}
              <Form.Item
                name="fromBankAccountId"
                label="Account Number"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="EX:100043xxxxxxx"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  loading={searchLoading}
                  onSelect={handelSetAccountFrom}
                >
                  {fromBankAccountList?.map((x) => (
                    <Select.Option value={x?.id} data={x}>
                      {x?.accountNumber} ({x?.shortBankName})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="fromBankName" label="Bank Name">
                <Input disabled />
              </Form.Item>
              <Form.Item name="fromAccountName" label="Account Name">
                <Input disabled />
              </Form.Item>
              <Form.Item name="fromBranchAddress" label="Bank Branch">
                <Input disabled />
              </Form.Item>
            </div>
            <div className="col-1">
              <Divider>To</Divider>

              {/* <Form.Item
                name="toBankAccountId"
                label="Account Number"
                rules={[{ required: true }]}
              >
                <Search
                  placeholder="EX:100043xxxxxxx"
                  onSearch={onSearch}
                  enterButton
                />
              </Form.Item> */}
              <Form.Item
                name="toBankAccountId"
                label="Account Number"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="EX:100043xxxxxxx"
                  optionFilterProp="children"
                  onSearch={(val) => onSearchBankAccountSendTo(val)}
                  loading={searchToLoading}
                  onSelect={handelSetAccountTo}
                >
                  {toBankAccountList?.map((x) => (
                    <Select.Option value={x?.id} data={x}>
                      {x?.accountNumber} ({x?.shortBankName})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="toBankName" label="Bank Name">
                <Input disabled />
              </Form.Item>
              <Form.Item name="toAccountName" label="Account Name">
                <Input disabled />
              </Form.Item>
              <Form.Item name="toBranchAddress" label="Bank Branch">
                <Input disabled />
              </Form.Item>
            </div>
          </div>
          <Form.Item name="amount" label="Amount">
            <Input type="number" />
          </Form.Item>
          <Button
            // loading={addressAddLod || addressUpdateLod}
            type="primary"
            htmlType="submit"
            className="block ms-auto w-full max-w-xs"
            loading={isLoading}
          >
            Submit
          </Button>
        </Form>
      </OwnModal>
    </>
  );
}

export default ModalBankTransfer;

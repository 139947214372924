import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

// export const addressGet = catchAsync(async id => {
//   const { data } = await axiosInstance(`/Addresses/${id}`);
//   return data;
// });

// export const addressListGet = catchAsync(async () => {
//   const { data } = await axiosInstance(`/Addresses`);
//   console.log('addressListGet  data:', data);
//   return data?.model;
// });

export const addressCreate = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Addresses', body);
  return data;
});

// export const addressUpdate = catchAsync(async body => {
//   const { data } = await axiosInstance.put('/Addresses', body);
//   return data;
// });

// export const addressDelete = catchAsync(async id => {
//   const { data } = await axiosInstance.delete(`/Addresses/${id}`);
//   return data;
// });

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalAddPaybleInvoice from 'components/modals/recurreningModals/ModalAddPaybleInvoice';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useAPInvoicesList from 'hooks/api/useAPInvoicesList';
import { deleteAPInvoice } from 'network/account-payable/invoices';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import { dateFormat } from 'utils/utils';

function PayableInvoices() {
  const { message } = App.useApp();
  const client = useQueryClient();

  const { mutate: onDeleteAPInvoices } = useMutation(deleteAPInvoice, {
    onSuccess: res => {
      console.log('Structure  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.APInvoicesList]);
    },
  });

  const { APInvoicesList } = useAPInvoicesList();
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const columns = [
    {
      key: 'invoiceNumber',
      dataIndex: 'invoiceNumber',
      title: 'Number',
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: 'Amount',
    },
    {
      key: 'invoicePaymentMethod',
      dataIndex: 'invoicePaymentMethod',
      title: 'Payment Method',
      render: (val, _) => val?.nameEN,
    },

    {
      key: 'invoiceType',
      dataIndex: 'invoiceType',
      title: 'Type',
      render: (val, _) => val?.nameEN,
    },
    {
      key: 'creationDate',
      dataIndex: 'creationDate',
      title: 'Creation Date',
      render: (val, _) => dateFormat(val),
    },

    {
      key: 'accounted',
      dataIndex: 'accounted',
      title: 'Accounted',
      render: val => <Switch checked={val} />,
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: val => <Switch checked={val} />,
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteAPInvoices(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = APInvoicesList;

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Invoices" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table dataSource={data} sticky columns={columns} />
      </div>
      <ModalAddPaybleInvoice
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  );
}

export default PayableInvoices;

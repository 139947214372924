import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

// Manual Journals
// export const getAllManualJournals = async (searchQuery = '') => {
//   const data = await axiosInstance(`/Journals?${searchQuery}`);
//   console.log('getAllManualJournals  data:', data);
//   return data;
// };
export const getAllManualJournals = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Journals?${searchQuery}`);
  return data;
});

export const getManualJournal = catchAsync(async id => {
  const { data } = await axiosInstance(`/Journals/${id}`);
  return data;
});

export const getManualJournalByName = catchAsync(async name => {
  const { data } = await axiosInstance(`/Journals/getbyname/${name}`);
  return data;
});

export const createManualJournal = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Journals`, body);
  return data;
});

export const postManualJournal = catchAsync(async id => {
  const { data } = await axiosInstance.put(`/Journals/${id}/PostJournal`, null);
  return data;
});

export const addRJournalLines = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Journals/addLines`, body);
  return data;
});

// Recurring Journals
export const getAllRecurringJournals = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Journals/recurringJournals?${searchQuery}`);
  return data;
});

export const createRecurringJournal = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Journals/recurringJournal`, body);
  return data;
});

export const addRecurringJournalLines = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Journals/addRecurringJournalLines`, body);
  return data;
});

export const searchJournalsAdvanced = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Journals/advanced`, body);
  return data;
});

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalAddDistributionSet from 'components/modals/recurreningModals/ModalAddDistributionSet';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useAPDistributionList from 'hooks/api/useAPDistributionList';
import { deleteAPDistribution } from 'network/account-payable/distributions';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function Distributions() {
  const { message } = App.useApp();
  const client = useQueryClient();

  const { mutate: onDeleteAPDistribution } = useMutation(deleteAPDistribution, {
    onSuccess: res => {
      console.log('Structure  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.APDistributionList]);
    },
  });

  const { APDistributionList } = useAPDistributionList();
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'name',
    },

    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },

    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: 'Active',
      render: val => <Switch checked={val} />,
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteAPDistribution(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = APDistributionList;

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Distributions" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table dataSource={data} sticky columns={columns} />
      </div>
      <ModalAddDistributionSet
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  );
}

export default Distributions;

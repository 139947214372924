import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, InputNumber, Select } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { createCalendar, updateCalendar } from 'network/general-ledger/calendar';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ModalCalender({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const { periodTypes, yearTypes, metaDataLod } = useMetaData();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const { mutate: onCreateCalendar, isLoading: createCalendarLod } = useMutation(createCalendar, {
    onSuccess: res => {
      console.log('ModalCalender  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.calendarList]);

      form.resetFields();
      onCancel();
    },
  });
  const { mutate: onUpdateCalendar, isLoading: updateCalendarLod } = useMutation(updateCalendar, {
    onSuccess: res => {
      console.log('ModalCalender  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.calendarList]);

      form.resetFields();
      onCancel();
    },
  });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem?.id]);

  const onFinish = values => {
    console.log('ModalCalender  values:', values);
    const dataObj = { ...values };
    console.log('onFinish  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateCalendar(dataObj);
    } else {
      onCreateCalendar(dataObj);
    }
  };
  return (
    <OwnModal title={editItem ? 'Edit Calendar' : 'Add Calendar'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name="periodTypeId" label="Period Type" rules={constents.rulesRequeried}>
            <Select
              loading={metaDataLod}
              options={periodTypes?.map(el => ({
                value: el?.id,
                label: el?.nameEN,
              }))}
            />
          </Form.Item>

          <Form.Item name="periodsPerYear" label="Periods per year" rules={constents.rulesRequeried}>
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <div className="col-2">
          <Form.Item
            name="yearTypeId"
            label="Year Type"
            // rules={constents.rulesRequeried}
          >
            <Select
              loading={metaDataLod || updateCalendarLod}
              options={yearTypes?.map(el => ({
                value: el?.id,
                label: el?.nameEN,
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 2 }} />
          </Form.Item>
        </div>

        <Button type="primary" htmlType="submit" className="block ms-auto w-full max-w-xs" loading={createCalendarLod || updateCalendarLod}>
          DONE
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalCalender;

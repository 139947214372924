import { App, Button, Divider, Form, Input, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createCustomer, getCustomer, updateCustomer } from 'network/account-receivable/customer';
import AccountField from 'components/common/AccountField';

function CustomerNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();

  const client = useQueryClient();
  const { data: cusromerData } = useQuery([queryKeys.customerList, params?.id], () => getCustomer(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.customerList]);

    form.resetFields();
    navigate('/account-receivable/customers');
  };

  const { mutate: onCreateCustomer, isLoading: onCreateCustomerLod } = useMutation(createCustomer, {
    onSuccess,
  });
  const { mutate: onUpdateCustomer, isLoading: onUpdateCustomerLod } = useMutation(updateCustomer, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && cusromerData) {
      const editItem = cusromerData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, cusromerData]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = cusromerData?.model?.id;
      return onUpdateCustomer(dataObj);
    }

    onCreateCustomer(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: <Link to="/account-receivable/customers">Customers</Link> }]} current={isEdit ? 'Update' : 'Create'} />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <h2 className="title-24 mb-4">Customer Information</h2>
          <div className="col-3">
            <Form.Item name="number" label="Number" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="contact" label="Contact" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="customerTypeId" label="Customer Type" rules={[{ required: true }]}>
              <Select options={metadata?.customerTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item name="customerStatusId" label="Customer Status" rules={[{ required: true }]}>
              <Select options={metadata?.customerStatuses?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item name="taxpayerId" label="Tax payer" rules={[{ required: true }]}>
              <Select options={metadata?.receiptTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item name="reference" label="Reference" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="organizationAlias" label="Alias">
              <Input />
            </Form.Item>
            <Form.Item name="organizationUrl" label="URL">
              <Input />
            </Form.Item>
            <Form.Item name="dunsNumber" label="D-U-N-S Number">
              <Input />
            </Form.Item>
          </div>

          <Divider>Account Information</Divider>
          <div className="col-2">
            {/* <Form.Item name="accountNumber" label="Account Number" rules={[{ required: true }]}>
              <Input />
            </Form.Item> */}
            <AccountField form={form} name="accountNumber" label="Account Number" required />
            <Form.Item name="accountDescription" label="Account Description">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>

          <Divider>Address Information</Divider>
          <div className="col-2">
            <Form.Item name="address" label="Address" rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
            <Form.Item name="location" label="Location">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateCustomerLod || onUpdateCustomerLod}>
            {isEdit ? 'Update' : 'Complete'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default CustomerNew;

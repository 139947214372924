import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Form, Input, Select } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useFixedAssetValueList from 'hooks/api/useFixedAssetValueList';
import useGLValueSetList from 'hooks/api/useGLValueSetList';
import { createFixedAssetValue, updateFixedAssetValue } from 'network/fixed-assets/fixedAssetValues';
import { createGLValue, updateGLValue } from 'network/general-ledger/GLValues';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalAddValue({ open, onCancel, editItem, setEditItem, fixedAsset }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();
  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys[fixedAsset ? 'fixedAssetValueList' : 'GLValueList']]);
    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateValue, isLoading: createValueLod } = useMutation(fixedAsset ? createFixedAssetValue : createGLValue, { onSuccess });
  const { mutate: onUpdateValue, isLoading: updateValueLod } = useMutation(fixedAsset ? updateFixedAssetValue : updateGLValue, { onSuccess });

  const { fixedAssetValueList } = useFixedAssetValueList();
  const { GLValueSetList } = useGLValueSetList();

  const valueSetList = fixedAsset ? fixedAssetValueList : GLValueSetList;

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);
    dataObj.isParent = !!dataObj.isParent;
    dataObj.allowBudgeting = !!dataObj.allowBudgeting;
    dataObj.allowPosting = !!dataObj.allowPosting;

    if (editItem) {
      dataObj.id = editItem.id;

      onUpdateValue(dataObj);
    } else {
      onCreateValue(dataObj);
    }
  };
  return (
    <OwnModal title={editItem ? 'Edit Value' : 'Add Value'} open={open} onCancel={onCancel} width={1200}>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [''] }}>
        <div className="col-2">
          <Form.Item name="value" label="value" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={fixedAsset ? 'fixedAssetValueSetId' : 'valueSetId'} label="Value Set">
            <Select options={valueSetList?.map(s => ({ value: s?.id, label: s?.title }))} />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input.TextArea autoSize={{ maxRows: 1 }} />
          </Form.Item>
          <Form.Item name="isParent" valuePropName="checked">
            <Checkbox>Parent</Checkbox>
          </Form.Item>
          <Form.Item name="allowBudgeting" valuePropName="checked">
            <Checkbox>Allow budgeting</Checkbox>
          </Form.Item>
          <Form.Item name="allowPosting" valuePropName="checked">
            <Checkbox>Allow posting</Checkbox>
          </Form.Item>
        </div>

        <Button loading={createValueLod || updateValueLod} type="primary" htmlType="submit" className="block ms-auto w-full max-w-xs">
          DONE
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalAddValue;

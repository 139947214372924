import ApplicationtList from 'pages/human-resources/setup/ApplicationtList';
import AppraisalNew from 'pages/human-resources/appraisal/AppraisalNew';
import Appraisals from 'pages/human-resources/appraisal/Appraisals';
import DepartmentList from 'pages/human-resources/setup/DepartmentList';
import Employees from 'pages/human-resources/setup/Employees';
import GoalNew from 'pages/human-resources/goals/GoalNew';
import Goals from 'pages/human-resources/goals/Goals';
import Contracts from 'pages/human-resources/payroll/contracts/Contracts';
import ContractNew from 'pages/human-resources/payroll/contracts/ContractNew';
import WorkLocations from 'pages/human-resources/setup/WorkLocationsList';
import WorkTimes from 'pages/human-resources/setup/WorkTimes';
import Expenses from 'pages/human-resources/expenses/Expenses';
import ExpensesCategory from 'pages/human-resources/expenses/ExpensesCategory';
import ExpensesReport from 'pages/human-resources/expenses/ExpensesReport';
import TimeOff from 'pages/human-resources/payroll/work-entry/time-off/TimeOff';
import TimeOffNew from 'pages/human-resources/payroll/work-entry/time-off/TimeOffNew';
import StructureTypes from 'pages/human-resources/setup/salary/structure-types/StructureTypes';
import StructureTypeNew from 'pages/human-resources/setup/salary/structure-types/StructureTypeNew';
import Structure from 'pages/human-resources/setup/salary/structure/Structure';
import StructureNew from 'pages/human-resources/setup/salary/structure/StructureNew';
import Rules from 'pages/human-resources/setup/salary/rules/Rules';
import RuleNew from 'pages/human-resources/setup/salary/rules/RuleNew';
import WorkEntryTypes from 'pages/human-resources/setup/work-entry/types/WorkEntryTypes';
import WorkEntryTypeNew from 'pages/human-resources/setup/work-entry/types/WorkEntryTypeNew';
import DailyEntries from 'pages/human-resources/payroll/work-entry/daily-entries/DailyEntries';
import DailyEntryNew from 'pages/human-resources/payroll/work-entry/daily-entries/DailyEntryNew';
import Questions from 'pages/human-resources/setup/appraisals/questions/Questions';
import QuestionNew from 'pages/human-resources/setup/appraisals/questions/QuestionNew';
import PaySlips from 'pages/human-resources/payroll/pay-slip/PaySlips';
import PaySlipNew from 'pages/human-resources/payroll/pay-slip/PaySlipNew';

const routesHumanResources = [
  {
    path: 'human-resources',
    children: [
      {
        path: 'appraisals',
        children: [
          {
            index: true,
            element: <Appraisals />,
          },
          {
            path: 'new',
            element: <AppraisalNew />,
          },
          {
            path: 'details/:id',
            element: <AppraisalNew isEdit />,
          },
        ],
      },
      {
        path: 'expenses',
        children: [
          {
            index: true,
            element: <Expenses />,
          },
          {
            path: 'expenses-category',
            element: <ExpensesCategory />,
          },
          {
            path: 'expenses-report',
            element: <ExpensesReport />,
          },
        ],
      },
      {
        path: 'goals',
        children: [
          {
            index: true,
            element: <Goals />,
          },
          {
            path: 'new',
            element: <GoalNew />,
          },
          {
            path: 'details/:id',
            element: <GoalNew isEdit />,
          },
        ],
      },
      {
        path: 'payroll',
        children: [
          {
            path: 'contracts',
            children: [
              {
                index: true,
                element: <Contracts />,
              },
              {
                path: 'new',
                element: <ContractNew />,
              },
              {
                path: 'details/:id',
                element: <ContractNew isEdit />,
              },
            ],
          },
          {
            path: 'work-entry',
            children: [
              {
                path: 'daily-entries',
                children: [
                  {
                    index: true,
                    element: <DailyEntries />,
                  },
                  {
                    path: 'new',
                    element: <DailyEntryNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <DailyEntryNew isEdit />,
                  },
                ],
              },
              {
                path: 'time-off',
                children: [
                  {
                    index: true,
                    element: <TimeOff />,
                  },
                  {
                    path: 'new',
                    element: <TimeOffNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <TimeOffNew isEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: 'pay-slip',
            children: [
              {
                index: true,
                element: <PaySlips />,
              },
              {
                path: 'new',
                element: <PaySlipNew />,
              },
              {
                path: 'edit/:id',
                element: <PaySlipNew isEdit />,
              },
            ],
          },
        ],
      },
      {
        path: 'setup',
        children: [
          {
            path: 'departments',
            element: <DepartmentList />,
          },
          {
            path: 'work-locations',
            element: <WorkLocations />,
          },
          {
            path: 'work-times',
            element: <WorkTimes />,
          },

          {
            path: 'applications',
            element: <ApplicationtList />,
          },
          {
            path: 'employees',
            element: <Employees />,
          },
          {
            path: 'work-entry',
            children: [
              {
                path: 'types',
                children: [
                  {
                    index: true,
                    element: <WorkEntryTypes />,
                  },
                  {
                    path: 'new',
                    element: <WorkEntryTypeNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <WorkEntryTypeNew isEdit />,
                  },
                ],
              },
              {
                path: 'working-schedules',
                children: [
                  {
                    index: true,
                    element: <h1>working-schedules</h1>,
                  },
                  {
                    path: 'new',
                    element: <h1>working-schedules new</h1>,
                  },
                  {
                    path: 'edit/:id',
                    element: <h1>working-schedules details</h1>,
                  },
                ],
              },
            ],
          },
          {
            path: 'appraisals',
            children: [
              {
                path: 'questions',
                children: [
                  {
                    index: true,
                    element: <Questions />,
                  },
                  {
                    path: 'new',
                    element: <QuestionNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <QuestionNew isEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: 'salary',
            children: [
              {
                path: 'structure-types',
                children: [
                  {
                    index: true,
                    element: <StructureTypes />,
                  },
                  {
                    path: 'new',
                    element: <StructureTypeNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <StructureTypeNew isEdit />,
                  },
                ],
              },
              {
                path: 'structure',
                children: [
                  {
                    index: true,
                    element: <Structure />,
                  },
                  {
                    path: 'new',
                    element: <StructureNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <StructureNew isEdit />,
                  },
                ],
              },
              {
                path: 'rules',
                children: [
                  {
                    index: true,
                    element: <Rules />,
                  },
                  {
                    path: 'new',
                    element: <RuleNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <RuleNew isEdit />,
                  },
                ],
              },
            ],
          },

          // {
          //   path: "projects",
          //   children: [
          //     {
          //       index: true,
          //       element: <Projects />,
          //     },
          //     {
          //       path: ":id",
          //       element: <Tasks />,
          //     },
          //   ],
          // }
        ],
      },
    ],
  },
];

export default routesHumanResources;

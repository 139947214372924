import { useQuery } from '@tanstack/react-query';
import { getAllTaxes } from 'network/taxes/taxes';
import { queryKeys } from 'services/react-query/queryKeys';

function useTaxList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.taxList, searchQueryStr], () => getAllTaxes(searchQueryStr));

  const taxList = data?.model;

  return {
    taxList,
    taxListLod: isLoading,
  };
}

export default useTaxList;

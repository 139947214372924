import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addExpensesCategory, getExpensesCategory } from "network/Human-resources/expenses-category";
import { queryKeys } from "services/react-query/queryKeys";

export const useExpensesCategory = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.expensesCategory, id],
    queryFn: () => getExpensesCategory(id),
  });
  const expensessCategory = data?.model;
  return { expensessCategory, ...rest };
};

export const useCreateExpensesCategory = () => {
    const client = useQueryClient();
    const mutation = useMutation({
      mutationFn: addExpensesCategory,
      onSuccess:()=>client.invalidateQueries([queryKeys.expensesCategory])
    });
    mutation.createExpensesCategory = mutation.mutate;
    mutation.isCreatingExpensesCategory = mutation.isLoading;
    return mutation;
  };
  
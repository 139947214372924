import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import { App, Button, Checkbox, Divider, Form, Input, Select, Space } from 'antd';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import useBudgetList from 'hooks/api/useBudgetList';
import useCurrencyList from 'hooks/api/useCurrencyList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addBudgetLines, getAccountsByBudgetId, getBudgetDividedPeriods } from 'network/general-ledger/budget';
import { queryKeys } from 'services/react-query/queryKeys';
import usePeriodList from 'hooks/api/usePeriodList';
import dayjs from 'dayjs';
import constents, { budgetStatusOptions } from 'utils/constants';
import useAccountList from 'hooks/api/useAccountList';

const monthCount = 12;
function BudgetEnterAmounts() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const userData = useSelector(s => s.user?.data);
  const client = useQueryClient();
  const { budgetList, budgetListLod } = useBudgetList();
  const { currencyList, currencyListLod } = useCurrencyList();
  const { periodList, periodListLod } = usePeriodList();

  const [breakdown, setBreakdown] = useState(null);
  const [selectedBudget, setSelectedBudget] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  console.log('BudgetEnterAmounts  selectedBudget:', selectedBudget);

  const { mutate: onAddBudgetLines, isLoading: addBudgetLinesLod } = useMutation(addBudgetLines, {
    onSuccess: res => {
      console.log('ModalBudgitLines  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.budgetList]);

      form.resetFields();
      // onCancel();
    },
  });

  // const { data: selectedBudgetAccountData, isLoading: selectedBudgetAccountDataLod } = useQuery(
  //   [queryKeys?.accountList, { budgetId: selectedBudget?.id }],
  //   () => getAccountsByBudgetId(selectedBudget?.id),
  //   {
  //     enabled: !!selectedBudget,
  //   }
  // );

  const { data: budgetDividedPeriods, isLoading: budgetDividedPeriodsLod } = useQuery(
    [queryKeys?.budgetDividedPeriods, { monthCount }],
    () => getBudgetDividedPeriods(monthCount),
    {
      enabled: !!monthCount,
    }
  );
  console.log('BudgetEnterAmounts  budgetDividedPeriods:', budgetDividedPeriods);

  // const selectedBudgetAccountList = selectedBudgetAccountData?.model;
  // console.log('BudgetEnterAmounts  selectedBudgetAccountList:', selectedBudgetAccountList);

  const { accountList, accountListLod } = useAccountList();

  const onFinish = values => {
    console.log('ModalBudgitLines  values:', values);

    const preiodsKeyList = budgetDividedPeriods?.model?.filter(p => p?.enabled)?.map(p => p?.periodLable);
    console.log('onFinish  preiodsKeyList:', preiodsKeyList);
    const Lines = values?.names?.map(el => {
      const ammountPeriods = preiodsKeyList?.map(key => ({ amountPeriod: key, amount: el[key] }));
      return {
        periods: ammountPeriods,
        accountId: el.accountId,
      };
    });

    const dataObj = { budgetId: values?.budgetId, Lines };
    console.log('onFinish  dataObj:', dataObj);

    onAddBudgetLines(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb noHome items={[{ title: 'Budget' }]} current="Enter Amounts" />

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          names: [''],
          companyId: userData?.legalEntity?.companyId,
        }}>
        <div className="space-y-6">
          <div className="bg-white py-8 px-8 rounded-lg">
            <div className="col-2">
              <Form.Item name="budgetId" label="Budget" className="col-span-full" rules={[{ required: true }]}>
                <Select
                  loading={budgetListLod}
                  options={budgetList?.map(el => ({
                    value: el?.id,
                    label: el?.nameEN,
                    item: el,
                  }))}
                  onChange={(_, option) => setSelectedBudget(option?.item)}
                />
              </Form.Item>
              {/* <Form.Item name="companyId" label="Budget Organization">
                <Select
                  options={[
                    {
                      value: userData?.legalEntity?.companyId,
                      label: 'Our Company',
                    },
                  ]}
                />
              </Form.Item> */}

              <Form.Item label="Funds Stauts">
                <Select disabled value={selectedBudget?.status} options={budgetStatusOptions} />
              </Form.Item>

              <Form.Item label="Currency">
                <Select
                  disabled
                  value={selectedBudget?.currencyId}
                  loading={currencyListLod}
                  options={currencyList?.map(el => ({
                    value: el?.id,
                    label: el?.nameEN,
                  }))}
                />
              </Form.Item>
            </div>
            <div>
              <Divider>
                <h4 className="title-18 mb-2">Account Periods</h4>
              </Divider>
              <div className="col-2">
                <Form.Item label="From">
                  <Select
                    disabled
                    value={selectedBudget?.periodFromId}
                    loading={periodListLod}
                    options={periodList?.map(p => ({
                      value: p?.id,
                      label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                    }))}
                  />
                </Form.Item>
                <Form.Item label="To">
                  <Select
                    disabled
                    value={selectedBudget?.periodToId}
                    loading={periodListLod}
                    options={periodList?.map(p => ({
                      value: p?.id,
                      label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                    }))}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="bg-white py-8 px-8 rounded-lg">
            <div className="flex flex-col items-center gap-2 mb-4 border-b pb-4">
              <h2 className="title-24">Budget breakdown by</h2>
              <Select
                className="w-56"
                placeholder="Please select"
                size="large"
                onChange={value => setBreakdown(value)}
                options={[
                  {
                    label: 'Budget Manual',
                    value: 'budget_manual',
                  },
                  {
                    label: 'Budget Rules',
                    value: 'budget_rules',
                  },
                ]}
              />
            </div>

            {breakdown === 'budget_manual' && (
              <Form.List name="names">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <div className=" bg-gray-50 p-3 rounded">
                            <div className="col-2">
                              <Form.Item name={[index, 'accountId']} label="Account" rules={[{ required: true }]}>
                                <Select
                                  loading={accountListLod}
                                  options={accountList?.map(el => ({
                                    value: el?.id,
                                    label: el?.id,
                                    item: el,
                                  }))}
                                  onChange={(_, option) => setSelectedAccount(option?.item)}
                                />
                              </Form.Item>
                              <Form.Item label="Account Description">
                                <TextArea value={selectedAccount?.description} readOnly autoSize={{ maxRows: 1 }} />
                              </Form.Item>
                            </div>
                            <div className="grid grid-cols-3 md:grid-cols-6 xl:grid-cols-12  gap-x-3">
                              {budgetDividedPeriods?.model?.map((p, i) => (
                                <Form.Item key={p?.periodLable + i} name={[index, p?.periodLable]} label={p?.periodLable} rules={[{ required: p?.enabled }]}>
                                  <Input disabled={!p?.enabled} />
                                </Form.Item>
                              ))}
                              <Form.Item label="Total" className="col-span-full">
                                <Input disabled />
                              </Form.Item>
                            </div>
                          </div>
                          {fields.length > 1 ? (
                            <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                              Remove Above Field
                            </Button>
                          ) : null}
                        </div>
                      ))}
                      <Divider />
                      <Form.Item>
                        <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            )}
            {breakdown === 'budget_rules' && (
              <div>
                <Form layout="vertical" form={form}>
                  <div className="col-2">
                    <Form.Item name="Rule" label="Rule">
                      <Select loading />
                    </Form.Item>
                    <Form.Item name="Amount" label="Amount">
                      <Input />
                    </Form.Item>
                  </div>
                  <Form.Item name="Account" label="Account">
                    <Select loading />
                  </Form.Item>
                  <Form.Item name="Account Description" label="Account Description">
                    <TextArea disabled autoSize={{ maxRows: 1 }} />
                  </Form.Item>
                </Form>
              </div>
            )}
          </div>
          {!!breakdown && (
            <Space className="flex justify-end mt-4">
              <Button type="primary" htmlType="submit" loading={addBudgetLinesLod}>
                Post
              </Button>
              <Button type="primary" htmlType="reset" ghost>
                Reset
              </Button>
            </Space>
          )}
        </div>
      </Form>
    </div>
  );
}

export default BudgetEnterAmounts;

import { App, Button, Form, Input, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createAppraisalQuestion, getAppraisalQuestion, updateAppraisalQuestion } from 'network/Human-resources/appraisalQuestions';

function QuestionNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();

  const client = useQueryClient();
  const { data: appraisalQuestion } = useQuery([queryKeys.appraisalQuestion, params?.id], () => getAppraisalQuestion(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('salaryStructure', salaryStructure);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.salaryStructureList]);

    form.resetFields();
    navigate('/human-resources/setup/appraisals/questions');
  };

  const { mutate: onCreateAppraisalQuestion, isLoading: onCreateAppraisalQuestionLod } = useMutation(createAppraisalQuestion, {
    onSuccess,
  });
  const { mutate: onUpdateAppraisalQuestion, isLoading: onUpdateAppraisalQuestionLod } = useMutation(updateAppraisalQuestion, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && appraisalQuestion) {
      const editItem = appraisalQuestion?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['startDate']), dayjs(dataObj['endDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, appraisalQuestion, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.useWorkedDayLines = !!dataObj.useWorkedDayLines;
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = appraisalQuestion?.model?.id;
      return onUpdateAppraisalQuestion(dataObj);
    }

    onCreateAppraisalQuestion(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[
          { title: 'Human Resources' },
          { title: 'Setup' },
          { title: 'Appraisals' },
          { title: <Link to="/human-resources/setup/appraisals/questions">Questions</Link> },
        ]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="questionTypeId" label="Question Type" rules={[{ required: true }]} className="col-span-full">
              <Select
                options={metadata?.employeeAppraisalQuestionTypes?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="nameEN" label="Name English" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Name Arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateAppraisalQuestionLod || onUpdateAppraisalQuestionLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default QuestionNew;

import { Spin } from "antd";

const OwnModalLoader = () => {
  return (
    <div className="flex flex-col gap-5 items-center justify-center py-36">
      <Spin />
      <p>Fetching Data</p>
    </div>
  );
};

export default OwnModalLoader;

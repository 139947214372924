import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { Table } from "antd";
import ModalWarehouse from "components/modals/inventoryModals/warehouse/ModalWarehouse";
import ActionsMenu from "components/common/ActionsMenu";
import useWarehouses from "hooks/api/supply-chain/warehouse/useWarehouses";
import useSearchQuery from "hooks/useSearchQuery";

export default function Warehouse() {
  const { data } = useWarehouses();
  const { setSearchQuery } = useSearchQuery();

  const newWarehouse = () => setSearchQuery({ open: true, mode: "new" });
  const editWarehouse = (id) =>
    setSearchQuery({ open: true, mode: "edit", id });

  const columns = [
    {
      title: "Warehouse",
      dataIndex: "nameEN",
      key: "Warehouse",
    },
    {
      title: "Location Stock",
      key: "locationStock",
      render: ({ location }) => location?.nameEN,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Buy to Resupply?",
      key: "buyToReSupply",
      render: ({ buyToReSupply }) => buyToReSupply ? "Yes" : "No",
    },
    {
      title: "actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: "edit",
              label: "Edit",
              onClick: () => editWarehouse(record?.id),
              disabled: record?.isDeleted,
            },
            {
              key: "Delete",
              label: "Delete",
              // onClick: () => onDeleteSalesOrder(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Warehouse" />
        <SearchHero onAddBtn={newWarehouse} />
        <Table
          dataSource={data ?? []}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalWarehouse />
    </>
  );
}

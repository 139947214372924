import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Space, Tabs } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useARSystemOptions from 'hooks/api/useARSystemOptions';
import { updateARSystemOptions } from 'network/account-receivable/system-options';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import Accounting from './tabs/Accounting';
import TransAndCustomers from './tabs/TransAndCustomers';

function SystemOption() {
  const { tabKey } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const client = useQueryClient();

  const { ARSystemOptions } = useARSystemOptions();
  console.log('SystemOption  ARSystemOptions:', ARSystemOptions);

  const { mutate: onUpdateARSystemOptions } = useMutation(updateARSystemOptions, {
    onSuccess: res => {
      console.log('createCustome  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.ARSystemOptions]);

      // form.resetFields();
    },
  });
  useEffect(() => {
    if (ARSystemOptions) {
      // console.log('useEffect  editItem:', editItem);
      const editItem = ARSystemOptions;
      const dataObj = { ...editItem };

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [ARSystemOptions]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.id = ARSystemOptions?.id;

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    onUpdateARSystemOptions(dataObj);
  };

  const items = [
    {
      key: 'accounting',
      label: 'Accounting',
      children: <Accounting />,
      forceRender: true,
    },
    {
      key: 'trans-and-customers',
      label: 'Trans and customers',
      children: <TransAndCustomers />,
      forceRender: true,
    },
  ];

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: 'Setup' }]} current="System Option" />
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="bg-white py-8 px-8 rounded-lg">
          <Tabs activeKey={tabKey || 'accounting'} onChange={key => navigate('/account-receivable/setup/system-option/' + key)} items={items} />
        </div>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit">
            CONFIRM
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default SystemOption;

import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import ModalVendorPricelists from "components/modals/orders/ModalVendorPricelists";
import { useVendorPriceList } from "hooks/api/supply-chain/vendor-priceList/useVendorPriceList";
import dayjs from 'dayjs';

export default function VendorPricelists() {
  const [locationwarehouseModal, setLocationWarehouseModal] = useState(false);
  const { vendorPriceList, isLoading } = useVendorPriceList();
  console.log(vendorPriceList, "vendorPriceList");
  const navigate = useNavigate();

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Vendor Pricelists" />
        <SearchHero onAddBtn={() => setLocationWarehouseModal(true)} />
        <Table
          isLoading={isLoading}
          dataSource={vendorPriceList}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalVendorPricelists
        open={locationwarehouseModal}
        onCancel={() => setLocationWarehouseModal(false)}
      />
    </>
  );
}

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllFixedAssetStructures = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/FixedAssetStructures?${searchQuery}`);
  return data;
});

export const getFixedAssetStructure = catchAsync(async id => {
  const { data } = await axiosInstance(`/FixedAssetStructures/${id}`);
  return data;
});

export const createFixedAssetStructure = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/FixedAssetStructures`, body);
  return data;
});

export const updateFixedAssetStructure = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/FixedAssetStructures`, body);
  return data;
});

export const deleteFixedAssetStructure = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/FixedAssetStructures/${id}`);
  return data;
});

import { useQuery } from '@tanstack/react-query';
import { getAllReceipts } from 'network/account-receivable/receipt';
import { queryKeys } from 'services/react-query/queryKeys';

function useReceiptList(searchQueryStr) {
  const { data, isLoading } = useQuery(
    [queryKeys.receiptList, searchQueryStr],
    () => getAllReceipts(searchQueryStr)
  );

  const receiptList = data?.model;

  return {
    receiptList,
    receiptListLod: isLoading,
  };
}

export default useReceiptList;

import { Button, Checkbox, Form, Input } from "antd";
import OwnModal from "components/own/OwnModal";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-number-input";

function ModalContactAccount({ open, onCancel, editItem, setEditItem }) {
  console.log("ModalAddSegment  editItem:", editItem);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onCancel();
    console.log("ModalAddSegment  values:", values);
  };
  return (
    <OwnModal
      title={editItem ? "Edit Contact Account" : "Add Contact Account"}
      open={open}
      onCancel={onCancel}
      width={902}
    >
      <Form
        layout="vertical"
        form={form}
        // size="large"
        onFinish={onFinish}
        // className="pt-4"
      >
        <div className="col-2">
          <Form.Item name="Name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item
            name="Phone Number"
            label="Phone Number"
            // rules={[{ required: true }]}
          >
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item name="Email" label="Email">
            <Input />
          </Form.Item>
        </div>
        <Button
          // loading={addressAddLod || addressUpdateLod}
          type="primary"
          htmlType="submit"
          className="block ms-auto w-full max-w-xs"
        >
          ADD
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalContactAccount;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import OwnModal from "components/own/OwnModal";
import dayjs from "dayjs";
import useMetaData from "hooks/api/useMetaData";
import { createTaxes, updateTaxes } from "network/taxes/taxes";
import React from "react";
import { queryKeys } from "services/react-query/queryKeys";
import constents from "utils/constants";

function ModalAddTax({ open, onCancel, editItem, setEditItem }) {
  const {
    taxTypes,
    countries,
    taxApplyingMethods,
    taxApplyingLevels,
    taxRates,
  } = useMetaData();
  const [form] = Form.useForm();
  const client = useQueryClient();

  const { mutate: onCreateTax, isLoading } = useMutation(createTaxes, {
    onSuccess: (res) => {
      console.log("ModalPeriod  res:", res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.taxList]);
      form.resetFields();
      onCancel();
    },
  });

  const { mutate: onUpdateTax, isLoading: updateLod } = useMutation(
    updateTaxes,
    {
      onSuccess: (res) => {
        console.log("ModalTax  res:", res);
        message.success(res?.metas?.message);
        client.invalidateQueries([queryKeys.taxList]);
        form.resetFields();
        onCancel();
      },
    }
  );

  // {
  //   "name": "Test Supplier name",
  //   "phoneNumber": "010544587879",
  //   "email": "test@t.com",
  //   "dunsNumber": "",
  //   "faxNumber": "02222454578",
  //   "supplierTypeId": 1,
  //   "countryId": "462493B3-2081-4EAB-BEC3-852B04D69278",
  //   "cityId": "2E040A05-F8E0-42DB-8295-A383A5DC28AA",
  //   "addressName": "Cairo",
  //   "addressLine1": "",
  //   "addressLine2": "",
  //   "addressLine3": "",
  //   "isForPurchasingPayment": false,
  //   "isForRFQOnly": true,
  //   "liabilityAccount": "test liabilityAccount",
  //   "prepayment": "test prepayment",
  //   "paymentMethodId": 2,
  //   "paymentMethodEndDate": "2023-09-15T12:55:11.925Z"
  // }

  // useEffect(() => {
  //     const dataObj = { ...editItem };

  //     dataObj.areaId = editItem?.area_id;
  //     dataObj.provinceId = editItem?.province_id;

  //     form.setFieldsValue(dataObj);
  //   }, [editItem?.id]);
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const onFinishAddTax = ({ effectiveDate, ...values }) => {
    console.log("ModalStructure  values:", values);
    const body = {
      ...values,
      taxValue: +values?.taxValue,
      effectiveStartDate: dayjs(effectiveDate?.[0]).format(
        constents.dateFormat
      ),
      effectiveEndDate: dayjs(effectiveDate?.[1]).format(constents.dateFormat),
    };

    if (editItem) {
      body.id = editItem.id;
      onUpdateTax(body);
    } else {
      onCreateTax(body);
    }
  };
  return (
    <OwnModal
      title={editItem ? "Edit Tax" : "Add Tax"}
      open={open}
      onCancel={onCancel}
      width={902}
    >
      <Form
        layout="vertical"
        form={form}
        // size="large"
        onFinish={onFinishAddTax}
        // className="pt-4"
      >
        <div className="col-2">
          <Form.Item
            name="countryId"
            label="Country"
            rules={[{ required: true }]}
          >
            <Select
              allowClear
              options={countries?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="name" label="Tax Name">
            <Input />
          </Form.Item>
          <Form.Item name="taxTypeId" label="Tax type">
            <Select
              allowClear
              options={taxTypes?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="taxRateId" label="Tax rate">
            <Select
              allowClear
              options={taxRates?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="taxValue" label="Tax value">
            <Input type="number" />
          </Form.Item>
          <Form.Item name="taxApplyingLevels" label="Applying level">
            <Select
              allowClear
              options={taxApplyingLevels?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="taxApplyingMethodId" label="Applying method">
            <Select
              allowClear
              options={taxApplyingMethods?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="expenseAccount" label="Expense Account">
            <Input />
          </Form.Item>
          <Form.Item name="liabilityAccount" label="Liability Account">
            <Input />
          </Form.Item>
          <Form.Item name="effectiveDate" label="Effective date">
            <RangePicker disabledDate={disabledDate} />
          </Form.Item>
        </div>
        <Button
          loading={isLoading || updateLod}
          type="primary"
          htmlType="submit"
          className="block ms-auto w-full max-w-xs"
        >
          DONE
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalAddTax;

import { App, Button, Form, Input } from 'antd';
import BrandLogo from 'components/common/BrandLogo';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { authEditCompanyProfile } from 'network/auth';
import { setUserData } from 'services/store/slices/user';

function Register() {
  const naviggate = useNavigate();
  const dispatch = useDispatch();
  const { message } = App.useApp();
  // const [form] = Form.useForm();
  const userData = useSelector(s => s.user?.data);
  const { mutate: onAuthEditCompanyProfile, isLoading } = useMutation(
    authEditCompanyProfile,
    {
      onSuccess: res => {
        console.log('Register  res:', res);

        // const newUserData = { ...userData, legalEntity: res?.model };
        // dispatch(setUserData(newUserData));
        message.success(res?.metas?.message);
        naviggate('/complete-data/logo');
      },
      onError: error => {
        console.log('Register  error:', error);
        // message.error(
        //   'Please enter a correct email and password. Note that both fields may be case-sensitive.'
        // );
      },
    }
  );
  const onFinish = values => {
    console.log('onFinish  values:', values);
    parsePhoneNumber(values.phone);
    const { countryCallingCode, nationalNumber } = parsePhoneNumber(
      values.phone
    );

    const body = { ...values };
    body.phoneKey = '+' + countryCallingCode;
    body.phoneNumber = nationalNumber;
    delete body.phone;
    console.log('body', body);

    onAuthEditCompanyProfile(body);
    return {};
  };
  return (
    <div className="auth-box-wrapper">
      <div className="auth-box-holder">
        <div className="flex justify-center mb-9">
          <BrandLogo />
        </div>
        <Form
          initialValues={{
            userName: userData?.userName,
            phone: userData?.phoneKey + userData?.phoneNumber,
          }}
          className="form-style"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="userName"
            label="User Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Write User name" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true }]}
          >
            <PhoneInput
              defaultCountry="EG"
              placeholder="Enter phone number"
              // value={value}
              // onChange={setValue}
            />
          </Form.Item>
          {/* <Form.Item
            name="Email"
            label="Email"
            // rules={[{ required: true }]}
          >
            <Input placeholder="Write Email, User name" />
          </Form.Item> */}
          <Form.Item
            name="password"
            label="Password"
            // rules={[{ required: true }]}
          >
            <Input.Password placeholder="Write Here" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              // { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match!')
                  );
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Write Here" />
          </Form.Item>

          {/* <Link to="forgot-password" className="block w-fit ms-auto">
          Forget Password
        </Link> */}

          <Button htmlType="submit" type="primary" block loading={isLoading}>
            NEXT
          </Button>
          <p className="account-register-row">
            Already have an account?
            <Link to="/login">Sign In</Link>
          </p>
        </Form>
      </div>
    </div>
  );
}

export default Register;

import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table } from "antd";
import ModalOperationType from "components/modals/inventoryModals/warehouse/ModalOperationType";
import useOperationTypes from "hooks/api/supply-chain/operationTypes/useOperationTypes";
import useSearchQuery from "hooks/useSearchQuery";

export default function OperationType() {
  const { operationTypes } = useOperationTypes();
  const { setSearchQuery } = useSearchQuery();

  const newOperationType = () => {
    setSearchQuery({ open: true, mode: "new" });
  };

  const editOperationType = (id) => {
    setSearchQuery({ open: true, mode: "edit", id });
  };

  const columns = [
    {
      title: "Operation Type",
      dataIndex: "nameEN",
      key: "operationType",
    },
    {
      title: "Warehouse",
      key: "warehouse",
      render: ({ warehouse }) => warehouse?.nameEN,
    },
    {
      title: "Reservation Method",
      key: "reservationMethod",
      render: ({ reservationMethod }) => reservationMethod?.nameEN,
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <Button onClick={() => editOperationType(id)}>Edit</Button>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Location" />
        <SearchHero onAddBtn={newOperationType} />
        <Table
          dataSource={operationTypes}
          sticky
          columns={columns}
          pagination={{ pageSize: 10 }}
        />
      </div>
      <ModalOperationType />
    </>
  );
}

import { Form, Select } from 'antd';
import useAPSpecialCalenderList from 'hooks/api/useAPSpecialCalenderList';
import React from 'react';

function Calendar() {
  const { APSpecialCalenderList } = useAPSpecialCalenderList();
  return (
    <div className="col-2">
      <Form.Item name="calenderId" label="Depreciation Calendar">
        <Select options={APSpecialCalenderList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
      </Form.Item>
      {/* <Form.Item name="Current fiscal year" label="Current fiscal year">
        <Select loading />
      </Form.Item> */}
      <Form.Item name="prorateCalenderId" label="Prorate Calendar">
        <Select options={APSpecialCalenderList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
      </Form.Item>
      {/* <Form.Item name="Current period" label="Current period">
        <Select loading />
      </Form.Item> */}
    </div>
  );
}

export default Calendar;

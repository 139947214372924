import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import ModalReorderingAdd from "components/modals/inventoryModals/products/ModalReorderingAdd";
import { useReordering } from "hooks/products/useReordering";

export default function Reordering() {
  const { reorderingData } = useReordering();
  const [reordering, setReordering] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    // {
    //     title: "Location Name",
    //     dataIndex: "locationName",
    //     key: "locationName",
    // },
    // {
    //     title: "On Hand",
    //     dataIndex: "onHand",
    //     key: "onHand",
    // },
    {
      title: "Alert At",
      dataIndex: "alertAt",
      key: "alertAt",
    },
    {
      title: "Min Quantity",
      dataIndex: "minimumQuantity",
      key: "minimumQuantity",
    },
    {
      title: "Max Quantity",
      dataIndex: "maximumQuantity",
      key: "maximumQuantity",
    },
    {
      title: "To Order",
      dataIndex: "toOrder",
      key: "toOrder",
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Reordering" />
        <SearchHero onAddBtn={() => setReordering(true)} />
        <Table
          dataSource={reorderingData}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalReorderingAdd
        open={reordering}
        onCancel={() => setReordering(false)}
      />
    </>
  );
}

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";


export const getDepartment = catchAsync(async (id) => {
    const { data } = await axiosInstance.get("/Departments", {
      params: {
        id,
      },
    });
    return data;
  });
  
  export const addDepartment = catchAsync(async (body) => {
    const res = await axiosInstance.post("/Departments", body);
    return res;
  });
  
  export const updateDepartment = catchAsync(async (body) => {
    const res = await axiosInstance.put("/Departments", body);
    return res;
  });
  
import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import {
  ModalDeleteProject,
  ModalMutateProject,
} from "components/modals/project-manager/projects/ModalProject";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useProjects } from "hooks/api/projects-management/projects";
import useModalParams from "hooks/useModalParams";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";

export default function Projects() {
  const { openAsNew, openAsEdit, openAsDelete } = useModalParams("projects");
  const { pathname } = useLocation();

  const { projects } = useProjects();

  console.log(projects, "projects");

  const columns = [
    {
      title: "Project Name",
      key: "name",
      render: (_, { name, id }) => (
        <Link to={pathname + "/" + id}>
          <p className="text-color-primary">{name}</p>
        </Link>
      ),
    },
    {
      title: "Project Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Start Date",
      key: "startDate",
      render: ({ startDate }) => (
        <p>{dayjs(new Date(startDate)).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      title: "Finish Date",
      key: "finishDate",
      render: ({ finishDate }) => (
        <p>{dayjs(new Date(finishDate)).format("DD/MM/YYYY")}</p>
      ),
    },
    {
      title: "Project Manager",
      dataIndex: "manager",
      key: "manager",
      render: (_, { manager }) => <p>{manager?.name}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, { id }) => (
        <div className="flex gap-4 items-center">
          <Button onClick={() => openAsEdit({ id })}>Edit</Button>
          <Button danger onClick={() => openAsDelete({ id })}>Delete</Button>
        </div>
      ),
    },
  ];

  const breadcrumbsItems = [
    {
      title: <p className="text-black">Project Managment</p>,
    },
    {
      title: <Link to="/project-managment/projects">Projects</Link>,
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb
          items={breadcrumbsItems}
        />
        <SearchHero onAddBtn={() => openAsNew()} />
        <Table columns={columns} dataSource={projects ?? []} />
      </div>
      <ModalMutateProject />
      <ModalDeleteProject />
    </>
  );
}

import { Button, Table, Tag } from "antd";
import SearchHero from "components/common/SearchHero";
import ApplicationModal from "components/modals/human-resources/setup/ApplicationModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import dayjs from "dayjs";
import { useJobApplication } from "hooks/human-resourses/useJobApplication";
import { useState } from "react";

function ApplicationtList() {
  const { applicationData } = useJobApplication();
  console.log(applicationData, "applicationData");
  // const { openAsNew, openAsEdit } = useModalParams();
  const [isOpen, setIsOpen] = useState(false);
  const [LocationId, setLocationId] = useState("");

  const columns = [
    {
      title: "Application Name",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    {
      title: "Applicant Name",
      dataIndex: "applicantName",
      key: "applicantName",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      key: "mobile",
    },
    // {
    //   title: "Department",
    //   dataIndex: "departmentId",
    //   key: "departmentId",
    // },
    {
      title: "Expected Salary",
      dataIndex: "expectedSalary",
      key: "expectedSalary",
    },
    {
      title: "Available Date",
      key: "Available Date",
      render: ({ availabilityDate }) =>
        dayjs(availabilityDate).format("YYYY-MM-DD"),
    },
    {
      title: "Status",
      key: "Status",
      render: ({ id }) => <Tag color="magenta">Proposal</Tag>,
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <>
          <Button
            type="primary"
            ghost
            onClick={() => {
              setIsOpen({ id });
              setLocationId(id);
            }}
          >
            Edit
          </Button>
          {/* <Button type="primary" onClick={() => setIsOpen({ id })}>View</Button> */}
        </>
      ),
    },
  ];

  const data = [
    {
      "Application Name": "Application 1",
      "Applicant's Name": "Mostafa gamal",
      email: "email1@example.com",
      "Phone Number": "123456789",
      "Applied Job": "Job 1",
      Department: "Department 1",
      "Expected Salary": 50000,
      "Available Date": "2022-01-01",
    },
    {
      "Application Name": "Application 2",
      "Applicant's Name": "Ali ahmed",
      email: "email2@example.com",
      "Phone Number": "987654321",
      "Applied Job": "Job 2",
      Department: "Department 2",
      "Expected Salary": 60000,
      "Available Date": "2022-02-01",
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Human Resources / Setup / Applications" />
        <SearchHero
          onAddBtn={() => {
            setIsOpen(true);
            setLocationId("");
          }}
        />
        <Table sticky dataSource={applicationData} columns={columns} />
      </div>
      <ApplicationModal
        open={isOpen}
        LocationId={LocationId}
        handelClose={() => setIsOpen(false)}
      />
    </>
  );
}

export default ApplicationtList;

import { SwapOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useDailyRateList from 'hooks/api/useDailyRateList';
import { deleteDailyRate } from 'network/general-ledger/daily-rate';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { arrayFrom, dateFormat } from 'utils/utils';

function DailyRates() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteDailyRate } = useMutation(deleteDailyRate, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.dailyRateList]);
    },
  });
  const { dailyRateList } = useDailyRateList();

  const columns = [
    {
      key: 'fromCurrencyId',
      dataIndex: 'fromCurrencyId',
      title: 'from',
      render: (val, record) => 'Need from Currency name',
    },
    {
      key: 'toCurrencyId',
      dataIndex: 'toCurrencyId',
      title: 'to',
      render: (val, record) => 'Need to Currency name',
    },
    {
      key: 'toDate',
      dataIndex: 'toDate',
      title: 'Rate',
      render: (val, record) => (
        <div className="space-x-2">
          <span className="text-red-500">{record?.fromToCurrencyValue}</span>
          <SwapOutlined />
          <span className="text-green-500">{record?.toFromCurrencyValue}</span>
        </div>
      ),
    },
    {
      key: 'fromDate',
      dataIndex: 'fromDate',
      title: 'from Date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'toDate',
      dataIndex: 'toDate',
      title: 'to Date',
      render: (val, _) => dateFormat(val),
    },

    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteDailyRate(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = dailyRateList;
  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Daily Rate" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table
        dataSource={data}
        sticky
        columns={columns}
        // rowSelection={rowSelection}
        // scroll={{ y: '66vh' }}
        // className="overflow-auto min-w-max"
      />
    </div>
  );
}

export default DailyRates;

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
} from "antd";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import dayjs from "dayjs";
import {
  createPaymentTerms,
  getPaymentTerms,
  updatePaymentTerms,
} from "network/account-receivable/payment-terms";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { queryKeys } from "services/react-query/queryKeys";
import constents from "utils/constants";

function PaymentTermsNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();
  const { data: paymentTermsData } = useQuery(
    [queryKeys.customerList, params?.id],
    () =>
      getPaymentTerms(params?.id),
    {
      enabled: !!params?.id,
    },
  );
  // console.log('paymentTermsData', paymentTermsData);

  const onSuccess = (res) => {
    console.log("createCustome  res:", res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.customerList]);

    form.resetFields();
    navigate("/account-receivable/customers/payment-terms");
  };

  const { mutate: onCreatePaymentTerms } = useMutation(createPaymentTerms, {
    onSuccess,
  });
  const { mutate: onUpdatePaymentTerms } = useMutation(updatePaymentTerms, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && paymentTermsData) {
      // console.log('useEffect  editItem:', editItem);
      const editItem = paymentTermsData?.model || {};
      const dataObj = { ...editItem };
      dataObj["fromTo"] = [
        dayjs(dataObj["effectiveDateFrom"]),
        dayjs(dataObj["effectiveDateTo"]),
      ];

      // dataObj['paymentTermRules'] = dataObj;
      // dataObj['glDate'] = dayjs(dataObj['glDate']);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, paymentTermsData]);

  const onFinish = (values) => {
    console.log("onFinish  values:", values);
    const dataObj = { ...values };
    dataObj["effectiveDateFrom"] = dayjs(dataObj["fromTo"]?.[0]).format(
      constents.dateFormat,
    );
    dataObj["effectiveDateTo"] = dayjs(dataObj["fromTo"]?.[1]).format(
      constents.dateFormat,
    );
    delete dataObj["fromTo"];

    if (!!dataObj.paymentTermRules?.length) {
      dataObj.paymentTermRules = dataObj?.paymentTermRules?.map((item) => ({
        days: item?.days,
        percentage: item?.percentage,
        sequence: item?.sequence,
        id: item?.id,
      }));
      const totalPercentage = dataObj?.paymentTermRules?.reduce((a, c) => {
        return a + c.percentage;
      }, 0);

      console.log("totalPercentage  totalPercentage:", totalPercentage);
      if (totalPercentage !== 100) {
        message.error("Total percentage must equal 100");
        return;
      }
    }
    console.log("onFinish  dataObj:", dataObj);
    // return;

    // return;
    if (isEdit) {
      dataObj.id = paymentTermsData?.model?.id;
      return onUpdatePaymentTerms(dataObj);
    }

    onCreatePaymentTerms(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{
          title: (
            <Link to="/account-receivable/customers/payment-terms">
              Payment Terms
            </Link>
          ),
        }]}
        current={isEdit ? "Edit" : "Create"}
      />

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        className="space-y-6"
        initialValues={{ paymentTermRules: [""] }}
      >
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>

            <Form.Item
              name="fromTo"
              label="Effective Dates From - To"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker
                className="w-full"
                format="YYYY-MM-DD"
                disabledDate={(current) => {
                  // Can not select days before today and today
                  return current && current < dayjs().endOf("day");
                }}
              />
            </Form.Item>
          </div>
        </div>

        <Divider>
          <h2 className="title-24 mb-4">Payment Schedule</h2>
        </Divider>
        <div className="bg-white pt-8 pb-8 px-8 rounded-lg">
          <Form.List name="paymentTermRules">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key + Math.random()}>
                      <div className="col-3 p-3 bg-gray-50 rounded-lg">
                        <Form.Item hidden name={[index, "id"]}>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[index, "sequence"]}
                          label="Sequence"
                          rules={[{ required: true }]}
                        >
                          <InputNumber className="w-full" />
                        </Form.Item>
                        <Form.Item
                          name={[index, "percentage"]}
                          label="Percentage"
                          rules={[
                            {
                              type: "number",
                              max: 100,
                            },
                            { required: true },
                          ]}
                        >
                          <InputNumber suffix="%" className="w-full" />
                        </Form.Item>
                        <Form.Item
                          name={[index, "days"]}
                          label="Days"
                          rules={[{ required: true }]}
                        >
                          <InputNumber className="w-full" />
                        </Form.Item>
                      </div>

                      {fields.length > 1
                        ? (
                          <Button
                            type="danger"
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            icon={<MinusCircleOutlined />}
                          >
                            Remove Above Field
                          </Button>
                        )
                        : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      onClick={add}
                      icon={<PlusOutlined />}
                    />
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit">
            {isEdit ? "Update" : "Create"}
          </Button>

          <Button
            type="primary"
            htmlType="reset"
            ghost
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default PaymentTermsNew;

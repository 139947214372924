import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  Col,
  Row,
  Radio,
  message,
} from "antd";
import { FolderAddOutlined } from "@ant-design/icons";

import OwnModal from "components/own/OwnModal";
import { useState } from "react";
import FormListProducts from "pages/inventory/products/add-product/FormListProducts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addProducts } from "network/products/products";
import { useCategories } from "hooks/products/useCategoryProduct";
import useMetaData from "hooks/api/useMetaData";
import useSupplierList from "hooks/api/useSupplierList";
import useAccountList from "hooks/api/useAccountList";
import { queryKeys } from "services/react-query/queryKeys";

export default function ModalAddProduct(props) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const [productCategcory, setProductCategory] = useState(1);
  const [productType, setProductType] = useState(1);
  const [account, setaccount] = useState(1);
  const { supplierList } = useSupplierList();
  const res = useAccountList();
  const client = useQueryClient();
  console.log(res, "supplierList");
  const { mutate: mutateProduct, isLoading } = useMutation({
    mutationFn: addProducts,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.products]);
      message.success("Created successfully");
      props.onCancel();
    },
    onError: (error) => message.error(`Error: ${error?.message}`),
  });

  const { categoriesData } = useCategories();
  const { productTypes, supplierTypes } = useMetaData();
  const removaleStrategyChange = (value) => {
    console.log("search:", value);
    setProductCategory(value);
  };
  const accountChange = (value) => {
    console.log("search:", value);
    setaccount(value);
  };
  const productTypeChange = (value) => {
    console.log("search:", value);
    setProductType(value);
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const onFinish = (values) => {
    let productAvaliblity = {};
    if (values?.product_availability == "canBeSold") {
      productAvaliblity = { canBeSold: true, canBePurchased: false };
    } else {
      productAvaliblity = { canBeSold: false, canBePurchased: true };
    }
    delete values.product_availability;
    mutateProduct({
      ...values,
      ...productAvaliblity,
      incomeAccountId: "f7df0769-8e25-4576-8100-12c8ef2d8817",
      expenseAccountId: "091f9328-7143-44ee-a52b-a75b8fdc244c",
      priceDiffernceAccountId: "3711F77D-37F0-4BC8-867D-FF8C4B924BC2",
    });
  };
  return (
    <OwnModal title="Add Product" {...props}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="grid md:grid-cols-2 gap-x-9 gap-y-4">
          <Form.Item
            name="nameEN"
            label="Product Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item label="Product Image">
            <Upload.Dragger
              // beforeUpload={() => {
              //   return false;
              // }}
              prefixCls="upload-no-style"
              fileList={fileList}
              showUploadList={false}
              maxCount={1}
              accept="image.jpeg,image.png, image.jpg"
              onChange={({ file, fileList }) => {
                setFileList(fileList);
                console.log("UploadLogo  file.status:", file.status);
                if (file.status !== "uploading") {
                  console.log(file, fileList);
                }
                if (file.status === "done") {
                }
                getBase64(file.originFileObj, (url) => {
                  console.log("UploadLogo  url:", url);
                  // setLoading(false);
                  setImageUrl(url);
                });
              }}
            >
              {imageUrl ? (
                <div className="bg-red-30 h-[300px] flex items-center justify-center">
                  <img
                    src={imageUrl}
                    alt="avatar"
                    className="block max-w-full max-h-full "
                  />
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center gap-4">
                  <FolderAddOutlined style={{ fontSize: 50 }} />
                  <div className="text-center">
                    <h4 className="text-2xl">Upload Image</h4>
                    <p className="text-xs text-[#868686]">Drag it here</p>
                  </div>
                </div>
              )}
            </Upload.Dragger>
          </Form.Item> */}
          <Form.Item
            name="product_availability"
            label="Product Availability"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value="canBeSold">Can be Sold</Radio>
              <Radio value="canBePurchased">Can be Purchased</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="productCategoryId"
            label="Product Category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // placeholder="Select a person"
              // initialvalues="category one"
              // onChange={removaleStrategyChange}
              options={categoriesData?.map((category) => ({
                label: category?.nameEN,
                value: category?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="salesPrice"
            label="Sales Price"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Cost"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="productTypeId"
            label="Product Type"
            // required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // placeholder="Select a person"
              // initialvalues="type one"
              onChange={productTypeChange}
              options={productTypes?.map((el) => ({
                label: el?.nameEN,
                value: el?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="internalReferance"
            label="Internal Refrence"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="barcode"
            label="Barcode"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Divider />
        <h2 className="title-20 mb-2">Purchase</h2>
        <FormListProducts supplierTypes={supplierList} />
        <h2 className="title-20 mb-2">Vendor Bills</h2>
        <div className="mt-4 grid md:grid-cols-2 gap-x-9 gap-y-4">
          <Form.Item
            name="controlPolicyId"
            label="Control Policy"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value="0">On Ordered Quantities</Radio>
              <Radio value="1">On Ordered Received</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="purchaseDescription"
            label="Purchase Description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Divider />
        <h2 className="title-20 mb-2">Inventory</h2>
        <div className="mt-4 grid md:grid-cols-2 gap-x-9 gap-y-4">
          <Form.Item
            name="responsibleEmail"
            label="Resposible"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="weight"
            label="Weight"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter weight per kg" />
          </Form.Item>
          <Form.Item
            name="volume"
            label="Volume"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Enter volume per m" />
          </Form.Item>
          <Form.Item
            name="customer_lead_time"
            label="Customer Lead Time"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="receiptDescription"
            label="Description For Recepits"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="deliveryDescription"
            label="Description For Delivery Orders"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="internalDescription"
            label="Description For Internal Transfer"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="internalNote"
            label="Internal Note"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Divider />
        <h2 className="title-20 mb-2">Accounting</h2>
        <div className="mt-4 grid md:grid-cols-2 gap-x-9 gap-y-4 ">
          <h2 className="font-semibold">Receivables</h2>
          <div className="col-span-1"></div>
          <Form.Item
            name="incomeAccountId"
            label="Income Account"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="col-span-1"></div>
          <h2 className="font-semibold">Payabels</h2>
          <div className="col-span-1"></div>
          <Form.Item
            name="expenseAccountId"
            label="Expense Account"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="priceDiffernceAccountId"
            label="Price Difference Account"
            // required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // placeholder="Select a person"
              initialvalues="account one"
              onChange={accountChange}
              options={[
                {
                  label: "account one",
                  value: "account one",
                },
                {
                  label: "account two",
                  value: "account two",
                },
              ]}
            />
          </Form.Item>
        </div>

        <Button
          type="primary"
          className="mt-4"
          htmlType="submit"
          loading={isLoading}
          // onClick={onAddNewLine}
        >
          Submit
        </Button>
      </Form>
    </OwnModal>
  );
}

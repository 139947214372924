import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, InputNumber, Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import TransferColumn from './TransferColumn';

function TransferRow({ field, index, fields, remove, accountList, accountListLod, selectedBudget, collectTransferAmount, setCollectTransferAmount }) {
  const [amountType, setAmountType] = useState('fixed');
  const [originalAmmount, setOriginalAmmount] = useState(null);
  const [transferAmmount, setTransferAmmount] = useState(0);

  const getCollectTransferAmount = useCallback(() => {
    const amount = amountType === 'percentage' ? (transferAmmount / 100) * originalAmmount : transferAmmount;
    console.log('getCollectTransferAmount  amount:', amount);
    setCollectTransferAmount(amount);
  }, [amountType, transferAmmount, originalAmmount, setCollectTransferAmount]);

  useEffect(() => {
    getCollectTransferAmount();
  }, [getCollectTransferAmount]);
  return (
    <div className="mb-4">
      <div className="flex gap-x-4">
        <TransferColumn
          title="From"
          selectedBudget={selectedBudget}
          accountList={accountList}
          accountListLod={accountListLod}
          setOriginalAmmount={setOriginalAmmount}
          index={index}
          collectTransferAmount={collectTransferAmount}
        />

        <Divider type="vertical" className="h-auto" />

        <TransferColumn
          title="To"
          selectedBudget={selectedBudget}
          accountList={accountList}
          accountListLod={accountListLod}
          setOriginalAmmount={setOriginalAmmount}
          index={index}
          collectTransferAmount={collectTransferAmount}
        />
      </div>

      <div className="col-2 bg-gray-50 p-3 rounded">
        {/* <Form.Item name={[index, 'lineFrom', 'amount_type']} label="Amount type"> */}
        <Form.Item label="Amount type">
          <Select
            value={amountType}
            onChange={value => setAmountType(value)}
            options={[
              {
                label: 'Fixed',
                value: 'fixed',
              },
              {
                label: 'Percentage',
                value: 'percentage',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name={[index, 'lineFrom', 'amount']}
          label="Amount"
          rules={[{ required: true }, { type: 'number', min: 0, max: amountType === 'percentage' ? 100 : originalAmmount }]}>
          <InputNumber
            suffix={amountType === 'percentage' && '%'}
            controls={false}
            className="w-full"
            onChange={value => {
              setTransferAmmount(value);
            }}
          />
        </Form.Item>
      </div>

      {fields.length > 1 ? (
        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
          Remove Above Field
        </Button>
      ) : null}
    </div>
  );
}

export default TransferRow;

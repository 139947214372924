import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const authLogin = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Account/login', body);
  console.log('authLogin  data:', data);
  return data;
});

export const authRegisterCompany = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Account/register', body);
  return data;
});

export const authEditCompanyProfile = catchAsync(async body => {
  const { data } = await axiosInstance.post(
    '/Account/EditCompanyProfile',
    body
  );
  return data;
});

export const authChangeLogo = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Account/ChangeLogo', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
});

export const authLegalEntities = catchAsync(async body => {
  const { data } = await axiosInstance.post('/LegalEntities', body);
  return data;
});

export const authAddresses = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Addresses', body);
  return data;
});

export const authLedgers = catchAsync(async body => {
  const { data } = await axiosInstance.post('/Ledgers', body);
  return data;
});

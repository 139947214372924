import { useQuery } from "@tanstack/react-query";
import { getMetaData } from "network/metaData";
import { queryKeys } from "services/react-query/queryKeys";

function useMetaData() {
  const { data, isLoading } = useQuery([queryKeys.metadata], getMetaData, {
    staleTime: Infinity,
  });

  const model = data?.model || {};

  // const {
  //   accountMethods,
  //   calenderTypes,
  //   languages,
  //   periodTypes,
  //   yearTypes,
  //   cities,
  //   countries,
  //   bankBranchTypes,
  //   taxRates,
  //   taxTypes,
  //   taxApplyingMethods,
  //   taxApplyingLevels,
  //   bankStatementLineTypes,
  // } = model;

  // return {
  //   // ...model,
  //   accountMethods,
  //   calenderTypes,
  //   languages,
  //   periodTypes,
  //   yearTypes,
  //   cities,
  //   countries,
  //   bankBranchTypes,
  //   taxRates,
  //   taxTypes,
  //   taxApplyingMethods,
  //   taxApplyingLevels,
  //   bankStatementLineTypes,
  //   metaDataLod: isLoading,
  // };

  return model;
}

export default useMetaData;

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
} from "antd";
import OwnModal from "components/own/OwnModal";
import useMetaData from "hooks/api/useMetaData";
import React, { useEffect, useState } from "react";

function ModalStatementLines({ open, onCancel, editItem, bankStatmentInfo }) {
  console.log("bankStatmentInfo:", bankStatmentInfo);
  const [form] = Form.useForm();
  const validationType = Form.useWatch("Validation type", form);
  const { bankStatementLineType } = useMetaData();

  useEffect(() => {
    if (!editItem) return;
    const dataObj = { ...editItem };

    dataObj.areaId = editItem?.area_id;
    dataObj.provinceId = editItem?.province_id;

    form.setFieldsValue(dataObj);
  }, [editItem?.key]);

  const onFinish = (values) => {
    console.log("ModalSalesInvoiceLines  values:", values);
  };
  return (
    <>
      <OwnModal
        // title={editItem ? 'Edit Segment' : 'Add Segment'}
        title={"Statement Line"}
        open={open}
        onCancel={onCancel}
        centered
        width={1366}
        // style={{ top: 20 }}
      >
        <div className="space-y-">
          {/* <div className="max-w-2xl mx-auto items-center flex justify-between flex-wrap gap-2">

          </div> */}
          <div className="col-3 gap-9">
            <h4 className="text-3xl font-medium text-[#424242]">
              Account Number : {bankStatmentInfo?.bankName}
            </h4>
            <h4 className="text-3xl font-medium text-[#424242]">
              Bank Name : {bankStatmentInfo?.bankName}
            </h4>
            <h4 className="text-3xl font-medium text-[#424242]">
              Account Name : {bankStatmentInfo?.accountName}
            </h4>
            <h4 className="text-3xl font-medium text-[#424242]">
              Bank Branch : {bankStatmentInfo?.branchAddress}
            </h4>
            <h4 className="text-3xl font-medium text-[#424242]">Date :</h4>
            <h4 className="text-3xl font-medium text-[#424242]">Currency :</h4>
            <h4 className="text-3xl font-medium text-[#424242]">
              Document Number : {bankStatmentInfo?.documentNumber}
            </h4>
            <h4 className="text-3xl font-medium text-[#424242]">GL Date :</h4>
            <h4 className="text-3xl font-medium text-[#424242]">
              Check Digits :
            </h4>
          </div>

          <Divider className="!max-w-3xl min-w-0 mx-auto" />
          <div className="bg-white py-8 px-8 rounded-lg">
            <Form
              layout="vertical"
              form={form}
              // size="small"
              onFinish={onFinish}
              initialValues={{ fields: [undefined] }}
            >
              <Form.List name="bankStatementLines">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <div key={field.key}>
                          <div className="col-7 bg-gray-50 p-3 rounded items-end">
                            <Form.Item name={[index, "number"]} label="Number">
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name={[index, "bankStatementLineTypeId"]}
                              label="Type"
                            >
                              <Select
                                allowClear
                                options={bankStatementLineType?.map((s) => ({
                                  value: s?.id,
                                  label: s?.nameEN,
                                }))}
                              />
                            </Form.Item>
                            {/* <Form.Item
                              name={[index, "Currency"]}
                              label="Currency"
                            >
                              <Input />
                            </Form.Item> */}
                            <Form.Item name={[index, "amount"]} label="Amount">
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name={[index, "amountCleared"]}
                              label="Amount cleared"
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name={[index, "clearedDate"]}
                              label="Cleared date"
                            >
                              <DatePicker />
                            </Form.Item>
                            <Form.Item
                              name={[index, "date"]}
                              label="Transaction Date"
                            >
                              <DatePicker />
                            </Form.Item>
                            <Form.Item name={[index, "glDate"]} label="GL Date">
                              <DatePicker />
                            </Form.Item>
                          </div>

                          {fields.length > 1 ? (
                            <Button
                              type="danger"
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                              icon={<MinusCircleOutlined />}
                            >
                              Remove Above Field
                            </Button>
                          ) : null}
                        </div>
                      ))}
                      <Divider />
                      <Form.Item>
                        <Button
                          type="primary"
                          size="large"
                          onClick={add}
                          icon={<PlusOutlined />}
                        >
                          add new line
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>

              <Space wrap className="flex justify-end mt-4">
                <Button type="primary" onClick={() => onCancel()}>
                  Reconcile
                </Button>
              </Space>
            </Form>
          </div>
        </div>
      </OwnModal>
    </>
  );
}

export default ModalStatementLines;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import dayjs from 'dayjs';
import { createPeriod, updatePeriod } from 'network/general-ledger/period';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ModalPeriod({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();
  const { mutate: onCreatePeriod, isLoading: createPeriodLod } = useMutation(createPeriod, {
    onSuccess: res => {
      console.log('ModalPeriod  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.periodList]);

      form.resetFields();
      onCancel();
    },
  });
  const { mutate: onUpdatePeriod, isLoading: updatePeriodLod } = useMutation(updatePeriod, {
    onSuccess: res => {
      console.log('ModalPeriod  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.periodList]);

      form.resetFields();
      onCancel();
    },
  });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['from']), dayjs(dataObj['to'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['from'] = dayjs(dataObj['fromTo'][0]).format(constents.dateFormat);
    dataObj['to'] = dayjs(dataObj['fromTo'][1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    dataObj['adjusting'] = !!dataObj['adjusting'];
    console.log('ModalPeriod  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdatePeriod(dataObj);
    } else {
      onCreatePeriod(dataObj);
    }
  };
  return (
    <OwnModal title={editItem ? 'Edit Period' : 'Add Period'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name="prefix" label="Prefix" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>

          <Form.Item name="year" label="Year" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>

          <Form.Item name="fromTo" label="From - To" rules={constents.rulesRequeried}>
            <DatePicker.RangePicker
              className="w-full"
              format="YYYY-MM-DD"
              disabledDate={current => {
                // Can not select days before today and today
                return current && current < dayjs().endOf('day');
              }}
            />
          </Form.Item>

          <Form.Item name="number" label="Number" rules={constents.rulesRequeried}>
            <Input />
          </Form.Item>

          <Form.Item name="adjusting" valuePropName="checked">
            <Checkbox>Adjusting</Checkbox>
          </Form.Item>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createPeriodLod || updatePeriodLod}>
            DONE
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalPeriod;

import { App, Button, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDepartments } from 'hooks/human-resourses/useDepartment';
import constents from 'utils/constants';
import { createStructureType, getStructureType, updateStructureType } from 'network/Human-resources/salary-structure-types';

function StructureTypeNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();
  const { departmentData } = useDepartments();

  const client = useQueryClient();
  const { data: salaryStructureType } = useQuery([queryKeys.salaryStructureType, params?.id], () => getStructureType(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('salaryStructureType', salaryStructureType);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.salaryStructureTypeList]);

    form.resetFields();
    navigate('/human-resources/setup/salary/structure-types');
  };

  const { mutate: onCreateStructureType, isLoading: onCreateStructureTypeLod } = useMutation(createStructureType, {
    onSuccess,
  });
  const { mutate: onUpdateStructureType, isLoading: onUpdateStructureTypeLod } = useMutation(updateStructureType, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && salaryStructureType) {
      const editItem = salaryStructureType?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['startDate']), dayjs(dataObj['endDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, salaryStructureType, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['startDate'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['endDate'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = salaryStructureType?.model?.id;
      return onUpdateStructureType(dataObj);
    }

    onCreateStructureType(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Setup' }, { title: <Link to="/human-resources/setup/salary/structure-types">StructureTypes</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="nameEN" label="Name English" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Name Arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="wageTypeId" label="Wage Type" rules={[{ required: true }]}>
              <Select
                options={metadata?.wageTypes?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>

            <Form.Item name="workingTimeId" label="Working Time" rules={[{ required: true }]}>
              <Select options={[{ label: 'need list', value: '03d41a3c-676e-473b-d85c-08dbf7676d1d' }]} />
            </Form.Item>
            <Form.Item name="workEntryTypeId" label="Work Entry Type" rules={[{ required: true }]}>
              <Select options={[{ label: 'need list', value: '7388ca66-b792-40e1-2a7a-08dbf766fdca' }]} />
            </Form.Item>
            <Form.Item name="scheduledPayId" label="Scheduled Pay" rules={[{ required: true }]}>
              <Select
                options={metadata?.scheduledPays?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateStructureTypeLod || onUpdateStructureTypeLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default StructureTypeNew;

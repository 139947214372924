import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import { App, Button, Dropdown, Table } from 'antd';
import { useState } from 'react';
import ModalBudgitRanges from 'components/modals/ModalBudgitRanges';
import ModalBudgetaryControl from 'components/modals/ModalBudgetaryControl';
import ModalBudget from 'components/modals/ModalBudget';
import useSearchQuery from 'hooks/useSearchQuery';
import { useQueryClient } from '@tanstack/react-query';
import { MoreOutlined } from '@ant-design/icons';
import SearchHero from 'components/common/SearchHero';
import useBudgetList from 'hooks/api/useBudgetList';
import ActionsMenu from 'components/common/ActionsMenu';
import { useNavigate } from 'react-router-dom';

function BudgetDefine() {
  const navigate = useNavigate();
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const { message } = App.useApp();
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const client = useQueryClient();

  const [budgetAddRanges, setBudgetAddRanges] = useState(null);
  const [budgetAddBudgetaryControl, setBudgetAddBudgetaryControl] = useState(null);

  const { budgetList, budgetListLod } = useBudgetList();
  console.log('BudgetDefine  budgetList:', budgetList);
  // const { mutate: onDeleteCurrency } = useMutation(deleteCurrency, {
  //   onSuccess: res => {
  //     console.log('onSuccess  res:', res);
  //     message.success(res?.metas?.message);
  //     client.invalidateQueries([queryKeys.currencyList]);
  //   },
  // });
  const data = budgetList || [];

  const columns = [
    {
      key: 'nameEN',
      dataIndex: 'nameEN',
      title: 'Name',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'status',
    },
    {
      key: 'total',
      dataIndex: 'total',
      title: 'total',
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'add-ranges',
              label: 'Add Ranges',
              onClick: () => setBudgetAddRanges(record),
            },
            {
              key: 'add-budgetary-control',
              label: 'Add Budgetary Control',
              onClick: () => setBudgetAddBudgetaryControl(record),
            },
            // {
            //   key: 'Delete',
            //   label: 'Delete',
            //   onClick: () => onDeleteCurrency(record?.id),
            // },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Budget Define" />
        <SearchHero onAddBtn={() => navigate('/general-ledger/budget/new')} searchKey="word" />
        <Table
          dataSource={data}
          sticky
          columns={!!data?.length ? columns : []}
          pagination={{
            // pageSize: 1,
            current: +searchQueryObj.page || 1,
            onChange: page => {
              const queryObj = { ...searchQueryObj };
              queryObj['page'] = page;
              setSearchQuery(queryObj);
            },
          }}
        />
      </div>
      <ModalBudget
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
      <ModalBudgitRanges budget={budgetAddRanges} onCancel={() => setBudgetAddRanges(null)} />
      <ModalBudgetaryControl budget={budgetAddBudgetaryControl} onCancel={() => setBudgetAddBudgetaryControl(null)} />
    </>
  );
}

export default BudgetDefine;

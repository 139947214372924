import { Form, Input, InputNumber, Select } from 'antd';
import React, { useEffect, useState } from 'react';

function TransactioAmountItem({ item, index, amountTypes, original, form }) {
  console.log('TransactioAmountItem  original:', original);
  console.log('TransactioAmountItem  amountTypes:', amountTypes);
  const [amountType, setAmountType] = useState(amountTypes?.[0]?.id);
  const [amount, setAmount] = useState(0);
  const [balanceDue, setBalanceDue] = useState(original);

  const updateBalanceDue = (amount, type) => {
    console.log('updateBalanceDue  amount, type:', amount, type);
    let newBalanceDue = balanceDue;
    if (type) {
      if (type === 1) newBalanceDue = original - amount;
      else if (type === 2) newBalanceDue = original - (amount / 100) * original;
      setBalanceDue(newBalanceDue);
    }

    //
    const fields = form.getFieldsValue();
    const { creditInvoiceLines } = fields;
    Object.assign(creditInvoiceLines[index], { balanceDue: newBalanceDue });

    form.setFieldsValue({ creditInvoiceLines });
  };

  useEffect(() => {
    if (original) {
      const fields = form.getFieldsValue();
      const { creditInvoiceLines } = fields;
      Object.assign(creditInvoiceLines[index], { originalAmount: original });
      form.setFieldsValue({ creditInvoiceLines });
      updateBalanceDue(amount, amountType);
    }
  }, [original]);

  // if (!original) return '';
  return (
    <div key={item?.id}>
      <h4 className="title-20 mb-2">{item?.nameEN}</h4>
      <Form.Item name={['creditInvoiceLines', index, 'creditInvoiceLineTypeId']} hidden initialValue={item?.id}>
        <Input />
      </Form.Item>
      <div className="col-4">
        <Form.Item name={['creditInvoiceLines', index, 'amountTypeId']} label="Amount type" initialValue={amountTypes?.[0]?.id}>
          <Select
            onChange={value => {
              setAmountType(value);
              updateBalanceDue(amount, value);
            }}
            options={amountTypes?.map(s => ({
              value: s?.id,
              label: s?.nameEN,
            }))}
          />
        </Form.Item>
        <Form.Item
          name={['creditInvoiceLines', index, 'amount']}
          label="Amount"
          rules={[
            {
              type: 'number',
              max: amountType === 2 ? 100 : original,
            },
          ]}
          initialValue={0}>
          <InputNumber
            suffix={amountType === 2 && '%'}
            controls={false}
            className="w-full"
            onChange={value => {
              console.log('TransactioAmountItem  value:', value);
              setAmount(value);
              updateBalanceDue(value, amountType);
            }}
          />
        </Form.Item>
        <Form.Item name={['creditInvoiceLines', index, 'originalAmount']} label="Original" initialValue={original}>
          <InputNumber controls={false} className="w-full" readOnly />
        </Form.Item>
        <Form.Item name={['creditInvoiceLines', index, 'balanceDue']} label="Balance Due" initialValue={balanceDue}>
          <InputNumber controls={false} className="w-full" readOnly />
        </Form.Item>
      </div>
    </div>
  );
}

export default TransactioAmountItem;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllSalesInvoices = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/SalesInvoices?${searchQuery}`);
  return data;
});

export const getSalesInvoice = catchAsync(async id => {
  const { data } = await axiosInstance(`/SalesInvoices/${id}`);
  return data;
});

export const createSalesInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/SalesInvoices`, body);
  return data;
});

export const updateSalesInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/SalesInvoices`, body);
  return data;
});

export const deleteSalesInvoice = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/SalesInvoices/${id}`);
  return data;
});

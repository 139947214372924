import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import { App, Button, Divider, Form, Input, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createCustomer, getCustomer, updateCustomer } from 'network/account-receivable/customer';
import AccountField from 'components/common/AccountField';
import useCustomerList from 'hooks/api/useCustomerList';

const statusList = ['New', 'Qualified', 'Proposition', 'Won', 'Lost'];
function LeadNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();
  const { customerList } = useCustomerList();

  const client = useQueryClient();
  const { data: cusromerData } = useQuery([queryKeys.customerList, params?.id], () => getCustomer(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.customerList]);

    form.resetFields();
    navigate('/account-receivable/customers');
  };

  const { mutate: onCreateCustomer, isLoading: onCreateCustomerLod } = useMutation(createCustomer, {
    onSuccess,
  });
  const { mutate: onUpdateCustomer, isLoading: onUpdateCustomerLod } = useMutation(updateCustomer, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && cusromerData) {
      const editItem = cusromerData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, cusromerData]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = cusromerData?.model?.id;
      return onUpdateCustomer(dataObj);
    }

    onCreateCustomer(dataObj);
  };
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <OwnBreadcrumb items={[{ title: 'CRM' }, { title: 'Leads' }]} current="New Lead" />

        <Space>
          <Select placeholder="Status" options={statusList.map(e => ({ value: e, label: e }))} style={{ width: 200 }} />
          <Button type="primary" onClick={() => -1}>
            New Quotation
          </Button>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
        </Space>
      </div>
      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <Form.Item name="Lead Name" label="Lead Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <div className="col-2">
            <Form.Item name="Expected revienue" label="Expected revienue" rules={[{ required: true }]}>
              <Input prefix="LE" />
            </Form.Item>
            <Form.Item name="Probability" label="Probability" rules={[{ required: true }, { type: 'number', min: 0, max: 100 }]}>
              <Input prefix="at" suffix="%" />
            </Form.Item>
            <Form.Item name="customerId" label="Customer" rules={[{ required: true }]}>
              <Select
                options={customerList?.map(s => ({
                  value: s?.id,
                  label: s?.name + ` (${s.number})`,
                }))}
              />
            </Form.Item>
            <Form.Item name="Note" label="Note" rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
            {/* <Form.Item name="Lead Name" label="Lead Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="Lead Name" label="Lead Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item> */}
            {/* <Form.Item name="customerTypeId" label="Customer Type" rules={[{ required: true }]}>
              <Select options={metadata?.customerTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item> */}
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateCustomerLod || onUpdateCustomerLod}>
            {isEdit ? 'Update' : 'Complete'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default LeadNew;

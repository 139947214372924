import { FilterOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Dropdown, Button } from 'antd';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useEmployeeGoalList from 'hooks/api/useEmployeeGoalList';
import { useNavigate } from 'react-router-dom';
import { arrayFrom } from 'utils/utils';
import GoalCard from './services/GoalCard';

function Goals() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { employeeGoalList } = useEmployeeGoalList();
  console.log('Goals  employeeGoalList:', employeeGoalList);

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: 'Human Resources' }]} current="Goals" />
      <SearchHero
        onAddBtn={() => navigate('new')}
        // extra={<CustomerSearch setFilterdCustomerList={setFilterdCustomerList} isfilterMood={isfilterMood} setIsfilterMood={setIsfilterMood} />}
        extra={
          <Dropdown
            menu={{
              items: [
                { key: 'Status', label: 'Status', type: 'group' },
                { key: 'all', label: 'All' },
                { key: 'To confirm', label: 'To confirm' },
                { key: 'Confirmed', label: 'Confirmed' },
                { key: 'Done', label: 'Done' },
                { key: 'Canceled', label: 'Canceled' },
              ],
              className: 'w-40',
            }}>
            <Button type="primary" icon={<FilterOutlined />} />
          </Dropdown>
        }
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {employeeGoalList?.map((el, i) => (
          <GoalCard key={i} goal={el} />
        ))}
      </div>
    </div>
  );
}

export default Goals;

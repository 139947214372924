import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Form, Table } from "antd";
import { useSubtaskMutation } from "hooks/api/projects-management/subTasks";
import { useState } from "react";
import { createMergedColumns } from "./columns";
import dayjs from "dayjs";
import EditableCell from "./EditableCell";

export default function SubTasks({ task }) {
  const [editingId, setEditingId] = useState("");
  const mutation = useSubtaskMutation();
  const [disableActions, setDisableActions] = useState(false);
  const [form] = Form.useForm();

  const isEditing = (record) => record.id === editingId;

  const { id, taskManagerId } = task;

  const edit = (record) => {
    console.log(record, "recordrecordrecord");
    form.setFieldsValue({
      ...record,
      plannedDate: [
        dayjs(record.plannedStartDate),
        dayjs(record.plannedFinishDate),
      ],
      transactionDate: [
        dayjs(record.transactionStartDate),
        dayjs(record.transactionFinishDate),
      ],
    });
    setEditingId(record?.id);
  };

  const create = () => {
    mutation.quickCreateSubtask(id, taskManagerId);
  };

  const save = async (record) => {
    try {
      setDisableActions(true);
      const subTask = await form.validateFields();
      console.log(subTask, "subTasksubTasksubTasksubTask");
      mutation.updateSubtask({
        ...record,
        // ...subTask,
        id: editingId,
        name: subTask?.name,
        number: subTask?.number,
        companyId: record?.companyId,
        projectTaskId: record?.projectTaskId,
        subTaskManangerId: subTask?.subTaskManagerId,
        plannedStartDate: dayjs(subTask?.plannedDate?.[0]).toDate(),
        plannedFinishDate: dayjs(subTask?.plannedDate?.[1]).toDate(),
        transactionStartDate: dayjs(subTask?.transactionDate?.[0]).toDate(),
        transactionFinishDate: dayjs(subTask?.transactionDate?.[1]).toDate(),
      }, {
        onSettled: () => {
          setDisableActions(false);
          setEditingId("");
        },
      });
    } catch (error) {
      console.log(error);
      setDisableActions(false);
    }
  };

  const remove = (record) => {
    setDisableActions(true);
    mutation.deleteSubtask(record?.id, {
      onSettled: () => {
        setDisableActions(false);
        setEditingId("");
      },
    });
  };

  const options = [
    {
      title: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable
          ? (
            <div className="space-x-2">
              <Button
                onClick={() => setEditingId("")}
                icon={<CloseOutlined />}
                disabled={disableActions}
              />
              <Button
                icon={<SaveOutlined />}
                onClick={() => save(record)}
                disabled={disableActions}
                htmlType="submit"
              />
              <Button
                onClick={() => remove(record)}
                icon={<DeleteOutlined />}
                disabled={disableActions}
                danger
              />
            </div>
          )
          : (
            <Button
              disabled={editingId !== ""}
              onClick={() => edit(record)}
              icon={<EditOutlined />}
            />
          );
      },
      width: isEditing ? 200 : 50,
    },
  ];

  const columns = createMergedColumns(options, isEditing, editingId !== "", {
    title: "Task Manager",
    dataIndex: "subTaskManagerId",
    key: "subTaskManagerId",
    width: 300,
    editable: true,
    datatype: "select",
    render: (_, record) => record?.subTaskManager?.name,
  });

  return (
    <div className="space-y-4">
      <Form form={form}>
        {task?.subTasks?.length !== 0 &&
          (
            <Table
              className="ms-12"
              columns={columns}
              pagination={{ hideOnSinglePage: true }}
              dataSource={task?.subTasks}
              showHeader={false}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          )}
      </Form>
      <Button
        className="ms-12"
        style={{ width: 200 }}
        type="dashed"
        onClick={create}
      >
        Create a Task
      </Button>
    </div>
  );
}

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllSalaryStructures = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/SalaryStructures?${searchQuery}`);
  return data;
});

export const getSalaryStructure = catchAsync(async id => {
  const { data } = await axiosInstance(`/SalaryStructures/${id}`);
  return data;
});

export const createSalaryStructure = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/SalaryStructures`, body);
  return data;
});

export const updateSalaryStructure = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/SalaryStructures`, body);
  return data;
});

export const deleteSalaryStructure = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/SalaryStructures/${id}`);
  return data;
});

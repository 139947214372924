import { useQuery } from "@tanstack/react-query";
import { Button, Checkbox, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import ModalCashStatmenet from "components/modals/ModalCashStatmenet";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import useSearchQuery from "hooks/useSearchQuery";
import { getAllBankStatements } from "network/cash-managment/bankStatment";
import React, { useState } from "react";
import { queryKeys } from "services/react-query/queryKeys";
import { arrayFrom } from "utils/utils";

function BankStatmenet() {
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const { data: statementsList } = useQuery(
    [queryKeys.BankStatementsList, searchQueryStr],
    () => getAllBankStatements(searchQueryStr)
  );

  const handelReconcile = (id) => {};

  const columns = [
    {
      title: "document Number",
      dataIndex: "documentNumber",
      key: "documentNumber",
    },
    {
      title: "opening Balance",
      dataIndex: "openingBalance",
      key: "openingBalance",
    },
    {
      title: "closing Balance",
      dataIndex: "closingBalance",
      key: "closingBalance",
    },
    {
      title: "payments",
      dataIndex: "payments",
      key: "payments",
    },
    {
      title: "checkDigits",
      dataIndex: "checkDigits",
      key: "checkDigits",
    },
    {
      title: "Number",
      dataIndex: "documentNumber",
      key: "documentNumber",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
      key: "Currency",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Amount cleared",
      dataIndex: "Amount cleared",
      key: "Amountcleared",
    },
    {
      title: "cleared date",
      dataIndex: "cleared date",
      key: "cleareddate",
    },
    {
      title: "GL date",
      dataIndex: "GLdate",
      key: "GLdate",
    },
    {
      title: "date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      with: 300,
      render: (_, record) => {
        return record?.reConciledDate ? (
          <p>Reconciled</p>
        ) : (
          <Button type="primary" onClick={() => handelReconcile(record?.Id)}>
            Reconcile
          </Button>
        );
        //       <div className="table-summary-action">
        //       </div>
      },
    },
  ];

  const data = arrayFrom(12)?.map((_, i) => ({
    key: i,
    Type: "Text here",
    Number: "Text here",
    Currency: "Text here",
    Amount: "Text here",
    Amountcleared: "Text here",
    cleareddate: "Text here",
    date: "Text here",
    GLdate: "Text here",
  }));

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Bank Statement" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table
          dataSource={statementsList?.model || []}
          sticky
          columns={columns}
          // rowSelection={rowSelection}
          scroll={{
            x: 1800,
          }}
          // summary={(pageData) => {
          //   if (selectedRowKeys?.length) {
          //     return (
          //       <div className="table-summary-action">
          //         <Button type="primary">Reconcile</Button>
          //       </div>
          //     );
          //   } else {
          //     return null;
          //   }
          // }}
          // rowSelection={rowSelection}
          // scroll={{ y: '66vh' }}
          // className="overflow-auto min-w-max"
        />
      </div>
      <ModalCashStatmenet
        open={iseModalUpdat}
        onCancel={() => setIseModalUpdat(false)}
      />
    </>
  );
}

export default BankStatmenet;

import { useQuery } from "@tanstack/react-query";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import { getAllEmployees } from "network/Human-resources/employees";
import { useState } from "react";
import { queryKeys } from "services/react-query/queryKeys";

const lookupInputType = {
  number: InputNumber,
  text: Input,
  date: DatePicker,
  select: Select,
};

export default function EditableCell(
  { editing, dataIndex, title, inputType, record, index, children, ...props },
) {
  const { employees } = useEmployees(inputType === "select");
  let InputNode = lookupInputType[inputType];

  if (editing && inputType === "date") {
    InputNode = DatePicker.RangePicker;
  }

  return (
    <td {...props}>
      {editing
        ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: ``,
              },
            ]}
          >
            {inputType === "select"
              ? <InputNode options={employees} />
              : <InputNode />}
          </Form.Item>
        )
        : children}
    </td>
  );
}

const useEmployees = (isEnabled = false) => {
  const query = useQuery({
    queryFn: getAllEmployees,
    queryKey: [queryKeys.employee],
    enabled: isEnabled,
    select: (data) => data?.model,
  });

  query.employees = query?.data?.map((employee) => ({
    ...employee,
    value: employee?.id,
    label: employee?.name,
  }));

  return query;
};

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllSalaryRules = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/SalaryRules?${searchQuery}`);
  return data;
});

export const getSalaryRule = catchAsync(async id => {
  const { data } = await axiosInstance(`/SalaryRules/${id}`);
  return data;
});

export const createSalaryRule = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/SalaryRules`, body);
  return data;
});

export const updateSalaryRule = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/SalaryRules`, body);
  return data;
});

export const deleteSalaryRule = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/SalaryRules/${id}`);
  return data;
});

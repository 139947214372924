import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Table } from "antd";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { Link, useLocation, useParams } from "react-router-dom";

const BudgetDetails = () => {
  const params = useParams();
  const location = useLocation();

  const data = [
    {
      number: 1.1,
      name: "Initiate",
      uom: "Hours",
      quantity: 0,
      rawCost: 0,
      cost: 0,
    },
    {
      number: 1.2,
      name: "Plan",
      uom: "Hours",
      quantity: 0,
      rawCost: 0,
      cost: 0,
    },
    {
      number: 2,
      name: "Training Execute",
      uom: "Hours",
      quantity: 0,
      rawCost: 0,
      cost: 0,
    },
  ];

  const columns = [
    {
      title: "Task Number",
      dataIndex: "number",
      key: "number",
      width: 200,
    },
    {
      title: "Task Name",
      key: "name",
      render: (_, { name }) => (
        <Link to="budget-lines" className="text-color-primary font-semibold">
          {name}
        </Link>
      ),
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: "Qunatity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Raw Cost",
      dataIndex: "rawCost",
      key: "rawCost",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
    {
      title: "actions",
      key: "actions",
      render: () => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  label: "Assign Accounting Role",
                  key: 1,
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const breadcrumbItems = [
    {
      title: "Project Management",
    },
    {
      title: "Budget",
      href: "/project-managment/budget",
    },
    {
      title: "Budget Details",
    },
    {
      title: params?.projectId,
      href: location.pathname,
    },
  ];
  return (
    <>
      <OwnBreadcrumb items={breadcrumbItems} />
      <section className="mt-7">
        <div className="bg-white rounded-[9px] p-8 mt-7">
          <h1 className="font-bold text-xl">Budget Details</h1>
          <div className="mt-2 flex items-center gap-4">
            <div>
              <p className="py-2">Version Name</p>
              <Input value="Draft" disabled />
            </div>
            <div>
              <p className="py-2">Version Number</p>
              <Input value="Draft" disabled />
            </div>
          </div>
        </div>
        <div className="mt-10">
          <Table columns={columns} dataSource={data} />
        </div>
      </section>
    </>
  );
};

export default BudgetDetails;

import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { App, Button, Form, Input, Popover, Select, Space, Tooltip } from 'antd';
import useMetaData from 'hooks/api/useMetaData';
import React, { useState } from 'react';
import { css } from '@emotion/css';
import { searchJournalsAdvanced } from 'network/general-ledger/journals';
import usePeriodList from 'hooks/api/usePeriodList';
import dayjs from 'dayjs';
import constents from 'utils/constants';

function JournalsManualSearch({ isfilterMood, setIsfilterMood, setFilterdList }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const metadata = useMetaData();
  const { periodList, periodListLod } = usePeriodList();

  const { mutate: onSearchJournals, isLoading: searchJournalsLod } = useMutation(searchJournalsAdvanced, {
    onSuccess: res => {
      console.log('createReceipt  res:', res);
      message.success(res?.metas?.message);
      setFilterdList(res?.model);
      setIsfilterMood(true);
      setOpen(false);
    },
  });

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    onSearchJournals(dataObj);
  };

  const content = (
    <div className="w-[500px]">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size="small"
        className={css`
          .ant-form-item {
            margin-bottom: 5px;
            .ant-form-item-label {
              padding-bottom: 0;
            }
          }
        `}>
        <div className="grid grid-cols-2 items-end gap-x-4">
          <Form.Item name="batchName" label="Batch">
            <Input />
          </Form.Item>

          <Form.Item name="controlTotal" label="Control Total">
            <Input />
          </Form.Item>

          <Form.Item name="debit" label="Debit">
            <Input />
          </Form.Item>
          <Form.Item name="credit" label="Credit">
            <Input />
          </Form.Item>
          <Form.Item name="Control Total" label="Control Total">
            <Input />
          </Form.Item>
          <Form.Item name="periodId" label="Period">
            <Select
              loading={periodListLod}
              options={periodList?.map(p => ({
                value: p?.id,
                label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
              }))}
            />
          </Form.Item>

          <Form.Item name="accountTypeId" label="Balance type">
            <Select options={metadata?.accountTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
          </Form.Item>
        </div>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit-" loading={searchJournalsLod}>
            Go
          </Button>
          <Button type="primary" ghost htmlType="reset">
            CLEAR
          </Button>
        </Space>
      </Form>
    </div>
  );

  if (isfilterMood)
    return (
      <Tooltip title="Clear Search">
        <Button type="primary" icon={<CloseOutlined />} onClick={() => setIsfilterMood(false)} />
      </Tooltip>
    );

  return (
    <Popover title="Advanced Search" content={content} placement="bottomLeft" open={open} onOpenChange={status => setOpen(status)} trigger="click">
      <Button type="primary" icon={<FilterOutlined />} />
    </Popover>
  );
}

export default JournalsManualSearch;

import { useQuery } from '@tanstack/react-query';
import { getAllCurrencies } from 'network/general-ledger/currency';
import { queryKeys } from 'services/react-query/queryKeys';

function useCurrencyList(searchQueryStr) {
  const { data, isLoading } = useQuery(
    [queryKeys.currencyList, searchQueryStr],
    () => getAllCurrencies(searchQueryStr)
  );

  const currencyList = data?.model;

  return {
    currencyList,
    currencyListLod: isLoading,
  };
}

export default useCurrencyList;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getAllBankAccounts = catchAsync(async (searchQuery) => {
  const { data } = await axiosInstance(`/BankAccounts?${searchQuery}`);
  return data;
});

export const getBankAccounts = catchAsync(async (id) => {
  const { data } = await axiosInstance(`/BankAccounts/${id}`);
  return data;
});

export const searchBankAccount = catchAsync(async (id) => {
  const { data } = await axiosInstance(
    `/BankAccounts/SearchByAccountNumber?AccountNumber=${id}`
  );
  return data;
});

export const searchBankAccountTo = catchAsync(async (id) => {
  const { data } = await axiosInstance(
    `/BankAccounts/SearchByAccountNumber?AccountNumber=${id}`
  );
  return data;
});

export const createBankAccounts = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/BankAccounts`, body);
  return data;
});

export const updateBankAccounts = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/BankAccounts`, body);
  return data;
});

export const deleteBankAccounts = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/BankAccounts/${id}`);
  return data;
});

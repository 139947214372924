import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Checkbox, DatePicker, Divider, Form, Input, Select, Table, Space, App } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { createSupplier, updateSupplier } from 'network/account-payable/supplier';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import { queryKeys } from 'services/react-query/queryKeys';
import { SupplierUseOptions } from 'utils/constants';

function ModalAddSupplier({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { supplierPaymentMethods, supplierTypes, cities } = useMetaData();
  const [PaymentMethod, setPaymentMethod] = useState(null);
  const [paymentMethodEndDate, setpaymentMethodEndDate] = useState(null);
  console.log(supplierPaymentMethods);
  const client = useQueryClient();

  const columns = [
    {
      title: 'Payment Method',
      dataIndex: 'nameEN',
      key: 'nameEN',
    },
    Table.SELECTION_COLUMN,
    {
      title: 'Set Default',
      dataIndex: 'id',
      key: 'id',
      render: () => <>Default</>,
    },
    {
      title: 'End Date',
      dataIndex: 'EndDate',
      key: 'EndDate',
      render: (text, record) => {
        console.log(PaymentMethod, 'PaymentMethodPaymentMethod');
        return <DatePicker disabled={record?.id !== PaymentMethod?.[0]} onChange={val => setpaymentMethodEndDate(val)} />;
      },
    },
  ];

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.supplierList]);

    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateSupplier, isLoading: createSupplierLod } = useMutation(createSupplier, { onSuccess });
  const { mutate: onUpdateSupplier, isLoading: updateSupplierLod } = useMutation(updateSupplier, { onSuccess });

  const onFinish = values => {
    if (!PaymentMethod) {
      message?.error('please select payment Method');
      return false;
    }
    console.log('onFinish  values:', values);
    const dataObj = {
      ...values,
      countryId: '462493b3-2081-4eab-bec3-852b04d69278',
      isForPurchasingPayment: values?.AccountUse?.some(s => s === 'isForPurchasingPayment'),
      isForRFQOnly: values?.AccountUse?.some(s => s === 'isForRFQOnly'),
      paymentMethodId: PaymentMethod?.[0],
      paymentMethodEndDate: paymentMethodEndDate,
      // inactiveDate: dayjs(values?.inactiveDate).format(constents.dateFormat),
    };
    console.log('ModalStructure  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateSupplier(dataObj);
    } else {
      onCreateSupplier(dataObj);
    }
  };

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  return (
    <OwnModal title={editItem ? 'Edit Supplier' : 'Add Supplier'} open={open} onCancel={onCancel} width={902}>
      <Form
        layout="vertical"
        form={form}
        // size="large"
        onFinish={onFinish}
        // className="pt-4"
      >
        <div className="col-2">
          <Form.Item name="name" label="Supplier Name">
            <Input />
          </Form.Item>
          <Form.Item name="supplierTypeId" label="Supplier type">
            <Select
              allowClear
              options={supplierTypes?.map(s => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="cityId" label="City">
            <Select
              allowClear
              options={cities?.map(s => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item name="dunsNumber" label="D-U-N-S Number">
            <Input />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            // rules={[{ required: true }]}
          >
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item name="email" label="Email Address">
            <Input />
          </Form.Item>
          <Form.Item name="faxNumber" label="Fax Number">
            <Input />
          </Form.Item>
          <Form.Item name="AccountUse" label="Account Use">
            <Checkbox.Group options={SupplierUseOptions} />
          </Form.Item>

          <Form.Item name="addressLine1" label="Address Line 1">
            <Input />
          </Form.Item>
          <Form.Item name="addressLine2" label="Address Line 2">
            <Input />
          </Form.Item>
          <Form.Item name="addressLine3" label="Address Line 3">
            <Input />
          </Form.Item>
          <Form.Item name="addressName" label="Address Name">
            <Input />
          </Form.Item>
        </div>
        <Divider>Accounting</Divider>
        <div className="col-2">
          <Form.Item name="liabilityAccount" label="Liability Account">
            <Input />
          </Form.Item>
          <Form.Item name="prepayment" label="Prepayment">
            <Input />
          </Form.Item>
        </div>
        <Divider>Payment Details</Divider>
        <div className="col-1">
          <Table
            rowSelection={{
              type: 'radio',
              onChange: val => setPaymentMethod(val),
            }}
            rowKey="id"
            dataSource={supplierPaymentMethods}
            columns={columns}
            pagination={{ position: ['none'] }}
          />
        </div>
        <Space className="flex justify-end mt-4">
          <Button loading={createSupplierLod || updateSupplierLod} type="primary" htmlType="submit" className="w-56">
            {editItem ? 'UPDATE' : 'ADD'}
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalAddSupplier;

import { Checkbox, Divider, Form } from 'antd';
import React from 'react';

function TransAndCustomers() {
  return (
    <div>
      <Divider>
        <h2 className="title-24 mb-4">Transactions</h2>
      </Divider>
      <div className="col-2">
        <Form.Item name="allowChangeToPrintedInvoice" valuePropName="checked">
          <Checkbox>Allow Change to Printed Transactions</Checkbox>
        </Form.Item>
        <Form.Item name="allowTaxInvoicePrint" valuePropName="checked">
          <Checkbox>Tax Invoice Printing Options</Checkbox>
        </Form.Item>
        <Form.Item name="invoiceNumber" valuePropName="checked">
          <Checkbox>Automatic Transaction Numbering</Checkbox>
        </Form.Item>
        {/* <Form.Item name="Invoice Number Generation Level" label="Invoice Number Generation Level">
            <Select
              options={[
                {
                  label: 'Manual',
                  value: 'Manual',
                },
                {
                  label: 'Automatic',
                  value: 'Automatic',
                },
              ]}
            />
          </Form.Item> */}

        {/* <Form.Item name="First Number" label="First Number">
            <InputNumber className="w-full" />
          </Form.Item>
          <Form.Item name="Last Number" label="Last Number">
            <InputNumber className="w-full" />
          </Form.Item> */}
      </div>
      <Divider>
        <h2 className="title-24 mb-4">Customers</h2>
      </Divider>
      <div className="col-2">
        <Form.Item name="allowAutomaticCustomerNumber" valuePropName="checked">
          <Checkbox>Automatic Customer Numbering</Checkbox>
        </Form.Item>
        <Form.Item name="allowAutomaticCustomerSiteNumbering" valuePropName="checked">
          <Checkbox>Automatic Site Numbering</Checkbox>
        </Form.Item>
      </div>
    </div>
  );
}

export default TransAndCustomers;

import { MoreOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Dropdown, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalCurrency from 'components/modals/ModalCurrency';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import dayjs from 'dayjs';
import useCurrencyList from 'hooks/api/useCurrencyList';
import useSearchQuery from 'hooks/useSearchQuery';
import { deleteCurrency } from 'network/general-ledger/currency';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';
import { dateFormat } from 'utils/utils';

function CurrencyDefine() {
  const { message } = App.useApp();
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const client = useQueryClient();

  const { currencyList, currencyListLod } = useCurrencyList(searchQueryStr);
  const { mutate: onDeleteCurrency } = useMutation(deleteCurrency, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.currencyList]);
    },
  });
  const data = currencyList;
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const columns = [
    {
      key: 'code',
      dataIndex: 'code',
      title: 'code',
    },
    {
      key: 'nameEN',
      dataIndex: 'nameEN',
      title: 'name',
    },

    {
      key: 'from',
      dataIndex: 'from',
      title: 'from',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'to',
      dataIndex: 'to',
      title: 'to',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'enabled',
      dataIndex: 'enabled',
      title: 'enabled',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <ActionsMenu
            items={[
              {
                key: 'Edit',
                label: 'Edit',
                onClick: () => setEditItem(record),
              },
              {
                key: 'Delete',
                label: 'Delete',
                onClick: () => onDeleteCurrency(record?.id),
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Currency Define" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} searchKey="word" />
        <Table
          dataSource={data}
          sticky
          columns={!!data?.length ? columns : []}
          pagination={{
            // pageSize: 1,
            current: +searchQueryObj.page || 1,
            onChange: page => {
              const queryObj = { ...searchQueryObj };
              queryObj['page'] = page;
              setSearchQuery(queryObj);
            },
          }}
        />
      </div>
      <ModalCurrency
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  );
}

export default CurrencyDefine;

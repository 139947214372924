import React from "react";
import { Form, Input, Button, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function FormListProducts({supplierTypes}) {
  return (
    <Form.List
      name="supplierPriceLines"
      initialValue={[""]} // Initial value, can be an array with default items
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <div key={key} style={{ display: "flex", marginBottom: 8 }}>
              <Form.Item
                {...restField}
                name={[name, "supplierId"]}
                fieldKey={[fieldKey, "Supplier"]}
                label="Supplier"
                style={{ marginRight: 8, flex: 1 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  // placeholder="Select a person"
                  // initialvalues="type one"
                  options={supplierTypes?.map((el) => ({
                    label: el?.name,
                    value: el?.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "price"]}
                fieldKey={[fieldKey, "price"]}
                label="Price"
                style={{ marginRight: 8, flex: 1 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              {fields.length > 1 && (
                <MinusCircleOutlined
                  onClick={() => {
                    remove(name);
                  }}
                  style={{
                    margin: "8px 8px 0 0",
                    fontSize: "20px",
                    color: "#ff4d4f",
                  }}
                />
              )}
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => {
                add();
              }}
              icon={<PlusOutlined />}
            >
              Add New Line
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

import React from "react";
import { Form, Input, Select, Button, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export default function FormListOrders({ order }) {
  return (
    <div>
      <h4 className="text-2xl pt-9 pb-4 font-medium text-[#424242]">
        Add Products :
      </h4>
      <Form.List name="lines" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={key} className={`grid  md:grid-cols-4 gap-2`}>
                <Form.Item
                  {...restField}
                  name={[name, "nameEN"]}
                  fieldKey={[fieldKey, "nameEN"]}
                  label="Product"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "description"]}
                  fieldKey={[fieldKey, "description"]}
                  label="Description"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "quantity"]}
                  fieldKey={[fieldKey, "quantity"]}
                  label="Quantity"
                >
                  <Input type="number" />
                </Form.Item>

                {order == "request" && (
                  <Form.Item
                    {...restField}
                    name={[name, "recived_quantity"]}
                    fieldKey={[fieldKey, "recived_quantity"]}
                    label="Recieved Quantity"
                  >
                    <Input type="number" />
                  </Form.Item>
                )}
                <Form.Item
                  {...restField}
                  name={[name, "unitPrice"]}
                  fieldKey={[fieldKey, "unitPrice"]}
                  label="Unit"
                >
                  <Input />
                </Form.Item>
                {/* <Form.Item
                  {...restField}
                  name={[name, "taxes"]}
                  fieldKey={[fieldKey, "taxes"]}
                  label="Taxes"
                >
                  <h2 className="ps-3">14%</h2>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "total"]}
                  fieldKey={[fieldKey, "total"]}
                  label="Total"
                >
                  <h2>2000 LE</h2>
                </Form.Item> */}
                {fields.length > 1 && (
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                    }}
                    style={{
                      margin: "8px 8px 0 0",
                      fontSize: "20px",
                      color: "#ff4d4f",
                    }}
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add({});
                }}
                icon={<PlusOutlined />}
              >
                Add New Line
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}

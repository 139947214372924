import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Divider, Form, Input, InputNumber, Space, Switch } from 'antd';
import OwnModal from 'components/own/OwnModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'services/react-query/queryKeys';
import { createAPDistribution, updateAPDistribution } from 'network/account-payable/distributions';
import { useEffect } from 'react';

function ModalAddDistributionSet({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.APDistributionList]);

    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateAPDistribution, isLoading: createAPDistributionLod } = useMutation(createAPDistribution, { onSuccess });
  const { mutate: onUpdateAPDistribution, isLoading: updateAPDistributionLod } = useMutation(updateAPDistribution, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj.fields = dataObj.distributionAccountSets;
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    dataObj.distributionAccountSets = dataObj?.fields?.map((el, i) => {
      const obj = {
        number: i,
        percentage: el?.percentage,
        distributionAccount: el?.distributionAccount,
        description: el?.description,
      };
      if (el?.id) obj.id = el?.id;
      return obj;
    });

    delete dataObj.fields;

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateAPDistribution(dataObj);
    } else {
      onCreateAPDistribution(dataObj);
    }
  };
  return (
    <>
      <OwnModal
        title={editItem ? 'Distribution Set' : 'Distribution Set'}
        open={open}
        onCancel={onCancel}
        // width={"80%"}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ fields: [{}] }}>
          <div className="col-2">
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea autoSize={{ maxRows: 1 }} className="w-full" />
            </Form.Item>
            <Form.Item name="isActive" label="Active">
              <Switch />
            </Form.Item>
          </div>

          <Divider>
            <h4 className="text-3xl font-medium text-[#424242]">Distribution Account</h4>
          </Divider>

          <Form.List name="fields">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <div className="col-4 bg-gray-50 p-3 rounded items-end">
                        <Form.Item hidden name={[index, 'id']}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'number']} label="Number">
                          <Input disabled value={index + 1} defaultValue={index + 1} />
                        </Form.Item>
                        <Form.Item name={[index, 'percentage']} label="%">
                          <InputNumber className="w-full" />
                        </Form.Item>
                        <Form.Item name={[index, 'distributionAccount']} label="Distribution Account">
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'description']} label="Description">
                          <Input />
                        </Form.Item>
                      </div>

                      {fields.length > 1 ? (
                        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                          Remove Account
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button type="link" size="large" onClick={add} icon={<PlusOutlined />}>
                      Add new Account
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Space className="flex justify-end mt-4">
            <Button loading={createAPDistributionLod || updateAPDistributionLod} type="primary" htmlType="submit" className="block ms-auto w-full max-w-xs">
              {editItem ? 'UPDATE' : 'ADD'}
            </Button>
          </Space>
        </Form>
      </OwnModal>
    </>
  );
}

export default ModalAddDistributionSet;

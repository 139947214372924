import { useQuery } from '@tanstack/react-query';
import { getARSystemOptions } from 'network/account-receivable/system-options';
import { queryKeys } from 'services/react-query/queryKeys';

function useARSystemOptions() {
  const { data, isLoading } = useQuery([queryKeys.ARSystemOptions], getARSystemOptions);

  const ARSystemOptions = data?.model;

  return {
    ARSystemOptions,
    ARSystemOptionsLod: isLoading,
  };
}

export default useARSystemOptions;

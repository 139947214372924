import { FolderAddOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { App, Button, Col, Row, Upload } from "antd";
import BrandLogo from "components/common/BrandLogo";
import { authChangeLogo } from "network/auth";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "services/store/slices/user";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

function UploadLogo() {
  const naviggate = useNavigate();
  const { message } = App.useApp();
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState();
  const userData = useSelector((s) => s.user?.data);
  console.log("UploadLogo  userData:", userData);

  const { mutate: onAuthChangeLogo, isLoading } = useMutation(authChangeLogo, {
    onSuccess: (res) => {
      console.log("Register  res:", res);

      const newUserData = { ...userData, logoImagePath: "??" };
      dispatch(setUserData(newUserData));
      message.success(res?.metas?.message);
      naviggate("/complete-data/legal-entity");
    },
    onError: (error) => {
      console.log("Register  error:", error);
      // message.error(
      //   'Please enter a correct email and password. Note that both fields may be case-sensitive.'
      // );
    },
  });

  const onFinish = async (values) => {
    console.log("onFinish  values:", values);
    if (!fileList.length) return message.warning(`Please select logo first!`);
    // console.log('input', input);
    const fd = new FormData();

    const file = fileList.at(0)?.originFileObj;
    // console.log('onSendMessage  file:', file);
    fd.append("Logo", file);
    console.log("onFinish  Logo:", Object.fromEntries(fd));

    onAuthChangeLogo(fd);
  };

  return (
    <div className="container">
      <div className=" px-4 py-6 min-h-screen flex flex-col justify-center">
        <div className="flex justify-center mb-9">
          <BrandLogo />
        </div>
        <Row gutter={[30, 30]} justify="center">
          <Col xs={24} lg={12}>
            <div>
              <h2 className="text-3xl font-bold text-[#424242] mb-4">
                Upload logo
              </h2>
              <p className="text-base text-[#424242]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div className="w-[516px] min-h[336px] max-w-full mx-auto rounded-[20px] border-4 border-solid border-[#212121] p-5 flex flex-col justify-center items-center gap-4">
              <Upload.Dragger
                // beforeUpload={() => {
                //   return false;
                // }}
                prefixCls="upload-no-style"
                fileList={fileList}
                showUploadList={false}
                maxCount={1}
                accept="image.jpeg,image.png, image.jpg"
                onChange={({ file, fileList }) => {
                  setFileList(fileList);
                  console.log("UploadLogo  file.status:", file.status);
                  if (file.status !== "uploading") {
                    console.log(file, fileList);
                  }
                  if (file.status === "done") {
                  }
                  getBase64(file.originFileObj, (url) => {
                    console.log("UploadLogo  url:", url);
                    // setLoading(false);
                    setImageUrl(url);
                  });
                }}
              >
                {imageUrl ? (
                  <div className="bg-red-30 h-[300px] flex items-center justify-center">
                    <img
                      src={imageUrl}
                      alt="avatar"
                      className="block max-w-full max-h-full "
                    />
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center gap-4">
                    <FolderAddOutlined style={{ fontSize: 150 }} />
                    <div className="text-center">
                      <h4 className="text-2xl">Upload Logo</h4>
                      <p className="text-xs text-[#868686]">Drag it here</p>
                    </div>
                  </div>
                )}
              </Upload.Dragger>
            </div>
          </Col>
        </Row>
        <div className="flex justify-between icon gap-8 flex-wrap mt-12">
          {!!userData?.logoImagePath && (
            <Button
              type="primary"
              ghost
              onClick={() => naviggate("/complete-data/legal-entity")}
            >
              SKIP
            </Button>
          )}
          <Button type="primary" onClick={onFinish} loading={isLoading}>
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UploadLogo;

import useSalesInvoiceList from 'hooks/api/useSalesInvoiceList';
import { dateFormat } from 'utils/utils';
import { Switch, Table } from 'antd';
function DashboardTable() {
  const { salesInvoiceList } = useSalesInvoiceList();

  const columns = [
    {
      key: 'number',
      dataIndex: 'number',
      title: 'number',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: 'Active',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      key: 'isCompleted',
      dataIndex: 'isCompleted',
      title: 'Completed',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
  ];
  const data = salesInvoiceList;
  return (
    <div className="bg-white p-4 border rounded-xl shadow">
      <h4 className="text-xl font-medium mb-4">Upcoming Invoices</h4>
      {/* <Table dataSource={data?.concat(data?.concat(data))} sticky columns={columns} size="small" pagination={{ hideOnSinglePage: true }} /> */}
      <Table dataSource={Array(3).fill(data?.[0])} sticky columns={columns} size="small" pagination={{ hideOnSinglePage: true }} />
    </div>
  );
}

export default DashboardTable;

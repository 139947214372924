import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllCreditInvoices = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/CreditInvoices?${searchQuery}`);
  return data;
});

export const getCreditInvoice = catchAsync(async id => {
  const { data } = await axiosInstance(`/CreditInvoices/${id}`);
  return data;
});

export const createCreditInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/CreditInvoices`, body);
  return data;
});

export const updateCreditInvoice = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/CreditInvoices`, body);
  return data;
});

export const deleteCreditInvoice = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/CreditInvoices/${id}`);
  return data;
});

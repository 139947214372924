import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateInventoryConfiguration } from "network/supply-chain/inventoryConfiguration";
import { queryKeys } from "services/react-query/queryKeys";

const useUpdateInventoryConfiguration = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateInventoryConfiguration,
    onSuccess: () =>
      client.invalidateQueries([queryKeys.inventoryConfiguration]),
  });

  return mutation;
};

export default useUpdateInventoryConfiguration;

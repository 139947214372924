import { useQuery } from "@tanstack/react-query";
import { getWarehouse } from "network/supply-chain/warehouse";
import { queryKeys } from "services/react-query/queryKeys";

const useWarehouse = ({ enabled = true, id }) => {
  const query = useQuery({
    queryFn: () => getWarehouse(id),
    select: (data) => data?.model,
    enabled,
  });

  query.warehouse = query.data;
  return query;
};

export default useWarehouse;

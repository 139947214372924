import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllBudgets = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Budget?${searchQuery}`);
  return data;
});

export const getBudget = catchAsync(async id => {
  const { data } = await axiosInstance(`/Budget/${id}`);
  return data;
});

export const getBudgetByName = catchAsync(async name => {
  const { data } = await axiosInstance(`/Budget/getbyname/${name}`);
  return data;
});

export const createBudget = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Budget`, body);
  return data;
});

// export const updateBudget = catchAsync(async body => {
//   const { data } = await axiosInstance.put(`/Budget`, body);
//   return data;
// });

// export const deleteBudget = catchAsync(async id => {
//   const { data } = await axiosInstance.delete(`/Budget/${id}`);
//   return data;
// });

export const getLinesByBudgetIdAndAccountId = catchAsync(async (budgetId, accountId = '') => {
  const { data } = await axiosInstance(`/Budget/${budgetId}/Lines/${accountId}`);
  return data;
});

export const getAccountPeriodsByBudgetIdAndAccountId = catchAsync(async (budgetId, accountId = '') => {
  const { data } = await axiosInstance(`/Budget/${budgetId}/AccountPeriods/${accountId}`);
  return data;
});

export const getAccountsByBudgetId = catchAsync(async budgetId => {
  const { data } = await axiosInstance(`/Budget/${budgetId}/Accounts`);
  return data;
});

export const getBudgetDividedPeriods = catchAsync(async monthCount => {
  const { data } = await axiosInstance(`/Budget/GetPeriods/${monthCount}`);
  return data;
});

export const transferLines = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Budget/transferLines`, body);
  return data;
});

export const addBudgetLines = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Budget/addLines`, body);
  return data;
});

export const addBudgetRanges = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Budget/addRanges`, body);
  return data;
});

export const addBadgetaryControls = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Budget/addBudgetaryControls`, body);
  return data;
});

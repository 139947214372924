import { useQuery } from '@tanstack/react-query';
import { getAllBudgets } from 'network/general-ledger/budget';
import { queryKeys } from 'services/react-query/queryKeys';

function useBudgetList(searchQueryStr) {
  const { data, isLoading } = useQuery(
    [queryKeys.budgetList, searchQueryStr],
    () => getAllBudgets(searchQueryStr)
  );

  const budgetList = data?.model;

  return {
    budgetList,
    budgetListLod: isLoading,
  };
}

export default useBudgetList;

import { App, Button, Checkbox, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createSalaryRule, getSalaryRule, updateSalaryRule } from 'network/Human-resources/salary-rules';

function RuleNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();

  const client = useQueryClient();
  const { data: salaryRule } = useQuery([queryKeys.salaryRule, params?.id], () => getSalaryRule(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('salaryRule', salaryRule);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.salaryRuleList]);

    form.resetFields();
    navigate('/human-resources/setup/salary/Rules');
  };

  const { mutate: onCreateRule, isLoading: onCreateRuleLod } = useMutation(createSalaryRule, {
    onSuccess,
  });
  const { mutate: onUpdateRule, isLoading: onUpdateRuleLod } = useMutation(updateSalaryRule, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && salaryRule) {
      const editItem = salaryRule?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, salaryRule, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.isActive = !!dataObj.isActive;
    dataObj.appearOnPayslip = !!dataObj.appearOnPayslip;
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = salaryRule?.model?.id;
      return onUpdateRule(dataObj);
    }

    onCreateRule(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Setup' }, { title: <Link to="/human-resources/setup/salary/Rule-types">Rules</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="nameEN" label="Name English" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Name Arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="code" label="Code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="sequence" label="sequence" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="amountTypeId" label="Amount Type" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: 1, label: 'Fixed' },
                  { value: 2, label: 'Percentage' },
                ]}
              />
            </Form.Item>
            <Form.Item name="fixedAmount" label="Fixed Amount" rules={[{ required: true }]}>
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item name="salaryRuleCategoryId" label="Category" rules={[{ required: true }]}>
              <Select
                options={metadata?.salaryRuleCategories?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>

            <Space>
              <Form.Item name="isActive" label=" " valuePropName="checked">
                <Checkbox>Active</Checkbox>
              </Form.Item>
              <Form.Item name="appearOnPayslip" label=" " valuePropName="checked">
                <Checkbox>Appear On Pay slip</Checkbox>
              </Form.Item>
            </Space>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateRuleLod || onUpdateRuleLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default RuleNew;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDepartment, getDepartment } from "network/Human-resources/department";
import { addJobApplication, getJobApplication, updateJobApplication } from "network/Human-resources/job-application";
import { queryKeys } from "services/react-query/queryKeys";

export const useJobApplication = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.jobApplication, id],
    queryFn: () => getJobApplication(id),
  });
  const applicationData = data?.model;
  return { applicationData, ...rest };
};

export const useCreateJobApplication = () => {
    const client = useQueryClient();
    const mutation = useMutation({
      mutationFn: addJobApplication,
      onSuccess:()=>client.invalidateQueries([queryKeys.jobApplication])
    });
    mutation.createJobApplication = mutation.mutate;
    mutation.isCreatingJobApplication = mutation.isLoading;
    return mutation;
  };
  
  export const useUpdateJobApplication = () => {
    const client = useQueryClient();
    const mutation = useMutation({
      mutationFn: updateJobApplication,
      onSuccess:()=>client.invalidateQueries([queryKeys.jobApplication])
    });
    mutation.updateJobApplication = mutation.mutate;
    mutation.isUpdateJobApplication = mutation.isLoading;
    return mutation;
  };
  
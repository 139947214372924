import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllGLStructures = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Structures?${searchQuery}`);
  return data;
});

export const getGLStructure = catchAsync(async id => {
  const { data } = await axiosInstance(`/Structures/${id}`);
  return data;
});

export const createGLStructure = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Structures`, body);
  return data;
});

export const updateGLStructure = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Structures`, body);
  return data;
});

export const deleteGLStructure = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Structures/${id}`);
  return data;
});

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import {
  addSalesMember,
  addSalesTeam,
  getSalesTeam,
  getSalesTeamLead,
  getSalesTeamMembers,
} from "network/supply-chain/salesTeam";

export const useSalesTeam = () => {
  const { data, ...rest } = useQuery({
    queryFn: getSalesTeam,
    queryKey: [queryKeys.salesTeam],
  });
  const salesTeam = data?.model;
  return { salesTeam, ...rest };
};
export const useSalesTeamLeads = () => {
  const { data, ...rest } = useQuery({
    queryFn: getSalesTeamLead,
    queryKey: [queryKeys.salesTeamLeads],
  });
  const salesTeam = data?.model;
  return { salesTeam, ...rest };
};
export const useSalesTeamMembers = () => {
  const { data, ...rest } = useQuery({
    queryFn: getSalesTeamMembers,
    queryKey: [queryKeys.salesMembers],
  });
  const salesTeamMembers = data?.model;
  return { salesTeamMembers, ...rest };
};

export const useCreateSalesTeam = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addSalesTeam,
    onSuccess: () => client.invalidateQueries([queryKeys.salesTeam]),
  });
  const { mutate, ...rest } = mutation;
  return { mutate, ...rest };
};

export const useCreateSalesMember = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addSalesMember,
    onSuccess: () => client.invalidateQueries([queryKeys.salesMembers]),
  });
  const { mutate, isLoading } = mutation;
  return { mutate, isLoading };
};

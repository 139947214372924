import catchAsync from "utils/catchAsync";
import { axiosInstance } from "services/axiosInstance";

export const getAllEmployees = catchAsync(async ({ word, Page } = {}) => {
  const { data } = await axiosInstance.get("/Employees", {
    params: {
      word,
      Page,
    },
  });

  return data;
});

export const addEmployee = catchAsync(async (body) => {
  const res = await axiosInstance.post("/Employees", body);
  return res;
});

export const getEmployee = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/Employees/${id}`);
  return data;
});

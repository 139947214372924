import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
} from "antd";

function Periods() {
  const [form] = Form.useForm();

  return (
    <div className="space-y-6">
      <OwnBreadcrumb noHome items={[{ title: "Setup" }]} current="periods" />
      <div className="bg-white py-8 px-8 rounded-lg">
        {/* <h2 className="title-24 mb-4">Transaction</h2> */}
        <Form
          layout="vertical"
          form={form}
          // onFinish={onFinish}
        >
          <div className="col-2">
            <Form.Item name="Ledger" label="Ledger">
              <Input />
            </Form.Item>
            <Form.Item name="Latest Open Period" label="Latest Open Period">
              <Input />
            </Form.Item>
          </div>
          <Space className="flex justify-end mt-4">
            <Button
              type="primary"
              // onClick={() => setIsValuesMod(true)}
              // ghost
            >
              OPEN NEXT PERIOD
            </Button>
          </Space>
        </Form>
      </div>
      <div className="bg-white py-8 px-8 rounded-lg">
        <h2 className="title-24 mb-4">Accounting Periods</h2>
        <Form
          layout="vertical"
          form={form}
          // onFinish={onFinish}
        >
          <div className="col-6">
            <Form.Item name="Status" label="Status">
              <Select
                options={[
                  {
                    label: "Never open",
                    value: "Never open",
                  },
                  {
                    label: "Open",
                    value: "Open",
                  },
                  {
                    label: "Close",
                    value: "Close",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item name="Number" label="Number">
              <Input />
            </Form.Item>
            <Form.Item name="Year" label="Year">
              <Input />
            </Form.Item>
            <Form.Item name="Name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="Start Date" label="Start Date">
              <DatePicker className="w-full" />
            </Form.Item>
            <Form.Item name="End Date" label="End Date">
              <DatePicker className="w-full" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item name="Status" label="Status">
              <Select
                options={[
                  {
                    label: "Never open",
                    value: "Never open",
                  },
                  {
                    label: "Open",
                    value: "Open",
                  },
                  {
                    label: "Close",
                    value: "Close",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item name="Number" label="Number">
              <Input />
            </Form.Item>
            <Form.Item name="Year" label="Year">
              <Input />
            </Form.Item>
            <Form.Item name="Name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="Start Date" label="Start Date">
              <DatePicker className="w-full" />
            </Form.Item>
            <Form.Item name="End Date" label="End Date">
              <DatePicker className="w-full" />
            </Form.Item>
          </div>
          <div className="col-6">
            <Form.Item name="Status" label="Status">
              <Select
                options={[
                  {
                    label: "Never open",
                    value: "Never open",
                  },
                  {
                    label: "Open",
                    value: "Open",
                  },
                  {
                    label: "Close",
                    value: "Close",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item name="Number" label="Number">
              <Input />
            </Form.Item>
            <Form.Item name="Year" label="Year">
              <Input />
            </Form.Item>
            <Form.Item name="Name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="Start Date" label="Start Date">
              <DatePicker className="w-full" />
            </Form.Item>
            <Form.Item name="End Date" label="End Date">
              <DatePicker className="w-full" />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Periods;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllAccounts = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Accounts?${searchQuery}`);
  return data;
});

// export const getAccounts = catchAsync(async id => {
//   const { data } = await axiosInstance(`/Accounts/${id}`);
//   return data;
// });

// export const getAccounts = catchAsync(async name => {
//   const { data } = await axiosInstance(`/Accounts/getbyname/${name}`);
//   return data;
// });

// export const createAccounts = catchAsync(async body => {
//   const { data } = await axiosInstance.post(`/Accounts`, body);
//   return data;
// });

// export const updateAccounts = catchAsync(async body => {
//   const { data } = await axiosInstance.put(`/Accounts`, body);
//   return data;
// });

// export const deleteAccounts = catchAsync(async id => {
//   const { data } = await axiosInstance.delete(`/Accounts/${id}`);
//   return data;
// });

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateOperationType } from "network/supply-chain/operationTypes";
import { queryKeys } from "services/react-query/queryKeys";

const useUpdateOperationType = (id) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateOperationType,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.operationTypes]);
    },
  });

  mutation.isUpdating = mutation.isLoading;
  mutation.update = mutation.mutate;
  return mutation;
};

export default useUpdateOperationType;

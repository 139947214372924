import CustomerNew from 'pages/CRM/customers/CustomerNew';
import Customers from 'pages/CRM/customers/Customers';
import LeadNew from 'pages/CRM/leads/LeadNew';
import Leads from 'pages/CRM/leads/Leads';
import TaskNew from 'pages/CRM/tasks/TaskNew';
import Tasks from 'pages/CRM/tasks/Tasks';
import { Navigate } from 'react-router-dom';

const routesCRM = [
  {
    path: 'CRM',
    children: [
      {
        index: true,
        // element: <CRM />,
      },
      {
        path: 'leads',
        children: [
          { index: true, element: <Leads /> },
          { path: 'new', element: <LeadNew /> },
          { path: 'edit/:id', element: <LeadNew isEdit /> },
        ],
      },
      {
        path: 'tasks',
        children: [
          {
            index: true,
            element: <Tasks />,
          },
          {
            path: 'new',
            element: <TaskNew />,
          },
          {
            path: 'edit/:id',
            element: <TaskNew isEdit />,
          },
        ],
      },
      {
        path: 'quotations',
        element: <h1>CRM Quotations</h1>,
      },
      {
        path: 'sales-teams',
        children: [
          {
            path: 'leaders',
            element: <Navigate to={'/orders/sales_team_leaders'} />,
          },
          {
            path: 'members',
            element: <Navigate to={'/orders/sales_team_members'} />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            index: true,
            element: <Customers />,
          },
          {
            path: 'new',
            element: <CustomerNew />,
          },
          {
            path: 'edit/:id',
            element: <CustomerNew isEdit />,
          },
        ],
      },
    ],
  },
];

export default routesCRM;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllTimeOffs = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/TimeOffs?${searchQuery}`);
  return data;
});

export const getTimeOff = catchAsync(async id => {
  const { data } = await axiosInstance(`/TimeOffs/${id}`);
  return data;
});

export const createTimeOff = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/TimeOffs`, body);
  return data;
});

export const updateTimeOff = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/TimeOffs`, body);
  return data;
});

export const deleteTimeOff = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/TimeOffs/${id}`);
  return data;
});

import { Form, Input, Select, Table } from "antd";
import OwnModal from "components/own/OwnModal";
import useModalParams from "hooks/useModalParams";

const ModalAssignResources = () => {
  const { isNew, close } = useModalParams("assign-resources");
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
  ];
  return (
    <OwnModal
      title="Assign Resources"
      open={isNew}
      onCancel={() => close()}
    >
      <Form>
        <Form.Item label="Resource Class">
          <Select
            options={[
              {
                label: "Labor",
                value: 1,
              },
              {
                label: "Non-Labor",
                value: 2,
              },
              {
                label: "Materials",
                value: 3,
              },
              {
                label: "Assets",
                value: 4,
              },
              {
                label: "Assets",
                value: 4,
              },
            ]}
          />
        </Form.Item>
      </Form>

      <Table />
    </OwnModal>
  );
};

export default ModalAssignResources;

import { Steps } from 'antd';
import React from 'react';

function StepsCompleteData({ current }) {
  return (
    <Steps
      current={current}
      items={[
        { title: 'Legal Entity' },
        { title: 'Select Address' },
        { title: 'Ledger option' },
      ]}
      labelPlacement="vertical"
      responsive={false}
      className="max-w-2xl mx-auto"
    />
  );
}

export default StepsCompleteData;

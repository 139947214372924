import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import InternalModal from "components/modals/inventoryModals/receipts/ReceiptModal";
import { useId, useState } from "react";
import { Button, Table, Tag } from "antd";

function Receipts() {
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);

  const dataSource = [
    {
      reference: "WH/IN/00001",
      from: "Partners/Vendors",
      to: "WH/Stock",
      content: "xyz",
      scheduledDate: "2023/9/13",
      sourceDocument: "P000001",
      status: "done",
    },
    {
      reference: "WH/IN/00002",
      from: "Partners/Vendors",
      to: "WH/Stock",
      content: "abc",
      scheduledDate: "2023/10/13",
      sourceDocument: "P000002",
      status: "draft",
    },
  ];
  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
    },
    {
      title: "Source Document",
      dataIndex: "sourceDocument",
      key: "sourceDocument",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <Tag color={status === "done" ? "green" : "gray"}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Receipts" />
        <SearchHero onAddBtn={() => setReceiptModalOpen(true)} />
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
          sticky
        />
      </div>
      <InternalModal
        open={receiptModalOpen}
        onCancel={() => setReceiptModalOpen(false)}
      />
    </>
  );
}

export default Receipts;

import { useQuery } from '@tanstack/react-query';
import { getAllFixedAssetValues } from 'network/fixed-assets/fixedAssetValues';
import { queryKeys } from 'services/react-query/queryKeys';

function useFixedAssetValueList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.fixedAssetValueList, searchQueryStr], () => getAllFixedAssetValues(searchQueryStr));

  const fixedAssetValueList = data?.model;

  return {
    fixedAssetValueList,
    fixedAssetValueListLod: isLoading,
  };
}

export default useFixedAssetValueList;

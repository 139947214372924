import { useQuery } from '@tanstack/react-query';
import { getAllPaymentTerms } from 'network/account-receivable/payment-terms';
import { queryKeys } from 'services/react-query/queryKeys';

function usePaymentTermsList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.paymentTermsList, searchQueryStr], () => getAllPaymentTerms(searchQueryStr));

  const paymentTermsList = data?.model;

  return {
    paymentTermsList,
    paymentTermsListLod: isLoading,
  };
}

export default usePaymentTermsList;

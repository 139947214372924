import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import useModalParams from "hooks/useModalParams";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useCreateDepartment } from "hooks/human-resourses/useDepartment";
import {
  useCreateWorkTimes,
  useUpdateWorkTimes,
} from "hooks/human-resourses/useWorkTimes";
import {
  useCreateWorkLocations,
  useUpdateWorkLocations,
} from "hooks/human-resourses/useWorkingLocations";
import dayjs from "dayjs";
import useMetaData from "hooks/api/useMetaData";
import { useCreateExpenses } from "hooks/human-resourses/useExpenses";
import { useExpensesCategory } from "hooks/human-resourses/useExpenseCategory";
import { useEmployee } from "hooks/human-resourses/useEmployee";

function ExpensesModal({ open, handelClose, LocationId }) {
  const { employeeExpenseStatuses } = useMetaData();
  const { expensessCategory } = useExpensesCategory();
  const { employeeData } = useEmployee();

  console.log(employeeData, "expensessCategory");
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const { createExpenses, isCreatingExpenses } = useCreateExpenses();
  const onFinish = (values) => {
    const formattedValues = {
      ...values,
      expenseDate: dayjs(values.expenseDate).format("YYYY-MM-DD"),
      // Format the expenseDate values in expenseLines array
      expenseLines: values.expenseLines.map((line) => ({
        ...line,
        expenseDate: dayjs(line.expenseDate).format("YYYY-MM-DD"),
      })),
    };
    createExpenses(
      formattedValues,
      {
        onSuccess: () => {
          message.success("Created successfully");
          handelClose();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      }
    );
  };
  return (
    <OwnModal
      title={"Add Employee Expense"}
      open={open}
      onCancel={() => handelClose()}
      width={1200}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-2 py-4">
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            {/* <DatePicker picker={"time"} /> */}
            <Input />
          </Form.Item>
          <Form.Item
            label=" Notes"
            name="notes"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            {/* <DatePicker picker={"time"} /> */}
            <Input />
          </Form.Item>
          <Form.Item label=" Total" name="total" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          {/* date picker */}
          <Form.Item
            label="Expense Date"
            name="expenseDate"
            rules={[{ required: true }]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item label="Paid By" name="paidBy" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  label: "Employee",
                  value: 0,
                },
                {
                  label: "Company",
                  value: 1,
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Expense Status "
            name="expenseStatusId"
            rules={[{ required: true }]}
          >
            <Select
              // placeholder="Select a person"
              // initialvalues="type one"
              options={employeeExpenseStatuses?.map((el) => ({
                label: el?.nameEN,
                value: el?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="expense Category Id"
            name="expenseCategoryId"
            rules={[{ required: true }]}
          >
            <Select
              // placeholder="Select a person"
              // initialvalues="type one"
              options={expensessCategory?.map((el) => ({
                label: el?.nameEN,
                value: el?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Employee Id"
            name="employeeId"
            rules={[{ required: true }]}
          >
            <Select
              options={employeeData?.map((s) => ({
                value: s?.id,
                label: s?.name,
              }))}
            />
          </Form.Item>
          {/* {/* <Form.Item label="Company Full Time" name="Company Full Time">
            <Input type="number" />
          </Form.Item> */}
          {/* <Form.Item
            label="Work From"
            name="workFrom"
            rules={[{ required: true }]}
          >
            <DatePicker picker={"time"} />
          </Form.Item>
          <Form.Item label="Work To" name="workTo" rules={[{ required: true }]}>
            <DatePicker picker={"time"} />
          </Form.Item> */}
        </div>
        <Form.List
          name="expenseLines"
          initialValue={[""]} // Initial value, can be an array with default items
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} style={{ display: "flex", marginBottom: 8 }}>
                  <Form.Item
                    {...restField}
                    name={[name, "description"]}
                    fieldKey={[fieldKey, "description"]}
                    label="Description"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "subTotal"]}
                    fieldKey={[fieldKey, "subTotal"]}
                    label="Sub Total"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "expenseDate"]}
                    fieldKey={[fieldKey, "expenseDate"]}
                    label="Expense Date"
                    style={{ marginRight: 8, flex: 1 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker disabledDate={disabledDate} />
                  </Form.Item>

                  {fields.length > 1 && (
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                      }}
                      style={{
                        margin: "8px 8px 0 0",
                        fontSize: "20px",
                        color: "#ff4d4f",
                      }}
                    />
                  )}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  icon={<PlusOutlined />}
                >
                  Add New Line
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={isCreatingExpenses}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default ExpensesModal;


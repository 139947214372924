import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Form, Input, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useFixedAssetStructureList from 'hooks/api/useFixedAssetStructureList';
import useGLStructureList from 'hooks/api/useGLStructureList';
import { createFixedAssetSegment, updateFixedAssetSegment } from 'network/fixed-assets/fixedAssetSegments';
import { createGLSegment, updateGLSegment } from 'network/general-ledger/GLSegments';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalAddSegment({ open, onCancel, editItem, setEditItem, fixedAsset }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys[fixedAsset ? 'fixedAssetSegmentList' : 'GLSegmentList']]);

    form.resetFields();
    onCancel();
  };

  const { fixedAssetStructureList } = useFixedAssetStructureList();
  const { GLStructureList } = useGLStructureList();
  const structureList = fixedAsset ? fixedAssetStructureList : GLStructureList;
  const { mutate: onCreateSegment, isLoading: createSegmentLod } = useMutation(fixedAsset ? createFixedAssetSegment : createGLSegment, { onSuccess });
  const { mutate: onUpdateSegment, isLoading: updateSegmentLod } = useMutation(fixedAsset ? updateFixedAssetSegment : updateGLSegment, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;

      onUpdateSegment(dataObj);
    } else {
      onCreateSegment(dataObj);
    }
  };

  return (
    <OwnModal title={editItem ? 'Edit Segment' : 'Add Segment'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name={fixedAsset ? 'fixedAssetStructureId' : 'structureId'} label="Structure">
            <Select options={structureList?.map(s => ({ value: s?.id, label: s?.title }))} />
          </Form.Item>
          <Form.Item name="number" label="Number">
            <Input />
          </Form.Item>
          <Form.Item name="column" label="Column">
            <Input />
          </Form.Item>

          <div className="flex gap-8">
            <Form.Item name="isDisplayed" valuePropName="checked">
              <Checkbox>Display</Checkbox>
            </Form.Item>
            <Form.Item name="isEnabled" valuePropName="checked">
              <Checkbox>Enable</Checkbox>
            </Form.Item>
          </div>
        </div>
        <Space className="flex justify-end mt-4">
          <Button loading={createSegmentLod || updateSegmentLod} type="primary" htmlType="submit">
            DONE
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalAddSegment;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllContracts = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/EmployeeContracts?${searchQuery}`);
  return data;
});

export const getContract = catchAsync(async id => {
  const { data } = await axiosInstance(`/EmployeeContracts/${id}`);
  return data;
});

export const createContract = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/EmployeeContracts`, body);
  return data;
});

export const updateContract = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/EmployeeContracts`, body);
  return data;
});

export const deleteContract = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/EmployeeContracts/${id}`);
  return data;
});

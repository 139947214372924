import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllAPDistributions = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Distributions?${searchQuery}`);
  return data;
});

export const getAPDistribution = catchAsync(async id => {
  const { data } = await axiosInstance(`/Distributions/${id}`);
  return data;
});

export const createAPDistribution = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Distributions`, body);
  return data;
});

export const updateAPDistribution = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Distributions`, body);
  return data;
});

export const deleteAPDistribution = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Distributions/${id}`);
  return data;
});

export const searchAPDistribution = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Distributions/Search`, body);
  return data;
});

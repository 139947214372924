import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Space, Switch, Table, Tag } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalAddSupplier from 'components/modals/ModalAddSupplier';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useSupplierList from 'hooks/api/useSupplierList';
import { deleteSupplier } from 'network/account-payable/supplier';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function Suppliers() {
  const { message } = App.useApp();
  const client = useQueryClient();

  const { mutate: onDeleteSupplier } = useMutation(deleteSupplier, {
    onSuccess: res => {
      console.log('Structure  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.supplierList]);
    },
  });

  const { supplierList } = useSupplierList();
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const [page, setpage] = useState(0);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Type',
      dataIndex: 'SupplierType',
      key: 'SupplierType',
      render: (text, record) => <p>{record?.supplierType?.nameEN}</p>,
    },
    {
      title: 'Payment Method',
      dataIndex: 'Payment Method',
      key: 'Payment Method',
      render: (text, record) => <p>{record?.paymentMethod?.nameEN}</p>,
    },
    {
      title: 'Address',
      dataIndex: 'addressLine1',
      key: 'addressLine1',
    },
    {
      title: 'Purpose',
      dataIndex: 'Purpose',
      key: 'Purpose',
      render: (_, record) => (
        <Space size={[0, 8]} wrap>
          {record?.isForPurchasingPayment && <Tag color="magenta">Purchasing</Tag>}
          {record?.isForRFQOnly && <Tag color="geekblue">RFQ</Tag>}
        </Space>
      ),
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: val => <Switch checked={val} />,
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteSupplier(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];

  const data = supplierList;
  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Suppliers" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} />
        <Table
          dataSource={data}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            current: page || 1,
            onChange: page => {
              setpage(page);
            },
          }}
        />
      </div>
      <ModalAddSupplier
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  );
}

export default Suppliers;

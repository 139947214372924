import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import RateModal from "components/modals/project-manager/setup/RateModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import useModalParams from "hooks/useModalParams";
import { Link, useParams } from "react-router-dom";
import { useExpenditureType } from "hooks/api/projects-management/setup/expenditureType";
import { useRatesByExpenditureTypeId } from "hooks/api/projects-management/setup/rates";
import { dateFormat } from "utils/utils";

const NonLabor = 4;
const CostColumns = [1, 2, 3];

function DefineRate() {
  const { openAsNew, openAsEdit } = useModalParams("rate");
  const { id: expenditureTypeId } = useParams();
  const { type, isFetched } = useExpenditureType(expenditureTypeId);
  const { rates } = useRatesByExpenditureTypeId(expenditureTypeId);
  const showCostColumn = isFetched
    ? CostColumns.includes(type?.projectCategoryId)
    : false;

  const columns = [
    {
      title: "Resource",
      key: "resource",
      render: (_, { resource, cost }) => (
        <p>
          {type?.projectCategoryId === NonLabor ? cost : resource?.name}
        </p>
      ),
    },
    {
      title: "Unit of Measure",
      dataIndex: "description",
      key: "description",
      render: (_, { uom }) => <p>{uom?.nameEN}</p>,
    },
    {
      title: "Rate Type",
      dataIndex: "rateType",
      key: "rateType",
      render: (_, { rateType }) => (
        <p>{rateType === 0 ? "Payroll Rate" : "Fixed Rate"}</p>
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Effective From",
      dataIndex: "effectiveFrom",
      key: "effectiveFrom",
      render: (_, { effectiveDateFrom }) => dateFormat(effectiveDateFrom),
    },
    {
      title: "Effective To",
      dataIndex: "effectiveTo",
      key: "effectiveTo",
      render: (_, { effectiveDateTo }) => dateFormat(effectiveDateTo),
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <Button
          onClick={() => openAsEdit({ rateId: id, expenditureTypeId })}
        >
          Edit
        </Button>
      ),
    },
  ];

  const costCol = {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
  };

  if (showCostColumn) {
    columns.splice(5, 0, costCol);
  }

  const breadCrumbItems = [
    {
      title: <p className="text-black">Project Managment</p>,
    },
    {
      title: <p className="text-black">Setup</p>,
    },
    {
      title: (
        <Link to="/project-managment/setup/expenditure-types">
          Expenditure Types
        </Link>
      ),
    },
    {
      title: (
        <p className="text-black">
          {type?.name}
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb items={breadCrumbItems} current="Rates" />
        <SearchHero
          onAddBtn={() => openAsNew({ expenditureTypeId })}
        />
        <Table
          sticky
          dataSource={rates ?? []}
          columns={columns}
        />
      </div>
      <RateModal />
    </>
  );
}

export default DefineRate;

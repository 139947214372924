import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import TagElement from "components/common/TagElement";
import ModalSalesTeam from "components/modals/orders/ModalSalesTeam";
import { useSalesTeamMembers } from "hooks/api/supply-chain/sales-team/useSalesTeam";

export default function SalesTeamLeader() {
  const [modalsalesteam, setModalsalesteam] = useState(false);
  const { salesTeamMembers, isLoading } = useSalesTeamMembers();
  console.log(salesTeamMembers, "salesTeamMembers");
  const navigate = useNavigate();

  const columns = [
    {
      title: "Team Members Name",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    // {
    //   title: "Target",
    //   dataIndex: "salesPersonTarget",
    //   key: "salesPersonTarget",
    // },
    {
      title: "Sales Team Id",
      dataIndex: "salesTeamId",
      key: "salesTeamId",
    },
    {
      title: "Status",
      // dataIndex: "status",
      key: "status",
      render: ({ isActive }) => <TagElement type={isActive ? 5 : 6} />,
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Sales Team Leader" />
        <SearchHero onAddBtn={() => setModalsalesteam(true)} />
        <Table
          isLoading={isLoading}
          dataSource={salesTeamMembers}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalSalesTeam
        teamTypeMember={true}
        open={modalsalesteam}
        onCancel={() => setModalsalesteam(false)}
      />
    </>
  );
}

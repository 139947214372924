import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getPurchasingOrder = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/PurchasingOrder`);
  return data;
});

export const addPurchasingOrder = catchAsync(async (body) => {
  try {
    const res = await axiosInstance.post(`/PurchasingOrder`, body);
    return res;
  } catch (error) {
    return error;
  }
});

import { useQuery } from '@tanstack/react-query';
import { getAppraisalSkills } from 'network/Human-resources/appraisal';
import { queryKeys } from 'services/react-query/queryKeys';

function useAppraisalSkillList(id, searchQueryStr) {
  const { data, isInitialLoading } = useQuery([queryKeys.appraisalSkillList, String(id), searchQueryStr], () => getAppraisalSkills(id, searchQueryStr), {
    enabled: !!id,
  });

  const appraisalSkills = data?.model;

  return {
    appraisalSkills,
    appraisalSkillsLod: isInitialLoading,
  };
}

export default useAppraisalSkillList;

import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import ExpenditureCategoryModal from "components/modals/project-manager/setup/ExpenditureCategoryModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import useModalParams from "hooks/useModalParams";

function ExpenditureCategory() {
  const { openAsNew, openAsEdit } = useModalParams();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Effective From",
      dataIndex: "effective-from",
      key: "effective-from",
    },
    {
      title: "Effective To",
      dataIndex: "effective-to",
      key: "effective-to",
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <Button onClick={() => openAsEdit({ id })}>Edit</Button>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      name: "Labor",
      description: "Foo Bar Fam Bro",
      "effective-from": "13/13/2013",
      "effective-to": "13/13/2013",
    },
    {
      id: 2,
      name: "Non Labor",
      description: "Foo Bar Fam Bro",
      "effective-from": "13/13/2013",
      "effective-to": "13/13/2013",
    },
    {
      id: 3,
      name: "Assets",
      description: "Foo Bar Fam Bro",
      "effective-from": "13/13/2013",
      "effective-to": "13/13/2013",
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Project Managment / Setup / Expenditure Category" />
        <SearchHero onAddBtn={() => openAsNew()} />
        <Table
          // dataSource={data ?? []}
          sticky
          dataSource={data}
          columns={columns}
        />
      </div>
      <ExpenditureCategoryModal />
    </>
  );
}

export default ExpenditureCategory;

import { App, Button, Form, Input, Select } from "antd";
import OwnModal from "components/own/OwnModal";
import useMetaData from "hooks/api/useMetaData";
import { useProuducts } from "hooks/products/useProducts";
import { useCreateReordering } from "hooks/products/useReordering";
import { useState } from "react";

export default function   ModalReorderingAdd(props) {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { productTypes } = useMetaData();
  const { createReordering, isCreatingReordering } = useCreateReordering();
  const { productsData } = useProuducts();
  console.log(productsData, "productsData");
  const onFinish = (values) => {
    if (
      values?.minimumQuantity > values?.maximumQuantity ||
      values?.toOrder < values?.minimumQuantity ||
      values?.toOrder > values?.maximumQuantity
    ) {
      message.error("Please check minimum and maximum Quantities and to order");
    } else {
      createReordering(values, {
        onSuccess: () => {
          message.success("Created successfully");
          props.onCancel();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      });
    }
  };
  return (
    <OwnModal title="Reordering Product" {...props}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="grid md:grid-cols-2  gap-x-9 gap-y-1">
          <Form.Item
            label="Products "
            name="productId"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // placeholder="Select a person"
              //   defaultValue="product1"
              options={productsData?.map((el) => ({
                label: el?.nameEN,
                value: el?.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Alert At"
            name="alertAt"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Minimum Quantity "
            name="minimumQuantity"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Maximum Quantity "
            name="maximumQuantity"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="To Order"
            name="toOrder"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="btn-wrraper mt-4 text-center ">
          <Button
            type="primary"
            htmlType="submit"
            className="block m-auto  w-full max-w-xs"
            loading={isCreatingReordering}
          >
            Submit
          </Button>
        </div>
      </Form>
    </OwnModal>
  );
}

import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import ModalDeleteUnitOfMeasure from "components/modals/project-manager/setup/DeleteUnitOfMeasureModal";
import UnitsOfMeasuresModal from "components/modals/project-manager/setup/UnitsOfMeasuresModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useUnitOfMeasures } from "hooks/api/projects-management/setup/unitOfMeasures";
import useModalParams from "hooks/useModalParams";
import { dateFormat } from "utils/utils";

function UnitsOfMeasures() {
  const { openAsNew, openAsEdit, openAsDelete } = useModalParams("uom");
  const { uoms } = useUnitOfMeasures();

  const columns = [
    {
      title: "Name - English",
      dataIndex: "nameEN",
      key: "nameEN",
      width: 200,
    },
    {
      title: "Name - Arabic",
      dataIndex: "nameAR",
      key: "nameAR",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Effective From",
      key: "effective-from",
      render: ({ effectiveDateFrom }) => <p>{dateFormat(effectiveDateFrom)}</p>,
    },
    {
      title: "Effective To",
      key: "effective-to",
      render: ({ effectiveDateTo }) => <p>{dateFormat(effectiveDateTo)}</p>,
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <div className="flex items-center gap-2">
          <Button onClick={() => openAsEdit({ id })}>Edit</Button>
          <Button onClick={() => openAsDelete({ id })} danger>Delete</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Project Managment / Setup / Units Of Measures" />
        <SearchHero onAddBtn={() => openAsNew()} />
        <Table
          sticky
          dataSource={uoms ?? []}
          columns={columns}
        />
      </div>
      <UnitsOfMeasuresModal />
      <ModalDeleteUnitOfMeasure />
    </>
  );
}

export default UnitsOfMeasures;

import { useQuery } from '@tanstack/react-query';
import { getAllFixedAssetValueSets } from 'network/fixed-assets/fixedAssetValueSets';
import { queryKeys } from 'services/react-query/queryKeys';

function useFixedAssetValueSetList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.fixedAssetValueSetList, searchQueryStr], () => getAllFixedAssetValueSets(searchQueryStr));

  const fixedAssetValueSetList = data?.model;

  return {
    fixedAssetValueSetList,
    fixedAssetValueSetListLod: isLoading,
  };
}

export default useFixedAssetValueSetList;

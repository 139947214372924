import { Button } from "antd";
import dayjs from "dayjs";

const createColumns = (isEdit = false) => {
  return [
    {
      title: "Task Number",
      dataIndex: "number",
      key: "number",
      datatype: "text",
      editable: true,
      width: 200,
    },
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      datatype: "text",
      editable: true,
      width: 400,
    },
    {
      title: "Resources",
      key: "resources",
      render: () => (
        <Button
          // onClick={() => openAsNew()}
        >
          Assign
        </Button>
      ),
    },
    ...(isEdit
      ? [{
        title: "Planned Date",
        key: "plannedDate",
        dataIndex: "plannedDate",
        datatype: "date",
        render: (_, { plannedStartDate, plannedFinishDate }) => (
          <div className="col-2">
            <p>{dayjs(plannedStartDate).format("YYYY-MM-DD")}</p>
            <p>{dayjs(plannedFinishDate).format("YYYY-MM-DD")}</p>
          </div>
        ),
        editable: true,
      }]
      : [{
        title: "Planned Start Date",
        key: "plannedStartDate",
        dataIndex: "plannedStartDate",
        render: (_, { plannedStartDate }) => (
          <p>{dayjs(plannedStartDate).format("YYYY-MM-DD")}</p>
        ),
        datatype: "date",
        editable: true,
      }, {
        title: "Planned Finish Date",
        key: "plannedFinishDate",
        dataIndex: "plannedFinishDate",
        render: (_, { plannedFinishDate }) => (
          <p>{dayjs(plannedFinishDate).format("YYYY-MM-DD")}</p>
        ),
        datatype: "date",
        editable: true,
      }]),

    ...(isEdit
      ? [{
        title: "Transaction Date",
        key: "transactionDate",
        dataIndex: "transactionDate",
        datatype: "date",
        render: (_, { transactionStartDate, transactionFinishDate }) => (
          <div className="col-2">
            <p>{dayjs(transactionStartDate).format("YYYY-MM-DD")}</p>
            <p>{dayjs(transactionFinishDate).format("YYYY-MM-DD")}</p>
          </div>
        ),
        editable: true,
      }]
      : [
        {
          title: "Transaction Start Date",
          key: "transactionStartDate",
          dataIndex: "transactionStartDate",
          render: (_, { transactionStartDate }) => (
            <p>{dayjs(transactionStartDate).format("YYYY-MM-DD")}</p>
          ),
          datatype: "date",
          editable: true,
        },
        {
          title: "Transaction Finish Date",
          key: "transactionFinishDate",
          dataIndex: "transactionFinishDate",
          render: (_, { transactionFinishDate }) => (
            transactionFinishDate && (
              <p>{dayjs(transactionFinishDate).format("YYYY-MM-DD")}</p>
            )
          ),
          datatype: "date",
          editable: true,
        },
      ]),
  ];
};

// will refactor later, I promise..
export const createMergedColumns = (
  options,
  isEditing,
  isEdit,
  addtionalColumns = {},
) => {
  const columns_ = [...options, ...createColumns(isEdit), addtionalColumns];

  return columns_.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: col.datatype,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });
};

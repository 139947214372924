import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Form, Input, InputNumber, Radio, Select } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useGLSegmentList from 'hooks/api/useFixedAssetSegmentList';
import useFixedAssetSegmentList from 'hooks/api/useFixedAssetSegmentList';
import { createFixedAssetValueSet, updateFixedAssetValueSet } from 'network/fixed-assets/fixedAssetValueSets';
import { createGLValueSet, updateGLValueSet } from 'network/general-ledger/GLValueSets';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalAddValueSet({ open, onCancel, editItem, setEditItem, fixedAsset }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys[fixedAsset ? 'fixedAssetValueSetList' : 'GLValueSetList']]);

    form.resetFields();
    onCancel();
  };

  const { fixedAssetSegmentList } = useFixedAssetSegmentList();
  const { GLSegmentList } = useGLSegmentList();
  const segmentList = fixedAsset ? fixedAssetSegmentList : GLSegmentList;

  const { mutate: onCreateValueSet, isLoading: createValueSetLod } = useMutation(fixedAsset ? createFixedAssetValueSet : createGLValueSet, { onSuccess });
  const { mutate: onUpdateValueSet, isLoading: updateValueSetLod } = useMutation(fixedAsset ? updateFixedAssetValueSet : updateGLValueSet, { onSuccess });

  const validationType = Form.useWatch('isIndependent', form);

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    dataObj.isIndependent = !!dataObj.isIndependent;
    // BUG Static
    dataObj.independentValueSetId = null;

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateValueSet(dataObj);
    } else {
      onCreateValueSet(dataObj);
    }
  };

  return (
    <OwnModal title={editItem ? 'Edit Value set' : 'Add Value set'} open={open} onCancel={onCancel} width={902}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="col-2">
          <Form.Item name="nameEN" label="Value set name English">
            <Input />
          </Form.Item>
          <Form.Item name="nameAR" label="Value set name Arabic">
            <Input />
          </Form.Item>
          <Form.Item name={fixedAsset ? 'fixedAssetSegmentId' : 'segmentId'} label="Segment">
            <Select options={segmentList?.map(s => ({ value: s?.id, label: s?.title }))} />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ maxRows: 1 }} />
          </Form.Item>

          <Form.Item
            name="formatType"
            label="Format type"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item name="maximumSize" label="Maximum size">
            <InputNumber className="w-full" />
          </Form.Item>
        </div>
        <Form.Item name="valueType" label="Value type">
          <Radio.Group
            options={[
              {
                value: 0,
                label: 'Numbers only (0-9)',
              },
              {
                value: 1,
                label: 'Uppercase only (A-Z)',
              },
              {
                value: 2,
                label: 'Right justify and zero fill number (001)',
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="isIndependent" valuePropName="checked">
          <Checkbox>independent</Checkbox>
        </Form.Item>
        {!!validationType && (
          // <div className="flex gap-8">
          <Form.Item name="independentValueSetId" label="Independent value set">
            <Select>
              <Select.Option value="bank">bank</Select.Option>
              <Select.Option value="account">account</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Button loading={createValueSetLod || updateValueSetLod} type="primary" htmlType="submit" className="block ms-auto w-full max-w-xs">
          DONE
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalAddValueSet;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
} from 'antd';
import OwnModal from 'components/own/OwnModal';

function ModalReceiptApply({ open, onCancel }) {
  const [form] = Form.useForm();

  const onFinish = values => {
    console.log('ModalReceiptApply  values:', values);
  };
  return (
    <OwnModal
      // title={editItem ? 'Edit Segment' : 'Add Segment'}
      title={'Applications'}
      open={open}
      onCancel={onCancel}
      width={1366}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ names: [''] }}
      >
        <Form.List name="names">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    {/* <div className="grid grid-cols-3 lg:grid-cols-  gap-x-3  bg-gray-50 p-3 rounded items-end"> */}
                    <div className="col-7 bg-gray-50 p-3 rounded">
                      <Form.Item name={[index, 'Apply To']} label="Apply To">
                        <Select loading />
                      </Form.Item>

                      <Form.Item
                        name={[index, 'Billing Number']}
                        label="Billing Number"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'Apply Date']}
                        label="Apply Date"
                      >
                        <DatePicker className="w-full" />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'Original Amount']}
                        label="Original Amount"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name={[index, 'Disscount']} label="Disscount">
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'Amount Applied']}
                        label="Amount Applied"
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'Balance Due']}
                        label="Balance Due"
                      >
                        <Input />
                      </Form.Item>
                    </div>

                    {fields.length > 1 ? (
                      <Button
                        type="danger"
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove Above Field
                      </Button>
                    ) : null}
                  </div>
                ))}
                <Divider />
                <Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    onClick={add}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Space className="flex justify-end mt-4">
          {/* <Button
            type="primary"
            // onClick={() => setIsSetValueMod(true)}
          >
            budgetary control
          </Button> */}
          <Button
            type="primary"
            // onClick={() => setIsValuesMod(true)}
            // ghost
          >
            Confirm
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalReceiptApply;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalCalender from 'components/modals/ModalPeriod';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useCreditInvoiceList from 'hooks/api/useCreditInvoiceList';
import { deleteCreditInvoice } from 'network/account-receivable/credit-invoice';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { arrayFrom, dateFormat } from 'utils/utils';

function CreditInvoices() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();

  const { creditInvoiceList } = useCreditInvoiceList();
  console.log('CreditInvoices  creditInvoiceList:', creditInvoiceList);
  const { mutate: onDeleteCreditInvoice } = useMutation(deleteCreditInvoice, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.creditInvoiceList]);
    },
  });

  const columns = [
    {
      key: 'number',
      dataIndex: 'number',
      title: 'number',
    },
    {
      key: 'batchName',
      dataIndex: 'batchName',
      title: 'Name',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteCreditInvoice(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];

  const data = creditInvoiceList;

  // const data = creditInvoiceList?.map((_, i) => ({
  //   key: i,
  //   prefix: 'Text here',
  //   type: 'Text here',
  //   year: 'Text here',
  //   num: 'Text here',
  //   from: 'Text here',
  //   to: 'Text here',
  //   name: 'Text here',
  //   adjusting: 'Text here',
  // }));
  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Credit invoices" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table
        dataSource={data}
        sticky
        columns={columns}
        // rowSelection={rowSelection}
        // scroll={{ y: '66vh' }}
        // className="overflow-auto min-w-max"
      />
    </div>
  );
}

export default CreditInvoices;

import { Col, Row } from 'antd';
import DashboardTable from 'components/dashboard/DashboardTable';
import SalesOverview from 'components/dashboard/SalesOverview';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale } from 'chart.js/auto';
import DashBoardHero from 'components/dashboard/DashBoardHero';
import WeeklyStats from 'components/dashboard/WeeklyStats';
import SmallCardsWrapper from 'components/dashboard/SmallCardsWrapper';
import RevenueUpdates from 'components/dashboard/RevenueUpdates';
import CardArea from 'components/dashboard/CardArea';

// import WeeklyStats from 'components/dashboard/WeeklyStats';

ChartJS.register(ArcElement, Tooltip, Legend);
function Home() {
  return (
    <div className="space-y-6">
      {/* Header */}
      <DashBoardHero />
      {/* Content */}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SmallCardsWrapper />
        </Col>
        {/* <Col xs={24}>
          <RevenueUpdates />
        </Col> */}
        <Col xs={24} lg={16}>
          <div className="space-y-6">
            <WeeklyStats />
            <RevenueUpdates />
            <DashboardTable />
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="space-y-6">
            <SalesOverview key={1} />
            <CardArea />
            <SalesOverview key={2} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Home;

import { Button, Space } from "antd";
import AddressWrapper from "components/common/AddressWrapper";
import StepsCompleteData from "components/common/StepsCompleteData";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function AccountSetupStep2() {
  const naviggate = useNavigate();
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const onFinish = (values) => {
    console.log("onFinish  values:", values);
    naviggate("/complete-data/ledger");
    return {};
  };

  return (
    <div className="steps-card-wrapper">
      <div className="max-w-3xl mx-auto px-4 py-12 flex flex-col justify-center step-content-holder">
        <div className="mb-16">
          <StepsCompleteData current={1} />
        </div>

        <div className="min-h-[50vh] mb-9">
          <AddressWrapper
            selectedAddressId={selectedAddressId}
            setSelectedAddressId={setSelectedAddressId}
          />
        </div>

        <Space className="flex justify-between items-center mt-8">
          <Button
            htmlType="submit"
            type="primary"
            onClick={() => naviggate(-1)}
          >
            Back
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            disabled={!selectedAddressId}
            onClick={onFinish}
          >
            NEXT
          </Button>
        </Space>
      </div>
    </div>
  );
}

export default AccountSetupStep2;

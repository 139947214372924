import { useQuery } from '@tanstack/react-query';
import { getAllWorkEntryTypes } from 'network/Human-resources/work-entry-types';
import { queryKeys } from 'services/react-query/queryKeys';

function useWorkEntryTypeList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.workEntryTypeList, searchQueryStr], () => getAllWorkEntryTypes(searchQueryStr));

  const workEntryTypeList = data?.model;

  return {
    workEntryTypeList,
    workEntryTypeListLod: isLoading,
  };
}

export default useWorkEntryTypeList;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addReordering, getReordering } from "network/products/reordering";
import { queryKeys } from "services/react-query/queryKeys";

export const useReordering = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.reordering, id],
    queryFn: () => getReordering(id),
  });
  const reorderingData = data?.model;
  return { reorderingData, ...rest };
};

export const useCreateReordering = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addReordering,
    onSuccess: () => client.invalidateQueries([queryKeys.reordering]),
  });
  mutation.createReordering = mutation.mutate;
  mutation.isCreatingReordering = mutation.isLoading;
  return mutation;
};

import { useQuery } from "@tanstack/react-query";
import { getOperationType } from "network/supply-chain/operationTypes";

const useOperationType = ({ enabled = true, id }) => {
  const query = useQuery({
    queryFn: () => getOperationType(id),
    select: (data) => data?.model,
    enabled,
  });
  query.operationType = query?.data;
  return query;
};

export default useOperationType;

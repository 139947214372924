import { Button, Checkbox, Divider, Form, Input, Select, Space } from "antd";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import React from "react";
import {
  FileOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
export default function PayableOptions() {
  const [form] = Form.useForm();
  const options = [{ label: "use Clearing", value: "clear" }];

  const isSelectMulti = Form.useWatch("isSelectMulti", form);

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        noHome
        items={[{ title: "payable options" }]}
        current="Enter"
      />

      <div className="bg-white py-8 px-8 rounded-lg">
        <div className="flex items-center justify-between gap-2 flex-wrap mb-4">
          <h2 className="title-24">payable options</h2>
        </div>

        <Form
          layout="vertical"
          form={form}
          // onFinish={onFinish}
        >
          <>
            <Divider>Financial Options</Divider>
            <div className="col-3">
              <Form.Item name="Liability Account" label="Liability Account">
                <Input />
              </Form.Item>
              <Form.Item name="Prepayment" label="Prepayment">
                <Input />
              </Form.Item>
              <Form.Item name="Discount Taken" label="Discount Taken">
                <Input />
              </Form.Item>
              <Form.Item
                name="PO Rate Variance Gain"
                label="PO Rate Variance Gain"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="PO Rate Variance Loss"
                label="PO Rate Variance Loss"
              >
                <Input />
              </Form.Item>
            </div>
            <Divider>Payable system setup</Divider>
            <div className="col-3">
              <Form.Item name="Entry" label="Entry">
                <Select
                  allowClear
                  options={[
                    {
                      value: "Automatic",
                      label: "Automatic",
                    },
                    {
                      value: "Manual",
                      label: "Manual",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="Type" label="Type">
                <Select
                  allowClear
                  options={[
                    {
                      value: "Alphanumeric",
                      label: "Alphanumeric",
                    },
                    {
                      value: "Number",
                      label: "Number",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="Next Automatic Number"
                label="Next Automatic Number"
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item name="Terms Date Basis" label="Terms Date Basis">
                <Select
                  allowClear
                  options={[
                    {
                      value: "Goods Recevied",
                      label: "Goods Recevied",
                    },
                    {
                      value: "Invoice",
                      label: "Invoice",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <Divider>Payable Options</Divider>
            <div className="col-2">
              <div className="">
                <h4 className="title-18 mb-2">Account Option</h4>
                <Form.Item name="clear" label="">
                  <Checkbox.Group options={options} defaultValue={["clear"]} />
                </Form.Item>
              </div>
              <div className="">
                <h4 className="title-18 mb-2">Currency</h4>
                <Form.Item name="isSelectMulti" label="">
                  <Checkbox.Group
                    options={[
                      {
                        label: "Use Multiple Currencies",
                        value: "1",
                      },
                    ]}
                  />
                </Form.Item>
                {console.log(isSelectMulti, "isSelectMulti")}
              </div>
            </div>
            {isSelectMulti?.length > 0 && (
              <>
                <Divider>GL Accounts</Divider>
                <div className="col-3">
                  <Form.Item name="Realize Gain" label="Realize Gain">
                    <Input />
                  </Form.Item>
                  <Form.Item name="Realize Loss" label="Realize Loss">
                    <Input />
                  </Form.Item>
                  <Form.Item name="Rounding" label="Rounding">
                    <Input />
                  </Form.Item>
                </div>
              </>
            )}
          </>
        </Form>
      </div>
      <Space className="flex justify-end mt-4">
        <Button
          type="primary"
          // onClick={() => setIsSetValueMod(true)}
        >
          Submit
        </Button>
      </Space>
    </div>
  );
}

import { useQuery } from '@tanstack/react-query';
import { getAllDailyRates } from 'network/general-ledger/daily-rate';
import { queryKeys } from 'services/react-query/queryKeys';

function useDailyRateList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.dailyRateList, searchQueryStr], () => getAllDailyRates(searchQueryStr));

  const dailyRateList = data?.model;

  return {
    dailyRateList,
    dailyRateListLod: isLoading,
  };
}

export default useDailyRateList;

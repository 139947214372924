import { ClockCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from 'utils/utils';

function AppraisalCard({ appraisl }) {
  return (
    <Link to={`details/${appraisl?.id}`}>
      <div className="flex border shadow rounded max-w-full  h-full overflow-hidden relative">
        <div className="w-20 bg-teal-500 flex items-center justify-center text-white uppercase text-2xl">
          <span>{(appraisl?.employeeId).slice(0, 1)}</span>
        </div>
        <div className="flex-1">
          <div className="p-2 text-xs leading-normal">
            <h5 className="text-sm capitalize font-medium">{appraisl?.employeeId}</h5>
            <p>Administration</p>
            <p>
              <ClockCircleOutlined style={{ fontSize: 12, marginInlineEnd: 5 }} />
              {dateFormat(appraisl?.appraisalDate)}
            </p>
            <div className="flex justify-end items-center">
              <Button type="primary" className="!p-0 !text-xs inline-flex items-center justify-center !min-h-[auto] !text-white !w-6 !h-6">
                {(appraisl?.employeeId).slice(0, 1)}
              </Button>
            </div>
          </div>
        </div>
        {appraisl?.statusId === 3 && (
          <span className="w-20 bg-green-500 shadow flex items-center justify-center text-white capitalize font-semibold text-xs absolute top-3 -end-5 rotate-45">
            Done
          </span>
        )}
      </div>
    </Link>
  );
}

export default AppraisalCard;

// const x = [
//   {
//     nameEN: 'To Confirm',
//     nameAR: ' ',
//     id: 1,
//     creationDate: '2023-12-09T14:32:09.9033355',
//     modificationDate: null,
//     createdBy: 'SYSTEM',
//     modifieddBy: 'SYSTEM',
//     isDeleted: false,
//   },
//   {
//     nameEN: 'Confirmed',
//     nameAR: ' ',
//     id: 2,
//     creationDate: '2023-12-09T14:32:09.9036314',
//     modificationDate: null,
//     createdBy: 'SYSTEM',
//     modifieddBy: 'SYSTEM',
//     isDeleted: false,
//   },
//   {
//     nameEN: 'Done',
//     nameAR: ' ',
//     id: 3,
//     creationDate: '2023-12-09T14:32:09.903632',
//     modificationDate: null,
//     createdBy: 'SYSTEM',
//     modifieddBy: 'SYSTEM',
//     isDeleted: false,
//   },
// ];

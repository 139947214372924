import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import ModalAddProduct from "components/modals/inventoryModals/products/ModalAddProduct";
import { useProuducts } from "hooks/products/useProducts";

export default function Products() {
  const [CategoriesProduct, setCategoriesProduct] = useState(false);
  const navigate = useNavigate();
  const {productsData} =useProuducts()

  const columns = [
    {
      title: "Product Name",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    {
      title: "Product Category",
      dataIndex: "productCategoryId",
      key: "productCategoryId",
    },
    {
      title: "Product Type",
      dataIndex: "productTypeId",
      key: "productTypeId",
    },
    {
      title: "Sales Price",
      dataIndex: "salesPrice",
      key: "salesPrice",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current=" Products" />
        <SearchHero onAddBtn={() => setCategoriesProduct(true)} />
        <Table
          dataSource={productsData}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalAddProduct
        open={CategoriesProduct}
        onCancel={() => setCategoriesProduct(false)}
      />
    </>
  );
}

import React, { useState } from "react";
import { Button, Input, Space, Upload } from "antd";
import { css, cx } from "@emotion/css";
import { DeleteOutlined } from "@ant-design/icons";

const upload = css`
  .ant-upload-list {
    display: none;
  }
  .ant-upload-select {
    width: 100%;
  }
`;

const input = css`
  background: white !important;
  cursor: unset !important;
`;

const { Compact } = Space;

// onChange is called by Form.Item to set the inner FormInstance value of this
// value is passed when passing the initialValues of the Form or the Form.Item
function OwnUpload({ onChange, value, className }) {
  const [file, setFile] = useState(value ?? null);

  const handleFileSelect = ({ fileList }) => {
    setFile(fileList[0]);
    if (typeof onChange === "function") {
      onChange(fileList[0]);
    }
  };

  const resetFile = () => {
    setFile(null);
    if (typeof onChange === "function") {
      onChange(null);
    }
  };

  return (
    <Compact
      className={cx("flex flex-row items-center justify-between", className)}
    >
      <Input
        className={input}
        disabled
        value={file ? file?.name : "No file selected"}
      />
      {!!file && (
        <Button
          className="px-1"
          onClick={resetFile}
          icon={<DeleteOutlined style={{ color: "red" }} />}
        />
      )}
      <Upload
        className={upload}
        multiple={false}
        beforeUpload={() => false}
        maxCount={1}
        onChange={handleFileSelect}
      >
        <Button>Select File</Button>
      </Upload>
    </Compact>
  );
}

export default OwnUpload;

import { DeleteOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Form, Input, InputNumber } from "antd";
import Compact from "antd/es/space/Compact";
import OwnModal from "components/own/OwnModal";
import OwnUpload from "components/own/OwnUpload";
import useOwnTag from "components/own/useOwnTag";
import { useState } from "react";

function DeliveryModal({ onCancel, ...props }) {
  const { Tag, setTagValue } = useOwnTag("ready");
  const [form] = Form.useForm();

  const products = [
    {
      name: "Product 1",
      sourceLocation: "WH/Stock 1",
      reserved: 10,
      done: 9,
    },
    {
      name: "Product 2",
      sourceLocation: "WH/Stock 1",
      reserved: 11,
      done: 3,
    },
    {
      name: "Product 3",
      sourceLocation: "WH/Stock 2",
      reserved: 50,
      done: 20,
    },
    {
      name: "Product 4",
      sourceLocation: "WH/Stock 3",
      reserved: 20,
      done: 0,
    },
  ];

  return (
    <OwnModal
      {...props}
      title="Deliveries"
      onCancel={() => {
        form.resetFields();
        setTagValue("waiting");
        onCancel();
      }}
    >
      <div className="flex flex-row items-center justify-between my-10">
        <p className="py-2">
          Product Availability:{" "}
          <span className="text-green-500">Available</span>
        </p>
        <Tag />
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={() => setTagValue("done")}
        initialValues={{
          products: products,
        }}
      >
        <div className="col-2">
          <Form.Item
            label="Sales Order"
            name="salesOrder"
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Scheduled Date"
            name="scheduledDate"
            rules={[{ required: true }]}
            required
          >
            <DatePicker
              showTime={{
                format: "hh:mm a",
              }}
              format="YYYY-MM-DD hh:mm A"
            />
          </Form.Item>
          <Form.Item
            label="Source Document"
            name="sourceDocument"
          >
            <OwnUpload />
          </Form.Item>
        </div>

        <p className="py-2">
          Product Availability:{" "}
          <span className="text-green-500">Available</span>
        </p>

        <Divider>Detailed Operations</Divider>

        <Form.List name="products">
          {(fields) => (
            <>
              {fields.map((field) => (
                <div
                  className="flex flex-row items-center justify-between border-b border-solid border-b-blue-100 mt-9 py-5"
                  key={field.key}
                >
                  <Form.Item label="Name" name={[field.name, "name"]}>
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Source Location"
                    name={[field.name, "sourceLocation"]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "reserved"]}
                    label="Reserve"
                    rules={[{ required: true }]}
                    required
                  >
                    <InputNumber className="w-full" min={0} />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "done"]}
                    label="Done"
                    rules={[{ required: true }]}
                    required
                  >
                    <InputNumber className="w-full" min={0} />
                  </Form.Item>
                  <Form.Item label=" ">
                    <Button
                      type="primary"
                      style={{ width: 50 }}
                      danger
                      block
                      icon={<DeleteOutlined />}
                    />
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>

        <div className="col-5 mt-10">
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              onClick={() => setTagValue("draft")}
              block
            >
              Set Draft
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              block
            >
              Reserve
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              block
            >
              Unreserve
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              block
            >
              Check Availability
            </Button>
          </Form.Item>
        </div>
      </Form>
    </OwnModal>
  );
}

export default DeliveryModal;

import { App, Button, DatePicker, Form, Select, Space, Tabs } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { disabledDateBeforeToday } from 'utils/utils';
import TabAppraisals from './services/TabAppraisals';
import TabSkills from './services/TabSkills';
import { useDepartments } from 'hooks/human-resourses/useDepartment';
import { createAppraisal, getAppraisal, updateAppraisal } from 'network/Human-resources/appraisal';
import useAppraisalQuestionList from 'hooks/api/useAppraisalQuestionList';

function AppraisalNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();
  const { appraisalQuestionList, appraisalQuestionListLod } = useAppraisalQuestionList();
  console.log('TabAppraisals  appraisalQuestionList:', appraisalQuestionList);
  console.log('AppraisalNew  metadata?.employees:', metadata);
  const { departmentData } = useDepartments();
  // console.log('AppraisalNew  departmentData:', departmentData);

  const client = useQueryClient();
  const { data: appraisalData } = useQuery([queryKeys.appraislList, params?.id], () => getAppraisal(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('appraisalData', appraisalData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.appraislList]);

    form.resetFields();
    navigate('/human-resources/appraisals');
  };

  const { mutate: onCreateAppraisal, isLoading: onCreateAppraisalLod } = useMutation(createAppraisal, {
    onSuccess,
  });
  const { mutate: onUpdateAppraisal, isLoading: onUpdateAppraisalLod } = useMutation(updateAppraisal, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && appraisalData) {
      const editItem = appraisalData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['appraisalDate'] = dayjs(dataObj['appraisalDate']);
      console.log('useEffect  dataObj:', dataObj);
      // dataObj.employeeAppraisalAnswers = [
      //   {
      //     question: 'What do you know about us?',
      //     questionTypeId: 1,
      //     answer: '1',
      //   },
      //   {
      //     question: 'What do you think about ERP?',
      //     questionTypeId: 2,
      //     answer: '2',
      //   },
      //   {
      //     question: 'Erin Sparks',
      //     questionTypeId: 5,
      //     answer: '3',
      //   },
      // ];

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, appraisalData, form, appraisalQuestionList]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['appraisalDate'] = dayjs(dataObj.appraisalDate).format('YYYY-MM-DD');
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = appraisalData?.model?.id;
      return onUpdateAppraisal(dataObj);
    }

    onCreateAppraisal(dataObj);
  };

  const items = [
    {
      key: 'Appraisal',
      label: 'Appraisal',
      children: <TabAppraisals appraisalData={appraisalData?.model} appraisalQuestionList={appraisalQuestionList} />,
      forceRender: true,
    },
    {
      key: 'Skills',
      label: 'Skills',
      children: <TabSkills appraisalData={appraisalData?.model} />,
      forceRender: true,
    },
  ];

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: <Link to="/human-resources/appraisals">Appraisals</Link> }]}
        current={isEdit ? 'Details' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="employeeId" label="Employee" rules={[{ required: true }]}>
              <Select
                // options={metadata?.employees?.map(el => ({
                //   value: el?.id,
                //   label: el?.name,
                // }))}
                options={[{ label: 'Wagdi Engine', value: 'aebdbdf2-f872-4bdb-9a16-08dbf372011a' }]}
              />
            </Form.Item>
            <Form.Item name="managerId" label="Manager" rules={[{ required: true }]}>
              <Select
                options={[{ label: 'Test man', value: '5f9b22aa-f007-4e4b-9a17-08dbf372011a' }]}
                // options={[
                //   {
                //     label: 'EC1',
                //     value: 1,
                //   },
                //   {
                //     label: 'EC2',
                //     value: 2,
                //   },
                //   {
                //     label: 'EC3',
                //     value: 3,
                //   },
                // ]}
              />
            </Form.Item>
            <Form.Item name="departmentId" label="Department" rules={[{ required: true }]}>
              <Select
                options={departmentData?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="appraisalDate" label="Appraisal Date" rules={[{ required: true }]}>
              <DatePicker disabledDate={disabledDateBeforeToday} />
            </Form.Item>
          </div>
        </div>
        <div className="bg-white py-8 px-8 rounded-lg">
          <Tabs items={items} type="card" />
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateAppraisalLod || onUpdateAppraisalLod}>
            {isEdit ? 'Update' : 'Complete'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default AppraisalNew;

import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import useModalParams from "hooks/useModalParams";
import useMetaData from "hooks/api/useMetaData";
import { useUnitOfMeasures } from "hooks/api/projects-management/setup/unitOfMeasures";
import {
  useCreateExpenditureType,
  useExpenditureType,
  useUpdateExpenditureType,
} from "hooks/api/projects-management/setup/expenditureType";
import OwnModalLoader from "components/own/OwnModalLoader";

function ExpenditureTypeModal() {
  const { close, isNew, isEdit, id } = useModalParams("expenditure-type");
  const { projectCategories } = useMetaData();
  const { uoms } = useUnitOfMeasures();
  const { create, isCreating } = useCreateExpenditureType();
  const { update, isUpdating } = useUpdateExpenditureType(id);
  const { type, isFetching } = useExpenditureType(id);

  const handleOnFinish = (values) => {
    if (isNew) {
      create(values, {
        onSuccess: () => {
          message.success("Created expenditure type successfully");
          close();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      });
    } else {
      values.id = id;
      update(values, {
        onSuccess: () => {
          message.success("Updated expenditure type successfully");
          close("id");
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      });
    }
  };

  return (
    <OwnModal
      title={isNew ? "Create Expenditure Type" : "Edit Expenditure Type"}
      open={isNew || isEdit}
      onCancel={() => close("id")}
      width={570}
    >
      {isFetching ? <OwnModalLoader /> : (
        <Form
          layout="vertical"
          onFinish={handleOnFinish}
          initialValues={isEdit ? type : null}
        >
          <div className="py-4">
            <Form.Item label="Name" name="name">
              <Input />
            </Form.Item>

            <Form.Item label="Expenditure Category" name="projectCategoryId">
              <Select
                options={projectCategories?.map((category) => ({
                  label: category?.nameEN,
                  value: category?.id,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Units of Measurements"
              name="projectUnitOfMeasurementId"
            >
              <Select
                options={uoms?.map((uom) => ({
                  label: uom?.nameEN,
                  value: uom?.id,
                }))}
              />
            </Form.Item>
          </div>
          <Form.Item className="my-0 py-0">
            <Button
              loading={isCreating || isUpdating}
              disabled={isCreating || isUpdating}
              htmlType="submit"
              type="primary"
              size="large"
              block
            >
              {isNew ? "Create" : "Update"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </OwnModal>
  );
}

export default ExpenditureTypeModal;

import { useQuery } from '@tanstack/react-query';
import { getAllStructureTypes } from 'network/Human-resources/salary-structure-types';
import { queryKeys } from 'services/react-query/queryKeys';

function useSalaryStructureTypeList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.salaryStructureTypeList, searchQueryStr], () => getAllStructureTypes(searchQueryStr));

  const salaryStructureTypeList = data?.model;

  return {
    salaryStructureTypeList,
    salaryStructureTypeListLod: isLoading,
  };
}

export default useSalaryStructureTypeList;

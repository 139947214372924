import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Select,
  Radio,
  Table,
  Space,
  App,
} from "antd";
import OwnModal from "components/own/OwnModal";
import useMetaData from "hooks/api/useMetaData";
import { useCreateCategorie } from "hooks/products/useCategoryProduct";
import { addCategories } from "network/product-categories/categoriesProduct";
import { useState } from "react";
export default function ModalCategoriesProduct(props) {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { inventoryValuations, forceRemovalStrategies } = useMetaData();
  const { createCategorie, isCreatingCategorie } = useCreateCategorie();
  const onFinish = (values) => {
    createCategorie(
      {
        ...values,
         incomeAccountId: "3711f77d-37f0-4bc8-867d-ff8c4b924bc2",
         expenseAccountId: "091f9328-7143-44ee-a52b-a75b8fdc244c",
      },
      {
        onSuccess: () => {
          message.success("Created successfully");
          props.onCancel();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      }
    );
  };

  return (
    <OwnModal title="Add Product" {...props}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="grid md:grid-cols-2  gap-9">
          <Form.Item
            label="Category Name"
            name="nameEN"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Income Account"
            name="incomeAccountId"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Expense Account"
            name="expenseAccountId"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Force Removal Strategy"
            name="forceRemovalStrategyId"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              // placeholder="Select a person"
              //   defaultValue="filo"
              //   onChange={removaleStrategyChange}
              options={forceRemovalStrategies?.map((item) => ({
                label: item?.nameEN,
                value: item?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Inventory Valuation"
            name="inventoryValuationId"
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              //   defaultValue="minPrice"
              //   onChange={inventoryValuationChange}
              options={inventoryValuations?.map((item) => ({
                label: item?.nameEN,
                value: item?.id,
              }))}
            />
          </Form.Item>
        </div>

        <div className="btn-wrraper mt-4 text-center ">
          <Button
            type="primary"
            htmlType="submit"
            className="block m-auto  w-full max-w-xs"
            loading={isCreatingCategorie}
          >
            Submit
          </Button>
        </div>
      </Form>
    </OwnModal>
  );
}

import { useQuery } from "@tanstack/react-query";
import useMetaData from "hooks/api/useMetaData";
import { getWarehouseLocations } from "network/supply-chain/warehouseLocation";
import { queryKeys } from "services/react-query/queryKeys";

const useWarehouseLocations = () => {
  const { locationTypes } = useMetaData();

  const query = useQuery({
    queryFn: getWarehouseLocations,
    enabled: Boolean(locationTypes),
    queryKey: [queryKeys.warehouseLocation],
    select: (data) =>
      data?.model.map((location) => ({
        ...location,
        locationType: locationTypes?.filter((type) =>
          type?.id === location?.locationTypeId
        )[0],
      })),
  });

  query.locations = query.data;
  return query;
};

export default useWarehouseLocations;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Space, TreeSelect } from 'antd';
import OwnModal from 'components/own/OwnModal';
import React, { useState } from 'react';
import ModalInvoiceSummary from './ModalInvoiceSummary';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import ModalAddRecurringInvoices from './recurreningModals/ModalAddRecurringInvoices';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'services/react-query/queryKeys';
import { createAPSpecialCalender, updateAPSpecialCalender } from 'network/account-payable/special-calenders';
import { disabledDateBeforeToday } from 'utils/utils';
import constents from 'utils/constants';

function ModalAddSpecialCalender({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();
  const periodsNumber = Form.useWatch('Periods', form);

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.APSpecialCalenderList]);

    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateAPSpecialCalender, isLoading: createAPSpecialCalenderLod } = useMutation(createAPSpecialCalender, { onSuccess });
  const { mutate: onUpdateAPSpecialCalender, isLoading: updateAPSpecialCalenderLod } = useMutation(updateAPSpecialCalender, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj.names = dataObj.periods?.map(el => ({
        ...el,
        dueDate: dayjs(el?.dueDate),
        month: dayjs(el?.month),
        year: dayjs(el?.year),
        fromTo: [dayjs(el?.fromDate), dayjs(el?.toDate)],
      }));
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    dataObj.periods = dataObj?.names?.map(el => {
      const item = {
        id: el?.id,
        sequence: el?.sequence,
        systemName: el?.systemName,
        dueDate: dayjs(el?.dueDate).format(constents.dateFormat),
        month: dayjs(el?.month).format('YYYY-MM'),
        year: dayjs(el?.year).format('YYYY'),
        fromDate: dayjs(el?.['fromTo'][0]).format(constents.dateFormat),
        toDate: dayjs(el?.['fromTo'][1]).format(constents.dateFormat),
      };
      if (!item.id) delete item.id;
      return item;
    });

    delete dataObj.names;

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateAPSpecialCalender(dataObj);
    } else {
      onCreateAPSpecialCalender(dataObj);
    }
  };

  return (
    <>
      <OwnModal
        title={editItem ? 'Edit Special Calender' : 'Add Special Calender'}
        open={open}
        onCancel={onCancel}
        // width={"80%"}
      >
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [''] }}>
          <div className="col-2">
            <Form.Item name="name" label="Name">
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>

          {/* <Divider />
        <div className="col-1">
          <h4 className="text-3xl font-medium text-[#424242]">
            Invoice Actions
          </h4>
        </div> */}
          <Divider>
            <h4 className="text-3xl font-medium text-[#424242]">Period</h4>
          </Divider>
          <Form.List name="names">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key + Math.random()}>
                      <div className="col-3 p-3 bg-gray-50 rounded-lg">
                        <Form.Item hidden name={[index, 'id']}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'sequence']} label="Sequence" rules={[{ required: true }]}>
                          <InputNumber className="w-full" />
                        </Form.Item>
                        <Form.Item name={[index, 'systemName']} label="System Name" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'dueDate']} label="Due Date" rules={[{ required: true }]}>
                          <DatePicker />
                        </Form.Item>
                        <Form.Item name={[index, 'month']} label="Month" rules={[{ required: true }]}>
                          <DatePicker.MonthPicker />
                        </Form.Item>
                        <Form.Item name={[index, 'year']} label="Year" rules={[{ required: true }]}>
                          <DatePicker.YearPicker />
                        </Form.Item>
                        <Form.Item name={[index, 'fromTo']} label="From - To" rules={[{ required: true }]}>
                          <DatePicker.RangePicker
                            className="w-full"
                            format="YYYY-MM-DD"
                            disabledDate={current => {
                              // Can not select days before today and today
                              return current && current < dayjs().endOf('day');
                            }}
                          />
                        </Form.Item>
                      </div>

                      {fields.length > 1 ? (
                        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                          Remove Above Field
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
          <Space className="flex justify-end mt-4">
            <Button loading={createAPSpecialCalenderLod || updateAPSpecialCalenderLod} type="primary" htmlType="submit" className="w-56">
              {editItem ? 'UPDATE' : 'ADD'}
            </Button>
          </Space>
        </Form>
      </OwnModal>
    </>
  );
}

export default ModalAddSpecialCalender;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllCustomers = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Customers?${searchQuery}`);
  return data;
});

export const getCustomer = catchAsync(async id => {
  const { data } = await axiosInstance(`/Customers/${id}`);
  return data;
});

export const createCustomer = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Customers`, body);
  return data;
});

export const updateCustomer = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Customers`, body);
  return data;
});

export const deleteCustomer = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Customers/${id}`);
  return data;
});

export const searchCustomer = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Customers/Search`, body);
  return data;
});

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useFixedAssetSegmentList from 'hooks/api/useFixedAssetSegmentList';
import { createFixedAsset, getFixedAsset, updateFixedAsset } from 'network/fixed-assets/fixedAssets';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';

function AssetWorkbenchNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();

  const { message } = App.useApp();
  const [form] = Form.useForm();
  // const { currencyList } = useCurrencyList();
  // const { customerList } = useCustomerList();
  // const isDepreciation = Form.useWatch('depreciation', form);

  // const metadata = useMetaData();
  const { fixedAssetSegmentList } = useFixedAssetSegmentList();

  const client = useQueryClient();
  const { data: fixedAssetData } = useQuery([queryKeys.receiptList, params?.id], () => getFixedAsset(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('fixedAssetData', fixedAssetData);

  const onSuccess = res => {
    console.log('createReceipt  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.fixedAssetList]);

    form.resetFields();
    navigate('/fixed-assets/asset-workbench');
  };

  const { mutate: onCreateFixedAsset, isLoading: createFixedAssetLod } = useMutation(createFixedAsset, { onSuccess });
  const { mutate: onUpdateFixedAsset, isLoading: updateFixedAssetLod } = useMutation(updateFixedAsset, { onSuccess });

  useEffect(() => {
    if (isEdit && fixedAssetData) {
      // console.log('useEffect  editItem:', editItem);
      const editItem = fixedAssetData?.model || {};
      const dataObj = { ...editItem };
      dataObj.names = editItem?.fixedAssetEmployees;
      delete dataObj?.fixedAssetEmployees;

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, fixedAssetData, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj.fixedAssetEmployees = values.names?.map(el => ({
      id: el?.id,
      units: el?.units,
      expenseAccount: el?.expenseAccount,
      employeeId: el?.employeeId,
      fixedAssetSegmentId: el?.fixedAssetSegmentId,
    }));
    delete dataObj.names;

    dataObj['isInUse'] = !!dataObj['isInUse'];
    dataObj['isInPhysicalInventory'] = !!dataObj['isInPhysicalInventory'];

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = fixedAssetData?.model?.id;
      return onUpdateFixedAsset(dataObj);
    }

    onCreateFixedAsset(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: <Link to="/fixed-assets/asset-workbench">Assets Workbench</Link> }]}
        current={`${isEdit ? 'Edit' : 'New'} Asset Workbench`}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [''] }} className="space-y-6">
        <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
          <Divider>
            <h2 className="title-24">Asset information</h2>
          </Divider>
          <div className="col-2">
            <Form.Item name="number" label="Asset Number">
              <Input />
            </Form.Item>
            <Form.Item name="name" label="Asset Name">
              <Input />
            </Form.Item>
            <Form.Item name="tagNumber" label="Tag Number">
              <Input />
            </Form.Item>
            <Form.Item name="serialNumber" label="Serial Number">
              <Input />
            </Form.Item>
            <Form.Item name="type" label="Asset Type">
              <Select
                options={[
                  { value: 0, label: 'Capitalize' },
                  { value: 1, label: 'CIP' },
                ]}
              />
            </Form.Item>
            <Form.Item name="manufacturer" label="Manu facturer">
              <Input />
            </Form.Item>
            <Form.Item name="warrantyNumber" label="Warranty Number">
              <Input />
            </Form.Item>

            {/* <Form.Item name="major Category" label="Major Category">
                <Select loading />
              </Form.Item>
              <Form.Item name="Minor Category" label="Minor Category">
                <Select loading />
              </Form.Item> */}
            <Form.Item name="units" label="Number of units">
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
            <Form.Item name="bookId" label="Book">
              <Select
                loading
                options={[
                  { value: 0, label: 'Owned' },
                  { value: 1, label: 'leased' },
                ]}
              />
            </Form.Item>
            <Form.Item name="ownership" label="Ownership">
              <Select
                options={[
                  { value: 0, label: 'Owned' },
                  { value: 1, label: 'leased' },
                ]}
              />
            </Form.Item>
            <Form.Item name="bought" label="Bought">
              <Select
                options={[
                  { value: 0, label: 'Used' },
                  { value: 1, label: 'New' },
                ]}
              />
            </Form.Item>
            <Form.Item name="isInUse" valuePropName="checked">
              <Checkbox>In Use</Checkbox>
            </Form.Item>
            <Form.Item name="isInPhysicalInventory" valuePropName="checked">
              <Checkbox>In Physical Inventory</Checkbox>
            </Form.Item>
          </div>
        </div>
        <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
          <Divider>
            <h2 className="title-24">Financial information</h2>
          </Divider>
          <div className="col-2">
            <Form.Item name="originalCost" label="Asset cost">
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item name="ytdDepreciation" label="YTD Depreciation">
              <InputNumber className="w-full" />
            </Form.Item>

            {/* <Form.Item name="depreciation" valuePropName="checked">
                <Checkbox>Depreciation</Checkbox>
              </Form.Item>
              {!!isDepreciation && (
                <>
                  <Form.Item name="Method" label="Method" className="col-span-ful">
                    <Select loading />
                  </Form.Item>
                  <Form.Item label="Life years">
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item label="Months">
                    <Input readOnly />
                  </Form.Item>
                  <Form.Item name="Date in service" label="Date in service">
                    <DatePicker />
                  </Form.Item>
                  <Form.Item name="Prorate convention" label="Prorate convention">
                    <Select loading />
                  </Form.Item>
                  <Form.Item name="Prorate Date" label="Prorate Date">
                    <Select loading />
                  </Form.Item>
                </>
              )} */}
          </div>
        </div>
        <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
          <Divider>
            <h2 className="title-24">Employee</h2>
          </Divider>

          <Form.List name="names">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <div className="bg-gray-50 p-3 rounded items-end">
                        <div className="col-4">
                          <Form.Item name={[index, 'id']} hidden>
                            <Input />
                          </Form.Item>
                          <Form.Item name={[index, 'units']} label="Units">
                            <InputNumber className="w-full" />
                          </Form.Item>
                          <Form.Item name={[index, 'employeeId']} label="Employee">
                            {/* <Select loading /> */}
                            <Select options={fixedAssetSegmentList?.map(s => ({ value: s?.id, label: s?.title }))} />
                          </Form.Item>
                          <Form.Item name={[index, 'expenseAccount']} label="Expense account">
                            <Input />
                          </Form.Item>
                          <Form.Item name={[index, 'fixedAssetSegmentId']} label="fixed Asset Segment">
                            <Select options={fixedAssetSegmentList?.map(s => ({ value: s?.id, label: s?.title }))} />
                          </Form.Item>
                        </div>
                      </div>
                      {fields.length > 1 ? (
                        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                          Remove Above Field
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Form.Item>
                      <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                    </Form.Item>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createFixedAssetLod || updateFixedAssetLod}>
            {isEdit ? 'Update' : 'Confirm'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default AssetWorkbenchNew;

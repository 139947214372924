import { useQuery } from '@tanstack/react-query';
import { getAllAppraisalQuestions } from 'network/Human-resources/appraisalQuestions';
import { queryKeys } from 'services/react-query/queryKeys';

function useAppraisalQuestionList(searchQueryStr) {
  const { data, isInitialLoading } = useQuery([queryKeys.appraisalQuestionList, searchQueryStr], () => getAllAppraisalQuestions(searchQueryStr));

  const appraisalQuestionList = data?.model;

  return {
    appraisalQuestionList,
    appraisalQuestionListLod: isInitialLoading,
  };
}

export default useAppraisalQuestionList;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";


export const getJobApplication = catchAsync(async (id) => {
    const { data } = await axiosInstance.get("/JobApplications", {
      params: {
        id,
      },
    });
    return data;
  });
  
  export const addJobApplication = catchAsync(async (body) => {
    const res = await axiosInstance.post("/JobApplications", body);
    return res;
  });
  
  export const updateJobApplication = catchAsync(async (body) => {
    const res = await axiosInstance.put("/JobApplications", body);
    return res;
  });
  
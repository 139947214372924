import catchAsync from "utils/catchAsync";
import { axiosInstance } from "services/axiosInstance";

export const getAll = catchAsync(async (params) => {
  const { data } = await axiosInstance.get("/Projects", {
    params,
  });
  return data;
});

export const get = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/Projects/${id}`);
  return data;
});

export const create = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/Projects`, body);
  return data;
});

export const update = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/Projects`, body);
  return data;
});

export const delete_ = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/Projects/${id}`);
  return data;
});

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getDepartment } from "network/Human-resources/department";
import { addWorkingTimes, getWorkingTimes, updateWorkingTimes } from "network/Human-resources/work-times";
import { queryKeys } from "services/react-query/queryKeys";

export const useWorkingTimes = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.workTimes, id],
    queryFn: () => getWorkingTimes(id),
  });
  const workingTimesData = data?.model;
  return { workingTimesData, ...rest };
};

export const useCreateWorkTimes = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addWorkingTimes,
    onSuccess:()=>client.invalidateQueries([queryKeys.workTimes])
  });
  mutation.createWorkTimes = mutation.mutate;
  mutation.isCreatingWorkTimes = mutation.isLoading;
  return mutation;
};
export const useUpdateWorkTimes = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateWorkingTimes,
    onSuccess:()=>client.invalidateQueries([queryKeys.workTimes])
  });
  mutation.updateWorkTimes = mutation.mutate;
  mutation.isUpdatingWorkTimes = mutation.isLoading;
  return mutation;
};
import { MoreOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Dropdown, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalCalender from 'components/modals/ModalCalender';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useSearchQuery from 'hooks/useSearchQuery';
import { deleteCalendar, getAllCalendars } from 'network/general-ledger/calendar';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function Calendar() {
  const { message } = App.useApp();
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const client = useQueryClient();

  const { data: calendarData } = useQuery([queryKeys.calendarList, searchQueryStr], () => getAllCalendars(searchQueryStr));
  const { mutate: onDeleteCalendar } = useMutation(deleteCalendar, {
    onSuccess: res => {
      console.log('Calendar  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.calendarList]);
    },
  });
  const data = calendarData?.model;
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const columns = [
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'periodsPerYear',
      dataIndex: 'periodsPerYear',
      title: 'periods per year',
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteCalendar(record?.id),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Calendar" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} searchKey="word" />
        <Table
          dataSource={data}
          sticky
          columns={!!data?.length ? columns : []}
          // pagination={{
          //   pageSize: 1,
          //   current: +searchQueryObj.page || 1,
          //   onChange: page => {
          //     const queryObj = { ...searchQueryObj };
          //     queryObj['page'] = page;
          //     setSearchQuery(queryObj);
          //   },
          // }}
        />
      </div>
      <ModalCalender
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  );
}

export default Calendar;

import { App, Button, DatePicker, Form, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContract, getContract, updateContract } from 'network/Human-resources/contracts';
import { useDepartments } from 'hooks/human-resourses/useDepartment';
import constents from 'utils/constants';

function ContractNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();
  const { departmentData } = useDepartments();

  const client = useQueryClient();
  const { data: cusromerData } = useQuery([queryKeys.contractList, params?.id], () => getContract(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.contractList]);

    form.resetFields();
    navigate('/human-resources/payroll/contracts');
  };

  const { mutate: onCreateContract, isLoading: onCreateContractLod } = useMutation(createContract, {
    onSuccess,
  });
  const { mutate: onUpdateContract, isLoading: onUpdateContractLod } = useMutation(updateContract, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && cusromerData) {
      const editItem = cusromerData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['startDate']), dayjs(dataObj['endDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, cusromerData, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['startDate'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['endDate'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = cusromerData?.model?.id;
      return onUpdateContract(dataObj);
    }

    onCreateContract(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Payroll' }, { title: <Link to="/human-resources/payroll/work-entry/Contracts">Contracts</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="employeeId" label="Employee" rules={[{ required: true }]}>
              <Select
                // options={metadata?.employees?.map(el => ({
                //   value: el?.id,
                //   label: el?.name,
                // }))}
                options={[{ label: 'Wagdi Engine', value: 'aebdbdf2-f872-4bdb-9a16-08dbf372011a' }]}
              />
            </Form.Item>{' '}
            <Form.Item name="jobPosition" label="Job Position" rules={[{ required: true }]}>
              <Select
                options={metadata?.jobs?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="departmentId" label="Department" rules={[{ required: true }]}>
              <Select
                options={departmentData?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="workEntrySource" label="Work Entry Source" rules={[{ required: true }]}>
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item name="wage" label="Wage" rules={[{ required: true }]}>
              <Select
                options={metadata?.wageTypes?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="workingTimeId" label="Working Time" rules={[{ required: true }]}>
              <Select
                options={[{ label: 'need list', value: 'aebdbdf2-f872-4bdb-9a16-08dbf372011a' }]}

                // options={departmentData?.map(e => ({
                //   label: e?.nameEN,
                //   value: e?.id,
                // }))}
              />
            </Form.Item>
            <Form.Item name="fromTo" label="From - To" rules={[{ required: true }]}>
              <DatePicker.RangePicker
                className="w-full"
                format="YYYY-MM-DD"
                disabledDate={current => {
                  // Can not select days before today and today
                  return current && current < dayjs().endOf('day');
                }}
              />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateContractLod || onUpdateContractLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default ContractNew;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createWarehouse } from "network/supply-chain/warehouse";
import { queryKeys } from "services/react-query/queryKeys";

const useCreateWarehouse = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: createWarehouse,
    onSuccess: () => client.invalidateQueries([queryKeys.warehouse]),
  });

  mutation.create = mutation.mutate;
  mutation.isCreating = mutation.isLoading;
  return mutation;
};

export default useCreateWarehouse;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getWarehouse = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/Warehouse/${id}`);
  return data;
});

export const getWarehouseByName = catchAsync(async (name) => {
  const { data } = await axiosInstance.get(`Warehouse/getbyname/${name}`);
  return data;
});

export const getWarehouses = catchAsync(async () => {
  const { data } = await axiosInstance.get("/Warehouse");
  return data;
});

export const createWarehouse = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/Warehouse", body);
  return data;
});

import { FolderAddOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  Col,
  Row,
  Radio,
  DatePicker,
  message,
} from "antd";
import OwnModal from "components/own/OwnModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import FormListOrders from "./FormListOrders";
import { useCreateVendorPriceList } from "hooks/api/supply-chain/vendor-priceList/useVendorPriceList";
import useMetaData from "hooks/api/useMetaData";
import { useCreatePurchasingRequest } from "hooks/api/supply-chain/purchasing/purchasingRequest";
import useSupplierList from "hooks/api/useSupplierList";
import useCustomerList from "hooks/api/useCustomerList";
import { useCreatePurchasingOrder } from "hooks/api/supply-chain/purchasing/purchasingOrder";

export default function ModalRequestForQutation(props) {
  const [form] = Form.useForm();
  const { mutate: mutatePurchasingRequest, isLoading } =
    useCreatePurchasingRequest();
  const { mutate: mutatePurchasingOrder, isLoading: isLoadingPurchasingOrder } =
    useCreatePurchasingOrder();
  const { supplierTypes } = useMetaData();
  const { supplierList } = useSupplierList();
  const { customerList } = useCustomerList();
  console.log(supplierList, "supplierList");
  const [vendor, setVendor] = useState(1);
  const [deliver, setdeliver] = useState(1);
  const [productType, setProductType] = useState(1);
  const [account, setaccount] = useState(1);
console.log(props?.order ,"fkfkfkfkf");
  const vendorChange = (value) => {
    console.log("search:", value);
    setVendor(value);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const deliverChange = (value) => {
    console.log("search:", value);
    setdeliver(value);
  };

  const productTypeChange = (value) => {
    console.log("search:", value);
    setProductType(value);
  };

  const onFinish = (values) => {
    for (let index = 0; index < values?.lines.length; index++) {
      const line = values?.lines[index];
      line.subTotal = +line.unitPrice * +line.quantity;
    }
    const purchasingRequestOrder = { ...values, lines: values?.lines };
    if (props?.order === "purchasingOrder") {
      mutatePurchasingOrder(purchasingRequestOrder, {
        onSuccess: (res) => {
          console.log(res, "kskdk");
          if (res?.status == 200) {
            message.success(res?.data?.metas?.message);
            props.onCancel();
          } else {
            console.log(res, "errorss");
            message.error(res?.errors?.errors[0]?.errorMessage);
            // props.onCancel();
          }
        },
        onError: (error) => {
          message.error();
        },
      });
    } else {
      mutatePurchasingRequest(purchasingRequestOrder, {
        onSuccess: (res) => {
          console.log(res, "kskdk");
          if (res?.status == 200) {
            message.success(res?.data?.metas?.message);
            props.onCancel();
          } else {
            console.log(res, "errorss");
            message.error(res?.errors?.errors[0]?.errorMessage);
            // props.onCancel();
          }
        },
        onError: (error) => {
          message.error("eeee");
        },
      });
    }
  };

  return (
    <OwnModal
      title={
        props?.order === "request"? "Purchasing Order" : "Request For Quotation"
      }
      {...props}
    >
      <div className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <div className="col-2">
              <Form.Item
                name="supplierId"
                label="Supplier"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  options={supplierList?.map((s) => ({
                    value: s?.id,
                    label: s?.name,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="nameEN"
                label="Name"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="orderDeadline"
                label="Order Deadline"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item
                name="expectedArrival"
                label="Expected Arrival"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker disabledDate={disabledDate} />
              </Form.Item>
              {/* <Form.Item
                name="deliver_to"
                label="Deliver To"
                // required
                // rules={[
                //   {
                //     required: true,
                //   },
                // ]}
              >
                <Select
                  // placeholder="Select a person"
                  defaultValue="deliver one"
                  onChange={deliverChange}
                  options={[
                    {
                      label: "deliver one",
                      value: "deliver one",
                    },
                    {
                      label: "deliver two",
                      value: "deliver two",
                    },
                  ]}
                />
              </Form.Item> */}
              <Form.Item
                name="notes"
                label="Notes"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="total"
                label="Total"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {props.order == "request" && (
                <Form.Item
                  name="inventory_account"
                  label="Inventory Account"
                  // required
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </div>
            <FormListOrders order={props.order} />

            <Button
              loading={isLoading||isLoadingPurchasingOrder}
              type="primary"
              className="mt-4"
              htmlType="submit"
              // onClick={onAddNewLine}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </OwnModal>
  );
}

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getVendorPriceList = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/VendorPriceList`);
  return data;
});

export const addVendorPriceList = catchAsync(async (body) => {
  const res = await axiosInstance.post(`/VendorPriceList`, body);
  return res;
});

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Space, TreeSelect } from 'antd';
import OwnModal from 'components/own/OwnModal';
import dayjs from 'dayjs';
import useCurrencyList from 'hooks/api/useCurrencyList';
import useMetaData from 'hooks/api/useMetaData';
import useSupplierList from 'hooks/api/useSupplierList';
import { createAPInvoice, updateAPInvoice } from 'network/account-payable/invoices';
import React, { useEffect, useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ModalAddPaybleInvoice({ open, onCancel, editItem, setEditItem }) {
  const { currencyList } = useCurrencyList();
  const { supplierList } = useSupplierList();
  const metadata = useMetaData();

  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.APInvoicesList]);

    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateAPInvoice, isLoading: createAPInvoiceLod } = useMutation(createAPInvoice, { onSuccess });
  const { mutate: onUpdateAPInvoice, isLoading: updateAPInvoiceLod } = useMutation(updateAPInvoice, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj.invoiceDate = dayjs(dataObj.invoiceDate);
      dataObj.names = dataObj.invoiceLines;
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    dataObj.invoiceDate = dayjs(dataObj?.invoiceDate).format(constents.dateFormat);
    dataObj.invoiceLines = dataObj?.names?.map(el => {
      const item = {
        id: el?.id,
        lineType: el?.lineType,
        amount: el?.amount,
        poNumber: el?.poNumber,
        poRelease: el?.poRelease,
        poShipment: el?.poShipment,
        poLine: el?.poLine,
        distributionAccount: el?.distributionAccount,
        isTrackAsAsset: !!el?.isTrackAsAsset,
      };
      if (!item.id) delete item.id;
      return item;
    });

    delete dataObj.names;

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateAPInvoice(dataObj);
    } else {
      onCreateAPInvoice(dataObj);
    }
  };

  return (
    <>
      <OwnModal title={editItem ? 'Edit Invoice' : 'Add Invoice'} open={open} onCancel={onCancel}>
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [''] }}>
          <div className="col-2">
            <Form.Item name="invoiceNumber" label="invoiceNumber">
              <Input />
            </Form.Item>
            <Form.Item name="poNumber" label="poNumber">
              <Input />
            </Form.Item>
            <Form.Item name="rate" label="rate">
              <Input />
            </Form.Item>
            <Form.Item name="invoiceDate" label="invoiceDate">
              <DatePicker />
            </Form.Item>
            <Form.Item name="amount" label="amount">
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item name="currencyId" label="Currency" rules={[{ required: true }]}>
              <Select options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item name="supplierId" label="Supplier" rules={[{ required: true }]}>
              <Select options={supplierList?.map(s => ({ value: s?.id, label: s?.name }))} />
            </Form.Item>
            <Form.Item name="invoiceTypeId" label="Invoice Type" rules={[{ required: true }]}>
              <Select options={metadata?.salesInvoiceTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item name="invoicePaymentMethodId" label="Payment Method" rules={[{ required: true }]}>
              <Select options={metadata?.supplierPaymentMethods?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
          </div>

          <Divider>
            <h4 className="text-3xl font-medium text-[#424242]">Lines</h4>
          </Divider>

          <Form.List name="names">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key + Math.random()}>
                      <div className="col-3 p-3 bg-gray-50 rounded-lg">
                        <Form.Item hidden name={[index, 'id']}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'lineType']} label="lineType" rules={[{ required: true }]}>
                          <Select options={metadata?.creditInvoiceLineTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                        </Form.Item>
                        <Form.Item name={[index, 'amount']} label="Amount" rules={[{ required: true }]}>
                          <InputNumber className="w-full" />
                        </Form.Item>
                        <Form.Item name={[index, 'poNumber']} label="Po Number" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'poRelease']} label="Po Release" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'poShipment']} label="Po Shipment" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'poLine']} label="Po Line" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'distributionAccount']} label="Distribution Account" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'isTrackAsAsset']} valuePropName="checked">
                          <Checkbox>Track As Asset</Checkbox>
                        </Form.Item>
                      </div>

                      {fields.length > 1 ? (
                        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                          Remove Above Field
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>

          <Space className="flex justify-end mt-4">
            <Button loading={createAPInvoiceLod || updateAPInvoiceLod} type="primary" htmlType="submit" className="w-56">
              {editItem ? 'UPDATE' : 'ADD'}
            </Button>
          </Space>
        </Form>
      </OwnModal>
      {/* <ModalInvoiceSummary open={iseModalSummary} onCancel={() => setIseModalSummary(false)} /> */}
    </>
  );
}

export default ModalAddPaybleInvoice;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { queryKeys } from 'services/react-query/queryKeys';
import { addBudgetRanges } from 'network/general-ledger/budget';
import useCurrencyList from 'hooks/api/useCurrencyList';

function ModalBudgitRanges({ budget, onCancel }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const metadata = useMetaData();
  console.log('ModalBudgitRanges  metadata:', metadata);
  console.log('ModalBudgitRanges  metadata?.balanceTypes:', metadata?.balanceTypes);

  const { currencyList, currencyListLod } = useCurrencyList();

  const { mutate: onAddBudgetRanges, isLoading: addBudgetRangesLod } = useMutation(addBudgetRanges, {
    onSuccess: res => {
      console.log('ModalBudgitRanges  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.budgetList]);

      form.resetFields();
      onCancel();
    },
  });
  const onFinish = values => {
    console.log('ModalBudgitRanges  values:', values);
    const ranges = values?.ranges?.map(el => ({
      accountHighPrefix: el.accountHighPrefix,
      accountLowPrefix: el.accountLowPrefix,
      currencyId: el.currencyId,
      lineNumber: el.lineNumber,
      typeId: el.typeId,
    }));

    const dataObj = { budgetId: budget?.id, ranges };
    console.log('onFinish  dataObj:', dataObj);

    onAddBudgetRanges(dataObj);
  };
  return (
    <OwnModal title={'Ranges'} open={!!budget} onCancel={onCancel} width={1100}>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ ranges: [''] }}>
        <Form.List name="ranges">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <div className="col-5 bg-gray-50 p-3 rounded">
                      <Form.Item name={[index, 'lineNumber']} label="Line" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[index, 'accountLowPrefix']} label="Low" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[index, 'accountHighPrefix']} label="High" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={[index, 'typeId']} label="Type" rules={[{ required: true }]}>
                        <Select options={metadata?.balanceTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                      </Form.Item>
                      <Form.Item name={[index, 'currencyId']} label="Currency" rules={[{ required: true }]}>
                        <Select
                          loading={currencyListLod}
                          options={currencyList?.map(el => ({
                            value: el?.id,
                            label: el?.nameEN,
                          }))}
                        />
                      </Form.Item>
                    </div>

                    {fields.length > 1 ? (
                      <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                        Remove Above Field
                      </Button>
                    ) : null}
                  </div>
                ))}
                <Divider />
                <Form.Item>
                  <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={addBudgetRangesLod}>
            Range assignments
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalBudgitRanges;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Switch, Table, App } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useSalaryStructureList from 'hooks/api/useSalaryStructureList';
import { deleteSalaryStructure } from 'network/Human-resources/salary-structure';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';

function Structure() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteStructure } = useMutation(deleteSalaryStructure, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.salaryStructureList]);
    },
  });

  const { salaryStructureList } = useSalaryStructureList();

  const columns = [
    {
      key: 'code',
      dataIndex: 'code',
      title: 'code',
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: 'title',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteStructure(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = salaryStructureList;

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: 'Human Resources' }, { title: 'Setup' }]} current="Structure" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table dataSource={data} sticky columns={columns} />
    </div>
  );
}

export default Structure;

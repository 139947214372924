import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import OwnModal from 'components/own/OwnModal';
import React, { useEffect } from 'react';

function ModalSegmentQualifiers({ open, onCancel, editItem, setEditItem }) {
  console.log('ModalSegmentQualifiers  editItem:', editItem);
  const [form] = Form.useForm();

  // useEffect(() => {
  //   if (!editItem) return;
  //   const dataObj = { ...editItem };

  //   dataObj.areaId = editItem?.area_id;
  //   dataObj.provinceId = editItem?.province_id;

  //   form.setFieldsValue(dataObj);
  // }, [editItem?.key]);

  const onFinish = values => {
    console.log('ModalSegmentQualifiers  values:', values);
  };
  return (
    <OwnModal
      // title={editItem ? 'Edit Segment' : 'Add Segment'}
      title={'Qualifiers'}
      open={open}
      onCancel={onCancel}
      width={600}
    >
      <Form
        layout="vertical"
        form={form}
        // size="large"
        onFinish={onFinish}
        // className="pt-4"
      >
        <Form.Item name="Qualifiers">
          <Radio.Group size="large">
            <Space direction="vertical">
              <Radio value="Cost center segment">Cost center segment</Radio>
              <Radio value="Natural account segment">
                Natural account segment
              </Radio>
              <Radio value="Balancing segment">Balancing segment</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Button
          // loading={addressAddLod || addressUpdateLod}
          type="primary"
          htmlType="submit"
          className="block ms-auto w-full max-w-xs"
        >
          DONE
        </Button>
      </Form>
    </OwnModal>
  );
}

export default ModalSegmentQualifiers;

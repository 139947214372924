import { App, Button, Checkbox, ColorPicker, Form, Input, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createWorkEntryType, getWorkEntryType, updateWorkEntryType } from 'network/Human-resources/work-entry-types';
import { css } from '@emotion/css';

function WorkEntryTypeNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const isTimeOff = Form.useWatch('timeOff', form);

  const metadata = useMetaData();

  const client = useQueryClient();
  const { data: workEntryType } = useQuery([queryKeys.workEntryType, params?.id], () => getWorkEntryType(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('WorkEntryType', WorkEntryType);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.workEntryTypeList]);

    form.resetFields();
    navigate('/human-resources/setup/work-entry/types');
  };

  const { mutate: onCreateWorkEntryType, isLoading: onCreateWorkEntryTypeLod } = useMutation(createWorkEntryType, {
    onSuccess,
  });
  const { mutate: onUpdateWorkEntryType, isLoading: onUpdateWorkEntryTypeLod } = useMutation(updateWorkEntryType, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && workEntryType) {
      const editItem = workEntryType?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['startDate']), dayjs(dataObj['endDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, workEntryType, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj.color = dataObj.color?.toHexString();
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = workEntryType?.model?.id;
      return onUpdateWorkEntryType(dataObj);
    }

    onCreateWorkEntryType(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[
          { title: 'Human Resources' },
          { title: 'Setup' },
          { title: 'Work entry' },
          { title: <Link to="/human-resources/setup/work-entry/types">Types</Link> },
        ]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="payrollCode" label="Payroll Code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="externalCode" label="External Code" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Space size={32} wrap>
              <Form.Item name="color" label="Color" rules={[{ required: true }]}>
                <ColorPicker
                  className={css`
                    .ant-color-picker-color-block {
                      width: 120px;
                    }
                  `}
                />
              </Form.Item>
              <Form.Item name="timeOff" label=" " valuePropName="checked">
                <Checkbox>Time Off</Checkbox>
              </Form.Item>
            </Space>
            {isTimeOff && (
              <Form.Item name="timeOffTypeId" label="Time Off Type" rules={[{ required: true }]}>
                <Select
                  options={metadata?.timeOffTypes?.map(e => ({
                    label: e?.nameEN,
                    value: e?.id,
                  }))}
                />
              </Form.Item>
            )}
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateWorkEntryTypeLod || onUpdateWorkEntryTypeLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default WorkEntryTypeNew;

import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { Button, Table, Tag } from "antd";
import ModalLocation from "components/modals/inventoryModals/warehouse/ModalLocation";
import useWarehouseLocations from "hooks/api/supply-chain/warehouseLocation/useWarehouseLocations";
import useSearchQuery from "hooks/useSearchQuery";

export default function LocationsWarehouse() {
  const { data } = useWarehouseLocations();
  const { setSearchQuery } = useSearchQuery();

  const addNewLocation = () => {
    setSearchQuery({ open: true, mode: "new" });
  };

  const editLocation = (id) => {
    setSearchQuery({ open: true, mode: "edit", id });
  };

  const columns = [
    {
      title: "Location name",
      dataIndex: "nameEN",
      key: "locationName",
    },
    {
      title: "Location Type",
      key: "locatinType",
      render: ({ locationType }) => locationType?.nameEN,
    },
    {
      title: "Scrap Location?",
      key: "isScrapLocation",
      render: ({ isScrapLocation }) => isScrapLocation ? "Yes" : "No",
    },
    {
      title: "Return Location?",
      key: "isReturnLocation",
      render: ({ isReturnLocation }) => isReturnLocation ? "Yes" : "No",
    },
    {
      title: "Replenish Location?",
      key: "isReplenishLocation",
      render: ({ isReplenishLocation }) => isReplenishLocation ? "Yes" : "No",
    },
    {
      title: "Actions",
      key: "action",
      render: ({ id }) => (
        <Button onClick={() => editLocation(id)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Location" />
        <SearchHero onAddBtn={addNewLocation} />
        <Table
          dataSource={data ?? []}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
          }}
        />
      </div>
      <ModalLocation />
    </>
  );
}

import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { App, Button, Form, Input, Popover, Select, Space, Tooltip } from 'antd';
import useMetaData from 'hooks/api/useMetaData';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchCustomer } from 'network/account-receivable/customer';
import { css } from '@emotion/css';

function CustomerSearch({ isfilterMood, setIsfilterMood, setFilterdCustomerList }) {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const metadata = useMetaData();

  const { mutate: onSearchCustomer, isLoading: searchCustomerLod } = useMutation(searchCustomer, {
    onSuccess: res => {
      console.log('createReceipt  res:', res);
      message.success(res?.metas?.message);
      setFilterdCustomerList(res?.model);
      setIsfilterMood(true);
      setOpen(false);
    },
  });

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    onSearchCustomer(dataObj);
  };

  const content = (
    <div className="w-[500px]">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        size="small"
        className={css`
          .ant-form-item {
            margin-bottom: 5px;
            .ant-form-item-label {
              padding-bottom: 0;
            }
          }
        `}>
        <div className="grid grid-cols-2 items-end gap-x-4">
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>

          <Form.Item name="dunsNumber" label="D-U-N-S Number">
            <Input />
          </Form.Item>
          <Form.Item name="organizationUrl" label="Web Site">
            <Input />
          </Form.Item>
          <Form.Item name="accountNumber" label="Account Number">
            <Input />
          </Form.Item>
          <Form.Item name="taxpayerId" label="Taxpayer ID">
            <Select loading />
          </Form.Item>
          <Form.Item name="customerStatusId" label="Stauts">
            <Select options={metadata?.customerStatuses?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
          </Form.Item>
        </div>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={searchCustomerLod}>
            Go
          </Button>
          <Button type="primary" ghost htmlType="reset">
            CLEAR
          </Button>
        </Space>
      </Form>
    </div>
  );

  if (isfilterMood)
    return (
      <Tooltip title="Clear Search">
        <Button type="primary" icon={<CloseOutlined />} onClick={() => setIsfilterMood(false)} />
      </Tooltip>
    );

  return (
    <Popover title="Advanced Search" content={content} placement="bottomLeft" open={open} onOpenChange={status => setOpen(status)} trigger="click">
      <Button type="primary" icon={<FilterOutlined />} />
    </Popover>
  );
}

export default CustomerSearch;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Switch, Table, App } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useCustomerList from 'hooks/api/useCustomerList';
import { deleteCustomer } from 'network/account-receivable/customer';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { dateFormat } from 'utils/utils';
import CustomerSearch from './CustomerSearch';

function Customers() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteCustomer } = useMutation(deleteCustomer, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.customerList]);
    },
  });

  const { customerList } = useCustomerList();
  const [filterdCustomerList, setFilterdCustomerList] = useState([]);
  const [isfilterMood, setIsfilterMood] = useState(false);

  const columns = [
    {
      key: 'number',
      dataIndex: 'number',
      title: 'number',
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'contact',
      dataIndex: 'contact',
      title: 'contact',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteCustomer(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = isfilterMood ? filterdCustomerList : customerList;

  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Customers" />
      <SearchHero onAddBtn={() => navigate('new')} extra={<CustomerSearch setFilterdCustomerList={setFilterdCustomerList} isfilterMood={isfilterMood} setIsfilterMood={setIsfilterMood} />} />
      <Table dataSource={data} sticky columns={columns} />
    </div>
  );
}

export default Customers;

import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
import OwnLayout from 'components/layout/OwnLayout';
import OwnNotFound from 'components/own/OwnNotFound';
import Home from 'pages/Home';
import routesAccountReceivable from './routesAccountReceivable';
import routesGeneralLedger from './routesGeneralLedger';
import routesAuthentication from './routesAuthentication';
import routesAccountPayable from './routesAccountPayable';
import routesCashManagement from './routesCashManagement';
import routesTaxes from './routesTaxes';
import routesFixedAssets from './routesFixedAssets';
import routesInventory from './routesInventory';
import routesOrders from './routesOrder';
import routesProjectManagment from './routesProjectManagment';
import { getStoredToken } from 'services/user-storage';
import routesHumanResources from './routesHumanResources';
import routesCRM from './routesCRM';

function RoutesWrapper() {
  // const [authenticated] = useState(false);
  // const currentUser = useSelector((s) => s?.user?.data);
  // console.log("RoutesWrapper  currentUser:", currentUser);

  const routes = useRoutes([
    {
      path: '*',
      element: <OwnNotFound />,
    },
    {
      path: '/',
      // TODO: Comment this line if you need to ignore login
      element: getStoredToken() ? <OwnLayout /> : <Navigate to="/login" replace />,

      // element: <OwnLayout />,

      children: [
        {
          index: true,
          element: <Home />,
        },

        ...routesGeneralLedger,
        ...routesAccountReceivable,
        ...routesCashManagement,
        ...routesFixedAssets,
        ...routesTaxes,
        ...routesAccountPayable,
        ...routesInventory,
        ...routesOrders,
        ...routesProjectManagment,
        ...routesHumanResources,
        ...routesCRM,
      ],
    },
    ...routesAuthentication,
  ]);
  return routes;
}

export default RoutesWrapper;

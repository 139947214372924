import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllFixedAssetSegments = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/FixedAssetSegments?${searchQuery}`);
  return data;
});

export const getFixedAssetSegment = catchAsync(async id => {
  const { data } = await axiosInstance(`/FixedAssetSegments/${id}`);
  return data;
});

export const createFixedAssetSegment = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/FixedAssetSegments`, body);
  return data;
});

export const updateFixedAssetSegment = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/FixedAssetSegments`, body);
  return data;
});

export const deleteFixedAssetSegment = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/FixedAssetSegments/${id}`);
  return data;
});

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getAllTaxes = catchAsync(async (searchQuery) => {
  const { data } = await axiosInstance(`/Taxes?${searchQuery}`);
  return data;
});

export const getTaxes = catchAsync(async (id) => {
  const { data } = await axiosInstance(`/Taxes/${id}`);
  return data;
});

export const createTaxes = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/Taxes`, body);
  return data;
});

export const updateTaxes = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/Taxes`, body);
  return data;
});

export const deleteTaxes = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/Taxes/${id}`);
  return data;
});

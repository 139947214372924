import { Form, Input, InputNumber } from 'antd';
import React from 'react';

function Accounting() {
  return (
    <div>
      <div className="col-2">
        <Form.Item name="realizedGainsAccount" label="Realized Gains Account">
          <Input />
        </Form.Item>
        <Form.Item name="realizedLossesAccount" label="Realized Losses Account">
          <Input />
        </Form.Item>
        <Form.Item name="taxAccount" label="Tax Account">
          <Input />
        </Form.Item>
        <Form.Item name="revenueAccount" label="Revenue Account">
          <Input />
        </Form.Item>
        <Form.Item name="daysPerPostingCycle" label="Days per Posting Cycle">
          <InputNumber className="w-full" />
        </Form.Item>
      </div>
    </div>
  );
}

export default Accounting;

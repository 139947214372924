import { useMutation } from "@tanstack/react-query";
import { App, Button, Form, Input, Select, Space } from "antd";
import StepsCompleteData from "components/common/StepsCompleteData";
import useMetaData from "hooks/api/useMetaData";
import { authLedgers } from "network/auth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "services/store/slices/user";

function AccountSetupStep3() {
  const naviggate = useNavigate();
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { accountMethods, languages, metaDataLod } = useMetaData();
  console.log("AccountSetupStep3  languages:", languages);
  console.log("AccountSetupStep3  accountMethods:", accountMethods);
  const userData = useSelector((s) => s.user?.data);
  const { mutate: onAuthLedgers, isLoading } = useMutation(authLedgers, {
    onSuccess: (res) => {
      console.log("AccountSetupStep1  res:", res);

      const newUserData = { ...userData, ledger: res?.model };
      dispatch(setUserData(newUserData));
      message.success(res?.metas?.message);
      naviggate("/");
    },
    onError: (error) => {
      console.log("AccountSetupStep1  error:", error);
      // message.error(
      //   'Please enter a correct email and password. Note that both fields may be case-sensitive.'
      // );
    },
  });
  const onFinish = (values) => {
    console.log("onFinish  values:", values);
    console.log("isFieldsTouched", form.isFieldsTouched());
    // return;
    // if (!form.isFieldsTouched()) return naviggate('/');

    const body = { ...values };
    if (userData?.ledger?.id) {
      body.Id = userData?.ledger?.id;
    }

    onAuthLedgers(body);
  };

  return (
    <div className="steps-card-wrapper">
      <div className="max-w-3xl mx-auto px-4 py-12 flex flex-col justify-center step-content-holder">
        <div className="mb-16">
          <StepsCompleteData current={2} />
        </div>
        <Form
          initialValues={{ ...userData?.ledger }}
          layout="vertical"
          onFinish={onFinish}
        >
          <div className="col-2">
            <Form.Item
              name="nameEN"
              label="Name English"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>{" "}
            <Form.Item
              name="shortNameEN"
              label="Short Name English"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="nameAR"
              label="Name Arabic"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="shortNameAR"
              label="Short Name Arabic"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name="accountMethodId"
            label="Subledger Accounting Method"
            rules={[{ required: true }]}
          >
            <Select
              loading={metaDataLod}
              options={accountMethods?.map((el) => ({
                value: el?.id,
                label: el?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="languageId"
            label="Journal enter Language"
            rules={[{ required: true }]}
          >
            <Select
              loading={metaDataLod}
              options={languages?.map((el) => ({
                value: el?.id,
                label: el?.nameEN,
              }))}
            />
          </Form.Item>

          <Space className="flex justify-between items-center mt-8">
            <Button type="primary" onClick={() => naviggate(-1)}>
              Back
            </Button>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              NEXT
            </Button>
          </Space>
        </Form>
      </div>
    </div>
  );
}

export default AccountSetupStep3;

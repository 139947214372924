import { useQuery } from '@tanstack/react-query';
import { Divider, Form, Input, Select } from 'antd';
import { getAccountPeriodsByBudgetIdAndAccountId } from 'network/general-ledger/budget';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function TransferColumn({ title, accountList, accountListLod, index, selectedBudget, setOriginalAmmount, collectTransferAmount }) {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const { data: accountPeriodsData, isLoading: accountPeriodsLod } = useQuery(
    [
      queryKeys.accountPeriodList,
      {
        selectedBudgetId: selectedBudget?.id,
        selectedAccountId: selectedAccount?.id,
      },
    ],
    () => getAccountPeriodsByBudgetIdAndAccountId(selectedBudget?.id, selectedAccount?.id),
    {
      enabled: !!selectedBudget?.id && !!selectedAccount?.id,
    }
  );
  console.log('BudgetEnterTransfer  accountPeriodsData:', accountPeriodsData);

  const isFromCol = title === 'From';
  return (
    <div className="flex-1">
      <Divider>{title}</Divider>
      <div className="col-2">
        <Form.Item name={[index, isFromCol ? 'lineFrom' : 'lineTo', 'accountId']} label="Account">
          <Select
            loading={accountListLod}
            options={accountList?.map(el => ({
              value: el?.id,
              label: el?.id,
              item: el,
            }))}
            onChange={(_, option) => setSelectedAccount(option?.item)}
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input.TextArea value={selectedAccount?.description} readOnly autoSize={{ maxRows: 1 }} />
        </Form.Item>
      </div>
      <Form.Item name={[index, isFromCol ? 'lineFrom' : 'lineTo', 'amountPeriod']} label="Period">
        <Select
          loading={accountPeriodsLod}
          options={accountPeriodsData?.model?.[0]?.map(el => ({
            value: el?.amountPeriod,
            label: el?.amountPeriod + ` (${el?.amount})`,
            el,
          }))}
          onChange={(_, option) => {
            setSelectedPeriod(option?.el);
            if (isFromCol) setOriginalAmmount(option?.el?.amount);
          }}
        />
      </Form.Item>
      <div className="col-2">
        <Form.Item label="Old Balance">
          <Input disabled value={selectedPeriod?.amount} />
        </Form.Item>
        <Form.Item label="New Balance">
          <Input
            disabled
            value={selectedPeriod ? (isFromCol ? selectedPeriod?.amount - collectTransferAmount : selectedPeriod?.amount + collectTransferAmount) : undefined}
          />
        </Form.Item>
      </div>
    </div>
  );
}

export default TransferColumn;

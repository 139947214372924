import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, message, Spin } from "antd";
import useModalParams from "hooks/useModalParams";
import {
  useCreateUnitOfMeasure,
  useUnitOfMeasure,
  useUpdateUnitOfMeasure,
} from "hooks/api/projects-management/setup/unitOfMeasures";
import dayjs from "dayjs";

function UnitsOfMeasuresModal() {
  const { isNew, isEdit, id, close } = useModalParams("uom");
  const { create, isCreating } = useCreateUnitOfMeasure();
  const { update, isUpdating } = useUpdateUnitOfMeasure();
  const { initialValues, isFetching } = useUnitOfMeasure(id);

  const handleOnFinish = (values) => {
    console.log(values);
    const effectiveDateFrom = dayjs(values?.effectiveDate?.[0]).toDate();
    const effectiveDateTo = dayjs(values?.effectiveDate?.[1]).toDate();
    console.log(effectiveDateFrom, effectiveDateTo);

    isNew
      ? create({
        nameEN: values?.nameEN,
        nameAR: values?.nameAR,
        description: values?.description,
        effectiveDateFrom,
        effectiveDateTo,
      }, {
        onSuccess: () => {
          message.success("Created unit of measure successfully");
          close("id");
        },
        onError: (error) => {
          message.error(`Error: ${error}`);
        },
      })
      : update({
        id,
        nameEN: values?.nameEN,
        nameAR: values?.nameAR,
        description: values?.description,
        effectiveDateFrom,
        effectiveDateTo,
      }, {
        onSuccess: () => {
          message.success("Edited unit of measure successfully");
          close("id");
        },
        onError: (error) => {
          message.error(`Error: ${error}`);
        },
      });
  };

  return (
    <OwnModal
      title={isNew ? "Create a Unit of Measure" : "Edit Unit of Measure"}
      open={isNew || isEdit}
      onCancel={() => close("id")}
      width={600}
    >
      {isFetching
        ? (
          <div className="flex flex-col gap-5 items-center justify-center py-36">
            <Spin />
            <p>Fetching Unit of Meausre</p>
          </div>
        )
        : (
          <Form
            layout="vertical"
            initialValues={isEdit ? initialValues : null}
            onFinish={handleOnFinish}
          >
            <div className="py-4">
              <div className="col-2">
                <Form.Item
                  label="Name in English"
                  name="nameEN"
                  rules={[{
                    required: true,
                    min: 3,
                    message: "at least 3 characters",
                  }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Name in Arabic"
                  name="nameAR"
                  rules={[{
                    required: true,
                    min: 3,
                    message: "at least 3 characters",
                  }]}
                >
                  <Input />
                </Form.Item>
              </div>
              <Form.Item label="Description" name="description">
                <Input />
              </Form.Item>

              <Form.Item
                label="Effective Date"
                name="effectiveDate"
                rules={[{ required: true }]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </div>
            <Form.Item className="my-0 py-0">
              <Button
                loading={isCreating || isUpdating}
                disabled={isCreating || isUpdating}
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                {isNew ? "Create" : "Update"}
              </Button>
            </Form.Item>
          </Form>
        )}
    </OwnModal>
  );
}

export default UnitsOfMeasuresModal;

import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import DepartmentModal from "components/modals/human-resources/setup/DepartmentsModal";
import WorkLocationsModal from "components/modals/human-resources/setup/WorkLocationsModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useWorkingLocations } from "hooks/human-resourses/useWorkingLocations";
import useModalParams from "hooks/useModalParams";
import { useState } from "react";

function WorkLocations() {
  const { workLocationsData } = useWorkingLocations();
  console.log(workLocationsData, "workLocationsData");
  const [isOpen, setIsOpen] = useState(false);
  const [LocationId, setLocationId] = useState("");
  const columns = [
    {
      title: "Name",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    {
      title: "Address",
      dataIndex: "addressId",
      key: "addressId",
    },
    {
      title: "Location Number",
      dataIndex: "locationNumber",
      key: "locationNumber",
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <Button
          onClick={() => {
            setIsOpen({ id });
            setLocationId(id);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const data = [
    { Name: "Location 1", Address: "123 Main St", Number: 1 },
    { Name: "Location 2", Address: "456 Elm St", Number: 2 },
    { Name: "Location 3", Address: "789 Oak St", Number: 3 },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Human Resources / Setup / Work Locations" />
        <SearchHero
          onAddBtn={() => {
            setIsOpen(true);
            setLocationId("");
          }}
        />
        <Table sticky dataSource={workLocationsData} columns={columns} />
      </div>
      <WorkLocationsModal
        open={isOpen}
        LocationId={LocationId}
        handelClose={() => setIsOpen(false)}
      />
    </>
  );
}

export default WorkLocations;

import SalesInvoices from 'pages/account-receivable/invoices/sales-invoices/SalesInvoices';
import SalesInvoiceNew from 'pages/account-receivable/invoices/sales-invoices/SalesInvoiceNew';
import CreditInvoices from 'pages/account-receivable/invoices/credit-invoices/CreditInvoices';
import CreditInvoiceNew from 'pages/account-receivable/invoices/credit-invoices/CreditInvoiceNew';
import Receipts from 'pages/account-receivable/receipts/Receipts';
import ReceiptNew from 'pages/account-receivable/receipts/ReceiptNew';
import Periods from 'pages/account-receivable/setup/Periods';
import SystemOption from 'pages/account-receivable/setup/SystemOption/SystemOption';
import Customers from 'pages/account-receivable/customers/Customers';
import CustomerNew from 'pages/account-receivable/customers/CustomerNew';
import PaymentTerms from 'pages/account-receivable/customers/PaymentTerms';
import PaymentTermsNew from 'pages/account-receivable/customers/PaymentTermsNew';

const routesAccountReceivable = [
  {
    path: 'account-receivable',
    children: [
      {
        path: 'invoices',
        children: [
          {
            path: 'sales-invoices',
            children: [
              {
                index: true,
                element: <SalesInvoices />,
              },
              {
                path: 'new',
                element: <SalesInvoiceNew />,
              },
              {
                path: 'edit/:id',
                element: <SalesInvoiceNew isEdit />,
              },
            ],
          },
          {
            path: 'credit-invoices',
            children: [
              {
                index: true,
                element: <CreditInvoices />,
              },
              {
                path: 'new',
                element: <CreditInvoiceNew />,
              },
              {
                path: 'edit/:id',
                element: <CreditInvoiceNew isEdit />,
              },
            ],
          },
        ],
      },
      {
        path: 'receipts',
        children: [
          {
            index: true,
            element: <Receipts />,
          },
          {
            path: 'new',
            element: <ReceiptNew />,
          },
          {
            path: 'edit/:id',
            element: <ReceiptNew isEdit />,
          },
        ],
      },
      {
        path: 'customers',
        children: [
          {
            index: true,
            element: <Customers />,
          },
          {
            path: 'new',
            element: <CustomerNew />,
          },
          {
            path: 'edit/:id',
            element: <CustomerNew isEdit />,
          },
          {
            path: 'payment-terms',
            children: [
              {
                index: true,
                element: <PaymentTerms />,
              },
              {
                path: 'new',
                element: <PaymentTermsNew />,
              },
              {
                path: 'edit/:id',
                element: <PaymentTermsNew isEdit />,
              },
            ],
          },
        ],
      },
      {
        path: 'setup',
        children: [
          {
            path: 'system-option',
            children: [
              {
                index: true,
                element: <SystemOption />,
              },
              {
                path: ':tabKey',
                element: <SystemOption />,
              },
            ],
          },
          {
            path: 'periods',
            element: <Periods />,
          },
        ],
      },
    ],
  },
];

export default routesAccountReceivable;

import { Avatar, Divider, Space } from 'antd';
import { arrowDownRightIcon, arrowUpRightIcon } from 'assets/icons';
import chartImg from 'assets/images/chart.jpg';

function DashBoardHero() {
  return (
    <div className="flex bg-white h-[180px] gap-x-8 rounded-lg overflow-hidden">
      <div className="p-6">
        <Space align="center">
          <Avatar
            src={
              'https://images.unsplash.com/photo-1633332755192-727a05c4013d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D'
            }
            size="large"
          />

          <span className="text-lg font-medium text-gray-800">Welcome back Mostafa!</span>
        </Space>
        <div className="flex mt-6">
          {/* item */}
          <div className="flex flex-col gap-y-1 justify-center">
            <Space size={0}>
              <span className="text-3xl text-gray-800">$2,340</span>
              <span className="w-[24px] inline-block">{arrowUpRightIcon}</span>
            </Space>
            <span className="text-sm text-gray-600">Today’s Sales</span>
          </div>
          <Divider type="vertical" className="h-[60px] mx-6" />
          {/* item */}
          <div className="flex flex-col gap-y-1">
            <Space size={0}>
              <span className="text-3xl text-gray-800">35%</span>
              <span className="w-[24px] inline-block">{arrowDownRightIcon}</span>
            </Space>
            <span className="text-sm text-gray-600">Overall Performance</span>
          </div>
        </div>
      </div>
      <div className="h-full ms-auto flex-1 max-w-[50vw]">
        <img src={chartImg} alt="chart" className="h-full w-full object-cover brightness-110" />
      </div>
    </div>
  );
}

export default DashBoardHero;

import { Button, Table } from "antd";
import SearchHero from "components/common/SearchHero";
import DepartmentModal from "components/modals/human-resources/setup/DepartmentsModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useDepartments } from "hooks/human-resourses/useDepartment";
import useModalParams from "hooks/useModalParams";
import { useState } from "react";

function DepartmentList() {
  // const { openAsNew, openAsEdit } = useModalParams();
  const {departmentData}=useDepartments()
  console.log(departmentData,"departmentData");
  const [isOpen, setIsOpen] = useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    {
      title: "Manager Name",
      dataIndex: "managerId",
      key: "managerId",
    },
    {
      title: "Parent Department",
      dataIndex: "parentDepartmentId",
      key: "parentDepartmentId",
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }) => (
        <Button onClick={() => {setIsOpen({ id })}}>Edit</Button>
      ),
    },
  ];


  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Human Resources / Setup / Departments" />
        <SearchHero onAddBtn={() => setIsOpen(true)} />
        <Table
          sticky
          dataSource={departmentData}
          columns={columns}
        />
      </div>
      <DepartmentModal open={isOpen} handelClose={() => setIsOpen(false)} />
    </>
  );
}

export default DepartmentList;

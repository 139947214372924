import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, message, Select, Spin } from "antd";
import OwnModal from "components/own/OwnModal";
import useCreateOperationType from "hooks/api/supply-chain/operationTypes/useCreateOperationType";
import useOperationType from "hooks/api/supply-chain/operationTypes/useOperationType";
import useUpdateOperationType from "hooks/api/supply-chain/operationTypes/useUpdateOperationType";
import useWarehouses from "hooks/api/supply-chain/warehouse/useWarehouses";
import useMetaData from "hooks/api/useMetaData";
import useSearchQuery from "hooks/useSearchQuery";
import { queryKeys } from "services/react-query/queryKeys";

export default function ModalOperationType(props) {
  const [form] = Form.useForm();
  const { setSearchQuery, searchQueryObj } = useSearchQuery();
  const { data: warehouses } = useWarehouses();
  const { reservationMethods } = useMetaData();

  const isOpen = searchQueryObj.open === "true";
  const isEditMode = searchQueryObj.mode === "edit";
  const id = searchQueryObj.id;

  const { operationType, isFetching } = useOperationType({
    enabled: isEditMode,
    id,
  });

  const { create, isCreating } = useCreateOperationType();
  const { update, isUpdating } = useUpdateOperationType(id);
  const client = useQueryClient();

  const close = () => {
    setSearchQuery({});
    form.resetFields();
  };

  const handleOnFinish = (values) => {
    isEditMode
      ? update({ ...values, id }, {
        onSuccess: () => {
          message.success("Operation type updated successfully");
          client.invalidateQueries([queryKeys.warehouse]);
          close();
        },
        onError: () =>
          message.error("Error occurred during updating operation type"),
      })
      : create(values, {
        onSuccess: () => {
          message.success("Operation type created successfully");
          close();
        },
        onError: () =>
          message.error("Error occurred during creating operation type"),
      });
  };

  return (
    <OwnModal
      {...props}
      title={isEditMode ? "Edit Operation Type" : "Add Operation Type"}
      open={isOpen}
      onCancel={close}
    >
      {(isFetching && isEditMode)
        ? (
          <div className="flex flex-col gap-5 items-center justify-center py-36">
            <Spin />
            <p>Fetching Data</p>
          </div>
        )
        : (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOnFinish}
            initialValues={isEditMode ? operationType : {}}
            preserve={false}
          >
            <div className="grid md:grid-cols-2 gap-x-4 gap-y-2">
              <Form.Item
                label="Operation Type"
                name="nameEN"
                required
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="warehouseId"
                label="Warehouse"
                rules={[{ required: true }]}
              >
                <Select
                  options={warehouses?.map((warehouse) => ({
                    value: warehouse?.id,
                    label: warehouse?.nameEN,
                  }))}
                />
              </Form.Item>

              <Form.Item
                label="Reservation Method"
                name="reservationMethodId"
                required
                rules={[{ required: true }]}
              >
                <Select
                  options={reservationMethods?.map((type) => ({
                    value: type?.id,
                    label: type?.nameEN,
                  }))}
                />
              </Form.Item>
            </div>

            <div className="btn-wrraper mt-4 text-center ">
              <Button
                type="primary"
                htmlType="submit"
                className="block m-auto  w-full max-w-xs"
                loading={isCreating || isUpdating}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
    </OwnModal>
  );
}

import { MoreOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Dropdown, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalPeriod from 'components/modals/ModalPeriod';
import ModalPeriodOpenClose from 'components/modals/ModalPeriodOpenClose';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useMetaData from 'hooks/api/useMetaData';
import usePeriodList from 'hooks/api/usePeriodList';
import useSearchQuery from 'hooks/useSearchQuery';

import { deletePeriod, getAllPeriods } from 'network/general-ledger/period';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import { dateFormat } from 'utils/utils';

function Period() {
  const { message } = App.useApp();
  const client = useQueryClient();
  const { searchQueryStr, searchQueryObj, setSearchQuery } = useSearchQuery();
  const { periodList, periodListLod } = usePeriodList(searchQueryStr);
  const metadata = useMetaData();

  const { mutate: onDeletePeriod } = useMutation(deletePeriod, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.periodList]);
    },
  });

  const data = periodList;
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [iseModalOpenClose, setIseModalOpenClose] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [periodOpenClose, setPeriodOpenClose] = useState(null);

  const columns = [
    {
      key: 'number',
      dataIndex: 'number',
      title: 'number',
    },
    {
      key: 'prefix',
      dataIndex: 'prefix',
      title: 'prefix',
    },
    {
      key: 'year',
      dataIndex: 'year',
      title: 'year',
    },
    {
      key: 'from',
      dataIndex: 'from',
      title: 'from',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'to',
      dataIndex: 'to',
      title: 'to',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'statusId',
      dataIndex: 'statusId',
      title: 'status',
      render: (val, _) => metadata?.periodStatuses?.find(s => s?.id === +val)?.nameEN,
    },
    {
      key: 'adjusting',
      dataIndex: 'adjusting',
      title: 'adjusting',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'Open/Close',
              label: 'Open/Close',
              onClick: () => setPeriodOpenClose(record),
            },
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeletePeriod(record?.id),
            },
          ]}
        />
      ),
    },
  ];
  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Period" />
        <SearchHero onAddBtn={() => setIseModalUpdat(true)} searchKey="word" />
        <Table
          dataSource={data}
          sticky
          columns={!!data?.length ? columns : []}
          // pagination={{
          //   pageSize: 1,
          //   current: +searchQueryObj.page || 1,
          //   onChange: page => {
          //     const queryObj = { ...searchQueryObj };
          //     queryObj['page'] = page;
          //     setSearchQuery(queryObj);
          //   },
          // }}
        />
      </div>
      <ModalPeriod
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIseModalUpdat(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
      <ModalPeriodOpenClose
        open={!!periodOpenClose}
        onCancel={() => {
          setPeriodOpenClose(null);
        }}
        editItem={periodOpenClose}
        setEditItem={setPeriodOpenClose}
      />
    </>
  );
}

export default Period;

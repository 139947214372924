import { Button, Checkbox, Divider, Form, message, Spin } from "antd";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import React from "react";
import useInventoryConfiguration from "hooks/api/supply-chain/inventoryConfiguration/useInventoryConfiguration";
import useUpdateInventoryConfiguration from "hooks/api/supply-chain/inventoryConfiguration/useUpdateInventoryConfiguration";

export default function InventoryConfiguration() {
  const [form] = Form.useForm();
  const { data: configs, isFetching } = useInventoryConfiguration();
  const { mutate: update, isLoading: isUpdating } =
    useUpdateInventoryConfiguration();

  const handleOnFinish = (values) => {
    values.id = configs?.id;
    update(values, {
      onSuccess: () => message.success("Updated Successfully"),
      onError: (error) => console.error(error),
    });
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Inventory Configuration" />

      <div className="bg-white py-8 px-8 rounded-lg">
        <h2 className="title-24 mb-2">Operations</h2>

        {isFetching
          ? (
            <div className="flex flex-col gap-5 items-center justify-center py-36">
              <Spin />
              <p>Fetching Data</p>
            </div>
          )
          : (
            <Form
              layout="vertical"
              form={form}
              initialValues={configs}
              onFinish={handleOnFinish}
            >
              <div className="col-2">
                <Form.Item name="packages" valuePropName="checked">
                  <Checkbox>Packages</Checkbox>
                </Form.Item>
              </div>
              <Divider />
              <h2 className="title-20 mb-2">Shipping</h2>
              <div className="col-2">
                <Form.Item name="emailConfirmation" valuePropName="checked">
                  <Checkbox>Email Confirmation</Checkbox>
                </Form.Item>
                <Form.Item name="deliveryMethods" valuePropName="checked">
                  <Checkbox>Delivery Methods</Checkbox>
                </Form.Item>
              </div>
              <Divider />
              <h2 className="title-24 ">Products</h2>
              <div className="col-2">
                <Form.Item name="unitsOfMeasure" valuePropName="checked">
                  <Checkbox>Units Of Measure</Checkbox>
                </Form.Item>
              </div>
              <Divider />
              <h2 className="title-20 mb-2">Valuation</h2>
              <div className="col-2">
                <Form.Item name="landedCosts" valuePropName="checked">
                  <Checkbox>Landed Costs</Checkbox>
                </Form.Item>
              </div>
              <Divider />
              <h2 className="title-20 mb-2">Advanced Scheduling</h2>
              <div className="col-2">
                <Form.Item
                  name="securityLeatTimeForPurchase"
                  valuePropName="checked"
                >
                  <Checkbox>Security Leat Time For Purchase</Checkbox>
                </Form.Item>
              </div>

              <Form.Item>
                <Button
                  className="mt-4"
                  type="primary"
                  htmlType="submit"
                  loading={isUpdating}
                  disabled={isUpdating}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
      </div>
    </div>
  );
}

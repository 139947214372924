import { App, Button, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ModalTransactionAmounts({ open, onCancel, editItem, setEditItem }) {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [amountType, setAmountType] = useState('fixed');

  // useEffect(() => {
  //     const dataObj = { ...editItem };

  //     dataObj.areaId = editItem?.area_id;
  //     dataObj.provinceId = editItem?.province_id;

  //     form.setFieldsValue(dataObj);
  //   }, [editItem?.id]);

  const onFinish = values => {
    console.log('ModalTransactionAmounts  values:', values);
  };
  return (
    <OwnModal
      title="Transaction Amounts"
      open={open}
      onCancel={onCancel}
      width={902}
    >
      <Form
        layout="vertical"
        form={form}
        // size="large"
        onFinish={onFinish}
        // className="pt-4"
      >
        <Form.Item name="Credit Allocation" label="Credit Allocation">
          <Select loading={true}></Select>
        </Form.Item>
        <h4 className="title-20 mb-2">Line</h4>
        <div className="col-4">
          <Form.Item name="amount_type" label="Amount type">
            <Select
              value={amountType}
              onChange={value => setAmountType(value)}
              options={[
                {
                  label: 'Fixed',
                  value: 'fixed',
                },
                {
                  label: 'Percentage',
                  value: 'percentage',
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="Amount" label="Amount">
            <InputNumber
              suffix={amountType === 'percentage' && '%'}
              controls={false}
              className="w-full"
            />
          </Form.Item>
          <Form.Item name="Original" label="Original">
            <InputNumber controls={false} className="w-full" />
          </Form.Item>
          <Form.Item name="Balance Due" label="Balance Due">
            <InputNumber controls={false} className="w-full" />
          </Form.Item>
        </div>
        <h4 className="title-20 mb-2">Tax</h4>
        <div className="col-4">
          <Form.Item name="amount_type" label="Amount type">
            <Select
              value={amountType}
              onChange={value => setAmountType(value)}
              options={[
                {
                  label: 'Fixed',
                  value: 'fixed',
                },
                {
                  label: 'Percentage',
                  value: 'percentage',
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="Amount" label="Amount">
            <InputNumber
              suffix={amountType === 'percentage' && '%'}
              controls={false}
              className="w-full"
            />
          </Form.Item>
          <Form.Item name="Original" label="Original">
            <InputNumber controls={false} className="w-full" />
          </Form.Item>
          <Form.Item name="Balance Due" label="Balance Due">
            <InputNumber controls={false} className="w-full" />
          </Form.Item>
        </div>
        <h4 className="title-20 mb-2">Freight</h4>
        <div className="col-4">
          <Form.Item name="amount_type" label="Amount type">
            <Select
              value={amountType}
              onChange={value => setAmountType(value)}
              options={[
                {
                  label: 'Fixed',
                  value: 'fixed',
                },
                {
                  label: 'Percentage',
                  value: 'percentage',
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="Amount" label="Amount">
            <InputNumber
              suffix={amountType === 'percentage' && '%'}
              controls={false}
              className="w-full"
            />
          </Form.Item>
          <Form.Item name="Original" label="Original">
            <InputNumber controls={false} className="w-full" />
          </Form.Item>
          <Form.Item name="Balance Due" label="Balance Due">
            <InputNumber controls={false} className="w-full" />
          </Form.Item>
        </div>

        <Space wrap className="flex justify-end mt-4">
          <Button
            // loading={addressAddLod || addressUpdateLod}
            type="primary"
            htmlType="submit"
            className="w-64"
            onClick={() => {
              message.success('New Sales Invoice Added Successfully');
              navigate(-1);
            }}
          >
            Complete
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalTransactionAmounts;

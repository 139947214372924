import { useQuery } from '@tanstack/react-query';
import { getAllSalesInvoices } from 'network/account-receivable/sales-invoice';
import { queryKeys } from 'services/react-query/queryKeys';

function useSalesInvoiceList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.salesInvoiceList, searchQueryStr], () => getAllSalesInvoices(searchQueryStr));

  const salesInvoiceList = data?.model;

  return {
    salesInvoiceList,
    salesInvoiceListLod: isLoading,
  };
}

export default useSalesInvoiceList;

import axios from 'axios';
import { clearStoredToken, getStoredToken } from './user-storage';
// import store from './StoreProvider/configureStore';
// import { setUserData } from './StoreProvider/reducers/user';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'content-language': 'en',
    withCredentials: true,
  },
});

axiosInstance.interceptors.request.use(config => {
  const token = getStoredToken();
  if (token) config.headers.Authorization = 'Bearer ' + token;

  // console.log('config:', config);
  return config;
});

axiosInstance.interceptors.response.use(undefined, err => {
  console.log('interceptors err:', err);
  // TODO: Comment this line if you need to ignore login
  if (err?.code === 'ERR_NETWORK') {
    clearStoredToken();
    window.location.reload();
  }

  // const statusCode = err.response.status;
  // console.log('statusCode:', statusCode);
  // if (statusCode === 401) {
  //   store.dispatch(setUserData(null));
  // }

  return Promise.reject(err.response?.data);
});

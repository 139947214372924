import { useQuery } from '@tanstack/react-query';
import { getAllGLValues } from 'network/general-ledger/GLValues';
import { queryKeys } from 'services/react-query/queryKeys';

function useGLValueList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.GLValueList, searchQueryStr], () => getAllGLValues(searchQueryStr));

  const GLValueList = data?.model;

  return {
    GLValueList,
    GLValueListLod: isLoading,
  };
}

export default useGLValueList;

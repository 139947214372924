import { Checkbox, Divider, Form } from 'antd';
import React from 'react';

function AccountingRules() {
  return (
    <div>
      <div className="col-2">
        <Form.Item name="allowTaxExemptions" valuePropName="checked">
          <Checkbox>Allow Tax Exemptions</Checkbox>
        </Form.Item>
        <Form.Item name="allowTaxExceptions" valuePropName="checked">
          <Checkbox>Allow Tax Exceptions</Checkbox>
        </Form.Item>
      </div>
      <Divider>Allow Revaluation</Divider>
      <div className="col-2">
        <Form.Item name="revalueAccumulatedDepreciation" valuePropName="checked">
          <Checkbox>Revalue accumulated depreciation</Checkbox>
        </Form.Item>
        <Form.Item name="revalueYTDDepreciation" valuePropName="checked">
          <Checkbox>Revalue YTD Depreciation</Checkbox>
        </Form.Item>
        <Form.Item name="retireRevaluationReserve" valuePropName="checked">
          <Checkbox>Retire Revaluation reserve</Checkbox>
        </Form.Item>
        <Form.Item name="amortizeRevaluationReserve" valuePropName="checked">
          <Checkbox>Amortize Revaluation Reserve</Checkbox>
        </Form.Item>
        <Form.Item name="revalueFullyReservedAssets" valuePropName="checked">
          <Checkbox>Revalue fully reserved assets</Checkbox>
        </Form.Item>
      </div>
    </div>
  );
}

export default AccountingRules;

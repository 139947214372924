import ExpenditureCategory from "pages/project-managment/setup/ExpenditureCategory";
import ExpenditureTypes from "pages/project-managment/setup/ExpenditureTypes";
import UnitsOfMeasures from "pages/project-managment/setup/UnitsOfMeasures";
import Projects from "pages/project-managment/projects";
import Tasks from "pages/project-managment/projects/Tasks";
import Rates from "pages/project-managment/setup/Rates";
import Budget from "pages/project-managment/budget";
import BudgetDetails from "pages/project-managment/budget/BudgetDetails";
import BudgetLines from "pages/project-managment/budget/BudgetLines";

const routesProjectManagment = [
  {
    path: "project-managment",
    children: [
      {
        path: "setup",
        children: [
          {
            path: "units-of-measures",
            element: <UnitsOfMeasures />,
          },
          {
            path: "expenditure-types",
            children: [
              {
                element: <ExpenditureTypes />,
                index: true,
              },
              {
                path: ":id/rates",
                element: <Rates />,
              },
            ],
          },
        ],
      },
      {
        path: "projects",
        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: ":id",
            element: <Tasks />,
          },
        ],
      },
      {
        path: "budget",
        children: [
          {
            index: true,
            element: <Budget />,
          },
          {
            path: "budget-details/:projectId",
            children: [
              {
                index: true,
                element: <BudgetDetails />,
              },
              {
                path: "budget-lines",
                element: <BudgetLines />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routesProjectManagment;

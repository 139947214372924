import { useQuery } from '@tanstack/react-query';
import { getAllTimeOffs } from 'network/Human-resources/time-off';
import { queryKeys } from 'services/react-query/queryKeys';

function useTimeOffList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.timeOffList, searchQueryStr], () => getAllTimeOffs(searchQueryStr));

  const timeOffList = data?.model;

  return {
    timeOffList,
    timeOffListLod: isLoading,
  };
}

export default useTimeOffList;

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import dayjs from "dayjs";
import useModalParams from "hooks/useModalParams";
import { getEmployee } from "network/Human-resources/employees";
import {
  create,
  delete_,
  get,
  getAll,
  update,
} from "network/project-managments/projects/projects";
import { get as getTask } from "network/project-managments/projects/tasks";
import { queryKeys } from "services/react-query/queryKeys";

export const useProjects = () => {
  const query = useQuery({
    queryFn: getAll,
    queryKey: [queryKeys.projects],
  });

  const projects = query.data?.model;

  const managers = useQueries({
    queries: projects
      ? projects?.map((project) => {
        return {
          queryFn: () => getEmployee(project?.projectManagerId),
          queryKey: [queryKeys.employee, project?.projectManagerId],
        };
      })
      : [],
  });

  query.projects = projects?.map((project, index) => {
    return {
      ...project,
      manager: managers?.[index]?.data?.model,
    };
  });

  return query;
};

export const useProject = (id) => {
  const query = useQuery({
    queryFn: () => get(id),
    queryKey: [queryKeys.projects, id],
    enabled: id !== undefined,
  });

  query.project = query.data?.model;

  query.initialValues = {
    name: query?.project?.name,
    number: query?.project?.number,
    projectManagerId: query?.project?.projectManagerId,
    date: [
      dayjs(query?.project?.startDate, "YYYY-MM-DD"),
      dayjs(query?.project?.finishDate, "YYYY-MM-DD"),
    ],
  };

  const tasks_ = query.project?.tasks?.filter((task) =>
    task?.isDeleted === false
  );

  const tasks = useQueries({
    queries: tasks_
      ? tasks_?.map((task) => {
        return {
          queryFn: async () => {
            // the backend follows the concept of making every module restful and seprate
            // meaning there is no table joining happeing in the backend
            // the following code joins the data of tasks and the subtask with the employee which is the task manager
            // this doesn't scale at all and there will be memory, bandwidth, traffic costs.
            // i understand, this code is hella ugly, but just ship it i guess...
            const taskManager = await getEmployee(task?.taskManagerId);
            const task_ = await getTask(task?.id);
            task_.model.taskManager = taskManager.model;

            const subTasksManagers = await Promise.all(
              task_?.model?.subTasks?.map((subTask) =>
                getEmployee(subTask?.subTaskManagerId)
              ),
            );

            const subTasks = task_?.model?.subTasks?.map((subTask, index) => {
              return {
                ...subTask,
                subTaskManager: subTasksManagers?.[index]?.model,
              };
            });

            task_.model.subTasks = subTasks;

            return task_;
          },
          queryKey: [queryKeys.tasks, task?.id],
          select: (data) => data?.model,
        };
      })
      : [],
    enabled: !!tasks_,
  });

  query.tasks = tasks?.map((task) => ({
    ...task?.data,
    key: task?.data?.id,
  }));

  return query;
};

export const useMutateProject = () => {
  const { isNew, isEdit, isDelete, id } = useModalParams("projects");
  const client = useQueryClient();

  const mutationFn = isNew
    ? create
    : isEdit
    ? update
    : isDelete
    ? delete_
    : null;

  const mutation = useMutation({
    mutationFn,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.projects]);
      client.invalidateQueries([queryKeys.projects, id]);
    },
  });

  return mutation;
};

import { useMutation } from "@tanstack/react-query";
import { App, Button, Form, Input, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import constents from "utils/constants";
import { authLegalEntities } from "network/auth";
import { setUserData } from "services/store/slices/user";
import StepsCompleteData from "components/common/StepsCompleteData";

function AccountSetupStep1() {
  const naviggate = useNavigate();
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const userData = useSelector((s) => s.user?.data);
  const { mutate: onAuthLegalEntities, isLoading } = useMutation(
    authLegalEntities,
    {
      onSuccess: (res) => {
        console.log("AccountSetupStep1  res:", res);

        const newUserData = { ...userData, legalEntity: res?.model };
        dispatch(setUserData(newUserData));
        message.success(res?.metas?.message);
        naviggate("/complete-data/addresses");
      },
      onError: (error) => {
        console.log("AccountSetupStep1  error:", error);
        // message.error(
        //   'Please enter a correct email and password. Note that both fields may be case-sensitive.'
        // );
      },
    }
  );

  const onFinish = (values) => {
    console.log("onFinish  values:", values);
    console.log("isFieldsTouched", form.isFieldsTouched());
    // return;
    if (!form.isFieldsTouched()) return naviggate("/complete-data/addresses");

    const body = { ...values };
    if (userData?.legalEntity?.id) {
      body.Id = userData?.legalEntity?.id;
    }
    onAuthLegalEntities(body);
  };

  return (
    <div className="steps-card-wrapper">
      <div className="max-w-3xl mx-auto px-4 py-12 flex flex-col justify-center step-content-holder">
        <div className="mb-16">
          <StepsCompleteData current={0} />
        </div>

        <Form
          initialValues={{ ...userData?.legalEntity }}
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="terriotry"
            label="Terriotry"
            rules={constents.rulesRequeried}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="legalEntityIdentifire"
            label="Legal Entity Identifier"
            rules={constents.rulesRequeried}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="organizationNameEN"
            label="Organization Name"
            rules={constents.rulesRequeried}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="organizationNameAR"
            label="Organization Name"
            rules={constents.rulesRequeried}
          >
            <Input />
          </Form.Item>

          <Space className="flex justify-between items-center mt-8">
            <Button type="primary" onClick={() => naviggate(-1)}>
              Back
            </Button>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              NEXT
            </Button>
          </Space>
        </Form>
      </div>
    </div>
  );
}

export default AccountSetupStep1;

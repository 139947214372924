import { useQuery } from '@tanstack/react-query';
import { getAllEmployeeGoals } from 'network/Human-resources/employee-goals';
import { queryKeys } from 'services/react-query/queryKeys';

function useEmployeeGoalList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.employeeGoalList, searchQueryStr], () => getAllEmployeeGoals(searchQueryStr));

  const employeeGoalList = data?.model;

  return {
    employeeGoalList,
    employeeGoalListLod: isLoading,
  };
}

export default useEmployeeGoalList;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getReordering = catchAsync(async (id) => {
  const { data } = await axiosInstance.get("/ReorderingRule", {
    params: {
      id,
    },
  });
  return data;
});

export const addReordering = catchAsync(async (fd) => {
  const res = await axiosInstance.post("/ReorderingRule", fd);
  return res;
});

import QueryProvider from 'services/react-query';
import OwnScrollToTop from 'components/own/OwnScrollToTop';
import { ConfigProvider, App as AntApp, FloatButton } from 'antd';
// import 'antd/dist/reset.css';
import 'react-phone-number-input/style.css';
import 'styles/globals.scss';
import RoutesWrapper from './Routes';
import dayjs from 'dayjs';
import uts from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { UpOutlined } from '@ant-design/icons';
import OwnFetchingIndicator from 'components/own/OwnFetchingIndicator';
dayjs.extend(duration);
dayjs.extend(uts);

function App() {
  const validateMessages = {
    required: `please input field data`,
  };

  return (
    <QueryProvider>
      <ConfigProvider
        // wave={{ wave: "Inset" }}
        theme={{
          token: {
            fontFamily: 'Poppins, sans-serif',
            colorPrimary: '#2464a4',
            colorInfo: '#faad14',
            // colorBgContainer: '#f7f7f7',
            borderRadius: 8,
            // colorBorderSecondary: '#0000001a',
            colorText: '#212121',
            // colorLinkHover: '#777',
            fontSize: 16,
            controlHeight: 44,
            colorBtnColor: '#fff',
            secBtnBg: '#F8F5FF',
            secColor: '#8A4CF5',
            thirdColor: '#59A6ED',
            Accent: '#EBF2FA',
            line: '#CFD8E3',
            grayTxt: '#595959',
            linearBg: 'linear-gradient(270deg, #8A4CF5 0%, #1727FF 100%)',
            secBtnBorderColor: '#C7BBF9',
            colorPrimaryBgHover: '#1727FF',
            colorPrimaryHover: '#1727FF',
            colorTextDescription: '#2464a4',
            colorPrimaryActive: '#2464a4',
            colorPrimaryText: '#2464a4',
            colorPrimaryTextActive: '#2464a4',
            colorPrimaryTextHover: '#2464a4',
            colorLink: '#2464a4',
            colorLinkHover: '#2464a4',
            colorSuccess: '#238709',
            colorSuccessBg: '#E9FFDA',
            colorWarning: '#ff9d00',
            colorWarningBg: '#FFF4E2',
            colorError: '#cf2110',
            colorErrorBg: '#FFE7E4',
            colorTextBase: '#000',
            colorFillQuaternary: '#ebf2fa',
            colorFillSecondary: '#ebf2fa',
            colorPrimaryBg: '#ede8ff',
            fontSizeXL: 18,
            fontSizeHeading5: 16,
            fontSizeHeading4: 18,
            fontSizeHeading3: 22,
            fontSizeHeading2: 25,
            fontSizeHeading1: 32,
            marginMD: 24,
            wireframe: false,
            marginLG: 32,
            marginXL: 40,
            paddingMD: 24,
            paddingLG: 32,
            paddingXL: 40,
            borderRadiusSM: 4,
            borderRadiusLG: 12,
            borderRadiusXS: 4,
            colorBgLayout: '#F0F1F2',
            colorBgContainer: '#ffffff',
            colorBgElevated: '#ffffff',
            boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1);',
            boxShadowSecondary: '0px 0px 24px rgba(0, 0, 0, 0.1);',
            colorPrimaryBorder: '#2464a4',
          },
          components: {
            Radio: {
              colorPrimary: '#2464a4',
            },
            Checkbox: {
              borderRadius: 4,
            },
            Select: {},
            Form: {
              // size: 'large',
              size: 50,
            },
          },
        }}
        form={{ validateMessages, requiredMark: true }}
        virtual>
        <AntApp>
          <RoutesWrapper />
          <OwnScrollToTop />
          <FloatButton.BackTop icon={<UpOutlined style={{ color: '#777' }} />} className="h-11 w-11 bottom-[86px]" />
          <OwnFetchingIndicator />
        </AntApp>
      </ConfigProvider>
    </QueryProvider>
  );
}

export default App;

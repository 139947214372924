import { App, Button, DatePicker, Form, Input, Select, Space, TimePicker } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import constents from 'utils/constants';
import { createWorkEntry, getWorkEntry, updateWorkEntry } from 'network/Human-resources/work-entries';

function DailyEntryNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const { data: cusromerData } = useQuery([queryKeys.timeOffList, params?.id], () => getWorkEntry(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.workEntryList]);

    form.resetFields();
    navigate('/human-resources/payroll/work-entry/daily-entries');
  };

  const { mutate: onCreateWorkEntry, isLoading: onCreateWorkEntryLod } = useMutation(createWorkEntry, {
    onSuccess,
  });
  const { mutate: onUpdateWorkEntry, isLoading: onUpdateWorkEntryLod } = useMutation(updateWorkEntry, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && cusromerData) {
      const editItem = cusromerData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['duration'] = dayjs(dataObj['duration'], 'HH:mm:ss');
      dataObj['fromTo'] = [dayjs(dataObj['fromDate']), dayjs(dataObj['toDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, cusromerData, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['duration'] = dayjs(dataObj['duration']).format('HH:mm:ss');
    dataObj['fromDate'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['toDate'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = cusromerData?.model?.id;
      return onUpdateWorkEntry(dataObj);
    }

    onCreateWorkEntry(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Payroll' }, { title: <Link to="/human-resources/payroll/work-entry/time-off">Time Off</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="employeeId" label="Employee" rules={[{ required: true }]}>
              <Select
                // options={metadata?.employees?.map(el => ({
                //   value: el?.id,
                //   label: el?.name,
                // }))}
                options={[{ label: 'need list', value: 'aebdbdf2-f872-4bdb-9a16-08dbf372011a' }]}
              />
            </Form.Item>
            <Form.Item name="workEntryTypeId" label="Work Entry Type" rules={[{ required: true }]}>
              <Select options={[{ label: 'need list', value: '7388ca66-b792-40e1-2a7a-08dbf766fdca' }]} />
            </Form.Item>
            <Form.Item name="status" label="Status" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: 0, label: 'Draft' },
                  { value: 1, label: 'Validated' },
                  { value: 2, label: 'Conflict' },
                ]}
              />
            </Form.Item>

            <Form.Item name="fromTo" label="From - To" rules={[{ required: true }]}>
              <DatePicker.RangePicker
                className="w-full"
                format="YYYY-MM-DD"
                disabledDate={current => {
                  // Can not select days before today and today
                  return current && current < dayjs().endOf('day');
                }}
              />
            </Form.Item>
            <Form.Item name="duration" label="Duration" rules={[{ required: true }]}>
              <TimePicker className="w-full" />
            </Form.Item>

            <Form.Item name="description" label="Description" rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateWorkEntryLod || onUpdateWorkEntryLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default DailyEntryNew;

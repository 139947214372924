import OwnModal from "components/own/OwnModal";
import useModalParams from "hooks/useModalParams";
import { Button, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useDeleteExpenditureType } from "hooks/api/projects-management/setup/expenditureType";

const DeleteExpenditureTypeModal = () => {
  const { isDelete, id, close } = useModalParams("expenditure-type");
  const { delete_, isDeleting } = useDeleteExpenditureType(id);

  const deleteUnit = () => {
    delete_(id, {
      onSuccess: () => {
        message.success("Expenditure type deleted");
        close("id");
      },
      onError: (error) => {
        message.error(`Error deleting expenditure type: ${error.message}`);
      },
    });
  };

  return (
    <OwnModal
      width={700}
      centered
      title=" "
      open={isDelete}
      onCancel={() => close("id")}
    >
      <div className="flex flex-col items-center gap-5 py-2">
        <CloseCircleOutlined className="text-color-danger text-[86px]" />
        <p className="font-medium text-xl py-4">
          Are you sure you want to delete this expenditure type?
        </p>
        <div className="flex items-center gap-3">
          <Button
            onClick={deleteUnit}
            size="large"
            className="w-[150px]"
            loading={isDeleting}
            disabled={isDeleting}
            danger
          >
            Yes
          </Button>
          <Button
            disabled={isDeleting}
            size="large"
            className="w-[150px]"
            onClick={() => close("id")}
          >
            No
          </Button>
        </div>
      </div>
    </OwnModal>
  );
};

export default DeleteExpenditureTypeModal;

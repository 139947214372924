import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import constents from 'utils/constants';
import { createTimeOff, getTimeOff, updateTimeOff } from 'network/Human-resources/time-off';
import { useSelector } from 'react-redux';

function TimeOffNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const userData = useSelector(s => s.user?.data);

  const metadata = useMetaData();

  const client = useQueryClient();
  const { data: cusromerData } = useQuery([queryKeys.timeOffList, params?.id], () => getTimeOff(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.timeOffList]);

    form.resetFields();
    navigate('/human-resources/payroll/work-entry/time-offs');
  };

  const { mutate: onCreateTimeOff, isLoading: onCreateTimeOffLod } = useMutation(createTimeOff, {
    onSuccess,
  });
  const { mutate: onUpdateTimeOff, isLoading: onUpdateTimeOffLod } = useMutation(updateTimeOff, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && cusromerData) {
      const editItem = cusromerData?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['fromDate']), dayjs(dataObj['toDate'])];
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, cusromerData, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['fromDate'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['toDate'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = cusromerData?.model?.id;
      return onUpdateTimeOff(dataObj);
    }

    onCreateTimeOff(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: 'Payroll' }, { title: <Link to="/human-resources/payroll/work-entry/time-off">Time Off</Link> }]}
        current={isEdit ? 'Update' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="nameEN" label="Name English" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Name Arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="mode" label="Mode" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="fromTo" label="From - To" rules={[{ required: true }]}>
              <DatePicker.RangePicker
                className="w-full"
                format="YYYY-MM-DD"
                disabledDate={current => {
                  // Can not select days before today and today
                  return current && current < dayjs().endOf('day');
                }}
              />
            </Form.Item>
            <Form.Item name="duration" label="Duration" rules={[{ required: true }]}>
              <InputNumber className="w-full" />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true }]}>
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
            <Space>
              <Form.Item name="halfDay" label=" " valuePropName="checked">
                <Checkbox>Half Day</Checkbox>
              </Form.Item>
              <Form.Item name="customHours" label=" " valuePropName="checked">
                <Checkbox>Custom Hours</Checkbox>
              </Form.Item>
            </Space>
            <Form.Item name="timeOffTypeId" label="Time Off Type" rules={[{ required: true }]}>
              <Select
                options={metadata?.timeOffTypes?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="timeOffStatusId" label="Time Off Status" rules={[{ required: true }]}>
              <Select
                options={metadata?.timeOffStatuses?.map(e => ({
                  label: e?.nameEN,
                  value: e?.id,
                }))}
              />
            </Form.Item>
            <Form.Item name="employeeId" label="Employee" rules={[{ required: true }]}>
              <Select
                // options={metadata?.employees?.map(el => ({
                //   value: el?.id,
                //   label: el?.name,
                // }))}
                options={[{ label: 'Wagdi Engine', value: 'aebdbdf2-f872-4bdb-9a16-08dbf372011a' }]}
              />
            </Form.Item>
            <Form.Item name="companyId" label="Organization" rules={[{ required: true }]}>
              <Select
                options={[
                  {
                    value: userData?.legalEntity?.companyId,
                    label: 'Our Company',
                  },
                ]}
              />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateTimeOffLod || onUpdateTimeOffLod}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default TimeOffNew;

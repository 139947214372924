import { App, Button, Progress, Table } from 'antd';
import React, { useState } from 'react';
import ActionsMenu from 'components/common/ActionsMenu';
import ModalAddSkill from 'components/modals/ModalAddSkill';
import useAppraisalSkillList from 'hooks/api/useAppraisalSkillList';

function TabSkills({ appraisalData }) {
  console.log('TabSkills  appraisalData:', appraisalData);
  const { message } = App.useApp();
  const [isSkillMod, setIsSkillMod] = useState(false);
  const [editSkill, setEditSkill] = useState(null);

  const { appraisalSkills, appraisalSkillsLod } = useAppraisalSkillList(appraisalData?.id);
  console.log('TabSkills  appraisalSkills:', appraisalSkills);

  const columns = [
    {
      key: 'Skill',
      dataIndex: 'Skill',
      title: 'Skill',
    },
    {
      key: 'Skill level',
      dataIndex: 'Skill level',
      title: 'Skill level',
      render: (val, _) => {
        return <span>good({val}%)</span>;
      },
    },
    {
      key: 'Progress',
      dataIndex: 'Progress',
      title: 'Progress',
      render: (val, _) => {
        return <Progress percent={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => message.warning('No API for this action!'),
              // onClick: () => setEditSkill(record),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => message.warning('No API for this action!'),
              // onClick: () => onDeleteCustomer(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = appraisalSkills?.map(s => ({
    Skill: s?.skill,
    'Skill level': s?.skillLevel,
    Progress: s?.skillLevel,
  }));

  if (appraisalData)
    return (
      <>
        <div className="">
          <div className="flex items-center justify-between gap-x-10 gap-y-4 flex-wrap mb-6 text-sm ">
            <h4 className="">Manager's Feedback</h4>
            <Button type="primary" onClick={() => setIsSkillMod(true)}>
              Add Skill
            </Button>
          </div>
          <Table dataSource={data} sticky columns={columns} size="small" pagination={false} />
        </div>
        <ModalAddSkill
          open={isSkillMod || !!editSkill}
          onCancel={() => {
            setIsSkillMod(false);
            setEditSkill(null);
          }}
          appraisalData={appraisalData}
          editItem={editSkill}
        />
      </>
    );

  return <h1 className="pt-6 text-center ">Skills tab will be active once the appraisal is confirmed.</h1>;
}

export default TabSkills;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getProducts = catchAsync(async (id) => {
  const { data } = await axiosInstance.get("/Product", {
    params: {
      id,
    },
  });
  return data;
});

export const addProducts = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/Product", body);
  return data;
});

export const getProduct = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/Product/${id}`);
  return data;
});

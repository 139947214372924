import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Switch, Table, App } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useTimeOffList from 'hooks/api/useTimeOffList';
import { deleteTimeOff } from 'network/Human-resources/time-off';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { dateFormat } from 'utils/utils';

function TimeOff() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteTimeOff } = useMutation(deleteTimeOff, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.timeOffList]);
    },
  });

  const { timeOffList } = useTimeOffList();

  const columns = [
    {
      key: 'nameEN',
      dataIndex: 'nameEN',
      title: 'name',
    },
    {
      key: 'employeeId',
      dataIndex: 'employeeId',
      title: 'employee',
    },
    {
      key: 'timeOffTypeId',
      dataIndex: 'timeOffTypeId',
      title: 'Time Off Type',
    },
    {
      key: 'timeOffStatusId',
      dataIndex: 'timeOffStatusId',
      title: 'Time Off Status',
    },
    {
      key: 'fromDate',
      dataIndex: 'fromDate',
      title: 'from Date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'toDate',
      dataIndex: 'toDate',
      title: 'to Date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteTimeOff(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = timeOffList;

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: 'Human Resources' }, { title: 'Payroll' }, { title: 'Work entry' }]} current="Time Off" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table dataSource={data} sticky columns={columns} />
    </div>
  );
}

export default TimeOff;

import { Form, Input } from 'antd';
import ModalAccount from 'components/modals/ModalAccount';
import React, { useState } from 'react';

function AccountField({ form, name, lable = 'Account', required = true }) {
  const [isAccountMod, setIsAccountMod] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  console.log('AccountField  selectedValues:', selectedValues);
  return (
    <>
      <Form.Item name={name} label={lable} rules={[{ required }]}>
        <Input onClick={() => setIsAccountMod(true)} readOnly />
      </Form.Item>
      <ModalAccount
        open={isAccountMod}
        onCancel={() => setIsAccountMod(false)}
        form={form}
        name={name}
        setIsAccountMod={setIsAccountMod}
        setSelectedValues={setSelectedValues}
      />
    </>
  );
}

export default AccountField;

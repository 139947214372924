'use client';

import { css } from '@emotion/css';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

// Items EX:
// [
//     {
//         title:  <Link href='/'>{t(`About`)}</Link>
//     }
// ]

function OwnBreadcrumb({ items = [], current, noHome, separator, ...rest }) {
  const OwnBreadcrumbStyles = css`
    .ant-breadcrumb-link {
      color: #212121;
      text-transform: capitalize;

      /* a {
        color: #078bcc;
        height: auto;
        &:hover {
          background-color: unset;
        }
      } */
    }

    .ant-breadcrumb-separator {
      color: #777;
    }
  `;

  const renderItems = noHome
    ? items
    : [
        {
          title: <Link to="/">Home</Link>,
        },
        ...items,
      ];

  return (
    <Breadcrumb
      className={OwnBreadcrumbStyles}
      // separator={
      //   separator || (
      //     <MdOutlineChevronRight size={22} className="rtl:rotate-180" />
      //   )
      // }
      items={[
        ...renderItems,
        {
          title: current,
        },
      ]}
      {...rest}
    />
  );
}

export default OwnBreadcrumb;

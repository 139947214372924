import PayableOptions from "pages/account-payable/payable-options/PayableOptions";
import Suppliers from "pages/account-payable/suppliers/suppliers";
import PayableInvoices from "pages/account-payable/payable-invoices/PayableInvoices";
import RecurringInvoices from "pages/account-payable/recurring-invoices/RecurringInvoices";
import SpecialCalender from "pages/account-payable/special-calender/SpecialCalender";
import Distributions from "pages/account-payable/distributions/Distributions";

const routesAccountPayable = [
  {
    path: "account-payable",
    children: [
      {
        path: "payable-options",
        children: [
          {
            index: true,
            element: <PayableOptions />,
          },
        ],
      },
      {
        path: "invoice",
        children: [
          {
            index: true,
            element: <PayableInvoices />,
          },
        ],
      },
      {
        path: "recurring-invoices",
        children: [
          {
            index: true,
            element: <RecurringInvoices />,
          },
        ],
      },
      {
        path: "distributions",
        children: [
          {
            index: true,
            element: <Distributions />,
          },
        ],
      },
      {
        path: "special-calender",
        children: [
          {
            index: true,
            element: <SpecialCalender />,
          },
        ],
      },
      {
        path: "suppliers",
        children: [
          {
            index: true,
            element: <Suppliers />,
          },
        ],
      },
    ],
  },
];

export default routesAccountPayable;

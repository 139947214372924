import { useQuery } from '@tanstack/react-query';
import { getAllAccounts } from 'network/accounts';
import { queryKeys } from 'services/react-query/queryKeys';

function useAccountList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.accountList, searchQueryStr], () => getAllAccounts(searchQueryStr));

  const accountList = data?.model;

  return {
    accountList,
    accountListLod: isLoading,
  };
}

export default useAccountList;

import useSearchQuery from 'hooks/useSearchQuery';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const OwnScrollToTop = () => {
  const { pathname } = useLocation();
  const { searchQueryStr } = useSearchQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log('useEffect  closeAllDrawers', closeAllDrawers);
  }, [pathname, searchQueryStr, dispatch]);

  return null;
};
export default OwnScrollToTop;

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Divider, Form, Input, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { addBadgetaryControls } from 'network/general-ledger/budget';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalBudgetaryControl({ budget, onCancel }) {
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const client = useQueryClient();
  const { mutate: onAddBadgetaryControls, isLoading: addBadgetaryControlsLod } = useMutation(addBadgetaryControls, {
    onSuccess: res => {
      console.log('ModalBudgitRanges  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.budgetList]);

      form.resetFields();
      onCancel();
    },
  });

  const onFinish = values => {
    console.log('ModalBudgetaryControl  values:', values);
    const budgetaryControls = values?.names?.map(el => ({
      fundsCheckLevelId: el.fundsCheckLevelId,
      amountTypeId: el.amountTypeId,
      boundary: el.amountTypeId === 0 ? 'Period' : el.amountTypeId === 1 ? 'Year' : 'Quarter',
    }));

    const dataObj = { budgetId: budget?.id, budgetaryControls };
    console.log('onFinish  dataObj:', dataObj);
    // return;
    onAddBadgetaryControls(dataObj);
  };
  return (
    <OwnModal title={'Budgetary control'} open={!!budget} onCancel={onCancel} width={1100}>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [''] }}>
        <Form.List name="names">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <FormListItem key={field.key} field={field} index={index} listLength={fields.length} form={form} remove={remove} />
                ))}
                <Divider />
                <Form.Item>
                  <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={addBadgetaryControlsLod}>
            Ok
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalBudgetaryControl;

const FormListItem = ({ field, index, form, listLength, remove }) => {
  const metadata = useMetaData();

  const amountTypeId = Form.useWatch(['names', index, 'amountTypeId'], form);
  const boundary = amountTypeId === 1 ? 'Period' : amountTypeId === 2 ? 'Year' : amountTypeId === 3 ? 'Quarter' : '';

  return (
    <div>
      <div className="col-3 bg-gray-50 p-3 rounded">
        <Form.Item name={[index, 'fundsCheckLevelId']} label="Funds Check Level">
          <Select options={metadata?.fundsCheckLevels?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
        </Form.Item>

        <Form.Item name={[index, 'amountTypeId']} label="Amount Type">
          <Select options={metadata?.budgetaryAmountTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
        </Form.Item>

        <Form.Item label="Boundary">
          <Input value={boundary} readOnly />
        </Form.Item>
      </div>

      {listLength > 1 ? (
        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
          Remove Above Field
        </Button>
      ) : null}
    </div>
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import {
  addSalesMember,
  addSalesTeam,
  getSalesTeam,
  getSalesTeamLead,
  getSalesTeamMembers,
} from "network/supply-chain/salesTeam";
import {
  addVendorPriceList,
  getVendorPriceList,
} from "network/supply-chain/vendorPriceList";

export const useVendorPriceList = () => {
  const { data, ...rest } = useQuery({
    queryFn: getVendorPriceList,
    queryKey: [queryKeys.vendorPriceList],
  });
  const vendorPriceList = data?.model;
  return { vendorPriceList, ...rest };
};

export const useCreateVendorPriceList = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addVendorPriceList,
    onSuccess: () => client.invalidateQueries([queryKeys.vendorPriceList]),
  });
  const { mutate, isLoading } = mutation;
  return { mutate, isLoading };
};

import { useQuery } from '@tanstack/react-query';
import { getAllWorkEntries } from 'network/Human-resources/work-entries';
import { queryKeys } from 'services/react-query/queryKeys';

function useWorkEntryList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.workEntryList, searchQueryStr], () => getAllWorkEntries(searchQueryStr));

  const workEntryList = data?.model;

  return {
    workEntryList,
    workEntryListLod: isLoading,
  };
}

export default useWorkEntryList;

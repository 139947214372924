import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDepartment } from "network/Human-resources/department";
import { addExpenses, getExpenses } from "network/Human-resources/expenses";
import { queryKeys } from "services/react-query/queryKeys";

export const useExpenses = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.expenses, id],
    queryFn: () => getExpenses(id),
  });
  const expensess = data?.model;
  return { expensess, ...rest };
};

export const useCreateExpenses = () => {
    const client = useQueryClient();
    const mutation = useMutation({
      mutationFn: addExpenses,
      onSuccess:()=>client.invalidateQueries([queryKeys.expenses])
    });
    mutation.createExpenses = mutation.mutate;
    mutation.isCreatingExpenses = mutation.isLoading;
    return mutation;
  };
  
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import usePaymentTermsList from 'hooks/api/usePaymentTermsList';
import { deletePaymentTerms } from 'network/account-receivable/payment-terms';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { dateFormat } from 'utils/utils';

function PaymentTerms() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeletePaymentTerms } = useMutation(deletePaymentTerms, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.paymentTermsList]);
    },
  });

  const { paymentTermsList } = usePaymentTermsList();

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'effectiveDateFrom',
      dataIndex: 'effectiveDateFrom',
      title: 'from',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'effectiveDateTo',
      dataIndex: 'effectiveDateTo',
      title: 'to',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeletePaymentTerms(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = paymentTermsList;
  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Payment Terms" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table dataSource={data} sticky columns={columns} />
    </div>
  );
}

export default PaymentTerms;

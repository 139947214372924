import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import TagElement from "components/common/TagElement";
import ModalSalesTeam from "components/modals/orders/ModalSalesTeam";
import { useSalesTeam, useSalesTeamMembers } from "hooks/api/supply-chain/sales-team/useSalesTeam";
import useMetaData from "hooks/api/useMetaData";

export default function SalesTeamMember() {
  const [modalsalesteam, setModalsalesteam] = useState(false);
  const { salesTeam, isLoading } = useSalesTeam();

  const navigate = useNavigate();
  const dataSource = [
    {
      nameEN: "team leader 1",
      salesLeadName: "seif@gmail.com",
      invoiceTarget: "20,000",
    },
  ];
  const columns = [
    {
      title: "Teams Name",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    {
      title: "Sales Lead Name",
      dataIndex: "salesLeadName",
      key: "salesLeadName",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Target",
      dataIndex: "invoiceTarget",
      key: "invoiceTarget",
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Sales Teams Member" />
        <SearchHero onAddBtn={() => setModalsalesteam(true)} />
        <Table
          isLoading={isLoading}
          dataSource={salesTeam}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalSalesTeam
        open={modalsalesteam}
        onCancel={() => setModalsalesteam(false)}
      />
    </>
  );
}

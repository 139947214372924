import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllReceipts = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Receipts?${searchQuery}`);
  return data;
});

export const getReceipt = catchAsync(async id => {
  const { data } = await axiosInstance(`/Receipts/${id}`);
  return data;
});

export const createReceipt = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Receipts`, body);
  return data;
});

export const updateReceipt = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Receipts`, body);
  return data;
});

export const deleteReceipt = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Receipts/${id}`);
  return data;
});

export const reverseReceipt = catchAsync(async receiptId => {
  const { data } = await axiosInstance(`/Receipts/Reverse/${receiptId}`);
  return data;
});

import Calendar from 'pages/general-ledger/Calendar';
import Period from 'pages/general-ledger/Period';
import StructureDefine from 'pages/general-ledger/setup/StructureXXX/StructureDefine';
import CurrencyDefine from 'pages/general-ledger/setup/currency/CurrencyDefine';
import BudgetEnterAmounts from 'pages/general-ledger/budget/enter/BudgetEnterAmounts';
import BudgetEnterTransfer from 'pages/general-ledger/budget/enter/BudgetEnterTransfer/BudgetEnterTransfer';
import BudgetDefine from 'pages/general-ledger/budget/BudgetDefine';
import SegmentDefine from 'pages/general-ledger/setup/StructureXXX/SegmentDefine';
import ValuesDefine from 'pages/general-ledger/setup/StructureXXX/ValuesDefine';
import BudgetNew from 'pages/general-ledger/budget/BudgetNew';
import DailyRates from 'pages/general-ledger/setup/currency/daily-rate/DailyRates';
import DailyRateNew from 'pages/general-ledger/setup/currency/daily-rate/DailyRateNew';
import JournalsManual from 'pages/general-ledger/journals/JournalsManual';
import JournalsManualNew from 'pages/general-ledger/journals/JournalsManualNew';
import JournalRecurringNew from 'pages/general-ledger/journals/JournalRecurringNew';
import JournalsRecurring from 'pages/general-ledger/journals/JournalsRecurring';
import Structures from 'pages/general-ledger/setup/Structure/Structures';
import Segments from 'pages/general-ledger/setup/Structure/segments/Segments';
import ValueSets from 'pages/general-ledger/setup/Structure/value-sets/ValueSets';
import Values from 'pages/general-ledger/setup/Structure/Values/Values';

const routesGeneralLedger = [
  {
    path: 'general-ledger',
    children: [
      {
        path: 'journals',
        children: [
          {
            path: 'manual',
            children: [
              {
                index: true,
                element: <JournalsManual />,
              },
              {
                path: 'new',
                element: <JournalsManualNew />,
              },
            ],
          },
          {
            path: 'automatic',
            children: [
              {
                index: true,
                element: <JournalsRecurring />,
              },
              {
                path: 'new',
                element: <JournalRecurringNew />,
              },
            ],
          },
        ],
      },
      {
        path: 'budget',
        children: [
          {
            path: 'define',
            element: <BudgetDefine />,
          },
          {
            path: 'new',
            element: <BudgetNew />,
          },
          {
            path: 'enter',
            children: [
              {
                path: 'amounts',
                element: <BudgetEnterAmounts />,
              },
              {
                path: 'transfer',
                element: <BudgetEnterTransfer />,
              },
            ],
          },
        ],
      },
      {
        path: 'setup',
        children: [
          {
            path: 'currency',
            children: [
              {
                index: true,
                element: <CurrencyDefine />,
              },
              {
                path: 'define',
                element: <CurrencyDefine />,
              },
              {
                path: 'daily-rate',
                children: [
                  {
                    index: true,
                    element: <DailyRates />,
                  },
                  {
                    path: 'new',
                    element: <DailyRateNew />,
                  },
                  {
                    path: 'edit/:id',
                    element: <DailyRateNew isEdit />,
                  },
                ],
              },
            ],
          },
          {
            path: 'structure',
            children: [
              {
                path: 'define',
                element: <Structures />,
              },
              {
                path: 'segments',
                element: <Segments />,
              },
              {
                path: 'value-sets',
                element: <ValueSets />,
              },
              {
                path: 'values',
                element: <Values />,
              },
            ],
          },
        ],
      },
      // {
      //   path: 'structure',
      //   children: [
      //     {
      //       path: 'define',
      //       element: <StructureDefine />,
      //     },
      //     {
      //       path: 'segments',
      //       children: [
      //         {
      //           path: 'define',
      //           element: <SegmentDefine />,
      //         },
      //         {
      //           path: 'values',
      //           element: <ValuesDefine />,
      //         },
      //         // {
      //         //   path: "segments/:id",
      //         //   element: <StructureSegmentsQualifiers />,
      //         // },
      //       ],
      //     },
      //   ],
      // },
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'period',
        element: <Period />,
      },
    ],
  },
];

export default routesGeneralLedger;

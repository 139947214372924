import {
  QueryCache,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import {
  create,
  delete_,
  get,
  getAllByExpenditureTypeId,
  update,
} from "network/project-managments/setup/rates";
import {
  getAllEmployees,
  getEmployee,
} from "network/Human-resources/employees";
import { getProduct, getProducts } from "network/products/products";
import {
  getAllFixedAssets,
  getFixedAsset,
} from "network/fixed-assets/fixedAssets";
import { get as getUOMs } from "network/project-managments/setup/unitsOfMeasures";
import dayjs from "dayjs";

const resourceFetchers = {
  1: { all: getAllEmployees, single: getEmployee }, //labor
  2: { all: getProducts, single: getProduct }, // materials
  3: { all: getAllFixedAssets, single: getFixedAsset }, // assets
};

export const useRatesByExpenditureTypeId = (expenditureTypeId) => {
  const query = useQuery({
    queryFn: () => getAllByExpenditureTypeId(expenditureTypeId),
    queryKey: [queryKeys.rates, expenditureTypeId],
    select: (data) => data?.model,
  });

  const ratesResources = useQueries({
    queries: query?.data?.map((rate) => {
      return {
        queryFn: () =>
          resourceFetchers[rate?.referenceTypeId].single?.(
            rate?.referenceId,
          ),
        queryKey: [queryKeys.rates, rate?.id, queryKeys.resources],
        select: (data) => data?.model,
        enabled: Boolean(resourceFetchers[rate?.referenceTypeId]),
      };
    }) ?? [],
  });

  const ratesUOMs = useQueries({
    queries: query?.data?.map((rate) => {
      return {
        queryFn: () => getUOMs(rate?.projectUnitOfMeasurementId),
        queryKey: [queryKeys.rates, rate?.id, queryKeys.unitsOfMeasurements],
        select: (data) => data?.model,
      };
    }) ?? [],
  });

  query.rates = query?.data?.map((rate, i) => {
    return {
      ...rate,
      uom: ratesUOMs?.[i]?.data,
      resource: {
        ...ratesResources?.[i]?.data,
        name: ratesResources?.[i]?.data?.nameEN ??
          ratesResources?.[i]?.data?.name,
      },
    };
  });

  return query;
};

export const useRate = (id) => {
  const query = useQuery({
    queryFn: () => get(id),
    queryKey: [queryKeys.rates, id],
    select: (data) => data?.model,
    enabled: Boolean(id),
  });

  query.rate = query.data;

  query.initialValues = {
    ...query?.rate,
    effectiveDate: [
      dayjs(query?.rate?.effectiveDateFrom),
      dayjs(query?.rate?.effectiveDateTo),
    ],
  };

  return query;
};

export const useCreateRate = (expenditureTypeId) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: create,
    queryKey: [queryKeys.rates, expenditureTypeId],
    onSuccess: () => {
      client.invalidateQueries([queryKeys.rates]);
    },
  });

  mutation.create = mutation.mutate;
  mutation.isCreating = mutation.isLoading;

  return mutation;
};

export const useUpdateRate = (expenditureTypeId) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: update,
    queryKey: [queryKeys.rates, expenditureTypeId],
    onSuccess: () => {
      // TODO: make a fine graind cache clearing
      client.clear();
    },
  });

  mutation.update = mutation.mutate;
  mutation.isUpdating = mutation.isLoading;

  return mutation;
};

export const useResource = (resourceType) => {
  const query = useQuery({
    queryFn: () => resourceFetchers?.[resourceType]?.all?.(),
    queryKey: ["rate-resource"],
    enabled: resourceFetchers?.[resourceType]?.all !== undefined &&
      Boolean(resourceType),
    select: (data) => data?.model,
  });

  query.resources = query.data?.map((resource) => {
    return {
      value: resource?.id,
      label: resourceType === 2 ? resource?.nameEN : resource.name,
    };
  });

  return query;
};

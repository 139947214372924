import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

// export const getAllFixedAssets = catchAsync(async (searchQuery = '') => {
//   const { data } = await axiosInstance(`/FixedAssets/Book?${searchQuery}`);
//   return data;
// });

// export const getFixedAsset = catchAsync(async id => {
//   const { data } = await axiosInstance(`/FixedAssets/Book/${id}`);
//   return data;
// });

export const createFABook = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/FixedAssets/Book`, body);
  return data;
});

// export const updateFixedAsset = catchAsync(async body => {
//   const { data } = await axiosInstance.put(`/FixedAssets/Book`, body);
//   return data;
// });

// export const deleteFixedAsset = catchAsync(async id => {
//   const { data } = await axiosInstance.delete(`/FixedAssets/Book/${id}`);
//   return data;
// });

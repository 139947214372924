import { Menu } from 'antd';
import BrandLogo from 'components/common/BrandLogo';
import {
  ApartmentOutlined,
  BankOutlined,
  BookOutlined,
  CompassOutlined,
  DollarOutlined,
  PushpinOutlined,
  ReconciliationOutlined,
  RiseOutlined,
  TagOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
function SidebarMenu() {
  const navigate = useNavigate();
  const getItem = (key, label, children, icon) => ({
    key,
    label,
    children,
    icon,
  });

  const items = [
    getItem('', 'Dashboard', null, <BankOutlined />),
    getItem(
      'Finance',
      'Finance',
      [
        // General Ledger
        getItem('general-ledger', 'General Ledger', [
          getItem('general-ledger/journals', 'Journals', [
            getItem('general-ledger/journals/manual', 'Manual'),
            getItem('general-ledger/journals/automatic', 'Automatic'),
          ]),
          getItem('general-ledger/budget', 'Budget', [
            getItem('general-ledger/budget/define', 'Define'),
            getItem('general-ledger/budget/enter', 'Enter', [
              getItem('general-ledger/budget/enter/amounts', 'Amounts'),
              getItem('general-ledger/budget/enter/transfer', 'Transfer'),
            ]),
          ]),
          getItem('general-ledger/setup', 'Setup', [
            getItem('general-ledger/setup/currency', 'Currency', [
              getItem('general-ledger/setup/currency/define', 'Define'),
              getItem('general-ledger/setup/currency/daily-rate', 'Daily Rate'),
            ]),
            getItem('general-ledger/setup/structure', 'Structure', [
              getItem('general-ledger/setup/structure/define', 'Define'),
              getItem('general-ledger/setup/structure/segments', 'Segments'),
              getItem('general-ledger/setup/structure/value-sets', 'Value Set'),
              getItem('general-ledger/setup/structure/values', 'Values'),
            ]),
          ]),
          getItem('general-ledger/calendar', 'Calendar'),
          getItem('general-ledger/period', 'Period'),
        ]),

        // Account Receivable
        getItem('account-receivable', 'Account Receivable', [
          getItem('account-receivable/invoices', 'Invoices', [
            getItem('account-receivable/invoices/sales-invoices', 'Sales Invoices'),
            getItem('account-receivable/invoices/credit-invoices', 'Credit invoices'),
          ]),
          getItem('account-receivable/receipts', 'Receipts'),
          getItem('account-receivable/customers', 'Customers', [
            getItem('account-receivable/customers/', 'Search'),
            getItem('account-receivable/customers/new', 'Create'),
            getItem('account-receivable/customers/payment-terms', 'Payment terms'),
          ]),

          getItem('account-receivable/setup', 'Setup', [
            getItem('account-receivable/setup/system-option', 'System option'),
            getItem('account-receivable/setup/periods', 'Periods'),
          ]),
        ]),

        // cash management
        getItem('cash-management', 'cash management', [
          getItem('cash-management/banks', 'Bank'),
          getItem('cash-management/transfers', 'Bank transfers'),
          getItem('cash-management/statement', 'Bank Statement'),
        ]),

        // Fixed Assets
        getItem('fixed-assets', 'Fixed Assets', [
          getItem('fixed-assets/asset-workbench', 'Asset Workbench'),
          getItem('fixed-assets/setup', 'Setup', [
            getItem('fixed-assets/setup/structure', 'Structure', [
              getItem('fixed-assets/setup/structure/define', 'Define'),
              getItem('fixed-assets/setup/structure/segments', 'Segments'),
              getItem('fixed-assets/setup/structure/value-sets', 'Value Set'),
              getItem('fixed-assets/setup/structure/values', 'Values'),
            ]),
            // getItem('fixed-assets/setup/structure', 'Structure', [
            //   getItem('fixed-assets/setup/structure/define', 'Define'),
            //   getItem('fixed-assets/setup/structure/segments', 'Segments', [
            //     getItem('fixed-assets/setup/structure/segments/define', 'Define'),
            //     getItem('fixed-assets/setup/structure/segments/values', 'Values'),
            //   ]),
            // ]),
            getItem('fixed-assets/setup/book', 'Book'),
          ]),
        ]),

        // Taxes
        getItem('Taxes', 'Taxes', [getItem('Taxes', 'Taxes')]),

        // Account Payable
        getItem('account-payable', 'Account Payable', [
          getItem('account-payable/options', 'Options', [
            getItem('account-payable/payable-options', 'Enter'),
            // getItem("account-payable/financial-options", "financial options"),
            // getItem("account-payable/payable-periods", "Payable periods"),
          ]),
          getItem('account-payable/suppliers', 'Suppliers'),
          getItem('account-payable/distributions', 'Distributions'),
          getItem('account-payable/special-calender', 'Special Calenders'),
          getItem('account-payable/invoice', 'Invoices'),
          getItem('account-payable/recurring-invoices', 'Recurring Invoices'),
        ]),
      ],
      <DollarOutlined />
    ),
    getItem(
      'Supply chain',
      'Supply chain',
      // [
      //   getItem("Inventory", "Inventory", [
      //     getItem("Operation", "Operation", [
      //       getItem("inventory/operation/transfers", "transfers"),
      //       getItem("inventory/operation/receipts", "receipts"),
      //       getItem("inventory/operation/deliveries", "deliveries"),
      //       getItem("inventory/operation/internal", "internal"),
      //     ]),
      //     getItem("products", "products", [
      //       getItem("inventory/products", "products"),
      //     ]),
      //   ]),
      // ],
      [
        getItem('Inventory', 'Inventory', [
          getItem('Operation/Operation', 'Operation', [
            // getItem('inventory/operation/transfers', 'transfers'),
            getItem('inventory/operation/receipts', 'receipts'),
            getItem('inventory/operation/deliveries', 'deliveries'),
            getItem('inventory/operation/internal', 'internal'),
          ]),
          getItem('inventory/inventory-configration', 'Inventory Configration'),
          // getItem("inventory/inventory-configration", "Inventory Configration"),
          getItem('warehouse/warehouse', 'Warehouse', [
            getItem('inventory/warehouse', 'Warehouse'),
            getItem('inventory/warehouse/locations', 'Locations'),
            getItem('inventory/warehouse/operation-types', 'Operation Types'),
            // getItem("inventory/warehouse/putaway-rules", "putaway rules"),
          ]),
          getItem('products/products', 'products', [
            getItem('inventory/products', 'Products'),
            getItem('inventory/products/categories', 'categories'),
            getItem('inventory/products/reordering', 'reordering'),
          ]),
        ]),
        getItem('orders', 'orders', [
          getItem('orders/vendor', 'Vendors', [
            getItem('orders/vendor/purchasing_request', 'purchasing request'),
            getItem('orders/vendor/purchasing_order', 'purchasing order'),
          ]),
          getItem('orders/customer', 'Customers', [
            getItem('orders/customer/purchasing_request', 'purchasing request'),
            getItem('orders/customer/purchasing_order', 'purchasing order'),
          ]),

          getItem('orders/vendor_priceLists', 'vendor priceLists'),
          // getItem("orders/sales-order", "sales order"),
          getItem('orders/sales_team_leaders', 'sales team leaders'),
          getItem('orders/sales_team_members', 'sales team members'),
        ]),
      ],
      <ReconciliationOutlined />
    ),
    getItem(
      'Project Managment',
      'Project Managment',
      [
        getItem('Setup', 'Setup', [
          // getItem('project-managment/setup/expenditure-category', 'Expenditure Category'),
          getItem('project-managment/setup/units-of-measures', 'Units of Measures'),
          getItem('project-managment/setup/expenditure-types', 'Expenditure Types'),
        ]),
        getItem('project-managment/projects', 'Projects'),
        getItem('project-managment/budget', 'Budget'),
      ],
      <ApartmentOutlined />
    ),
    // Human Resources
    getItem(
      'Human Resources',
      'Human Resources',
      [
        getItem('people', 'people', [getItem('human-resources/setup/applications', 'Applications'), getItem('human-resources/setup/employees', 'Employees')]),
        getItem('expenses', 'expenses', [
          getItem('human-resources/expenses', 'Expenses'),
          getItem('human-resources/expenses/expenses-category', 'Expenses Category'),
          getItem('human-resources/expenses/expenses-report', 'Expenses Report'),
        ]),
        getItem('human-resources/appraisals', 'Appraisals'),
        getItem('human-resources/goals', 'Goals'),
        getItem('human-resources/payroll', 'Payroll', [
          getItem('human-resources/payroll/contracts', 'Contracts'),
          getItem('human-resources/payroll/work-entry', 'Work Entry', [
            getItem('human-resources/payroll/work-entry/daily-entries', 'Daily Entries'),
            getItem('human-resources/payroll/work-entry/time-off', 'Time Off'),
          ]),
          getItem('human-resources/payroll/pay-slip', 'Pay Slip'),
        ]),

        getItem('Project-Setup', 'Setup', [
          getItem('human-resources/setup/departments', 'Departments'),
          getItem('human-resources/setup/work-locations', 'work locations'),
          getItem('human-resources/setup/work-times', 'work times'),
          // getItem('human-resources/setup/units-of-measures', 'Units of Measures'),

          getItem('human-resources/setup/work-entry', 'Work Entry', [
            getItem('human-resources/setup/work-entry/types', 'Types'),
            // getItem(
            //   "human-resources/setup/work-entry/working-schedules",
            //   "Working Schedules"
            // ),
          ]),
          getItem('human-resources/setup/appraisals', 'Appraisals', [getItem('human-resources/setup/appraisals/questions', 'Questions')]),
          getItem('human-resources/setup/salary', 'Salary', [
            getItem('human-resources/setup/salary/structure-types', 'Structure Types'),
            getItem('human-resources/setup/salary/structure', 'Structure'),
            getItem('human-resources/setup/salary/rules', 'Rules'),
          ]),
        ]),
      ],
      <TeamOutlined />
    ),

    // CRM
    getItem(
      'CRM',
      'CRM',
      [
        getItem('CRM/leads', 'Leads'),
        getItem('CRM/tasks', 'Tasks'),
        getItem('CRM/quotations', 'Quotations'),
        getItem('CRM/sales-teams', 'Sales Teams', [getItem('CRM/sales-teams/leaders', 'Leaders'), getItem('CRM/sales-teams/members', 'Members')]),
        getItem('CRM/customers', 'Customers'),
      ],
      <TagOutlined />
    ),
  ];
  return (
    <div>
      <div className="flex justify-center py-4 my-4 px-2">
        <BrandLogo />
      </div>
      <Menu
        // theme="dark"
        mode="inline"
        // theme="dark"
        items={items}
        // defaultSelectedKeys={['sub2']}
        defaultOpenKeys={['sub2']}
        onClick={({ key }) => {
          console.log('getItem  key:', key);
          navigate('/' + key);
        }}
      />
    </div>
  );
}

export default SidebarMenu;

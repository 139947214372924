import AccountSetupStep1 from "pages/auth/account-setup/AccountSetupStep1";
import AccountSetupStep2 from "pages/auth/account-setup/AccountSetupStep2";
import AccountSetupStep3 from "pages/auth/account-setup/AccountSetupStep3";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import UploadLogo from "pages/auth/UploadLogo";

const routesAuthentication = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/complete-data",
    children: [
      {
        index: true,
        element: <Register />,
      },
      {
        path: "logo",
        element: <UploadLogo />,
      },
      {
        path: "legal-entity",
        element: <AccountSetupStep1 />,
      },
      {
        path: "addresses",
        element: <AccountSetupStep2 />,
      },
      {
        path: "ledger",
        element: <AccountSetupStep3 />,
      },
    ],
  },
];

export default routesAuthentication;

import { useQuery } from '@tanstack/react-query';
import { getAllAPSpecialCalenders } from 'network/account-payable/special-calenders';
import { queryKeys } from 'services/react-query/queryKeys';

function useAPSpecialCalenderList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.APSpecialCalenderList, searchQueryStr], () => getAllAPSpecialCalenders(searchQueryStr));

  const APSpecialCalenderList = data?.model;

  return {
    APSpecialCalenderList,
    APSpecialCalenderListLod: isLoading,
  };
}

export default useAPSpecialCalenderList;

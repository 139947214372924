import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useAccountList from 'hooks/api/useAccountList';
import useMetaData from 'hooks/api/useMetaData';
import { addRecurringJournalLines } from 'network/general-ledger/journals';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalJournalsRecurringLines({ journalRecurring, onCancel, salesOrderLines, setSalesOrderLines }) {
  console.log('ModalJournalsRecurringLines  journalRecurring:', journalRecurring);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const metadata = useMetaData();
  const { accountList, accountListLod } = useAccountList();

  const client = useQueryClient();
  const { mutate: onAddRecurringJournalLines, isLoading: addRecurringJournalLinesLod } = useMutation(addRecurringJournalLines, {
    onSuccess: res => {
      console.log('BudgetNew  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.journalRecurringList]);

      form.resetFields();
      onCancel();
    },
  });

  // useEffect(() => {
  //   if (!!salesOrderLines?.length) {
  //     form.setFieldsValue({ salesOrderLines });
  //   } else {
  //     form.resetFields();
  //   }
  // }, [salesOrderLines?.length]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['recurringJournalId'] = journalRecurring?.id;

    dataObj['lines'] = dataObj?.names?.map(el => {
      const formulas = el?.names?.map(f => ({
        stepNumber: f?.stepNumber,
        amount: f?.amount,
        operatorId: f?.operatorId,
        accountId: f?.accountId,
        amountTypeId: f?.amountTypeId,
        balanceTypeId: f?.balanceTypeId,
      }));
      return {
        lineNumber: el.lineNumber,
        description: el?.description,
        accountId: el?.accountId,
        formulas,
      };
    });
    delete dataObj['names'];

    console.log('BudgetNew  dataObj:', dataObj);
    // return;
    onAddRecurringJournalLines(dataObj);
  };
  return (
    <OwnModal
      // title={editItem ? 'Edit Segment' : 'Add Segment'}
      // title={`Add lines to specific Journal in ${journalRecurring?.nameEN}`}
      title={<h2 className="title-32 mb-8">Add lines to specific Journal in {journalRecurring?.nameEN}</h2>}
      open={!!journalRecurring}
      onCancel={onCancel}
      width={1366}
      style={{ top: 20 }}>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ names: [{ names: [undefined] }] }}>
        <div className="col-2">
          <Form.Item label="Recurring Journal">
            <Input readOnly value={journalRecurring?.nameEN} />
          </Form.Item>
          <Form.Item name="journalId" label="Journal">
            <Select options={journalRecurring?.journals?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
          </Form.Item>
        </div>
        <Form.List name="names">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <Divider>Line {index + 1}</Divider>
                    <div className="bg-gray-50 p-3 rounded items-end">
                      <div className="col-3">
                        <Form.Item name={[index, 'lineNumber']} label="lineNumber">
                          <Input />
                        </Form.Item>
                        <Form.Item name={[index, 'description']} label="Description">
                          <Input.TextArea autoSize={{ maxRows: 1 }} />
                        </Form.Item>
                        <Form.Item name={[index, 'accountId']} label="Account">
                          <Select
                            loading={accountListLod}
                            options={accountList?.map(el => ({
                              value: el?.id,
                              label: el?.id,
                            }))}
                          />
                        </Form.Item>
                      </div>
                      <Form.List name={[index, 'names']}>
                        {(fields, { add, remove }) => {
                          return (
                            <div>
                              {fields.map((field, index) => (
                                <div key={field.key}>
                                  <Divider>Formula {index + 1}</Divider>
                                  <div className="bg-gray-50 p-3 rounded items-end">
                                    <div className="col-6">
                                      <Form.Item name={[index, 'stepNumber']} label="Step">
                                        <InputNumber className="w-full" />
                                      </Form.Item>
                                      <Form.Item name={[index, 'operatorId']} label="Operator">
                                        <Select options={metadata?.formulaOperators?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                                      </Form.Item>
                                      <Form.Item name={[index, 'amount']} label="Amount">
                                        <InputNumber className="w-full" />
                                      </Form.Item>
                                      <Form.Item name={[index, 'accountId']} label="Account">
                                        <Select
                                          loading={accountListLod}
                                          options={accountList?.map(el => ({
                                            value: el?.id,
                                            label: el?.id,
                                          }))}
                                        />
                                      </Form.Item>
                                      <Form.Item name={[index, 'balanceTypeId']} label="Balance Type">
                                        <Select options={metadata?.balanceTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                                      </Form.Item>
                                      <Form.Item name={[index, 'amountTypeId']} label="Amount Type">
                                        <Select options={metadata?.amountTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                                      </Form.Item>
                                    </div>
                                  </div>
                                  {fields.length > 1 ? (
                                    <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                                      Remove Above Formula
                                    </Button>
                                  ) : null}
                                </div>
                              ))}
                              <Divider />
                              <Form.Item>
                                <Button type="primary" ghost onClick={add} icon={<PlusOutlined />}>
                                  Add Formula
                                </Button>
                              </Form.Item>
                            </div>
                          );
                        }}
                      </Form.List>
                    </div>
                    {fields.length > 1 ? (
                      <Button type="primary" danger className="mt-2" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                        Remove Above Line
                      </Button>
                    ) : null}
                  </div>
                ))}
                <Divider />
                <Form.Item>
                  <Button type="primary" onClick={add} icon={<PlusOutlined />}>
                    Add Line
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Space wrap className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={addRecurringJournalLinesLod}>
            Done
          </Button>
          <Button type="primary" htmlType="reset" onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalJournalsRecurringLines;

import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import useModalParams from "hooks/useModalParams";
import PhoneInput from "react-phone-number-input";
import {
  useCreateJobApplication,
  useUpdateJobApplication,
} from "hooks/human-resourses/useJobApplication";
import useMetaData from "hooks/api/useMetaData";
import { useDepartments } from "hooks/human-resourses/useDepartment";
import dayjs from "dayjs";

function ApplicationModal({ open, handelClose, LocationId }) {
  const x = useMetaData();

  const { degrees, jobs, applicationStatuses } = useMetaData();
  const { departmentData } = useDepartments();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const { createJobApplication, isCreatingJobApplication } =
    useCreateJobApplication();
  const { updateJobApplication, isUpdateJobApplication } =
    useUpdateJobApplication();
  const onFinish = (values) => {
    if (!LocationId) {
      createJobApplication(values, {
        onSuccess: () => {
          message.success("Created successfully");
          handelClose();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      });
    } else {
      updateJobApplication(
        {
          ...values,
          id: LocationId,
          addressId: "2F1C2BD7-DB31-45BB-E54B-08DBA3695500",
        },
        {
          onSuccess: () => {
            message.success("Updated successfully");
            handelClose();
          },
          onError: (error) => message.error(`Error: ${error?.message}`),
        }
      );
    }
  };
  return (
    <OwnModal
      title={!LocationId ? "Add Application" : "Edit Application"}
      open={open}
      onCancel={() => handelClose()}
      // width={500}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-2 py-4">
          <Form.Item
            label="Application Name English"
            name="nameEN"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Application Name Arabic"
            name="nameAR"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Applicant's Name"
            name="applicantName"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true }]}>
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="Phone Number"
            label="phone"
            rules={[{ required: true }]}
          >
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item
            name="Mobile Number"
            label="mobile"
            rules={[{ required: true }]}
          >
            <PhoneInput defaultCountry="EG" placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item
            label="linkedin profile"
            name="linkedInProfile"
            rules={[{ required: true }]}
          >
            <Input type="url" />
          </Form.Item>
          <Form.Item
            label="Degree"
            name="degreeId"
            rules={[{ required: true }]}
          >
            <Select
              options={degrees?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="Applied Job"
            name="jobId"
            rules={[{ required: true }]}
          >
            <Select
              options={jobs?.map((s) => ({
                value: s?.id,
                label: s?.nameEN,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Department"
            name="departmentId"
            rules={[{ required: true }]}
          >
            <Select
              options={departmentData?.map((e) => ({
                label: e?.nameEN,
                value: e?.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Expected Salary"
            name="expectedSalary"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Proposed Salary"
            name="proposedSalary"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Available Date"
            name="availability"
            rules={[{ required: true }]}
          >
            <DatePicker disabledDate={disabledDate}/>
          </Form.Item>
          <Form.Item
            label="Extra Advanteges"
            name="extraAdvanteges"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="statusId"
            rules={[{ required: true }]}
          >
            <Select
              options={applicationStatuses?.map((e) => ({
                label: e?.nameEN,
                value: e?.id,
              }))}
            />
          </Form.Item>
        </div>
        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={
              LocationId ? isUpdateJobApplication : isCreatingJobApplication
            }
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default ApplicationModal;

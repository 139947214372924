import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getWarehouseLocation = catchAsync(async (id) => {
  const { data } = await axiosInstance.get(`/WarehouseLocation/${id}`);
  return data;
});

export const getWarehouseLocations = catchAsync(async () => {
  const { data } = await axiosInstance.get(`/WarehouseLocation`);
  return data;
});

export const getWarehouseByName = catchAsync(async (name) => {
  const { data } = await axiosInstance.get(
    `/WarehouseLocation/getbyname/${name}`,
  );
  return data;
});

export const createWarehouseLocation = catchAsync(async (post) => {
  const { data } = await axiosInstance.post(`/WarehouseLocation`, post);
  return data;
});

export const updateWarehouseLocation = catchAsync(async (post) => {
  const { data } = await axiosInstance.put(`/WarehouseLocation`, post);
  return data;
});

import { useMutation } from '@tanstack/react-query';
import { App, Button, Form, Input } from 'antd';
import BrandLogo from 'components/common/BrandLogo';
import { authLogin } from 'network/auth';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setUserData } from 'services/store/slices/user';
import { setStoredToken } from 'services/user-storage';

function Login() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const { mutate: onAuthLogin, isLoading } = useMutation(authLogin, {
    onSuccess: res => {
      console.log('Login  res:', res);
      if (!res)
        return message.error(
          'Please enter a correct email and password. Note that both fields may be case-sensitive.'
        );

      const userData = res.model;
      setStoredToken(userData?.token);
      dispatch(setUserData(userData));
      if (!userData?.userName || !userData?.phoneNumber) {
        message.info(`Please complate your data`);
        return navigate('/complete-data');
      }
      if (!userData?.logoImagePath) {
        message.info(`Please complate your data`);
        return navigate('/complete-data/logo');
      }
      if (!userData?.legalEntity) {
        message.info(`Please complate your data`);
        return navigate('/complete-data/legal-entity');
      }
      if (!userData?.address) {
        message.info(`Please complate your data`);
        return navigate('/complete-data/addresses');
      }
      if (!userData?.ledger) {
        message.info(`Please complate your data`);
        return navigate('/complete-data/ledger');
      }
      message.success(`Welcome back ${userData?.userName}`);
      navigate('/');
    },
    onError: error => {
      console.log('Login  error:', error);
      message.error(
        'Please enter a correct email and password. Note that both fields may be case-sensitive.'
      );
    },
  });

  return (
    <div className="auth-box-wrapper">
      <div className="auth-box-holder">
        <div className="flex justify-center mb-9">
          <BrandLogo />
        </div>
        <Form className="form-style" layout="vertical" onFinish={onAuthLogin}>
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input placeholder="Write Email, User name" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="Write Here" />
          </Form.Item>

          <Link to="/forgot-password" className="block w-fit ms-auto">
            Forget Password
          </Link>

          <Button htmlType="submit" type="primary" block loading={isLoading}>
            LOGIN
          </Button>
          <p className="account-register-row">
            Don't have an account?
            <Link to="/register">Sign up</Link>
          </p>
        </Form>
      </div>
    </div>
  );
}

export default Login;

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getAllSuppliers = catchAsync(async (searchQuery = "") => {
  const { data } = await axiosInstance(`/Suppliers?${searchQuery}`);
  return data;
});

export const getSupplier = catchAsync(async (id) => {
  const { data } = await axiosInstance(`/Suppliers/${id}`);
  return data;
});

export const createSupplier = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/Suppliers`, body);
  return data;
});

export const updateSupplier = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/Suppliers`, body);
  return data;
});

export const deleteSupplier = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/Suppliers/${id}`);
  return data;
});

export const searchSupplier = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/Suppliers/Search`, body);
  return data;
});

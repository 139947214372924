import Taxes from 'pages/taxes/taxes/Taxes';

const routesTaxes = [
  {
    path: 'taxes',
    children: [
      {
        index: true,
        element: <Taxes />,
      },
    ],
  },
];

export default routesTaxes;

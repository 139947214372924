import { css } from '@emotion/css';
import { Breadcrumb, Button, Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import React, { useState } from 'react';
import SidebarMenu from './SidebarMenu';
import { MenuFoldOutlined, MenuUnfoldOutlined, UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
const { Content, Sider, Header, Footer } = Layout;

function OwnLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const OwnLayoutStyles = css`
    .ant-layout-sider-children {
      min-height: 100%;
      height: auto;
    }
  `;

  return (
    <div className={OwnLayoutStyles}>
      <Layout>
        <Sider
          width={282}
          theme="light"
          breakpoint="lg"
          // collapsedWidth="0"
          collapsed={collapsed}
          collapsible
          onBreakpoint={broken => {
            console.log(broken);
          }}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'sticky',
            left: 0,
            top: 0,
            bottom: 0,
          }}
          onCollapse={value => setCollapsed(value)}>
          <SidebarMenu />
        </Sider>
        <Layout className="min-h-screen">
          <Header
          // style={{
          //   padding: 0,
          //   background: colorBgContainer,
          // }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
              }}
            />
          </Header>
          <Content className="bg-whit m-[2.8vw] ">
            {/* <Breadcrumb style={{ margin: '10px 0' }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb> */}
            <Outlet />
          </Content>
          {/* <Footer style={{ textAlign: "center", backgroundColor: "#FFF" }}>
            ERP ©2023 Created by ERP
          </Footer> */}
        </Layout>
      </Layout>
    </div>
  );
}

export default OwnLayout;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllPayslips = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Payslips?${searchQuery}`);
  return data;
});

export const getPayslip = catchAsync(async id => {
  const { data } = await axiosInstance(`/Payslips/${id}`);
  return data;
});

export const createPayslip = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Payslips`, body);
  return data;
});

export const updatePayslip = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Payslips`, body);
  return data;
});

export const deletePayslip = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Payslips/${id}`);
  return data;
});

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useMetaData from 'hooks/api/useMetaData';
import { createAppraisalSkill } from 'network/Human-resources/appraisal';
import React, { useEffect, useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalAddSkill({ open, onCancel, appraisalData, editItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [actionAfterSubmit, setActionAfterSubmit] = useState('close');
  const metadata = useMetaData();

  const client = useQueryClient();
  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys['appraisalSkillList']]);
    form.resetFields();
    if (actionAfterSubmit !== 'new') onCancel();
  };

  const { mutate: onCreateAppraisalSkill, isLoading: createAppraisalSkillLod } = useMutation(createAppraisalSkill, { onSuccess });
  const { mutate: onUpdateAppraisalSkill, isLoading: updateAppraisalSkillLod } = useMutation(undefined, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalAddSkill  dataObj:', dataObj);

    if (actionAfterSubmit === 'new') {
      form.resetFields();
    } else {
      onCancel();
      form.resetFields();
    }
    // return;
    if (editItem) {
      dataObj.id = editItem.id;

      onUpdateAppraisalSkill(dataObj);
    } else {
      onCreateAppraisalSkill(dataObj);
    }
  };

  // const handleFormSubmit = (action)=>{
  //   onFinish()
  // }
  return (
    <OwnModal title={editItem ? 'Edit Skill' : 'Add Skill'} open={open} onCancel={onCancel} width={750}>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="employeeAppraisalId" initialValue={appraisalData?.id} hidden />
        <Form.Item name="skillTypeId" label="Skill Type" rules={[{ required: true }]}>
          <Select
            options={metadata?.employeeAppraisalSkillTypes?.map(el => ({
              value: el?.id,
              label: el?.nameEN,
            }))}
          />
        </Form.Item>
        <div className="col-2">
          <Form.Item name="skill" label="Skill">
            <Input />
          </Form.Item>
          <Form.Item name="skillLevel" label="Skill Level" rules={[{ required: true }, { type: 'number', max: 100 }]}>
            <InputNumber className="w-full" suffix="%" />
          </Form.Item>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" onClick={() => setActionAfterSubmit('close')} loading={createAppraisalSkillLod || updateAppraisalSkillLod}>
            Save & Close
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => setActionAfterSubmit('new')} loading={createAppraisalSkillLod || updateAppraisalSkillLod}>
            Save & New
          </Button>
          <Button type="primary" ghost htmlType="reset" onClick={onCancel}>
            Discard
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalAddSkill;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useJournalManualList from 'hooks/api/useJournalManualList';
import { postManualJournal } from 'network/general-ledger/journals';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { dateFormat } from 'utils/utils';
import JournalsManualSearch from './JournalsManualSearch';

function JournalsManual() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [filterdList, setFilterdList] = useState([]);
  const [isfilterMood, setIsfilterMood] = useState(false);

  const client = useQueryClient();
  const { mutate: onPostManualJournal } = useMutation(postManualJournal, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.journalManualList]);
    },
  });
  const { journalManualList } = useJournalManualList();

  const columns = [
    {
      key: 'nameEN',
      dataIndex: 'nameEN',
      title: 'name',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'effectiveDateFrom',
      dataIndex: 'effectiveDateFrom',
      title: 'Date From',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'effectiveDateTo',
      dataIndex: 'effectiveDateTo',
      title: 'Date To',
      render: (val, _) => dateFormat(val),
    },

    {
      key: 'posted',
      dataIndex: 'posted',
      title: 'posted',
      render: (val, record) => {
        return !!val ? dateFormat(record?.postedAt) : 'No';
      },
    },
    // {
    //   key: 'isDeleted',
    //   dataIndex: 'isDeleted',
    //   title: 'Deleted',
    //   render: (val, _) => {
    //     return <Switch checked={val} />;
    //   },
    // },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'post',
              label: 'Post',
              onClick: () => onPostManualJournal(record?.id),
              disabled: record?.posted,
            },
            {
              key: 'check-funds',
              label: 'Check funds',
              onClick: () => message.warning('No API for this action'),
            },
            {
              key: 'reserve-funds',
              label: 'Reserve funds',
              onClick: () => message.warning('No API for this action'),
            },
            // {
            //   key: 'edit',
            //   label: 'Edit',
            //   onClick: () => navigate(`edit/${record.id}`),
            //   disabled: record?.isDeleted,
            // },
            // {
            //   key: 'Delete',
            //   label: 'Delete',
            //   onClick: () => onDeleteReceipt(record?.id),
            //   disabled: record?.isDeleted,
            // },
          ]}
        />
      ),
    },
  ];
  const data = isfilterMood ? filterdList : journalManualList;

  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Manual Journals" />
      <SearchHero
        onAddBtn={() => navigate('/general-ledger/journals/manual/new')}
        extra={<JournalsManualSearch setFilterdList={setFilterdList} isfilterMood={isfilterMood} setIsfilterMood={setIsfilterMood} />}
      />
      <Table
        dataSource={data}
        sticky
        columns={columns}
        // rowSelection={rowSelection}
        // scroll={{ y: '66vh' }}
        // className="overflow-auto min-w-max"
      />
    </div>
  );
}

export default JournalsManual;

import { Button, Checkbox, Form, Input, message, Select, Spin } from "antd";
import OwnModal from "components/own/OwnModal";
import useCreateWarehouse from "hooks/api/supply-chain/warehouse/useCreateWarehouse";
import useWarehouse from "hooks/api/supply-chain/warehouse/useWarehouse";
import useWarehouseLocations from "hooks/api/supply-chain/warehouseLocation/useWarehouseLocations";
import useSearchQuery from "hooks/useSearchQuery";

function ModalWarehouse(props) {
  const [form] = Form.useForm();
  const { searchQueryObj, setSearchQuery } = useSearchQuery();
  const { mode, open, id } = searchQueryObj;
  const { locations } = useWarehouseLocations();
  const { create, isCreating } = useCreateWarehouse();
  const isEditingMode = mode === "edit";
  const isOpen = open === "true";
  const { warehouse, isFetching } = useWarehouse({
    enabled: isEditingMode,
    id,
  });

  const close = () => {
    setSearchQuery({});
    form.resetFields();
  };

  const handleOnFinish = (values) => {
    !isEditingMode &&
      create(values, {
        onSuccess: () => {
          message.success("Warehouse Created Successfully");
          close();
        },
        onError: () => message.error("Error happend during creating warehouse"),
      });
  };

  return (
    <OwnModal
      {...props}
      title={isEditingMode ? "Edit Warehouse" : "New Warehouse"}
      open={isOpen}
      onCancel={close}
    >
      {(isFetching && isEditingMode)
        ? (
          <div className="flex flex-col gap-5 items-center justify-center py-36">
            <Spin />
            <p>Fetching Data</p>
          </div>
        )
        : (
          <Form
            form={form}
            layout="vertical"
            initialValues={isEditingMode ? warehouse : { buyToReSupply: false }}
            preserve={false}
            onFinish={handleOnFinish}
          >
            <div className="grid md:grid-cols-2  gap-3">
              <Form.Item
                label="Name"
                name="nameEN"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Location Stock"
                name="locationId"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={locations?.map((location) => ({
                    label: location?.nameEN,
                    value: location?.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label="Address"
                name="address"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="buyToReSupply"
                valuePropName="checked"
                className="col-span-2"
              >
                <Checkbox>Buy to Resupply</Checkbox>
              </Form.Item>
            </div>

            <div className="btn-wrraper mt-4 text-center ">
              <Button
                type="primary"
                htmlType="submit"
                className="block m-auto w-full max-w-xs"
                loading={isCreating}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
    </OwnModal>
  );
}

export default ModalWarehouse;

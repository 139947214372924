import { useQuery } from '@tanstack/react-query';
import { getAllFixedAssetStructures } from 'network/fixed-assets/fixedAssetStructures';
import { queryKeys } from 'services/react-query/queryKeys';

function useFixedAssetStructureList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.fixedAssetStructureList, searchQueryStr], () => getAllFixedAssetStructures(searchQueryStr));

  const fixedAssetStructureList = data?.model;

  return {
    fixedAssetStructureList,
    fixedAssetStructureListLod: isLoading,
  };
}

export default useFixedAssetStructureList;

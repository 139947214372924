import useSearchQuery from "./useSearchQuery";

/**
 * @param {entity} string a name that describes what type of module the modal is linked to, ie User, Product, Settings
 */
const useModalParams = (entity = "") => {
  const { searchQueryObj, setSearchQuery, removeParams } = useSearchQuery();
  const { m, e } = searchQueryObj;

  const isCurrent = e === entity;

  const isNew = (m === "new") && isCurrent;
  const isEdit = (m === "edit") && isCurrent;
  const isDelete = (m === "delete") && isCurrent;

  const isClosed = !isNew && !isEdit && !isDelete;

  const openAsNew = (opts = {}) =>
    setSearchQuery({ m: "new", e: entity, ...opts });

  const openAsEdit = (opts = {}) =>
    setSearchQuery({ m: "edit", e: entity, ...opts });

  const openAsDelete = (opts = {}) =>
    setSearchQuery({ m: "delete", e: entity, ...opts });

  const close = (...opts) => removeParams("m", "e", ...opts);

  return {
    isClosed,
    isNew,
    isEdit,
    isDelete,
    openAsNew,
    openAsEdit,
    openAsDelete,
    close,
    ...searchQueryObj,
  };
};

export default useModalParams;

import { useQuery } from '@tanstack/react-query';
import { getAllManualJournals } from 'network/general-ledger/journals';
import { queryKeys } from 'services/react-query/queryKeys';

function useJournalManualList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.journalManualList, searchQueryStr], () => getAllManualJournals(searchQueryStr));

  const journalManualList = data?.model;

  return {
    journalManualList,
    journalManualListLod: isLoading,
  };
}

export default useJournalManualList;

import { useQuery } from '@tanstack/react-query';
import { getAllStructures } from 'network/general-ledger/structure';
import { queryKeys } from 'services/react-query/queryKeys';

function useSalaryStructureList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.salaryStructureList, searchQueryStr], () => getAllStructures(searchQueryStr));

  const salaryStructureList = data?.model;

  return {
    salaryStructureList,
    salaryStructureListLod: isLoading,
  };
}

export default useSalaryStructureList;

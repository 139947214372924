import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useFixedAssetList from 'hooks/api/useFixedAssetList';
import { deleteFixedAsset } from 'network/fixed-assets/fixedAssets';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { arrayFrom, dateFormat } from 'utils/utils';

function AssetsWorkbench() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteFixedAsset } = useMutation(deleteFixedAsset, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.fixedAssetList]);
    },
  });
  const { fixedAssetList } = useFixedAssetList();

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'name',
    },
    {
      key: 'number',
      dataIndex: 'number',
      title: 'number',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'description',
    },
    {
      key: 'creationDate',
      dataIndex: 'creationDate',
      title: 'Creation Date',
      render: (val, _) => dateFormat(val),
    },

    {
      key: 'serialNumber',
      dataIndex: 'serialNumber',
      title: 'Serial Number',
    },
    {
      key: 'tagNumber',
      dataIndex: 'tagNumber',
      title: 'tag Number',
    },
    {
      key: 'units',
      dataIndex: 'units',
      title: 'units',
    },
    {
      key: 'originalCost',
      dataIndex: 'originalCost',
      title: 'original Cost',
    },

    {
      key: 'isInPhysicalInventory',
      dataIndex: 'isInPhysicalInventory',
      title: 'In Physical Inventory',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      key: 'isInUse',
      dataIndex: 'isInUse',
      title: 'In Use',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteFixedAsset(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = fixedAssetList;
  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Assets Workbench" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table
        dataSource={data}
        sticky
        columns={columns}
        // rowSelection={rowSelection}
        // scroll={{ y: '66vh' }}
        // className="overflow-auto min-w-max"
      />
    </div>
  );
}

export default AssetsWorkbench;

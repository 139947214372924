import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllAppraisalQuestions = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/EmployeeAppraisalQuestions?${searchQuery}`);
  return data;
});

export const getAppraisalQuestion = catchAsync(async id => {
  const { data } = await axiosInstance(`/EmployeeAppraisalQuestions/${id}`);
  return data;
});

export const createAppraisalQuestion = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/EmployeeAppraisalQuestions`, body);
  return data;
});

export const updateAppraisalQuestion = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/EmployeeAppraisalQuestions`, body);
  return data;
});

export const deleteAppraisalQuestion = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/EmployeeAppraisalQuestions/${id}`);
  return data;
});

import { FolderAddOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  Col,
  Row,
  Radio,
  DatePicker,
  message,
} from "antd";
import OwnModal from "components/own/OwnModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import {
  useCreateSalesMember,
  useCreateSalesTeam,
  useSalesTeamLeads,
  useSalesTeamMembers,
} from "hooks/api/supply-chain/sales-team/useSalesTeam";
import useMetaData from "hooks/api/useMetaData";
import { useEmployee } from "hooks/human-resourses/useEmployee";
export default function ModalSalesTeam(props) {
  const { mutate: mutateSalesTeam, isLoading: isLoadingSalesTeam } =
    useCreateSalesTeam();
  const { mutate: mutateSalesTeamMember, isLoading } = useCreateSalesMember();
  const { salesTeamMembers } = useSalesTeamMembers();
  const { employeeData } = useEmployee(); //employees
  const { salesTeam } = useSalesTeamLeads();
  console.log(salesTeamMembers, "salesTeamMembers");
  const x = useMetaData();
  console.log(salesTeam, "fkfkfk");
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (props.teamTypeMember) {
      mutateSalesTeamMember(values, {
        onSuccess: (res) => {
          if (res?.status == 200) {
            message.success(res?.data?.metas?.message);
            props.onCancel();
          } else {
            console.log(res, "errorss");
            message.error(res?.errors?.errors[0]?.errorMessage);
            // props.onCancel();
          }
        },
      });
    } else {
      mutateSalesTeam(values, {
        onSuccess: (res) => {
          console.log(res, "fpfpfp");
          if (res?.status == 200) {
            message.success(res?.data?.metas?.message);
            props.onCancel();
          } else {
            console.log(res, "errorss");
            message.error(res?.errors?.errors[0]?.errorMessage);
            // props.onCancel();
          }
        },
      });
    }
  };
  return (
    <OwnModal title={"Add Sales Team"} {...props}>
      <div className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <div className="grid md:grid-cols-2 gap-3">
              <Form.Item
                name="nameEN"
                label="Sales Team Name"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {props?.teamTypeMember && (
                <Form.Item
                  name={"employeeId"}
                  label="Employees"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    options={employeeData?.map((el) => ({
                      value: el?.id,
                      label: el?.name,
                    }))}
                  />
                </Form.Item>
              )}
              {!props.teamTypeMember && (
                <>
                  <Form.Item
                    name="salesLeadId"
                    label="Sales Team Leader"
                    // required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      // placeholder="Select a person"
                      // onChange={teamLeaderChange}
                      options={salesTeamMembers?.map((el) => ({
                        value: el?.id,
                        label: el?.nameEN,
                      }))}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    name="invoiceTarget"
                    label="Invoicing Target"
                    // required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
            </div>
            {!props.teamTypeMember && (
              <>
                {" "}
                <Divider />
                <h2 className="title-20 mb-4">Add Sales Member</h2>
                <Form.List
                  name="salesMembers"
                  initialValue={[{}]} // Initial value, can be an array with default items
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <div key={key}>
                          <div className="grid md:grid-cols-2 gap-2">
                            <Form.Item
                              {...restField}
                              name={[name, "nameEN"]}
                              fieldKey={[fieldKey, "nameEN"]}
                              label="Sales Person Name"
                              style={{ marginRight: 8, flex: 1 }}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              name={[name, "employeeId"]}
                              label="Employees"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Select
                                options={employeeData?.map((el) => ({
                                  value: el?.id,
                                  label: el?.name,
                                }))}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[name, "salesPersonTarget"]}
                              label="Sales Person Target"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            {fields.length > 1 && (
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(name);
                                }}
                                style={{
                                  margin: "8px 8px 0 0",
                                  fontSize: "20px",
                                  color: "#ff4d4f",
                                  marginBottom: "30px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add New Line
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}

            <Button
              type="primary"
              className="mt-4"
              htmlType="submit"
              loading={isLoading || isLoadingSalesTeam}
              // onClick={onAddNewLine}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </OwnModal>
  );
}

import { App, Button, DatePicker, Form, Input, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import useMetaData from 'hooks/api/useMetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { disabledDateBeforeToday } from 'utils/utils';
import { createEmployeeGoal, getEmployeeGoal, updateEmployeeGoal } from 'network/Human-resources/employee-goals';

function GoalNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const metadata = useMetaData();

  const client = useQueryClient();
  const { data: employeeGoal } = useQuery([queryKeys.employeeGoalList, params?.id], () => getEmployeeGoal(params?.id), {
    enabled: false,
    // enabled: !!params?.id,
  });
  // console.log('cusromerData', cusromerData);

  const onSuccess = res => {
    console.log('createCustome  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.employeeGoalList]);

    form.resetFields();
    navigate('/human-resources/goals');
  };

  const { mutate: onCreateEmployeeGoal, isLoading: onCreateEmployeeGoalLod } = useMutation(createEmployeeGoal, {
    onSuccess,
  });
  const { mutate: onUpdateEmployeeGoal, isLoading: onUpdateEmployeeGoalLod } = useMutation(updateEmployeeGoal, {
    onSuccess,
  });

  useEffect(() => {
    if (isEdit && employeeGoal) {
      const editItem = employeeGoal?.model || {};
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, employeeGoal, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = employeeGoal?.model?.id;
      return onUpdateEmployeeGoal(dataObj);
    }

    onCreateEmployeeGoal(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: 'Human Resources' }, { title: <Link to="/human-resources/goals">Goals</Link> }]}
        current={isEdit ? 'Details' : 'Create'}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="nameEN" label="Goal in english" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="nameAR" label="Goal in arabic" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item name="managerId" label="Manager" rules={[{ required: true }]}>
              <Select options={[{ label: 'Need list', value: '5f9b22aa-f007-4e4b-9a17-08dbf372011a' }]} />
            </Form.Item>
            <Form.Item name="employeeId" label="Employee" rules={[{ required: true }]}>
              <Select
                // options={employeeGoalList?.map(s => ({
                //   value: s?.id,
                //   label: s?.name + ` (${s.number})`,
                // }))}
                options={[{ label: 'Need list', value: 'aebdbdf2-f872-4bdb-9a16-08dbf372011a' }]}
              />
            </Form.Item>
            <Form.Item name="deadlineDate" label="Deadline" rules={[{ required: true }]}>
              <DatePicker disabledDate={disabledDateBeforeToday} />
            </Form.Item>

            <Form.Item name="progress" label="Progress" rules={[{ required: true }]}>
              <Select
                options={[
                  { value: '0', label: '0%' },
                  { value: '25', label: '25%' },
                  { value: '50', label: '50%' },
                  { value: '100', label: '100%' },
                ]}
              />
            </Form.Item>
            <Form.Item name="description" label="Descraption" className="col-span-full">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={onCreateEmployeeGoalLod || onUpdateEmployeeGoalLod}>
            {isEdit ? 'Update' : 'Add'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default GoalNew;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllAPSpecialCalenders = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/SpecialCalenders?${searchQuery}`);
  return data;
});

export const getAPSpecialCalender = catchAsync(async id => {
  const { data } = await axiosInstance(`/SpecialCalenders/${id}`);
  return data;
});

export const createAPSpecialCalender = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/SpecialCalenders`, body);
  return data;
});

export const updateAPSpecialCalender = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/SpecialCalenders`, body);
  return data;
});

export const deleteAPSpecialCalender = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/SpecialCalenders/${id}`);
  return data;
});

export const searchAPSpecialCalender = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/SpecialCalenders/Search`, body);
  return data;
});

import { axiosInstance } from "services/axiosInstance";
import catchAsync from "utils/catchAsync";

export const getAllBankTransfer = catchAsync(async (searchQuery) => {
  const { data } = await axiosInstance(`/BankTransfers?${searchQuery}`);
  return data;
});

export const getBankTransfer = catchAsync(async (id) => {
  const { data } = await axiosInstance(`/BankTransfers/${id}`);
  return data;
});

export const createBankTransfer = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/BankTransfers`, body);
  return data;
});

export const updateBankTransfer = catchAsync(async (body) => {
  const { data } = await axiosInstance.put(`/BankTransfers`, body);
  return data;
});

export const deleteBankTransfer = catchAsync(async (id) => {
  const { data } = await axiosInstance.delete(`/BankTransfers/${id}`);
  return data;
});
//   {
//     "id": "5c6dca52-c5d6-4ba5-7dc6-08dbb1553c56",
//     "statmentDate": "2023-09-09T00:00:00",
//     "currencyId": "657de0e3-ef94-46c9-346c-08dbb1395efd",
//     "documentNumber": "987456321258",
//     "glDate": "2023-09-09T00:00:00",
//     "checkDigits": "none",
//     "openingBalance": 20000,
//     "receipts": 2,
//     "payments": 200,
//     "closingBalance": 200000,
//     "availableBalance": 100000,
//     "valueDateBalance": "<string>",
//     "isReConciled": false,
//     "reConciledDate": null,
//     "bankStatementId": "00000000-0000-0000-0000-000000000000",
//     "bankStatementLines": []
// }

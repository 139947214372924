const USER_LOCALSTORAGE_KEY = 'user-data';
const TOKEN_LOCALSTORAGE_KEY = 'access-token';

// helper to get user from localstorage
export function getStoredUser() {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY);
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user) {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
}

export function clearStoredUser() {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
}
// export function getStoredUser() {
//   const storedUser = sessionStorage.getItem(USER_LOCALSTORAGE_KEY);
//   return storedUser ? JSON.parse(storedUser) : null;
// }

// export function setStoredUser(user) {
//   sessionStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
// }

// export function clearStoredUser() {
//   sessionStorage.removeItem(USER_LOCALSTORAGE_KEY);
// }

export function getStoredToken() {
  const storedToken = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
  return storedToken ? JSON.parse(storedToken) : null;
}

export function setStoredToken(token) {
  localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, JSON.stringify(token));
}

export function clearStoredToken() {
  localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
}

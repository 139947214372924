import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'utils/utils';
import { deleteSalesInvoice } from 'network/account-receivable/sales-invoice';
import { queryKeys } from 'services/react-query/queryKeys';
import useSalesInvoiceList from 'hooks/api/useSalesInvoiceList';

function Tasks() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteSalesInvoice } = useMutation(deleteSalesInvoice, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.salesInvoiceList]);
    },
  });

  const { salesInvoiceList } = useSalesInvoiceList();

  const columns = [
    {
      key: 'number',
      dataIndex: 'number',
      title: 'number',
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: 'date',
      render: (val, _) => dateFormat(val),
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: 'Active',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      key: 'isCompleted',
      dataIndex: 'isCompleted',
      title: 'Completed',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },
    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteSalesInvoice(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = salesInvoiceList;

  return (
    <div className="space-y-6">
      <OwnBreadcrumb current="Tasks" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table dataSource={data} sticky columns={columns} />
    </div>
  );
}

export default Tasks;

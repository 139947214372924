import { Tag } from "antd";
import React from "react";
export default function TagElement({ type }) {
  const hadelGetTag = () => {
    switch (type) {
      case 1:
        return <Tag color="success">Approved</Tag>;
      case 2:
        return <Tag color="error">error</Tag>;
      case 3:
        return <Tag color="processing">processing</Tag>;
      case 4:
        return <Tag color="warning">warning</Tag>;
      case 5:
        return <Tag color="success">active</Tag>;
      case 6:
        return <Tag color="warning">in active</Tag>;
      default:
        return <Tag color="default">default</Tag>;
    }
  };

  return <div>{hadelGetTag(1)}</div>;
}

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllCurrencies = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Currencies?${searchQuery}`);
  return data;
});

export const getCurrency = catchAsync(async id => {
  const { data } = await axiosInstance(`/Currencies/${id}`);
  return data;
});

export const createCurrency = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Currencies`, body);
  return data;
});

export const updateCurrency = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Currencies`, body);
  return data;
});

export const deleteCurrency = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Currencies/${id}`);
  return data;
});

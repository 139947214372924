import { Button, Form, Input, Select, Space, Table } from "antd";
import OwnModal from "components/own/OwnModal";

function ReturnModal({ onCancel, products, ...props }) {
  const [form] = Form.useForm();
  console.log({ products });
  const dataSource = [
    {
      product: "Product 1",
      quantity: 10000,
    },
  ];
  const columns = [
    {
      title: "Products",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Action",
      render: () => {
        return <Button danger>Delete</Button>;
      },
    },
  ];
  return (
    <OwnModal
      {...props}
      title="Return"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
    >
      <div>
        <Table
          dataSource={dataSource}
          columns={columns}
        />
        <Form>
          <Form.Item name="returnLocation" label="Return Location">
            <Select
              showSearch
              optionFilterProp="label"
              options={[
                {
                  label: "Warehouse - 1",
                  value: 1,
                },
                {
                  label: "Warehouse - 2",
                  value: 2,
                },
              ]}
            />
          </Form.Item>
          <Space>
            <Form.Item>
              <Button type="primary" htmlType="submit">Done</Button>
            </Form.Item>
            <Form.Item>
              <Button onClick={() => onCancel()}>Cancel</Button>
            </Form.Item>
          </Space>
        </Form>
      </div>
    </OwnModal>
  );
}

export default ReturnModal;

import { FolderAddOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
  Col,
  Row,
  Radio,
  DatePicker,
  message,
} from "antd";
import OwnModal from "components/own/OwnModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import { useCreateVendorPriceList } from "hooks/api/supply-chain/vendor-priceList/useVendorPriceList";
import useMetaData from "hooks/api/useMetaData";
import useSupplierList from "hooks/api/useSupplierList";
import { useProuducts } from "hooks/products/useProducts";

export default function ModalVendorPricelists(props) {
  const { productTypes, supplierTypes } = useMetaData();
  const { supplierList } = useSupplierList();
  const {productsData} =useProuducts()
  console.log(productsData,"productsData");
  const { mutate: mutateVendorPriceList, isLoading } =
    useCreateVendorPriceList();
  console.log(isLoading, "isLoadingisLoading");

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [vendor, setVendor] = useState("");
  const [product, setproduct] = useState("");

  const vendorChange = (value) => {
    console.log("search:", value);
    setVendor(value);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const productChange = (value) => {
    console.log("search:", value);
    setproduct(value);
  };

  const onFinish = (values) => {
    const vendor = {
      ...values,
      validFrom: values?.validity[0],
      validTo: values?.validity[1],
    };
    //validFrom validTo  2023-10-24T21:18:35.127Z
    mutateVendorPriceList(vendor, {
      onSuccess: (res) => {
        console.log(res,"sosso");
        if (res?.status == 200) {
          message.success(res?.data?.metas?.message);
          props.onCancel();
        } else {
          console.log(res, "errorss");
          // message.error(res?.response?.data?.ErrorMessage);
           props.onCancel();
        }
      },
    });
    console.log(values, "fifkfkfkf");
  };
  return (
    <OwnModal title={"Add Vendor Pricelist"} {...props}>
      <div className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <div className="grid md:grid-cols-2 gap-3">
              <Form.Item
                name="supplierId"
                label="Vendor"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  // placeholder="Select a person"
                  //   defaultValue="product1"
                  options={supplierList?.map((el) => ({
                    label: el?.name,
                    value: el?.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="productId"
                label="Product Name"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  // placeholder="Select a person"
                  //   defaultValue="product1"
                  options={productsData?.map((el) => ({
                    label: el?.nameEN,
                    value: el?.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="unitPrice"
                label="Unit Price"
                // required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="validity"
                label="Validity"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <RangePicker disabledDate={disabledDate} />
              </Form.Item>
            </div>

            <Button
              type="primary"
              className="mt-4"
              htmlType="submit"
              loading={isLoading}
              // onClick={onAddNewLine}
            >
              Submit
            </Button>
          </Form>
        </div>
      </div>
    </OwnModal>
  );
}

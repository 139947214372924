import OwnModal from "components/own/OwnModal";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import useModalParams from "hooks/useModalParams";
import useMetaData from "hooks/api/useMetaData";
import { useCreateDepartment } from "hooks/human-resourses/useDepartment";

function ExpensesCategoryModal({ open, handelClose }) {
  const res = useMetaData();
  const { createDepartment, isCreatingDepartment } = useCreateDepartment();
  console.log(res, "slsoso");
  const onFinish = (values) => {
    createDepartment(
      {
        ...values,
        managerId: "5f9b22aa-f007-4e4b-9a17-08dbf372011a",
        parentDepartmentId: null,
      },
      {
        onSuccess: () => {
          message.success("Created successfully");
          handelClose();
        },
        onError: (error) => message.error(`Error: ${error?.message}`),
      }
    );
  };
  return (
    <OwnModal
      title={open ? "Add Department" : "Edit Department"}
      open={open}
      onCancel={() => handelClose()}
      width={500}
      style={{ paddingBottom: 0 }}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <div className="col-1 py-4">
          <Form.Item
            label="Name English"
            name="nameEN"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Name Arabic"
            name="nameAR"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Reference"
            name="reference"
            rules={[{ required: true, message: "minimum 4 letters" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item className="my-0 py-0">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={isCreatingDepartment}
          >
            Create
          </Button>
        </Form.Item>
      </Form>
    </OwnModal>
  );
}

export default ExpensesCategoryModal;

import { Divider, Form, Input, Rate } from 'antd';
import { randomNumber } from 'utils/utils';

function TabAppraisals(appraisalQuestionList, appraisalQuestionListLod) {
  console.log('TabAppraisals  appraisalQuestionList:', appraisalQuestionList);
  return (
    <div className="">
      <div>
        <div className="flex items-center justify-between gap-x-10 gap-y-4 flex-wrap mb-6 text-sm ">
          <h4 className="">Employee's Feedback</h4>
          <span className="text-gray-500">Visible & Editable by Manager</span>
        </div>

        <div className="space-y-8">
          <div className="space-y-4">
            <h4 className="font-semibold mb-2">My Work</h4>
            {!!appraisalQuestionList?.appraisalQuestionList?.length &&
              appraisalQuestionList?.appraisalQuestionList?.map((q, i) => (
                <div key={q?.id} className="space-y-1">
                  <p>{q?.nameEN}</p>
                  <Form.Item name={['employeeAppraisalAnswers', i, 'question']} initialValue={q?.nameEN} hidden />
                  <Form.Item name={['employeeAppraisalAnswers', i, 'questionTypeId']} initialValue={q?.questionTypeId} hidden />
                  <Form.Item name={['employeeAppraisalAnswers', i, 'answer']} rules={[{ required: true }]}>
                    <Input.TextArea autoSize={{ maxRows: 1 }} />
                  </Form.Item>
                </div>
              ))}

            {/* <div className="space-y-1">
              <p>What do you know about us?</p>
              <Form.Item name={['employeeAppraisalAnswers', 1, 'question']} initialValue="What do you know about us?" hidden />
              <Form.Item name={['employeeAppraisalAnswers', 1, 'questionTypeId']} initialValue={2} hidden />
              <Form.Item name={['employeeAppraisalAnswers', 1, 'answer']} rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div> */}
          </div>

          {/* <div className="space-y-4">
            <h4 className="font-semibold mb-2">My future</h4>
            <div className="space-y-1">
              <p>What are my short and long-term goals with the company, and for my career?</p>
              <Form.Item name="future-ques1" rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div>
            <div className="space-y-1">
              <p>What are my short and long-term goals with the company, and for my career?</p>
              <Form.Item name="future-ques2" rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div>
            <div className="space-y-1">
              <p>What are my short and long-term goals with the company, and for my career?</p>
              <Form.Item name="future-ques3" rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div>
          </div>

          <div className="space-y-4">
            <h4 className="font-semibold mb-2">My feelings</h4>
            <div className="space-y-1">
              <p>How do I feel about the company...</p>
              <Form.Item name="feelings-ques1" rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div>
            <div className="space-y-1">
              <p>How do I feel about the company...</p>
              <Form.Item name="feelings-ques2" rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div>
            <div className="space-y-1">
              <p>How do I feel about the company...</p>
              <Form.Item name="feelings-ques3" rules={[{ required: true }]}>
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
            </div>
          </div> */}
        </div>
      </div>

      <div className="space-y-8 mt-12">
        <Divider>Manager's Feedback</Divider>
        <div className="">
          <h3 className="font-semibold">Feedback</h3>
          <p>Give one positive achievement that convinced you of the employee's value.</p>
          <p className="text-xs text-gray-500">Some achievements comforting you in their strengths to face job's issues.</p>
        </div>

        <div className="space-y-8">
          <div className="space-y-4">
            <h4 className="font-semibold mb-2">Evaluation</h4>
            <div className="divide-y divide-solid w-[420px] max-w-full border text-sm">
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Stress Resistance</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>

              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Time Management</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Teamwork</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Autonomy</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Pro-activity</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <h4 className="font-semibold mb-2">Improvements</h4>
            <div className="divide-y divide-solid w-[420px] max-w-full border text-sm">
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Stress Resistance</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>

              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Time Management</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Teamwork</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Autonomy</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
              <div className="flex-col-reverse sm:flex-row flex gap-x-8 items-center leading-loose sm:divide-x divide-solid px-3 py-2">
                <span className="flex-1">Pro-activity</span>
                <span className="flex-1">
                  <Rate count={5} value={randomNumber(0, 5)} style={{ fontSize: 14 }} className="ps-3" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabAppraisals;

import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Select, Table } from "antd";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";

const BudgetLines = () => {
  const params = useParams();
  const location = useLocation();

  const startDate = dayjs("2023-1-1");
  const endDate = dayjs("2023-5-1");

  const columns = [
    {
      title: "Expenditure Type",
    },
    ...getMonthsBetweenTwoDates(startDate, endDate).map((month) => ({
      title: month.format("MMM-YYYY"),
    })),
    {
      title: "Period total",
    },
  ];

  const breadcrumbItems = [
    {
      title: "Project Management",
    },
    {
      title: "Budget",
      href: "/project-managment/budget",
    },
    {
      title: "Budget Details",
    },
    {
      title: params?.projectId,
      href: location.pathname,
    },
    {
      title: "Budget Lines",
    },
  ];
  return (
    <>
      <OwnBreadcrumb items={breadcrumbItems} />
      <section>
        <div className="bg-white rounded-[9px] p-8 mt-7">
          <h1 className="font-bold text-xl">Budget Lines</h1>
          <div className="mt-2 flex items-center gap-4">
            <div className="flex-grow">
              <p className="py-2">Task Name</p>
              <div className="flex items-center gap-1">
                <Input
                  value="Initiate"
                  disabled
                  className="w-full"
                />
                <Button
                  className="flex-shrink-0"
                  type="primary"
                  icon={<ArrowUpOutlined />}
                />
                <Button
                  className="flex-shrink-0"
                  type="primary"
                  icon={<ArrowDownOutlined />}
                />
              </div>
            </div>
            <div>
              <p className="py-2">Task Number</p>
              <Input value="1.1" disabled />
            </div>
            <div>
              <p className="py-2">Version Name</p>
              <Input value="Draft" disabled />
            </div>
            <div>
              <p className="py-2">Version Number</p>
              <Input value="Draft" disabled />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="bg-white p-4 mt-6 rounded-[9px]">
            <p className="font-semibold mb-3">Budget Periods</p>
            <div className="flex items-center gap-4">
              <div>
                <p className="mb-2">From</p>
                <DatePicker value={dayjs("2023-1-1")} disabled />
              </div>
              <div>
                <p className="mb-2">To</p>
                <DatePicker value={dayjs("2023-5-1")} disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-7">
          <Table
            columns={columns}
            footer={(props) => {
              return <Table />;
            }}
          />
        </div>
      </section>
    </>
  );
};

const getMonthsBetweenTwoDates = (startDate, endDate) => {
  const months = [];
  const diff = endDate.diff(startDate, "month");
  for (let i = 0; i <= diff; i++) {
    const month = startDate.add(i, "month");
    months.push(month);
  }

  return months;
};

export default BudgetLines;

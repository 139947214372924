import { useQueryClient } from '@tanstack/react-query';
import { App, Button, Form, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useGLSegmentList from 'hooks/api/useFixedAssetSegmentList';
import React, { useEffect } from 'react';

function ModalAccount({ form, name, open, onCancel, setSelectedValues, setIsAccountMod }) {
  const { message } = App.useApp();
  const [formInner] = Form.useForm();
  const client = useQueryClient();

  const { GLSegmentList, GLSegmentListLod } = useGLSegmentList();
  console.log('ModalAccount  GLSegmentList:', GLSegmentList);

  // useEffect(() => {
  //   if (editItem) {
  //     console.log('useEffect  editItem:', editItem);
  //     const dataObj = { ...editItem };

  //     form.setFieldsValue(dataObj);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [editItem?.id]);

  const onFinish = values => {
    console.log('ModalAccount  values:', values);
    const dataObj = { ...values };
    console.log('onFinish  dataObj:', dataObj);
    setSelectedValues(Object.values(dataObj));
    setIsAccountMod(false);
    form.setFieldValue(name, Object.values(dataObj).join(' '));

    // if (editItem) {
    //   dataObj.id = editItem.id;
    //   onUpdateCalendar(dataObj);
    // } else {
    //   onCreateCalendar(dataObj);
    // }
  };
  return (
    <OwnModal title={'Account'} open={open} onCancel={onCancel} width={700}>
      <Form layout="vertical" form={formInner} onFinish={onFinish}>
        <div className="grid grid-cols-3 gap-6 font-semibold text-cente capitalize mb-6">
          <span>Segment</span>
          <span>Values</span>
          <span>description</span>
        </div>
        {GLSegmentList?.map(segment => (
          <div className="grid grid-cols-3 gap-6 items-baseline text-sm text-cente">
            <span>{segment?.valueSet?.nameEN}</span>
            <Form.Item name={segment?.column}>
              <Select
                // loading
                showSearch
                // onSearch={}
                options={GLSegmentList?.map(el => ({
                  value: el?.number,
                  label: el?.nameEN,
                }))}
              />
            </Form.Item>
            <span>{segment?.valueSet?.description}</span>
          </div>
        ))}

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit">
            DONE
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalAccount;

import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { debounce } from 'debounce';
import useSearchQuery from 'hooks/useSearchQuery';
import { useCallback, useState } from 'react';

function SearchHero({ onAddBtn, wait = 500, searchKey = 'word', extra }) {
  const { searchQueryObj, setSearchQuery } = useSearchQuery();
  const [inputSearch, setInputSearch] = useState(searchQueryObj[searchKey] || '');

  const request = debounce(query => {
    const queryObj = { ...searchQueryObj };
    queryObj[searchKey] = query;
    delete queryObj['page'];
    if (!queryObj[searchKey]) delete queryObj[searchKey];
    setSearchQuery(queryObj);
  }, wait);

  const debounceRequest = useCallback(query => request(query), []);

  const onChange = ({ target }) => {
    setInputSearch(target.value);
    debounceRequest(target.value);
  };

  return (
    <Space.Compact block size="large">
      <Input placeholder="Search" className="h-full w-full" value={inputSearch} onChange={onChange} />
      {!!extra && extra}
      <Button type="primary" icon={<PlusOutlined />} className="!w-32" onClick={onAddBtn} />
    </Space.Compact>
  );
}

export default SearchHero;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllCalendars = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/Calenders?${searchQuery}`);
  return data;
});

export const getCalendar = catchAsync(async id => {
  const { data } = await axiosInstance(`/Calenders/${id}`);
  return data;
});

export const createCalendar = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/Calenders`, body);
  return data;
});

export const updateCalendar = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/Calenders`, body);
  return data;
});

export const deleteCalendar = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/Calenders/${id}`);
  return data;
});

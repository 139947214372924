import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import useAPDistributionList from 'hooks/api/useAPDistributionList';
import useAPSpecialCalenderList from 'hooks/api/useAPSpecialCalenderList';
import useCurrencyList from 'hooks/api/useCurrencyList';
import useSupplierList from 'hooks/api/useSupplierList';
import { createAPRecurringInvoice, updateAPRecurringInvoice } from 'network/account-payable/recurring-invoices';
import React, { useEffect } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';

function ModalAddRecurringInvoices({ open, onCancel, editItem, setEditItem }) {
  const { currencyList } = useCurrencyList();
  const { supplierList } = useSupplierList();
  const { APDistributionList } = useAPDistributionList();
  const { APSpecialCalenderList } = useAPSpecialCalenderList();

  const { message } = App.useApp();
  const [form] = Form.useForm();

  const client = useQueryClient();

  const onSuccess = res => {
    console.log('ModalSegment  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.APRecurringInvoiceList]);

    form.resetFields();
    onCancel();
  };

  const { mutate: onCreateAPRecurringInvoice, isLoading: createAPInvoiceLod } = useMutation(createAPRecurringInvoice, { onSuccess });
  const { mutate: onUpdateAPRecurringInvoice, isLoading: updateAPInvoiceLod } = useMutation(updateAPRecurringInvoice, { onSuccess });

  useEffect(() => {
    if (editItem) {
      console.log('useEffect  editItem:', editItem);
      const dataObj = { ...editItem };
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [editItem, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    console.log('ModalStructure  dataObj:', dataObj);

    if (editItem) {
      dataObj.id = editItem.id;
      onUpdateAPRecurringInvoice(dataObj);
    } else {
      onCreateAPRecurringInvoice(dataObj);
    }
  };
  return (
    <>
      <OwnModal title={editItem ? 'Edit Recurring Invoices' : 'Add Recurring Invoices'} open={open} onCancel={onCancel} width={'90%'}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <div className="col-2">
            <Form.Item name="liabilityAccount" label="liability Account">
              <Input />
            </Form.Item>
            <Form.Item name="supplierId" label="Supplier" rules={[{ required: true }]}>
              <Select options={supplierList?.map(s => ({ value: s?.id, label: s?.name }))} />
            </Form.Item>

            <Form.Item name="distributionId" label="Distribution" rules={[{ required: true }]}>
              <Select options={APDistributionList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item name="specialCalenderId" label="Special Calender" rules={[{ required: true }]}>
              <Select options={APSpecialCalenderList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
          </div>

          <Divider>Invoice Definitions</Divider>
          <div className="col-2">
            <Form.Item name="number" label="Number">
              <Input />
            </Form.Item>
            <Form.Item name="description" label="description">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
            <Form.Item name="currencyId" label="Currency" rules={[{ required: true }]}>
              <Select options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
          </div>

          <Divider>Amounts</Divider>
          <div className="col-2">
            <Form.Item name="firstAmount" label="First Amount">
              <Input />
            </Form.Item>
            <Form.Item name="nextAmount" label="Next Amount">
              <Input />
            </Form.Item>
            <Form.Item name="remainingAmount" label="Remaining Amount">
              <Input />
            </Form.Item>
            <Form.Item name="amountChange" label="Amount Change">
              <InputNumber suffix="%" className="w-full" />
            </Form.Item>
            <Form.Item name="numberOfRegularInvoices" label="Number Of Regular Invoices">
              <InputNumber className="w-full" />
            </Form.Item>
          </div>
          <Space className="flex justify-end mt-4">
            <Button loading={createAPInvoiceLod || updateAPInvoiceLod} type="primary" htmlType="submit">
              {!!editItem ? 'Update Recurring Invoices' : 'Create Recurring Invoices'}
            </Button>
          </Space>
        </Form>
      </OwnModal>
    </>
  );
}

export default ModalAddRecurringInvoices;

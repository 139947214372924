import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createWarehouseLocation } from "network/supply-chain/warehouseLocation";
import { queryKeys } from "services/react-query/queryKeys";

const useCreateWarehouseLocation = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: createWarehouseLocation,
    onSuccess: () =>
      client.invalidateQueries([queryKeys.warehouseLocation]),
  });

  mutation.create = mutation.mutate;
  mutation.isCreating = mutation.isLoading;
  return mutation;
};

export default useCreateWarehouseLocation;

import OwnModal from "components/own/OwnModal";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import useModalParams from "hooks/useModalParams";
import { useUnitOfMeasures } from "hooks/api/projects-management/setup/unitOfMeasures";
import {
  useCreateRate,
  useRate,
  useResource,
  useUpdateRate,
} from "hooks/api/projects-management/setup/rates";
import { useExpenditureType } from "hooks/api/projects-management/setup/expenditureType";
import dayjs from "dayjs";
import OwnModalLoader from "components/own/OwnModalLoader";
import { useSelector } from "react-redux";

const NonLabor = 4;

function RateModal() {
  const { isNew, isEdit, close, rateId, expenditureTypeId } = useModalParams(
    "rate",
  );
  const { uoms } = useUnitOfMeasures();
  const { type } = useExpenditureType(expenditureTypeId);
  const { resources } = useResource(type?.projectCategoryId);
  const { initialValues, isFetching } = useRate(rateId);
  const { create, isCreating } = useCreateRate(expenditureTypeId);
  const { update, isUpdating } = useUpdateRate(expenditureTypeId);
  const companyId = useSelector((state) =>
    state?.user?.data?.ledger?.companyId
  );

  const handleSubmit = (form) => {
    const data = {
      ...form,
      projectExpenditureTypeId: expenditureTypeId,
      referenceTypeId: type?.projectCategoryId,
      effectiveDateFrom: dayjs(form?.effectiveDate[0]).toDate(),
      effectiveDateTo: dayjs(form?.effectiveDate?.[1]).toDate(),
    };

    if (isNew) {
      create(data, {
        onSuccess: () => {
          message.success("Created rate successfully");
          closeModal();
        },
      });
    } else {
      update({
        ...data,
        id: rateId,
        expenditureType: expenditureTypeId,
        uom: data?.projectUnitOfMeasurementId,
        companyId,
      }, {
        onSuccess: () => {
          message.success("Updated rate successfully");
          closeModal();
        },
      });
    }
  };

  const closeModal = () => close("rateId", "expenditureTypeId");

  return (
    <OwnModal
      title="Define Rate"
      open={isNew || isEdit}
      onCancel={closeModal}
      width={1000}
    >
      {isFetching ? <OwnModalLoader /> : (
        <Form
          initialValues={isEdit ? initialValues : null}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div>
            <div className="col-2 py-4">
              {type?.projectCategoryId === NonLabor
                ? (
                  <Form.Item
                    label="Resource Name"
                    name="cost"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                )
                : (
                  <Form.Item
                    label="Resource Name"
                    name="referenceId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="label"
                      options={resources}
                    />
                  </Form.Item>
                )}
              <Form.Item
                label="Unit of Measure"
                name="projectUnitOfMeasurementId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  options={uoms?.map((uom) => ({
                    label: uom?.nameEN,
                    value: uom?.id,
                  }))}
                />
              </Form.Item>

              <Form.Item
                label="Rate Type"
                name="rateType"
                rules={[{ required: true }]}
              >
                <Select
                  options={[
                    {
                      label: "Payroll Rate",
                      value: 0,
                    },
                    {
                      label: "Fixed Rate",
                      value: 1,
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Effective Date"
                name="effectiveDate"
                rules={[{ required: true }]}
              >
                <DatePicker.RangePicker />
              </Form.Item>

              <Form.Item
                label="Rate"
                name="rate"
                rules={[{ required: true }]}
              >
                <InputNumber min={0} className="w-full" />
              </Form.Item>

              {type?.projectCategoryId !== NonLabor && (
                <Form.Item
                  label="Cost"
                  name="cost"
                  rules={[{ required: true }]}
                >
                  <Input className="w-full" />
                </Form.Item>
              )}
            </div>
          </div>

          <Form.Item className="my-0 py-0">
            <Button
              loading={isUpdating || isCreating}
              disabled={isUpdating || isCreating}
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              {isNew ? "Create" : "Update"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </OwnModal>
  );
}

export default RateModal;

import { ClockCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from 'utils/utils';

function GoalCard({ goal }) {
  return (
    <Link to={`details/goalId`}>
      <div className=" border shadow rounded max-w-full  h-full overflow-hidden relative">
        <div className="p-2 text-xs leading-normal">
          <h5 className="text-sm capitalize font-medium">{goal?.nameEN}</h5>
          <p className="">{goal?.employeeId}</p>
          <div className="flex justify-between items-center">
            <p>
              <ClockCircleOutlined style={{ fontSize: 12, marginInlineEnd: 5 }} />
              {dateFormat(goal?.deadlineDate)}
            </p>
            <Button type="primary" className="!p-0 !text-xs inline-flex items-center justify-center !min-h-[auto] !text-white !w-6 !h-6">
              {(goal?.employeeId).slice(0, 1)}
            </Button>
          </div>
        </div>
        {goal?.isDone && (
          <span className="w-20 bg-green-500 shadow flex items-center justify-center text-white capitalize font-semibold text-xs absolute top-3 -end-5 rotate-45">
            Done
          </span>
        )}
      </div>
    </Link>
  );
}

export default GoalCard;

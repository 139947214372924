import { css } from '@emotion/css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import ModalReceiptApply from 'components/modals/ModalReceiptApply';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import dayjs from 'dayjs';
import useCurrencyList from 'hooks/api/useCurrencyList';
import useCustomerList from 'hooks/api/useCustomerList';
import useMetaData from 'hooks/api/useMetaData';
import { createReceipt, getReceipt, updateReceipt } from 'network/account-receivable/receipt';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ReceiptNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [isApplyMod, setIsApplyMod] = useState(false);
  const { currencyList } = useCurrencyList();
  const { customerList } = useCustomerList();

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const metadata = useMetaData();
  console.log('ReceiptNew  selectedCustomer:', selectedCustomer);

  const client = useQueryClient();
  const { data: receipteData, isLoading: receipteDataLod } = useQuery([queryKeys.receiptList, params?.id], () => getReceipt(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('receipteData', receipteData);

  const onSuccess = res => {
    console.log('createReceipt  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.receiptList]);

    form.resetFields();
    navigate('/account-receivable/receipts');
  };

  const { mutate: onCreateReceipt, isLoading: createReceiptLod } = useMutation(createReceipt, { onSuccess });
  const { mutate: onUpdateReceipt, isLoading: updateReceiptLod } = useMutation(updateReceipt, { onSuccess });

  useEffect(() => {
    if (isEdit && receipteData) {
      // console.log('useEffect  editItem:', editItem);
      const editItem = receipteData?.model || {};
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, receipteData]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['date'] = dayjs(dataObj['date']).format(constents.dateFormat);
    dataObj['glDate'] = dayjs(dataObj['glDate']).format(constents.dateFormat);
    dataObj['isBillingSystemSource'] = !!dataObj['isBillingSystemSource'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = receipteData?.model?.id;
      return onUpdateReceipt(dataObj);
    }

    onCreateReceipt(dataObj);
  };

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb items={[{ title: <Link to="/account-receivable/receipts">Receipts</Link> }]} current={`${isEdit ? 'Edit' : 'New'} Receipt`} />

        <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-24 mb-4">Receipt</h2>

              <div className="col-2">
                <Form.Item name="receiptMethodId" label="Receipt Method" rules={[{ required: true }]}>
                  <Select options={metadata?.receiptMethods?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                </Form.Item>
                <Form.Item name="number" label="Receipt Number" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="amount"
                  label="Receipt Amount"
                  rules={[{ required: true }]}
                  className={css`
                    .ant-input-group-addon {
                      padding: 0;
                    }
                  `}>
                  <InputNumber
                    className="w-full"
                    addonBefore={
                      <Form.Item name="currencyId" className="!mb-0 w-36" rules={[{ required: true }]}>
                        <Select
                          className={css`
                            .ant-select-selector {
                              font-size: 14px;
                            }
                          `}
                          options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN }))}
                        />
                      </Form.Item>
                    }
                  />
                </Form.Item>
                <Form.Item name="receiptTypeId" label="Receipt Type" rules={[{ required: true }]}>
                  <Select options={metadata?.receiptTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                </Form.Item>
                <Form.Item name="date" label="Receipt Date" rules={[{ required: true }]}>
                  <DatePicker className="w-full" />
                </Form.Item>
                <Form.Item name="glDate" label="GL Date" rules={[{ required: true }]}>
                  <DatePicker className="w-full" />
                </Form.Item>
              </div>
            </div>
            <div className="col-span-3 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-20 mb-2">Balances</h2>
              {/* <Form layout="vertical" size="small"> */}
              <Form.Item name="Unidentified" label="Unidentified">
                <Input disabled />
              </Form.Item>
              <Form.Item name="Applied" label="Applied">
                <Input disabled />
              </Form.Item>
              <Form.Item name="Unapplied" label="Unapplied">
                <Input disabled />
              </Form.Item>
              {/* </Form> */}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-24 mb-4">Customer</h2>
              <Form.Item name="customerId" label="Customer" rules={[{ required: true }]}>
                <Select
                  onChange={(_, option) => setSelectedCustomer(option?.item)}
                  options={customerList?.map(s => ({
                    value: s?.id,
                    label: s?.name + ` (${s.number})`,
                    item: s,
                  }))}
                />
              </Form.Item>
              <div className="col-2">
                <Form.Item label="Name">
                  <Input readOnly value={selectedCustomer?.name} />
                </Form.Item>
                <Form.Item label="Number">
                  <Input readOnly value={selectedCustomer?.number} />
                </Form.Item>
                <Form.Item label="Location">
                  <Input readOnly value={selectedCustomer?.location} />
                </Form.Item>
                <Form.Item label="Taxpayer ID">
                  <Input readOnly value={selectedCustomer?.taxpayerId} />
                </Form.Item>
              </div>
            </div>
            <div className="col-span-3 bg-white py-8 px-8 rounded-lg">
              <h2 className="title-20 mb-2">Customer Bank</h2>
              {/* <Form layout="vertical" size="small"> */}
              <Form.Item name="Name" label="Name">
                <Input disabled />
              </Form.Item>
              <Form.Item name="Account" label="Account">
                <Input disabled />
              </Form.Item>
              {/* </Form> */}
            </div>
          </div>
          <div className="col-span-9 bg-white py-8 px-8 rounded-lg">
            {/* <h2 className="title-24 mb-4">Customer</h2> */}

            <Form.Item name="reference" label="Reference">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>

          <Space className="flex justify-end mt-4">
            <Button type="primary" htmlType="submit" loading={createReceiptLod || updateReceiptLod}>
              {isEdit ? 'Update' : 'Confirm'}
            </Button>

            <Button type="primary" onClick={() => setIsApplyMod(true)}>
              Apply
            </Button>

            <Button type="primary" ghost onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </Space>
        </Form>
      </div>
      <ModalReceiptApply open={isApplyMod} onCancel={() => setIsApplyMod(false)} />
    </>
  );
}

export default ReceiptNew;

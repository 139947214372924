import { useQuery } from "@tanstack/react-query";
import { getInventoryConfiguration } from "network/supply-chain/inventoryConfiguration";
import { queryKeys } from "services/react-query/queryKeys";

const useInventoryConfiguration = () => {
  const query = useQuery({
    queryFn: getInventoryConfiguration,
    queryKey: [queryKeys.inventoryConfiguration],
    select: (data) => data?.model,
  });

  return query;
};

export default useInventoryConfiguration;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addCategories,
  getCategories,
} from "network/product-categories/categoriesProduct";
import { getProducts } from "network/products/products";
import { queryKeys } from "services/react-query/queryKeys";

export const useProuducts = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.products, id],
    queryFn: () => getProducts(id),
  });
  const productsData = data?.model;
  return { productsData, ...rest };
};

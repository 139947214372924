import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllPaymentTerms = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/PaymentTerms?${searchQuery}`);
  return data;
});

export const getPaymentTerms = catchAsync(async id => {
  const { data } = await axiosInstance(`/PaymentTerms/${id}`);
  return data;
});

export const createPaymentTerms = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/PaymentTerms`, body);
  return data;
});

export const updatePaymentTerms = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/PaymentTerms`, body);
  return data;
});

export const deletePaymentTerms = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/PaymentTerms/${id}`);
  return data;
});

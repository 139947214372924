import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createOperationType } from "network/supply-chain/operationTypes";
import { queryKeys } from "services/react-query/queryKeys";

const useCreateOperationType = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: createOperationType,
    queryKey: [queryKeys.operationTypes],
    onSuccess: () => client.invalidateQueries([queryKeys.operationTypes]),
  });

  mutation.isCreating = mutation.isLoading;
  mutation.create = mutation.mutate;
  return mutation;
};

export default useCreateOperationType;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, DatePicker, Divider, Form, Input, Select, Space } from 'antd';
import OwnModal from 'components/own/OwnModal';
import dayjs from 'dayjs';
import useCurrencyList from 'hooks/api/useCurrencyList';
import usePeriodList from 'hooks/api/usePeriodList';
import { createBudget } from 'network/general-ledger/budget';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function ModalBudget({ open, onCancel, editItem, setEditItem }) {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const userData = useSelector(s => s.user?.data);
  console.log('ModalBudget  userData:', userData);
  const { periodList, periodListLod } = usePeriodList();
  console.log('BudgetDefine  periodList:', periodList);

  const enableSecutiry = Form.useWatch('enableSecurity', form);
  const { currencyList } = useCurrencyList();

  const client = useQueryClient();
  const { mutate: onCreateBudget, isLoading: createBudgetLod } = useMutation(createBudget, {
    onSuccess: res => {
      console.log('ModalBudget  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.budgetList]);

      form.resetFields();
      onCancel();
    },
  });

  // useEffect(() => {
  //   if (editItem) {
  //     console.log('useEffect  editItem:', editItem);
  //     const dataObj = { ...editItem };
  //     dataObj['fromTo'] = [dayjs(dataObj['from']), dayjs(dataObj['to'])];
  //     form.setFieldsValue(dataObj);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [editItem?.id]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['enableSecurity'] = !!dataObj['enabled'];
    dataObj['latestOpenYear'] = dayjs(dataObj['latestOpenYear']).format('YYYY');
    console.log('ModalBudget  dataObj:', dataObj);
    // return;
    onCreateBudget(dataObj);
  };
  return (
    <OwnModal title={editItem ? 'Edit Budget' : 'Add Budget'} open={open} onCancel={onCancel} width={1200} style={{ top: 20 }}>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          ledgerId: userData?.ledger?.id,
          companyId: userData?.legalEntity?.companyId,
          latestOpenYear: dayjs('2020'),
        }}>
        <div className="col-3">
          <Form.Item name="nameEN" label="Budget" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="ledgerId" label="Ledger" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  value: userData?.ledger?.id,
                  label: userData?.ledger?.nameEN,
                },
              ]}
            />
          </Form.Item>

          <Form.Item name="status" label="Status" rules={[{ required: true }]}>
            <Select
              options={[
                { value: 0, label: 'open' },
                { value: 1, label: 'freezed' },
              ]}
            />
          </Form.Item>

          <Form.Item name="currencyId" label="Currency" rules={[{ required: true }]}>
            <Select options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea autoSize={{ maxRows: 1 }} />
          </Form.Item>
        </div>
        <Divider>
          <h4 className="title-18 mb-4">Budget Periods</h4>
        </Divider>
        <div className="col-3">
          <Form.Item name="periodFromId" label="First" rules={[{ required: true }]}>
            <Select
              loading={periodListLod}
              options={periodList?.map(p => ({
                value: p?.id,
                label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
              }))}
            />
          </Form.Item>
          <Form.Item name="periodToId" label="Last" rules={[{ required: true }]}>
            <Select
              loading={periodListLod}
              options={periodList?.map(p => ({
                value: p?.id,
                label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
              }))}
            />
          </Form.Item>
          <Form.Item name="latestOpenYear" label="Latest Open Year">
            <DatePicker.YearPicker disabled />
          </Form.Item>
        </div>
        <Divider>
          <h4 className="title-18 mb-4">Budget Organization</h4>
        </Divider>
        <div className="col-2">
          <Form.Item name="companyId" label="Organization" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  value: userData?.legalEntity?.companyId,
                  label: 'Our Company',
                },
              ]}
            />
          </Form.Item>
          {/* <Form.Item
            name="Ordering Segment"
            label="Ordering Segment"
            rules={[{ required: true }]}
          >
            <Select loading />
          </Form.Item> */}

          <Form.Item name="enableSecurity" valuePropName="checked">
            <Checkbox>Enable Secutiry</Checkbox>
          </Form.Item>
          {!!enableSecutiry && (
            <>
              <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                <Input.Password placeholder="Write Here" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                  }),
                ]}
                hasFeedback>
                <Input.Password placeholder="Write Here" />
              </Form.Item>
            </>
          )}
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit">
            Create Budget
          </Button>
        </Space>
      </Form>
    </OwnModal>
  );
}

export default ModalBudget;

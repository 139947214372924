import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addCategories,
  getCategories,
} from "network/product-categories/categoriesProduct";
import { queryKeys } from "services/react-query/queryKeys";

export const useCategories = (id) => {
  const { data, ...rest } = useQuery({
    queryKey: [queryKeys.categories, id],
    queryFn: () => getCategories(id),
  });
  const categoriesData = data?.model;
  return { categoriesData, ...rest };
};

export const useCreateCategorie = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: addCategories,
    onSuccess:()=>client.invalidateQueries([queryKeys.categories])
  });
  mutation.createCategorie = mutation.mutate;
  mutation.isCreatingCategorie = mutation.isLoading;
  return mutation;
};

import { Button, Checkbox, Form, Input, message, Select, Spin } from "antd";
import OwnModal from "components/own/OwnModal";
import useCreateWarehouseLocation from "hooks/api/supply-chain/warehouseLocation/useCreateWarhouseLocation";
import useUpdateWarehouseLocation from "hooks/api/supply-chain/warehouseLocation/useUpdateWarehouseLocation";
import useWarehouseLocation from "hooks/api/supply-chain/warehouseLocation/useWarehouseLocation";
import useMetaData from "hooks/api/useMetaData";
import useSearchQuery from "hooks/useSearchQuery";

export default function ModalLocation(props) {
  const [form] = Form.useForm();
  const { locationTypes } = useMetaData();
  const { searchQueryObj, setSearchQuery } = useSearchQuery();
  const { open, mode, id } = searchQueryObj;
  const isEditMode = mode === "edit";
  const { create, isCreating } = useCreateWarehouseLocation();
  const { update, isUpdating } = useUpdateWarehouseLocation();
  const { data, isFetching } = useWarehouseLocation({
    enabled: isEditMode,
    id,
  });

  const close = () => {
    setSearchQuery({});
    form.resetFields();
  };

  const handleOnFinish = (values) => {
    mode === "new"
      ? create(values, {
        onSuccess: () => {
          message.success("Created warehouse location successfully");
          close();
        },
        onError: () => {
          message.error("Couldn't create the warehouse location");
        },
      })
      : update({ ...values, id: data?.id }, {
        onSuccess: () => {
          message.success("Updated warehouse location successfully");
          close();
        },
        onError: () => {
          message.error("Couldn't update the warehouse location");
        },
      });
  };

  return (
    <OwnModal
      {...props}
      title={mode === "new"
        ? "Add Warehouse Location "
        : "Edit Warehouse Location "}
      open={open === "true"}
      onCancel={close}
    >
      {(isFetching && mode === "edit")
        ? (
          <div className="flex flex-col gap-5 items-center justify-center py-36">
            <Spin />
            <p>Fetching Data</p>
          </div>
        )
        : (
          <Form
            form={form}
            layout="vertical"
            initialValues={isEditMode ? data : {}}
            onFinish={handleOnFinish}
            preserve={false}
          >
            <div className="grid md:grid-cols-2 gap-x-9">
              <Form.Item
                label="Location Name"
                name="nameEN"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Location Type"
                name="locationTypeId"
                rules={[{
                  required: true,
                }]}
              >
                <Select
                  options={locationTypes?.map((type) => ({
                    label: type?.nameEN,
                    value: type?.id,
                  }))}
                />
              </Form.Item>

              <div>
                <Form.Item name="isScrapLocation" valuePropName="checked">
                  <Checkbox checked={Boolean(data?.isScrapLocation)}>
                    Is a Scrap Location
                  </Checkbox>
                </Form.Item>
                <Form.Item name="isReturnLocation" valuePropName="checked">
                  <Checkbox checked={Boolean(data?.isReturnLocation)}>
                    Is a Return Location
                  </Checkbox>
                </Form.Item>
                <Form.Item name="isReplenishLocation" valuePropName="checked">
                  <Checkbox checked={Boolean(data?.isReplenishLocation)}>
                    Replenish location
                  </Checkbox>
                </Form.Item>
              </div>
            </div>

            <div className="btn-wrraper mt-4 text-center ">
              <Button
                loading={isCreating || isUpdating}
                type="primary"
                htmlType="submit"
                className="block m-auto w-full max-w-xs"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
    </OwnModal>
  );
}

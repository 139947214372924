import { EditOutlined } from '@ant-design/icons';
import { Button, Radio, Result, Space } from 'antd';
import ButtonAddAddress from 'components/buttons/ButtonAddAddress';
import ModalAddress from 'components/modals/ModalAddress';
import { useState } from 'react';
import { useSelector } from 'react-redux';

function AddressWrapper({ selectedAddressId, setSelectedAddressId }) {
  const userData = useSelector(s => s?.user?.data);

  const [isAddressMod, setIsAddressMod] = useState(false);
  const [editItem, setEditItem] = useState(null);

  return (
    <>
      <div>
        <h2 className="title-24 mb-4">Select Address</h2>
        <div>
          {
            // addressListLod ? (
            //   arrayFrom(3)?.map((_, i) => (
            //     <Skeleton.Button
            //       key={i}
            //       active
            //       className="!w-full !h-16"
            //     />
            //   ))
            // ) :
            !userData?.address ? (
              <Result title="No addresses yet!" />
            ) : (
              <Radio.Group
                className="block space-y-3"
                onChange={({ target }) => {
                  setSelectedAddressId(target.value);
                  // const address = addressList?.find(el=>el?.id === target.value)
                  // setCurrentAddress(address);
                }}
                value={selectedAddressId}>
                <Radio.Button value={userData?.address?.id} className="w-full h-auto py-4">
                  <div className="w-full flex justify-between ">
                    <p className="">{userData?.address?.address1}</p>
                    <Space>
                      <Button type="link" icon={<EditOutlined />} onClick={() => setEditItem(userData?.address)} />
                      {/* <Popconfirm title="Are you sure?" onConfirm={() => onAddressDelete(userData?.address?.id)}>
                        <Button type="link" icon={<DeleteOutlined />} loading={addressDeleteLod} />
                      </Popconfirm> */}
                    </Space>
                  </div>
                </Radio.Button>
              </Radio.Group>
            )
          }
        </div>
        {!userData?.address && (
          <div className="mt-6 text-end">
            <ButtonAddAddress />
          </div>
        )}
      </div>
      <ModalAddress
        open={isAddressMod || !!editItem}
        onCancel={() => {
          setEditItem(null);
          setIsAddressMod(false);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
    </>
  );
}

export default AddressWrapper;

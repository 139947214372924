import { SwapOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import ModalReceiptApply from 'components/modals/ModalReceiptApply';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import dayjs from 'dayjs';
import useCurrencyList from 'hooks/api/useCurrencyList';
import useCustomerList from 'hooks/api/useCustomerList';
import useMetaData from 'hooks/api/useMetaData';
import { createReceipt, getReceipt, updateReceipt } from 'network/account-receivable/receipt';
import { createDailyRate, getDailyRate, updateDailyRate } from 'network/general-ledger/daily-rate';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';

function DailyRateNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();

  const { message } = App.useApp();
  const [form] = Form.useForm();
  const { currencyList } = useCurrencyList();

  const metadata = useMetaData();

  const [currancyFrom, setCurrancyFrom] = useState('');
  const [currancyTo, setCurrancyTo] = useState('');

  const client = useQueryClient();
  const { data: dailyRateData, isLoading: dailyRateDataLod } = useQuery([queryKeys.dailyRateList, params?.id], () => getDailyRate(params?.id), {
    enabled: !!params?.id,
  });
  // console.log('dailyRateData', dailyRateData);

  const onSuccess = res => {
    console.log('createDailyRate  res:', res);
    message.success(res?.metas?.message);
    client.invalidateQueries([queryKeys.dailyRateList]);

    form.resetFields();
    navigate('/general-ledger/currency/daily-rate');
  };

  const { mutate: onCreateDailyRate, isLoading: createDailyRateLod } = useMutation(createDailyRate, { onSuccess });
  const { mutate: onUpdateDailyRate, isLoading: updateDailyRateLod } = useMutation(updateDailyRate, { onSuccess });

  useEffect(() => {
    if (isEdit && dailyRateData) {
      // console.log('useEffect  editItem:', editItem);
      const editItem = dailyRateData?.model || {};
      const dataObj = { ...editItem };
      dataObj['fromTo'] = [dayjs(dataObj['fromDate']), dayjs(dataObj['toDate'])];

      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, dailyRateData]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };
    dataObj['fromDate'] = dayjs(dataObj['fromTo']?.[0]).format(constents.dateFormat);
    dataObj['toDate'] = dayjs(dataObj['fromTo']?.[1]).format(constents.dateFormat);
    delete dataObj['fromTo'];

    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = dailyRateData?.model?.id;
      return onUpdateDailyRate(dataObj);
    }

    onCreateDailyRate(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: <Link to="/general-ledger/currency/daily-rate">Daily Rate</Link> }]} current={`${isEdit ? 'Edit' : 'New'} Daily Rate`} />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <div className="col-2">
            <Form.Item name="fromCurrencyId" label="From Currency">
              <Select
                options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN, item: s }))}
                onChange={(_, option) => setCurrancyFrom(option?.item?.nameEN)}
              />
            </Form.Item>
            <Form.Item name="toCurrencyId" label="To Currency">
              <Select
                options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN, item: s }))}
                onChange={(_, option) => setCurrancyTo(option?.item?.nameEN)}
              />
            </Form.Item>
            <Form.Item name="fromTo" label="From - To" rules={constents.rulesRequeried}>
              <DatePicker.RangePicker className="w-full" format="YYYY-MM-DD" disabledDate={constents.disabledDate} />
            </Form.Item>
            <Form.Item name="currencyDailyRateConversionTypeId" label="Type">
              <Select options={metadata?.currencyDailyRateConversionTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
            </Form.Item>
            <Form.Item
              name="fromToCurrencyValue"
              label={
                <div className="space-x-2">
                  <span className="text-sm text-red-500">{currancyFrom || '(From)'}</span>
                  <SwapOutlined />
                  <span className="text-sm text-green-500">{currancyTo || '(To)'}</span>
                </div>
              }>
              <Input />
            </Form.Item>
            <Form.Item
              name="toFromCurrencyValue"
              label={
                <div className="space-x-2">
                  <span className="text-sm text-red-500">{currancyTo || '(From)'}</span>
                  <SwapOutlined />
                  <span className="text-sm text-green-500">{currancyFrom || '(To)'}</span>
                </div>
              }>
              <Input />
            </Form.Item>
          </div>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createDailyRateLod || updateDailyRateLod}>
            {isEdit ? 'Update' : 'Confirm'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default DailyRateNew;

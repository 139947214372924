import { FileOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Badge, Button, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import constents from 'utils/constants';
import useMetaData from 'hooks/api/useMetaData';
import useCurrencyList from 'hooks/api/useCurrencyList';
import usePeriodList from 'hooks/api/usePeriodList';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import useAccountList from 'hooks/api/useAccountList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createManualJournal, createRecurringJournal } from 'network/general-ledger/journals';
import { queryKeys } from 'services/react-query/queryKeys';

function JournalRecurringNew({ isEdit }) {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const metadata = useMetaData();
  const { currencyList, currencyListLod } = useCurrencyList();
  const { periodList, periodListLod } = usePeriodList();
  const { accountList, accountListLod } = useAccountList();
  const userData = useSelector(s => s.user?.data);
  const enableSecutiry = Form.useWatch('enableSecurity', form);

  const client = useQueryClient();
  const { mutate: onCreateRecurringJournal, isLoading: createRecurringJournalLod } = useMutation(createRecurringJournal, {
    onSuccess: res => {
      console.log('BudgetNew  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.journalManualList]);

      form.resetFields();
      navigate('/general-ledger/journals/automatic');
    },
  });

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['enableSecurity'] = !!dataObj['enableSecurity'];

    dataObj['journals'] = dataObj?.names?.map(el => ({
      nameEN: el.nameEN,
      description: el?.description,
      effectiveDateFrom: dayjs(el['fromTo']?.[0]).format(constents.dateFormat),
      effectiveDateTo: dayjs(el['fromTo']?.[1]).format(constents.dateFormat),
      conversionTypeId: el?.conversionTypeId,
      periodId: el?.periodId,
      ledgerId: el?.ledgerId,
      categoryId: el?.categoryId,
      accountTypeId: el?.accountTypeId,
      journalStatusId: el?.journalStatusId,
      currencyId: el?.currencyId,
      //
      controlTotal: 0,
    }));
    delete dataObj['names'];

    dataObj['recurringExcutionSchedules'] = dataObj?.recurringExcutionSchedules?.map(el => ({
      excutionDate: dayjs(el.excutionDate).format(constents.dateFormat),
      isRecurring: !!el?.isRecurring,
    }));

    console.log('BudgetNew  dataObj:', dataObj);
    // return;
    onCreateRecurringJournal(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        items={[{ title: <Link to="/general-ledger/journals/automatic">Automatic Journals</Link> }]}
        current={`${isEdit ? 'Edit' : 'New'} Automatic Journal`}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ ledgerId: userData?.ledger?.id, names: [''], recurringExcutionSchedules: [''] }}>
        <div className="space-y-6">
          <div className="bg-white py-8 px-8 rounded-lg">
            <h2 className="title-24 mb-4">Recurning Batch</h2>
            <div className="col-2">
              <Form.Item name="nameEN" label="Batch" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="description" label="Descreption">
                <Input.TextArea autoSize={{ maxRows: 1 }} />
              </Form.Item>
              <Form.Item name="recurringBatchTypeId" label="Recurring Batch Type" rules={[{ required: true }]}>
                <Select options={metadata?.recurringBatchTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
              </Form.Item>

              <Form.Item name="ledgerId" label="Ledger" rules={[{ required: true }]}>
                <Select
                  options={[
                    {
                      value: userData?.ledger?.id,
                      label: userData?.ledger?.nameEN,
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="lastExcutedPeriodId" label="Last Excuted Period" rules={[{ required: true }]}>
                <Select
                  loading={periodListLod}
                  options={periodList?.map(p => ({
                    value: p?.id,
                    label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                  }))}
                />
              </Form.Item>
              <Form.Item name="enableSecurity" valuePropName="checked">
                <Checkbox>Enable Secutiry</Checkbox>
              </Form.Item>
              {!!enableSecutiry && (
                <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                  <Input.Password />
                </Form.Item>
              )}
            </div>
            {/* <h2 className="title-20 mb-2">Last Executed</h2>
          <div className="col-2">
            <Form.Item name="Period" label="Period">
              <Input />
            </Form.Item>
            <Form.Item name="Date" label="Date">
              <Input />
            </Form.Item>
          </div> */}
            <Button type="primary">AUTO COPY </Button>
          </div>
          <div className="bg-white py-8 px-8 rounded-lg">
            <Divider>Excution Schedules</Divider>

            <Form.List name="recurringExcutionSchedules">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <div className="bg-gray-50 p-3 rounded items-end">
                          <div className="col-2">
                            <Form.Item name={[index, 'excutionDate']} label="ExcutionDate" rules={[{ required: true }]}>
                              <DatePicker />
                            </Form.Item>
                            <Form.Item name={[index, 'isRecurring']} valuePropName="checked">
                              <Checkbox>Recurring</Checkbox>
                            </Form.Item>
                          </div>
                        </div>
                        {fields.length > 1 ? (
                          <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                            Remove Above Field
                          </Button>
                        ) : null}
                      </div>
                    ))}
                    <Divider />
                    <Form.Item>
                      <Form.Item>
                        <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                      </Form.Item>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </div>
          <Form.List name="names">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      <div className="bg-white py-8 px-8 rounded-lg">
                        <Divider>Journal Entry {index + 1}</Divider>
                        <div className="col-3">
                          <Form.Item name={[index, 'nameEN']} label="Journal" rules={[{ required: true }]}>
                            <Input />
                          </Form.Item>
                          <Form.Item name={[index, 'categoryId']} label="Category" rules={[{ required: true }]}>
                            <Select options={metadata?.categories?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>
                          <Form.Item name={[index, 'accountTypeId']} label="Method" rules={[{ required: true }]}>
                            <Select options={metadata?.accountTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>

                          <Form.Item name={[index, 'description']} label="Descreption">
                            <Input.TextArea autoSize={{ maxRows: 1 }} />
                          </Form.Item>
                          <Form.Item name={[index, 'fromTo']} label="Effective Dates From - To" rules={[{ required: true }]}>
                            <DatePicker.RangePicker className="w-full" format="YYYY-MM-DD" disabledDate={constents.disabledDate} />
                          </Form.Item>
                          <Form.Item name={[index, 'conversionTypeId']} label="Conversion Type" rules={[{ required: true }]}>
                            <Select options={metadata?.conversionTypes?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>
                          <Form.Item name={[index, 'journalStatusId']} label="Stauts" rules={[{ required: true }]}>
                            <Select options={metadata?.journalStatuses?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>

                          <Form.Item name={[index, 'ledgerId']} label="Ledger" rules={[{ required: true }]}>
                            <Select
                              options={[
                                {
                                  value: userData?.ledger?.id,
                                  label: userData?.ledger?.nameEN,
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name={[index, 'currencyId']} label="Currency" rules={[{ required: true }]}>
                            <Select loading={currencyListLod} options={currencyList?.map(s => ({ value: s?.id, label: s?.nameEN }))} />
                          </Form.Item>

                          <Form.Item name={[index, 'periodId']} label="Period" rules={[{ required: true }]}>
                            <Select
                              loading={periodListLod}
                              options={periodList?.map(p => ({
                                value: p?.id,
                                label: p?.prefix + ` (${dayjs(p.from).format(constents.dateFormat)}/${dayjs(p.to).format(constents.dateFormat)})`,
                              }))}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {fields.length > 1 ? (
                        <Button type="danger" className="dynamic-delete-button" onClick={() => remove(field.name)} icon={<MinusCircleOutlined />}>
                          Remove Above Field
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <Button type="primary" size="large" onClick={add} icon={<PlusOutlined />} />
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </div>

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createRecurringJournalLod}>
            APPROVE
          </Button>
          <Button type="primary" ghost htmlType="reset" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default JournalRecurringNew;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  create,
  delete_,
  update,
} from "network/project-managments/projects/tasks";
import { queryKeys } from "services/react-query/queryKeys";

export const useCreateTask = (projectId, projectManagerId) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: create,
    onSuccess: () => client.invalidateQueries([queryKeys.projects, projectId]),
  });

  mutation.quickCreateTask = () => {
    mutation.mutate({
      projectId,
      name: "New Task",
      plannedStartDate: new Date(),
      plannedFinishDate: new Date(),
      transactionStartDate: new Date(),
      transactionFinishDate: new Date(),
      taskManagerId: projectManagerId,
    });
  };

  return mutation;
};

export const useEditTask = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: update,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.tasks]);
      client.invalidateQueries([queryKeys.projects]);
    },
  });

  mutation.editTask = mutation.mutate;
  return mutation;
};

export const useDeleteTask = (projectId) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: delete_,
    onSuccess: () => client.invalidateQueries([queryKeys.projects, projectId]),
  });

  mutation.deleteTask = mutation.mutate;
  return mutation;
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import {
  createSubTask,
  deleteSubTask,
  updateSubTask,
} from "network/project-managments/projects/subTasks";

export const useSubtaskMutation = () => {
  const { quickCreateSubtask, isCreating } = useCreateSubtask();
  const { updateSubtask, isUpdating } = useUpdateSubtask();
  const { deleteSubtask, isDeleting } = useDeleteSubtask();

  return {
    quickCreateSubtask,
    isCreating,
    updateSubtask,
    isUpdating,
    deleteSubtask,
    isDeleting,
  };
};

export const useCreateSubtask = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: createSubTask,
    onSuccess: () => client.invalidateQueries(queryKeys.projects),
  });

  mutation.quickCreateSubtask = (projectTaskId, subTaskManagerId) => {
    mutation.mutate({
      name: "New Sub-Task",
      number: "123",
      plannedStartDate: new Date(),
      plannedFinishDate: new Date(),
      transactionStartDate: new Date(),
      transactionFinishDate: new Date(),
      subTaskManagerId,
      projectTaskId,
    });
  };

  mutation.createSubtask = mutation.mutate;
  mutation.isCreating = mutation.isLoading;
  return mutation;
};

export const useUpdateSubtask = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateSubTask,
    onSuccess: () => client.invalidateQueries(queryKeys.projects),
  });

  mutation.updateSubtask = mutation.mutate;
  mutation.isUpdating = mutation.isLoading;

  return mutation;
};

export const useDeleteSubtask = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteSubTask,
    onSuccess: () => client.invalidateQueries(queryKeys.projects),
  });

  mutation.deleteSubtask = mutation.mutate;
  mutation.isDeleting = mutation.isLoading;

  return mutation;
};

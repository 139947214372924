import { useQuery } from "@tanstack/react-query";
import { getAllEmployees } from "network/Human-resources/employees";
import { queryKeys } from "services/react-query/queryKeys";

export const useEmployees = () => {
  const query = useQuery({
    queryFn: getAllEmployees,
    queryKey: [queryKeys?.employees],
  });

  query.employees = query.data?.model;

  return query;
};

import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Space } from 'antd';
import { analysisIcon } from 'assets/icons';
import { arrayFrom, randomNumber } from 'utils/utils';
function SalesOverview() {
  const [dataaa, setData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch('https://gw.alipayobjects.com/os/bmw-prod/360c3eae-0c73-46f0-a982-4746a6095010.json')
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => {
        console.log('fetch data failed', error);
      });
  };
  const data = {
    labels: ['Profit', 'Expance', 'Revenue'],
    datasets: [
      {
        // label: '# of Votes',
        data: arrayFrom(3)?.map(() => randomNumber(1, 10)),
        backgroundColor: ['#49beff', '#5d87ff', '#999'],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 1,
        // spacing: 24,
      },
    ],
  };
  return (
    <div className="bg-white p-4 border rounded-xl shadow">
      <div>
        <h4 className="text-xl font-medium">Sales Overview</h4>
        <p className="text-gray-600">Every Month</p>
      </div>

      <div className="my-4 flex justify-center">
        <Doughnut
          data={data}
          //   data={{ datasets: [{}] }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
      <div className="flex justify-between gap-2 flex-wrap">
        {/*  */}
        <Space>
          <span className="inline-block w-6 border rounded p-0.5 border-[#49beff] text-[#49beff]">{analysisIcon}</span>
          <div className="flex flex-col">
            <span className="font-medium">$23,450</span>
            <span className="text-sm text-gray-600">Profit</span>
          </div>
        </Space>
        {/*  */}
        <Space>
          <span className="inline-block w-6 border rounded p-0.5 border-[#5d87ff] text-[#5d87ff]">{analysisIcon}</span>
          <div className="flex flex-col">
            <span className="font-medium">$23,450</span>
            <span className="text-sm text-gray-600">Expance</span>
          </div>
        </Space>
      </div>
    </div>
  );
}

export default SalesOverview;

import { useQueries, useQuery } from "@tanstack/react-query";
import useMetaData from "hooks/api/useMetaData";
import { getOperationTypes } from "network/supply-chain/operationTypes";
import { getWarehouse } from "network/supply-chain/warehouse";
import { queryKeys } from "services/react-query/queryKeys";

const useOperationTypes = () => {
  const { reservationMethods } = useMetaData();
  const query = useQuery({
    queryFn: getOperationTypes,
    queryKey: [queryKeys.operationTypes],
    select: (data) => data?.model,
  });

  const queries = useQueries({
    queries: query?.data?.map(({ warehouseId }) => ({
      queryFn: () => getWarehouse(warehouseId),
      queryKey: [queryKeys.warehouse, warehouseId],
    })) ?? [],
  });

  const operationTypes = query?.data?.map((operationType, index) => ({
    ...operationType,
    warehouse: queries?.[index]?.data?.model,
    reservationMethod: reservationMethods?.filter(({ id }) =>
      id === operationType?.reservationMethodId
    )[0],
  }));

  query.operationTypes = operationTypes;
  return query;
};

export default useOperationTypes;

import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getAllStructureTypes = catchAsync(async (searchQuery = '') => {
  const { data } = await axiosInstance(`/StructureTypes?${searchQuery}`);
  return data;
});

export const getStructureType = catchAsync(async id => {
  const { data } = await axiosInstance(`/StructureTypes/${id}`);
  return data;
});

export const createStructureType = catchAsync(async body => {
  const { data } = await axiosInstance.post(`/StructureTypes`, body);
  return data;
});

export const updateStructureType = catchAsync(async body => {
  const { data } = await axiosInstance.put(`/StructureTypes`, body);
  return data;
});

export const deleteStructureType = catchAsync(async id => {
  const { data } = await axiosInstance.delete(`/StructureTypes/${id}`);
  return data;
});

import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Table,
  message,
} from "antd";
import OwnModal from "components/own/OwnModal";
import React, { useState } from "react";
import ModalContactAccount from "./ModalContactAccount";
import constents, { AccountUseOptions } from "utils/constants";
import useMetaData from "hooks/api/useMetaData";
import dayjs from "dayjs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "services/react-query/queryKeys";
import { getAllCurrencies } from "network/general-ledger/currency";
import {
  createBankAccounts,
  updateBankAccounts,
} from "network/cash-managment/bank";

function ModalBank({ open, onCancel, editItem, setEditItem }) {
  const [form] = Form.useForm();
  const client = useQueryClient();
  const [isContactAccount, setIsContactAccount] = useState(false);
  const [contactAccountList, setContactAccountList] = useState([]);
  const { bankBranchTypes, countries } = useMetaData();

  const { mutate: onCreateBank, isLoading } = useMutation(createBankAccounts, {
    onSuccess: (res) => {
      console.log("ModalPeriod  res:", res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.bankList]);
      form.resetFields();
      onCancel();
    },
  });

  const { mutate: onUpdatebank, isLoading: updatebankLod } = useMutation(
    updateBankAccounts,
    {
      onSuccess: (res) => {
        console.log("Modalbank  res:", res);
        message.success(res?.metas?.message);
        client.invalidateQueries([queryKeys.bankList]);

        form.resetFields();
        onCancel();
      },
    }
  );

  const { data: currencyData } = useQuery([queryKeys.currencyList, {}], () =>
    getAllCurrencies({})
  );
  const onFinishAddBank = (values) => {
    console.log("ModalStructure  values:", values);
    const body = {
      ...values,
      isUsedForPayables: values?.AccountUse?.some(
        (s) => s === "isUsedForPayables"
      ),
      isUsedForPayroll: values?.AccountUse?.some(
        (s) => s === "isUsedForPayroll"
      ),
      isUsedForReceivables: values?.AccountUse?.some(
        (s) => s === "isUsedForReceivables"
      ),
      countryId: "462493b3-2081-4eab-bec3-852b04d69278",
      inactiveDate: dayjs(values?.inactiveDate).format(constents.dateFormat),
    };

    if (editItem) {
      body.id = editItem.id;
      onUpdatebank(body);
    } else {
      onCreateBank(body);
    }
  };

  const dataSource = [
    {
      key: "1",
      name: "Bill Payment",
      phone: "010343432222",
      email: "test@gamil.com",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <OwnModal
        title={editItem ? "Edit Bank" : "Add Bank"}
        open={open}
        onCancel={onCancel}
        width={902}
      >
        <Form
          layout="vertical"
          form={form}
          // size="large"
          onFinish={onFinishAddBank}
          // className="pt-4"
        >
          <Divider>Bank Information</Divider>

          <div className="col-2">
            <Form.Item
              name="accountNumber"
              label="Account Number"
              rules={[{ required: true }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name="countryId"
              label="Country"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                options={countries?.map((s) => ({
                  value: s?.id,
                  label: s?.nameEN,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="bankName"
              label="Bank Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="shortBankName"
              label="Short Bank Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item name="Bank Address" label="Bank Address">
              <Input />
            </Form.Item> */}
          </div>
          <Divider>Branch Information</Divider>

          <div className="col-2">
            <Form.Item
              name="branchName"
              label="Branch Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="bankBranchTypeId"
              label="Branch Type"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                options={bankBranchTypes?.map((s) => ({
                  value: s?.id,
                  label: s?.nameEN,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="branchAddress"
              label="Branch Address"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="ediLocation"
              label="EDI location"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>

          <Divider>Account Information</Divider>
          <div className="col-2">
            <Form.Item
              name="accountName"
              label="Account Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="accountHolder"
              label="Account Holder"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="iban" label="IBAN" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="inactiveDate" label="Inactive Date">
              <DatePicker disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              name="AccountUse"
              label="Account Use"
              rules={[{ required: true }]}
            >
              <Checkbox.Group options={AccountUseOptions} />
            </Form.Item>
            <Form.Item
              name="currencyId"
              label="Currency"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                options={currencyData?.model?.map((s) => ({
                  value: s?.id,
                  label: s?.nameEN,
                }))}
              />
            </Form.Item>
          </div>
          {contactAccountList?.length ? (
            <>
              <Divider>Contact Accounts</Divider>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={{ position: ["none"] }}
              />
            </>
          ) : null}
          <div className="col-1">
            <Button type="link" onClick={() => setIsContactAccount(true)}>
              + Add Contact Account
            </Button>
          </div>

          <Button
            loading={isLoading || updatebankLod}
            type="primary"
            htmlType="submit"
            className="block ms-auto w-full max-w-xs mt-16"
            // onClick={() => {
            //   onCancel();
            // }}
          >
            DONE
          </Button>
        </Form>
      </OwnModal>
      <ModalContactAccount
        open={isContactAccount}
        onCancel={() => setIsContactAccount(false)}
      />
    </>
  );
}

export default ModalBank;

import ActionsMenu from "components/common/ActionsMenu";
import { useNavigate } from "react-router-dom";
import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Button, Table, Tag } from "antd";
import ModalCategoriesProduct from "components/modals/inventoryModals/products/ModalCategoriesProduct";
import { useCategories } from "hooks/products/useCategoryProduct";

export default function CategoriesProduct() {
  const [CategoriesProduct, setCategoriesProduct] = useState(false);
  const navigate = useNavigate();

  const { categoriesData } = useCategories();
  // incomeAccountId companyId
  const columns = [
    {
      title: "Product Categories",
      dataIndex: "nameEN",
      key: "nameEN",
    },
    {
      title: "Company Id",
      dataIndex: "companyId",
      key: "companyId",
    },
    {
      title: "Income Account Id",
      dataIndex: "incomeAccountId",
      key: "incomeAccountId",
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Categories" />
        <SearchHero onAddBtn={() => setCategoriesProduct(true)} />
        <Table
          dataSource={categoriesData}
          sticky
          columns={columns}
          pagination={{
            pageSize: 10,
            // current: 1,
            // onChange: (page) => {
            //   const queryObj = { ...searchQueryObj };
            //   queryObj["page"] = page;
            //   setSearchQuery(queryObj);
            // },
          }}
        />
      </div>
      <ModalCategoriesProduct
        open={CategoriesProduct}
        onCancel={() => setCategoriesProduct(false)}
      />
    </>
  );
}

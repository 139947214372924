import { useQuery } from '@tanstack/react-query';
import { getAllRecurringJournals } from 'network/general-ledger/journals';
import { queryKeys } from 'services/react-query/queryKeys';

function useJournalRecurringList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.journalRecurringList, searchQueryStr], () => getAllRecurringJournals(searchQueryStr));

  const journalRecurringList = data?.model;

  return {
    journalRecurringList,
    journalRecurringListLod: isLoading,
  };
}

export default useJournalRecurringList;

import { useQuery } from '@tanstack/react-query';
import { getAllCreditInvoices } from 'network/account-receivable/credit-invoice';
import { queryKeys } from 'services/react-query/queryKeys';

function useCreditInvoiceList(searchQueryStr) {
  const { data, isLoading } = useQuery(
    [queryKeys.creditInvoiceList, searchQueryStr],
    () => getAllCreditInvoices(searchQueryStr)
  );

  const creditInvoiceList = data?.model;

  return {
    creditInvoiceList,
    creditInvoiceListLod: isLoading,
  };
}

export default useCreditInvoiceList;

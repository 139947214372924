import { useQuery } from '@tanstack/react-query';
import { getAllAPDistributions } from 'network/account-payable/distributions';
import { queryKeys } from 'services/react-query/queryKeys';

function useAPDistributionList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.APDistributionList, searchQueryStr], () => getAllAPDistributions(searchQueryStr));

  const APDistributionList = data?.model;

  return {
    APDistributionList,
    APDistributionListLod: isLoading,
  };
}

export default useAPDistributionList;

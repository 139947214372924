import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Space, Tag } from 'antd';
import { analysisIcon } from 'assets/icons';
import { arrayFrom, randomNumber } from 'utils/utils';
const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
function WeeklyStats() {
  const labels = days;
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'First dataset',
        data: arrayFrom(7)?.map(() => randomNumber(1, 10)),
        fill: true,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      },
      {
        label: 'Second dataset',
        data: arrayFrom(7)?.map(() => randomNumber(1, 10)),
        fill: false,
        borderColor: '#742774',
      },
    ],
  };
  const config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
      },
    },
  };
  return (
    <div className="bg-white p-4 border rounded-xl shadow">
      <div>
        <h4 className="text-xl font-medium">Weekly Stats</h4>
        <p className="text-gray-600">Average sales</p>
      </div>

      <div className="my-6 flex justify-center">
        <Line {...config} />
      </div>
      <div className="flex flex-col justify-between gap-3 max-w-3xl mx-auto">
        {/*  */}
        <div className="flex items-center justify-between">
          <Space>
            <span className="inline-block w-6 border rounded p-0.5 border-blue-500 text-blue-500">{analysisIcon}</span>
            <div className="flex flex-col">
              <span className="font-medium">Top Sales</span>
              <span className="text-sm text-gray-600">Johnathan Doe</span>
            </div>
          </Space>

          <Tag className="font-medium bg-blue-50 !text-blue-500 border-gray-100">+68</Tag>
        </div>
        {/*  */}
        <div className="flex items-center justify-between">
          <Space>
            <span className="inline-block w-6 border rounded p-0.5 border-green-500 text-green-500">{analysisIcon}</span>
            <div className="flex flex-col">
              <span className="font-medium">Best Seller</span>
              <span className="text-sm text-gray-600">Footware</span>
            </div>
          </Space>

          <Tag className="font-medium bg-green-50 !text-green-500 border-gray-100">+68</Tag>
        </div>
        {/*  */}
        <div className="flex items-center justify-between">
          <Space>
            <span className="inline-block w-6 border rounded p-0.5 border-orange-500 text-orange-500">{analysisIcon}</span>
            <div className="flex flex-col">
              <span className="font-medium">Most Commented</span>
              <span className="text-sm text-gray-600">Fashionware</span>
            </div>
          </Space>

          <Tag className="font-medium bg-orange-50 !text-orange-500 border-gray-100">+68</Tag>
        </div>
      </div>
    </div>
  );
}

export default WeeklyStats;

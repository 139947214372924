import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import dayjs from 'dayjs';
import useCurrencyList from 'hooks/api/useCurrencyList';
import useCustomerList from 'hooks/api/useCustomerList';
import useMetaData from 'hooks/api/useMetaData';
import useSalesInvoiceList from 'hooks/api/useSalesInvoiceList';
import { createCreditInvoice, getCreditInvoice, updateCreditInvoice } from 'network/account-receivable/credit-invoice';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';
import constents from 'utils/constants';
import { disabledDateBeforeToday } from 'utils/utils';
import TransactioAmountItem from './TransactioAmountItem';

function CreditInvoiceNew({ isEdit }) {
  const navigate = useNavigate();
  const params = useParams();
  // console.log('CreditInvoiceNew  params:', params);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  // console.log('CreditInvoiceNew  userData:', userData);

  const client = useQueryClient();
  const { data: creditInvoiceData, isLoading: creditInvoiceLod } = useQuery([queryKeys.creditInvoice, params?.id], () => getCreditInvoice(params?.id), {
    enabled: !!params?.id,
  });
  console.log('creditInvoiceData', creditInvoiceData);
  const { mutate: onCreateCreditInvoice, isLoading: createCreditInvoiceLod } = useMutation(createCreditInvoice, {
    onSuccess: res => {
      console.log('CreditInvoiceNew  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.creditInvoiceList]);

      form.resetFields();
      navigate(-1);
    },
  });
  const { mutate: onUpdateCreditInvoice, isLoading: updateCreditInvoiceLod } = useMutation(updateCreditInvoice, {
    onSuccess: res => {
      console.log('CreditInvoiceNew  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.creditInvoiceList]);

      form.resetFields();
      navigate(-1);
    },
  });

  const { currencyList } = useCurrencyList();
  const { salesInvoiceList } = useSalesInvoiceList();
  const { customerList } = useCustomerList();
  const metadata = useMetaData();
  // console.log('CreditInvoiceNew  metadata:', metadata);
  console.log('CreditInvoiceNew  selectedTransaction:', selectedTransaction);
  console.log('CreditInvoiceNew  metadata?.creditInvoiceLineTypes:', metadata?.creditInvoiceLineTypes);

  useEffect(() => {
    if (isEdit) {
      // console.log('useEffect  editItem:', editItem);
      const editItem = creditInvoiceData?.model || {};
      const dataObj = { ...editItem };
      dataObj['date'] = dayjs(dataObj['date']);
      dataObj['glDate'] = dayjs(dataObj['glDate']);
      form.setFieldsValue(dataObj);
    } else {
      form.resetFields();
    }
  }, [isEdit, creditInvoiceData?.model, form]);

  const onFinish = values => {
    console.log('onFinish  values:', values);
    const dataObj = { ...values };

    dataObj['date'] = dayjs(dataObj['date']).format(constents.dateFormat);
    dataObj['glDate'] = dayjs(dataObj['glDate']).format(constents.dateFormat);
    dataObj['isBillingSystemSource'] = !!dataObj['isBillingSystemSource'];
    console.log('CreditInvoiceNew  dataObj:', dataObj);
    // return;

    if (isEdit) {
      dataObj.id = creditInvoiceData?.model?.id;
      return onUpdateCreditInvoice(dataObj);
    }
    onCreateCreditInvoice(dataObj);
  };

  return (
    <div className="space-y-6">
      <OwnBreadcrumb
        // noHome
        items={[
          {
            title: <Link to="/account-receivable/invoices/credit-invoices">Credit Invoices</Link>,
          },
        ]}
        current={`${isEdit ? 'Edit' : 'New'} Credit Invoice`}
      />

      <Form layout="vertical" form={form} onFinish={onFinish} className="space-y-6">
        <div className="bg-white py-8 px-8 rounded-lg">
          <h2 className="title-24 mb-4">Credited Memo</h2>

          <div className="col-2">
            <Form.Item name="batchNumber" label="Batch" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="batchName" label="Batch Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="customerId" label="Customer" rules={[{ required: true }]}>
              <Select
                options={customerList?.map(s => ({
                  value: s?.id,
                  label: s?.name + ` (${s.number})`,
                }))}
              />
            </Form.Item>
            <Form.Item name="date" label="Date" rules={[{ required: true }]}>
              <DatePicker className="w-full" disabledDate={disabledDateBeforeToday} />
            </Form.Item>
            <Form.Item name="glDate" label="GL-Date" rules={[{ required: true }]}>
              <DatePicker className="w-full" disabledDate={disabledDateBeforeToday} />
            </Form.Item>
            <Form.Item name="number" label="Number" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="reference" label="Reference" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="salesInvoiceId" label="Transaction" rules={[{ required: true }]}>
              <Select
                onChange={(_, option) => setSelectedTransaction(option?.item)}
                options={salesInvoiceList?.map(s => ({
                  value: s?.id,
                  label: s?.number,
                  item: s,
                }))}
              />
            </Form.Item>

            <Form.Item name="currencyId" label="Currency" rules={[{ required: true }]}>
              <Select
                options={currencyList?.map(s => ({
                  value: s?.id,
                  label: s?.nameEN,
                }))}
              />
            </Form.Item>
            <Form.Item name="reason" label="Reason">
              <Input.TextArea autoSize={{ maxRows: 1 }} />
            </Form.Item>
          </div>
        </div>

        {!!selectedTransaction && (
          <div className="bg-white py-8 px-8 rounded-lg">
            <h2 className="title-24 mb-4">Transaction amounts</h2>

            {metadata?.creditInvoiceLineTypes?.map((el, index) => (
              <TransactioAmountItem
                key={el?.id}
                item={el}
                index={index}
                amountTypes={metadata?.amountTypes}
                original={selectedTransaction?.salesOrderLines?.find(s => s?.lineType === el?.id)?.amount}
                form={form}
              />
              // <div key={el?.id}>
              //   <h4 className="title-20 mb-2">{el?.nameEN}</h4>
              //   <Form.Item
              //     name={['creditInvoiceLines', index, 'creditInvoiceLineTypeId']}
              //     className="hidden"
              //     initialValue={el?.id}
              //   >
              //     <Input />
              //   </Form.Item>
              //   <div className="col-4">
              //     <Form.Item
              //       name={['creditInvoiceLines', index, 'amountTypeId']}
              //       label="Amount type"
              //     >
              //       <Select
              //         value={amountType}
              //         onChange={value => setAmountType(value)}
              //         options={metadata?.amountTypes?.map(s => ({
              //           value: s?.id,
              //           label: s?.nameEN,
              //         }))}
              //       />
              //     </Form.Item>
              //     <Form.Item
              //       name={['creditInvoiceLines', index, 'amount']}
              //       label="Amount"
              //     >
              //       <InputNumber
              //         suffix={amountType === 'percentage' && '%'}
              //         controls={false}
              //         className="w-full"
              //       />
              //     </Form.Item>
              //     <Form.Item
              //       name={['creditInvoiceLines', index, 'originalAmount']}
              //       label="Original"
              //       initialValue={1000}
              //     >
              //       <InputNumber controls={false} className="w-full" disabled />
              //     </Form.Item>
              //     <Form.Item
              //       name={['creditInvoiceLines', index, 'balanceDue']}
              //       label="Balance Due"
              //     >
              //       <InputNumber controls={false} className="w-full" disabled />
              //     </Form.Item>
              //   </div>
              // </div>
            ))}
          </div>
        )}

        <Space className="flex justify-end mt-4">
          <Button type="primary" htmlType="submit" loading={createCreditInvoiceLod}>
            {isEdit ? 'Update' : 'Complete'}
          </Button>

          <Button type="primary" ghost onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default CreditInvoiceNew;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import {
  create,
  delete_,
  get,
  getAll,
  update,
} from "network/project-managments/setup/unitsOfMeasures";
import { queryKeys } from "services/react-query/queryKeys";

export const useUnitOfMeasure = (id) => {
  const query = useQuery({
    queryFn: () => get(id),
    queryKey: [queryKeys.unitsOfMeasurements, id],
    enabled: Boolean(id),
  });

  query.uom = query.data?.model;

  query.initialValues = {
    ...query.uom,
    effectiveDate: [
      dayjs(query.uom?.effectiveDateFrom),
      dayjs(query.uom?.effectiveDateTo),
    ],
  };
  return query;
};

export const useUnitOfMeasures = () => {
  const query = useQuery({
    queryFn: getAll,
    queryKey: [queryKeys.unitsOfMeasurements],
  });

  query.uoms = query.data?.model;
  return query;
};

export const useCreateUnitOfMeasure = () => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: create,
    onSuccess: () => client.invalidateQueries([queryKeys.unitsOfMeasurements]),
  });

  mutation.create = mutation.mutate;
  mutation.isCreate = mutation.isLoading;
  return mutation;
};

export const useUpdateUnitOfMeasure = (id) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: update,
    onSuccess: () => {
      client.invalidateQueries([queryKeys.unitsOfMeasurements]);
      client.invalidateQueries([queryKeys.unitsOfMeasurements, id]);
    },
  });

  mutation.update = mutation.mutate;
  mutation.isUpdating = mutation.isLoading;
  return mutation;
};

export const useDeleteUnitOfMeasure = (id) => {
  const client = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => delete_(id),
    onSuccess: () => {
      client.invalidateQueries([queryKeys.unitsOfMeasurements]);
      client.invalidateQueries([queryKeys.unitsOfMeasurements, id]);
    },
  });

  mutation.delete_ = mutation.mutate;
  mutation.isDeleting = mutation.isLoading;
  return mutation;
};

import React from "react";
import { Link } from "react-router-dom";

import logo from "assets/images/demo-logo.webp";

function BrandLogo() {
  return (
    <Link to="/">
      <img src={logo} alt="" className="max-h-[60px]" />
    </Link>
  );
}

export default BrandLogo;

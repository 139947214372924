import SearchHero from "components/common/SearchHero";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Table, Tag } from "antd";
import InternalModal from "components/modals/inventoryModals/internal/InternalModal";

function Internal() {
  const [internalModal, setInternalModal] = useState(false);

  const dataSource = [
    {
      reference: "WH/IN/00001",
      from: "Partners/Vendors",
      to: "WH/Stock",
      contact: "xyz",
      scheduledDate: "2023/9/13",
      sourceDocument: "P000001",
      status: "done",
    },
    {
      reference: "WH/IN/00002",
      from: "Partners/Vendors",
      to: "WH/Stock",
      contact: "abc",
      scheduledDate: "2023/10/13",
      sourceDocument: "P000002",
      status: "draft",
    },
  ];
  const columns = [
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Scheduled Date",
      dataIndex: "scheduledDate",
      key: "scheduledDate",
    },
    {
      title: "Source Document",
      dataIndex: "sourceDocument",
      key: "sourceDocument",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => (
        <Tag color={status === "done" ? "green" : "gray"}>
          {status.toUpperCase()}
        </Tag>
      ),
    },
  ];
  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Receipts" />
        <SearchHero onAddBtn={() => setInternalModal(true)} />
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{
            pageSize: 10,
          }}
          sticky
        />
      </div>
      <InternalModal
        open={internalModal}
        onCancel={() => setInternalModal(false)}
      />
    </>
  );
}

export default Internal;

import BankS from 'pages/cash-management/BankS/BankS';
import BankStatmenet from 'pages/cash-management/BankStatmenet/BankStatmenet';
import BankTransfer from 'pages/cash-management/BankTransfer/BankTransfer';

const routesCashManagement = [
  {
    path: 'cash-management',
    children: [
      {
        index: true,
        element: <BankStatmenet />,
      },
      {
        path: 'banks',
        element: <BankS />,
      },
      {
        path: 'statement',
        element: <BankStatmenet />,
      },
      {
        path: 'transfers',
        element: <BankTransfer />,
      },
    ],
  },
];

export default routesCashManagement;

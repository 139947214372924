import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Switch, Table } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import ModalAddSegment from 'components/modals/ModalAddSegment';
import ModalSegmentQualifiers from 'components/modals/ModalSegmentQualifiers';
import ModalSegmentValueSet from 'components/modals/ModalAddValueSet';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import React, { useState } from 'react';
import { queryKeys } from 'services/react-query/queryKeys';
import useGLSegmentList from 'hooks/api/useFixedAssetSegmentList';
import { deleteGLSegment } from 'network/general-ledger/GLSegments';

function Segments() {
  const { message } = App.useApp();
  const client = useQueryClient();
  const [iseModalUpdat, setIseModalUpdat] = useState(false);
  const [isSetValueMod, setIsSetValueMod] = useState(false);
  const [isQualifiersMod, setIsQualifiersMod] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const { GLSegmentList } = useGLSegmentList();

  const { mutate: onDeleteSegment } = useMutation(deleteGLSegment, {
    onSuccess: res => {
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.GLSegmentList]);
    },
  });

  const columns = [
    {
      title: 'number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'column',
      dataIndex: 'column',
      key: 'column',
    },
    {
      title: 'Displayed',
      key: 'isDisplayed',
      dataIndex: 'isDisplayed',
      render: val => <Switch checked={val} />,
    },
    {
      title: 'Enable',
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      render: val => <Switch checked={val} />,
    },
  ].concat([
    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            // {
            //   key: 'value-set',
            //   label: 'Value set',
            //   onClick: () => setIsSetValueMod(true),
            // },
            {
              key: 'qualifiers',
              label: 'Qualifiers',
              onClick: () => setIsQualifiersMod(true),
            },
            {
              key: 'Edit',
              label: 'Edit',
              onClick: () => setEditItem(record),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteSegment(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ]);

  const data = GLSegmentList;

  return (
    <>
      <div className="space-y-6">
        <OwnBreadcrumb current="Segments" />
        <SearchHero
          onAddBtn={() => {
            setIseModalUpdat(true);
          }}
        />
        <Table dataSource={data} sticky columns={columns} />
      </div>
      <ModalAddSegment
        open={iseModalUpdat || !!editItem}
        onCancel={() => {
          setIseModalUpdat(false);
          setEditItem(null);
        }}
        editItem={editItem}
        setEditItem={setEditItem}
      />
      <ModalSegmentValueSet open={isSetValueMod} onCancel={() => setIsSetValueMod(false)} />
      <ModalSegmentQualifiers open={isQualifiersMod} onCancel={() => setIsQualifiersMod(false)} />
    </>
  );
}

export default Segments;

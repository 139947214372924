import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Switch, Table, App } from 'antd';
import ActionsMenu from 'components/common/ActionsMenu';
import SearchHero from 'components/common/SearchHero';
import OwnBreadcrumb from 'components/own/OwnBreadcrumb';
import useAppraisalQuestionList from 'hooks/api/useAppraisalQuestionList';
import { deleteAppraisalQuestion } from 'network/Human-resources/appraisalQuestions';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'services/react-query/queryKeys';

function Questions() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const client = useQueryClient();
  const { mutate: onDeleteStructure } = useMutation(deleteAppraisalQuestion, {
    onSuccess: res => {
      console.log('onSuccess  res:', res);
      message.success(res?.metas?.message);
      client.invalidateQueries([queryKeys.appraisalQuestionList]);
    },
  });

  const { appraisalQuestionList } = useAppraisalQuestionList();

  const columns = [
    {
      key: 'nameEN',
      dataIndex: 'nameEN',
      title: 'Name',
    },
    {
      key: 'questionTypeId',
      dataIndex: 'questionTypeId',
      title: 'Question Type',
    },

    {
      key: 'isDeleted',
      dataIndex: 'isDeleted',
      title: 'Deleted',
      render: (val, _) => {
        return <Switch checked={val} />;
      },
    },

    {
      title: 'actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <ActionsMenu
          items={[
            {
              key: 'edit',
              label: 'Edit',
              onClick: () => navigate(`edit/${record.id}`),
              disabled: record?.isDeleted,
            },
            {
              key: 'Delete',
              label: 'Delete',
              onClick: () => onDeleteStructure(record?.id),
              disabled: record?.isDeleted,
            },
          ]}
        />
      ),
    },
  ];
  const data = appraisalQuestionList;

  return (
    <div className="space-y-6">
      <OwnBreadcrumb items={[{ title: 'Human Resources' }, { title: 'Setup' }, { title: 'Appraisals' }]} current="Questions" />
      <SearchHero onAddBtn={() => navigate('new')} />
      <Table dataSource={data} sticky columns={columns} />
    </div>
  );
}

export default Questions;

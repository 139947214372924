import { Button } from 'antd';
import React, { useState } from 'react';
import ModalAddress from 'components/modals/ModalAddress';

function ButtonAddAddress() {
  const [isAddressMod, setIsAddressMod] = useState(false);
  return (
    <>
      <Button type="primary" onClick={() => setIsAddressMod(true)}>
        Add new address
      </Button>
      <ModalAddress
        open={isAddressMod}
        onCancel={() => setIsAddressMod(false)}
      />
    </>
  );
}

export default ButtonAddAddress;

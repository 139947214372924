import { SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select } from "antd";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Budget() {
  const [isDrafted, setIsDrafted] = useState(false);

  const breadcrumbItems = [
    {
      title: "Project Management",
    },
    {
      title: "Budget",
      href: "budget",
    },
  ];
  return (
    <>
      <OwnBreadcrumb items={breadcrumbItems} />
      <section className="bg-white rounded-[9px] p-8 mt-7">
        <h1 className="font-bold text-2xl">Budget</h1>
        <Divider className="py-4">
          Find Budget
        </Divider>
        <Form layout="vertical">
          <div className="col-2">
            <Form.Item label="Project Name">
              <Select
                options={[
                  {
                    label: "Project 1",
                    value: 1,
                  },
                  {
                    label: "Project 2",
                    value: 2,
                  },
                  {
                    label: "Project 3",
                    value: 3,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label="Budget Type">
              <Input defaultValue="Cost Budget" />
            </Form.Item>
          </div>

          <Divider className="py-4">
            Draft Budget
          </Divider>

          <div className="col-3">
            <Form.Item label="Version Name">
              <Select
                options={[
                  {
                    label: "Draft",
                    value: 1,
                  },
                  {
                    label: "Filed",
                    value: 2,
                  },
                ]}
                value={isDrafted ? 2 : 1}
                disabled
              />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => setIsDrafted(true)}
                type="primary"
                className="px-10"
                disabled={isDrafted}
              >
                File Draft
              </Button>
            </Form.Item>
          </div>
          <Form.Item className="mt-5 flex justify-end">
            <Link to="budget-details/11">
              <Button
                className="py-6 px-10"
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                Details
              </Button>
            </Link>
          </Form.Item>
        </Form>
      </section>
    </>
  );
}

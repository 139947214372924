import { useQuery } from '@tanstack/react-query';
import { getAllSuppliers } from 'network/account-payable/supplier';
import { queryKeys } from 'services/react-query/queryKeys';

function useSupplierList(searchQueryStr) {
  const { data, isLoading } = useQuery([queryKeys.supplierList, searchQueryStr], () => getAllSuppliers(searchQueryStr));

  const supplierList = data?.model;

  return {
    supplierList,
    supplierListLod: isLoading,
  };
}

export default useSupplierList;

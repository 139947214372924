import { Button, Table, Tag } from "antd";
import SearchHero from "components/common/SearchHero";
import TagElement from "components/common/TagElement";
import ExpensesModal from "components/modals/human-resources/expenses/ExpensesModal";
import ApplicationModal from "components/modals/human-resources/setup/ApplicationModal";
import OwnBreadcrumb from "components/own/OwnBreadcrumb";
import dayjs from "dayjs";
import { useExpenses } from "hooks/human-resourses/useExpenses";
import { useJobApplication } from "hooks/human-resourses/useJobApplication";
import { useState } from "react";

export default function Expenses() {
  const { expensess } = useExpenses();
  console.log(expensess, "applicationData");
  // const { openAsNew, openAsEdit } = useModalParams();
  const [isOpen, setIsOpen] = useState(false);
  const [LocationId, setLocationId] = useState("");

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyId",
      key: "companyId",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "expenseDate",
      dataIndex: "expenseDate",
      render: (expenseDate ) => (
        <>
          <h2>{dayjs(expenseDate).format("YYYY-MM-DD")}</h2>
        </>
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Status",
      key: "Status",
      render: ({ expenseStatusId }) => <TagElement type={expenseStatusId} />,
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: ({ id }) => (
    //     <>
    //       <Button
    //         type="primary"
    //         ghost
    //         onClick={() => {
    //           setIsOpen({ id });
    //           setLocationId(id);
    //         }}
    //       >
    //         Edit
    //       </Button>
    //       {/* <Button type="primary" onClick={() => setIsOpen({ id })}>View</Button> */}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <div className="space-y-7">
        <OwnBreadcrumb current="Human Resources / Expenses " />
        <SearchHero
          onAddBtn={() => {
            setIsOpen(true);
            setLocationId("");
          }}
        />
        <Table sticky dataSource={expensess} columns={columns} />
      </div>
      <ExpensesModal
        open={isOpen}
        LocationId={LocationId}
        handelClose={() => setIsOpen(false)}
      />
    </>
  );
}

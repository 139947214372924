import { axiosInstance } from 'services/axiosInstance';
import catchAsync from 'utils/catchAsync';

export const getARSystemOptions = catchAsync(async () => {
  const { data } = await axiosInstance(`/AccountsReceivableSystemOptions`);
  return data;
});

export const updateARSystemOptions = catchAsync(async body => {
  const { data } = await axiosInstance.post(
    `/AccountsReceivableSystemOptions`,
    body
  );
  return data;
});
